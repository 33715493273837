import React from "react"
import TextInput from "../../Inputs/TextInput"
import TextArea from "../../Inputs/TextArea"
import "./style.scss"
import DatePicker from "../../Inputs/DatePicker"
import dayjs, { Dayjs } from "dayjs"
import QuestCreationNextBtn from "../QuestCreationNextBtn"
import { DatePickerProps, Tooltip, Select } from "antd"
import { ReactComponent as UploadIcon } from "../../../assets/icons/uploadIcon.svg"
import { ReactComponent as InfoIcon } from "../../../assets/icons/infoIcon.svg"
import { useAppSelector } from "../../../hooks/reduxHooks"
import inputErrorIcon from "../../../assets/icons/inputErrorIcon.svg"
import { BsCaretDownFill } from "react-icons/bs"
import { DefaultOptionType } from "antd/es/select"

const { Option } = Select

export default function QuestInfoStep({
  questName,
  setQuestName,
  questDescription,
  setQuestDescription,
  questDisplayUrl,
  setQuestDisplayUrl,
  questDisplayUrlChanged,
  setQuestDisplayUrlChanged,
  questCollection,
  setQuestCollection,
  endDate,
  setEndDate,
  questCreationStep,
  setQuestCreationStep,
  questEndDateActive,
  setQuestEndDateActive,
  tweetText,
  setTweetText,
  questMetaImage,
  setQuestMetaImage,
  setQuestMetaImageFile,
  onSubmit,
  isDisabled,
}: {
  questName: string
  setQuestName: (value: string) => void
  questDescription: string
  setQuestDescription: (value: string) => void
  questDisplayUrl: string
  setQuestDisplayUrl: (value: string) => void
  questDisplayUrlChanged: boolean
  setQuestDisplayUrlChanged: (value: boolean) => void
  questCollection: { value: string; uuid: string } | null
  setQuestCollection: ({ value, uuid }: { value: string; uuid: string }) => void
  endDate: Dayjs | null
  setEndDate: (value: Dayjs | null) => void
  questCreationStep: number
  setQuestCreationStep: (value: number) => void
  questEndDateActive: boolean
  setQuestEndDateActive: (value: boolean) => void
  tweetText: string
  setTweetText: (value: string) => void
  questMetaImage: string
  setQuestMetaImage: (value: string) => void
  setQuestMetaImageFile: (value: File) => void
  onSubmit: () => void
  isDisabled: boolean
}) {
  const selectedCommunity = useAppSelector((state) => state.overview.selectedCommunity)
  const questsInCommunity = useAppSelector((state) => state.questCreation.quests)
  const collections = useAppSelector((state) => state.overview.collections)

  const isEditing = useAppSelector((state) => state.questCreation.isEditing)
  const selectedQuestInfo = useAppSelector((state) => state.questCreation.selectedQuestInfo)
  const QuestDisplayUrlsInUse = isEditing
    ? questsInCommunity
        ?.filter((quest) => quest.uuid !== selectedQuestInfo?.uuid)
        ?.map((ele) => ele.display_path)
    : questsInCommunity?.map((ele) => ele.display_path)

  const isError = QuestDisplayUrlsInUse?.includes(questDisplayUrl)

  const disabledDate: DatePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day")
  }

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files?.length) return
    const url = URL.createObjectURL(files[0])
    setQuestMetaImage(url)
    setQuestMetaImageFile(files[0])
    // const copyOfBadges = membershipBadges.map((ele) => {
    //     return { ...ele }
    // })
    // if (isEditing) {
    //     copyOfBadges[badgeIndex].old_image_url =
    //         copyOfBadges[badgeIndex]?.image_url
    // }
    // copyOfBadges[badgeIndex].image_url = URL.createObjectURL(files[0])
    // copyOfBadges[badgeIndex].is_video = false
    // if (files[0].type === "video/mp4") {
    //     copyOfBadges[badgeIndex].is_video = true
    // }
    // copyOfBadges[badgeIndex].file = files[0]
    // setMembershipBadges(copyOfBadges)
    // if (isEditing && copyOfBadges[badgeIndex]?.old_image_url) {
    //     setIsBadgePhotoChanged(true)
    // }
  }

  const handleOptionFilterChange = (value: string, uuid: string) => {
    setQuestCollection({ value: value, uuid: uuid as string })
  }

  return (
    <div className="quest-info-step-container">
      <div className="quest-info-heading">Quest Overview</div>
      <div className="quest-info-inputs-container">
        <div className="quest-info-input-wrapper">
          <TextInput
            value={questName}
            label="Quest name"
            placeholder=""
            onChange={(e) => setQuestName(e.target.value)}
            onBlur={() => {
              if (questDisplayUrlChanged) return
              setQuestDisplayUrl(questName)
              // TODO: check if valid or not
            }}
          />
        </div>
        <div className="quest-info-input-wrapper">
          <TextArea
            value={questDescription}
            label="Quest description"
            placeholder=""
            rows={7}
            onChange={(e) => setQuestDescription(e.target.value)}
          />
        </div>
        <div className="quest-info-input-wrapper">
          <div className="display-path-input-container">
            <label className="display-path-input-label">
              Display URL
              <span>
                <Tooltip title="quest url">
                  <InfoIcon />
                </Tooltip>
              </span>
            </label>
            <div
              className={`display-path-fixed-text-wrapper ${
                questDisplayUrl.length ? "display-path-input-wrapper-filled" : ""
              } ${isError ? "display-path-input-wrapper-error" : ""}`}
            >
              {`${selectedCommunity?.name.replace(/ /g, "").toLocaleLowerCase()}.rep3.gg/quest/`}
              <div className="display-path-input-wrapper">
                <input
                  type="text"
                  className={`display-path-input ${
                    questDisplayUrl.length ? "display-path-input-filled" : ""
                  } ${isError ? "display-path-input-error" : ""}`}
                  placeholder=""
                  value={questDisplayUrl}
                  onChange={(e) => {
                    setQuestDisplayUrl(e.target.value)
                    setQuestDisplayUrlChanged(true)
                  }}
                  // onBlur={onBlur}
                  // TODO: check if valid or not on blur
                />
                <div className="display-path-input-right-wrapper">
                  {isError ? (
                    <div className="display-path-input-error-wrapper">
                      <div className="error-tooltip">this display url is already used</div>
                      <img src={inputErrorIcon} alt="" />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="quest-info-input-wrapper">
          <div className="quest-post-banner-label-row">
            <div className="quest-post-banner-label">Select Collection</div>
            <div>Optional</div>
          </div>
          <div className="select-container" >
            <Select
              popupClassName="create-quest-collection-switch-dropdown"
              suffixIcon={
                <div>
                  <BsCaretDownFill />
                </div>
              }
              onChange={(value, option: DefaultOptionType | DefaultOptionType[]) => {
                handleOptionFilterChange(value, (option as DefaultOptionType)?.key)
              }}
              value={questCollection?.value}
              placeholder="Select Collection"
            >
              {collections?.map((collection, id) => {
                return (
                  <Option
                    label={`quest_name-${id}`}
                    value={collection?.name}
                    key={collection?.uuid}
                  >
                    <div className="collection-filter-switch-dropdown-item">
                      <div className="collection-filter-switch-dropdown-item-left">
                        {collection?.name}
                      </div>
                    </div>
                  </Option>
                )
              })}
            </Select>
          </div>
        </div>
        <div className="quest-info-input-wrapper">
          <div className="end-date-picker-label-row">
            <div className="end-date-picker-label-left">
              <input
                type="checkbox"
                checked={questEndDateActive}
                onChange={(e) => setQuestEndDateActive(e.target.checked)}
              />
              <div>End Date</div>
            </div>
            <div className="end-date-picker-label-right">Optional</div>
          </div>
          <DatePicker
            value={endDate}
            placeholder=""
            disabledDate={disabledDate}
            onChange={(value) => {
              setEndDate(value)
            }}
          />
        </div>
        <div className="quest-info-input-wrapper">
          <div className="quest-post-banner-label-row">
            <div className="quest-post-banner-label">Quest Banner</div>
            <div>Optional</div>
          </div>
          <label htmlFor={`upload-file-input-post-banner`}>
            {questMetaImage.length ? (
              <img src={questMetaImage} alt="" className="quest-banner-uploaded-image-preview" />
            ) : (
              <div className="post-banner-upload-wrapper">
                <div className="upload-icon-wrapper">
                  <UploadIcon />
                </div>
                <div className="upload-text-wrapper">
                  <div className="upload-text-heading">Upload Asset</div>
                  <div className="upload-text-info">Recommended resolution 800*450</div>
                </div>
              </div>
            )}
          </label>
          <input
            type="file"
            accept="image/png, image/gif, image/jpeg, video/mp4, video/"
            id={`upload-file-input-post-banner`}
            className="upload-file-input-hidden"
            style={{ display: "none" }}
            onChange={(e) => onFileChange(e)}
          />
        </div>
        <div className="quest-info-input-wrapper">
          <TextArea
            value={tweetText}
            label="Twitter Share Text"
            placeholder=""
            rows={2}
            onChange={(e) => setTweetText(e.target.value)}
          />
        </div>
      </div>
      <div className="quest-info-next-btn-wrapper">
        <QuestCreationNextBtn
          btnText="Setup Requirements"
          questCreationStep={questCreationStep}
          setQuestCreationStep={setQuestCreationStep}
          onSubmit={onSubmit}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  )
}
