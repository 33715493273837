import React, { useEffect, useState } from "react"
import "./style.scss"
import { MdClose } from "react-icons/md"
import { ReactComponent as DownloadFile } from "../../assets/icons/file_save.svg"
import { ReactComponent as Rocket } from "../../assets/icons/rocket_launch.svg"
import { TaskGroup } from "../../types/quest"
import CustomDragger from "../CustomDragger"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import { submitCSVForStrategy } from "../../store/actions/overviewAction"
import { message } from "antd"
import Lottie from "react-lottie"
import animationLoadingData from "../../assets/lottie/buttonLoader.json"

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationLoadingData,
  renderer: "svg",
}

const UploadCSVModal = ({
  closeModal,
  questId
} // CSVTaskGroups,
: {
  closeModal: () => void
  questId :string
  // CSVTaskGroups: TaskGroup[]
}) => {
  const dispatch = useAppDispatch()
  const [uploadedFiles, setUploadedFiles] = useState<{
    [key: string]: { name: string; file: File }
  } | null>(null)
  const [isRunningStrategy, setIsRunningStrategy] = useState<boolean>(false)
  const selectedQuest = useAppSelector((state) => state.questCreation.selectedQuestInfo)
  const CSVTaskGroups = selectedQuest?.task_groups.filter(
    (group) => group?.tasks?.some((task) => task.adapter?.adapter_type === "CSV"),
  )

  const addFileToList = (uuid: string, fileName: string, file: File) => {
    const tempList = { ...uploadedFiles }
    setUploadedFiles({ ...tempList, [uuid]: { name: fileName, file: file } })
  }

  function omit(obj: { [key: string]: { name: string; file: File } }, keyToRemove: string) {
    const { [keyToRemove]: _, ...rest } = obj
    return rest
  }

  const removeFileFromList = (uuid: string) => {
    const tempList = uploadedFiles
    const newObj = omit(tempList as { [key: string]: { name: string; file: File } }, uuid)
    setUploadedFiles(newObj)
  }

  const submitCSV = () => {
    if (uploadedFiles && Object.keys(uploadedFiles).length > 0) {
      setIsRunningStrategy(true)
      Promise.all(
        Object.keys(uploadedFiles).map((taskUuid) =>
          dispatch(
            submitCSVForStrategy(
              uploadedFiles[taskUuid].name,
              taskUuid,
              uploadedFiles[taskUuid].file,
            ),
          ),
        ),
      )
        .then((res) => {
          if (res.filter((response) => response === false).length > 0) {
            message.error("Something went wrong")
          } else {
            message.success("Strategy Updated")
            closeModal()
          }
          setIsRunningStrategy(false)
        })
        .catch((e) => {
          setIsRunningStrategy(false)
        })
    }
  }
  return (
    <div className="CSV-modal">
      <div className="CSV-modal-container">
        <div className="CSV-header">
          <div className="CSV-header-text">Upload CSV</div>
          <MdClose onClick={() => closeModal()} />
        </div>
        <div className="CSV-support-text">
          Manage and synchronise tier member updates by uploading a CSV file in the provided format.
        </div>
        <div className="CSV-template-download">
          Download Template <DownloadFile />{" "}
        </div>
        <div className="draggers-container">
          {CSVTaskGroups?.map((groups, key) => {
            return groups.tasks.map((task, key) => {
              if (task.adapter?.adapter_type === "CSV") {
                return (
                  <div key={key}>
                    <div className="tier-number">
                      Tier {groups.tier} CSV- {task.requirement}
                    </div>
                    <CustomDragger
                      addFileToList={addFileToList}
                      removeFileFromList={removeFileFromList}
                      taskUuid={task.uuid}
                    />
                  </div>
                )
              }
              return null
            })
          })}
        </div>
        <div className="run-strategy-btn">
          <div>
            <button
              disabled={
                !(uploadedFiles && Object.keys(uploadedFiles).length > 0) || isRunningStrategy
              }
              onClick={submitCSV}
            >
              Run Strategy{" "}
              {isRunningStrategy ? (
                <Lottie options={lottieOptions} width={24} height={24} />
              ) : (
                <Rocket />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadCSVModal
