// import { ConnectButton } from "@rainbow-me/rainbowkit"
import React from "react"
import { MdOutlineClose } from "react-icons/md"
import "./style.scss"
import { useAppDispatch } from "../../../hooks/reduxHooks"
import { showToast } from "../../../store/actions/toastAction"
import WalletConnectButton from "../../ConnectButton"
import { useNavigate } from "react-router-dom"
import { questCreationActions } from "../../../store/reducers/questCreationSlice"
import { overviewActions } from "../../../store/reducers/overviewSlice"

export default function QuestCreationHeader({
  questCreationStep,
  setQuestCreationStep,
}: {
  questCreationStep: number
  setQuestCreationStep: (value: number) => void
}) {
  // TODO: if not connected first connect

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  return (
    <div className="quest-creation-header-container">
      <div className="quest-creation-header-left">
        <div className="quest-creation-heading">
          <MdOutlineClose
            onClick={() => {
              navigate("/")
              dispatch(questCreationActions.setIsEditing(false))
              dispatch(questCreationActions.setIsDuplicating(false))
              dispatch(questCreationActions.setIsCreatingInsideCollection(false))
              dispatch(overviewActions.setSelectedCollection(null))
            }}
          />
          New Quest Creation
        </div>
        <div className="quest-creation-nav">
          <div
            className={`quest-creation-nav-item ${
              questCreationStep === 1 ? "selected-nav-item" : ""
            }`}
            onClick={() => setQuestCreationStep(1)}
          >
            Overview
          </div>
          <div
            className={`quest-creation-nav-item ${
              questCreationStep === 2 ? "selected-nav-item" : ""
            }`}
            onClick={() => setQuestCreationStep(2)}
          >
            Requirements
          </div>
          <div
            className={`quest-creation-nav-item ${
              questCreationStep === 3 ? "selected-nav-item" : ""
            }`}
            onClick={() => setQuestCreationStep(3)}
          >
            Rewards
          </div>
          <div
            className={`quest-creation-nav-item ${
              questCreationStep === 4 ? "selected-nav-item" : ""
            }`}
            // onClick={() => setQuestCreationStep(4)}
          >
            Publish
          </div>
        </div>
      </div>
      <div className="quest-creation-header-right">
        <WalletConnectButton />
        {/* <ConnectButton.Custom>
          {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
            const connected = mounted && account && chain
            return (
              <div
                {...(!mounted && {
                  "aria-hidden": true,
                  style: {
                    opacity: 0,
                    pointerEvents: "none",
                    userSelect: "none",
                  },
                })}
              >
                {(() => {
                  if (!connected) {
                    return (
                      <button
                        onClick={openConnectModal}
                        type="button"
                        className="connect-wallet-btn"
                      >
                        Connect
                      </button>
                    )
                  }

                  if (chain.unsupported) {
                    return (
                      <button onClick={openChainModal} type="button" className="connect-wallet-btn">
                        Wrong network
                      </button>
                    )
                  }

                  return (
                    <div style={{ display: "flex", gap: 12 }}>
                      <button
                        onClick={openAccountModal}
                        type="button"
                        className="connect-wallet-btn"
                      >
                        {account.displayName}
                      </button>
                    </div>
                  )
                })()}
              </div>
            )
          }}
        </ConnectButton.Custom> */}
      </div>
    </div>
  )
}
