import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ISubmitFormData, IUpdateSubmitFormData, IOverviewPageQuests } from "../../types/quest"
import { Community, CommunityWithCustomFields } from "../../types/community"
import { ICommunityOnChainGraphDayData, ISmartInsightsCount } from "../../types/graph"
import { TApiStatus } from "../../types/common"
import { Collection } from "../../types/collection"

interface SubmitActionPayloadType {
  formId: string
  formData: ISubmitFormData[]
}
export interface OverViewState {
  activeCampaignsAndQuests: IOverviewPageQuests[] | null
  userCommunities: CommunityWithCustomFields[] | null
  selectedCommunity: CommunityWithCustomFields | null
  communityOnChainGraphData: ICommunityOnChainGraphDayData[] | null
  smartInsightsData: ISmartInsightsCount | null
  fetchingCommunitiesStatus: TApiStatus
  allCommunities: Community[] | null
  submitFormInfo: { [key: string]: ISubmitFormData[] } | null
  collections: Collection[] | null
  selectedCollection: Collection | null
  isFetchingCampaigns: boolean
}

const initialState: OverViewState = {
  activeCampaignsAndQuests: null,
  userCommunities: null,
  selectedCommunity: null,
  communityOnChainGraphData: null,
  smartInsightsData: null,
  fetchingCommunitiesStatus: "idle",
  allCommunities: null,
  submitFormInfo: null,
  collections: null,
  selectedCollection: null,
  isFetchingCampaigns: true,
}

export const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    setActiveCampaignsAndQuests: (state, action: PayloadAction<IOverviewPageQuests[]>) => {
      state.activeCampaignsAndQuests = action.payload
    },
    setUserCommunities: (state, action: PayloadAction<CommunityWithCustomFields[] | null>) => {
      state.userCommunities = action.payload
    },
    setSelectedCommunity: (state, action: PayloadAction<CommunityWithCustomFields | null>) => {
      state.selectedCommunity = action.payload
    },
    setCommunityOnChainGraphData: (
      state,
      action: PayloadAction<ICommunityOnChainGraphDayData[]>,
    ) => {
      state.communityOnChainGraphData = action.payload
    },
    setSmartInsightsData: (state, action: PayloadAction<ISmartInsightsCount>) => {
      state.smartInsightsData = action.payload
    },
    setFetchingCommunitiesStatus: (state, action: PayloadAction<TApiStatus>) => {
      state.fetchingCommunitiesStatus = action.payload
    },
    setAllCommunities: (state, action: PayloadAction<Community[]>) => {
      state.allCommunities = action.payload
    },
    setSubmitFormInfo: (state, action: PayloadAction<SubmitActionPayloadType>) => {
      const tempObj: { [key: string]: ISubmitFormData[] } | null = state.submitFormInfo
      const { formId, formData } = action.payload
      if (tempObj && tempObj[formId]) {
        tempObj[formId] = [...tempObj[formId], ...formData]
        state.submitFormInfo = tempObj
      } else if (tempObj === null) {
        state.submitFormInfo = {
          [formId]: formData,
        }
      } else {
        state.submitFormInfo = { ...tempObj, ...{ [formId]: formData } }
      }
    },
    updateSubmitFormInfo: (
      state,
      action: PayloadAction<{ modalId: string; data: IUpdateSubmitFormData[] }>,
    ) => {
      if (action.payload.modalId && state?.submitFormInfo) {
        const currentForm = state?.submitFormInfo[action.payload.modalId]
        const updatedForm = currentForm.map((formRow) => {
          const temp = action.payload.data.filter((updData) => updData.uuid === formRow.uuid)?.[0]
          if (temp) {
            return { ...formRow, ...temp }
          } else {
            return formRow
          }
        })
        state.submitFormInfo[action.payload.modalId] = updatedForm
      }
    },
    deleteSubmitFormInfo: (state, action: PayloadAction<string>) => {
      if (action.payload && state?.submitFormInfo && state?.submitFormInfo[action.payload]) {
        const currentForm = state?.submitFormInfo
        delete currentForm[action.payload]
        state.submitFormInfo = currentForm
      }
    },
    setCollections: (state, action: PayloadAction<Collection[]>) => {
      state.collections = action.payload
    },
    setSelectedCollection: (state, action: PayloadAction<Collection | null>) => {
      state.selectedCollection = action.payload
    },
    setIsFetchingCampaigns: (state, action: PayloadAction<boolean>) => {
      state.isFetchingCampaigns = action.payload
    },
  },
})

export const overviewActions = overviewSlice.actions

export default overviewSlice
