import React, { useEffect, useRef, useState } from "react"
import "./style.scss"
import TextInput from "../Inputs/TextInput"
import TextArea from "../Inputs/TextArea"
import { ReactComponent as UploadIcon } from "../../assets/icons/uploadIcon.svg"
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitterIcon.svg"
import { ReactComponent as DiscordIcon } from "../../assets/icons/discordIcon.svg"
import { ReactComponent as WebsiteIcon } from "../../assets/icons/websiteIcon.svg"
import logoExample from "../../assets/images/logoExample.png"
import communitySpaceImg from "../../assets/images/CommunitySpaceImg.png"
import {
  Community,
  ICommunityAddingSocials,
  ICommunitySocial,
  TSocialLinkTypes,
} from "../../types/community"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import { updateCommunity } from "../../store/actions/createCommunity"
import { uploadFileToS3 } from "../../utils/awsUtils"
import { getFileExtension, stringWithoutSpecialChars } from "../../utils/regex"
import dayjs from "dayjs"
import {
  ClientToolBaseUrl,
  CloudFrontImageUrl,
  S3ImageBucketName,
} from "../../constant/constantValues"
import { message } from "antd"
import { useAccount } from "wagmi"
import { fetchCommunitySocials } from "../../store/actions/communityAction"
import animationLoadingData from "../../assets/lottie/buttonLoader.json"
import Lottie from "react-lottie"
import { ReactComponent as ReplayIcon } from "../../assets/icons/replayIcon.svg"

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationLoadingData,
  renderer: "svg",
}

const SocialLinkRow = ({
  socialLinkDetails,
  updateSocialLinkValue,
}: {
  socialLinkDetails: ICommunityAddingSocials
  updateSocialLinkValue: (socialLinkValue: string) => void
}) => {
  const socialLinkRef = useRef<HTMLInputElement | null>(null)
  return (
    <div className="community-social-input-row" onClick={() => socialLinkRef?.current?.focus()}>
      <div className="community-social-row-left">
        {socialLinkDetails.socialLinkIcon} {socialLinkDetails.socialLinkName}
      </div>
      <div className="community-social-row-right">
        <input
          type="text"
          placeholder={socialLinkDetails.socialLinkPlaceHolder}
          value={socialLinkDetails.socialLinkValue}
          onChange={(e) => updateSocialLinkValue(e.target.value)}
          ref={socialLinkRef}
        />
      </div>
    </div>
  )
}

const getSocialLinkValue = (
  socialType: TSocialLinkTypes,
  socialLinksArray: ICommunitySocial[] | null,
) => {
  return socialLinksArray?.find((ele) => ele.account_type === socialType)?.account_url || ""
}

const getSocialLinkUuid = (
  socialType: TSocialLinkTypes,
  socialLinksArray: ICommunitySocial[] | null,
) => {
  return socialLinksArray?.find((ele) => ele.account_type === socialType)?.uuid || null
}

export default function CommunitySpacePage() {
  const selectedCommunity = useAppSelector((state) => state.overview.selectedCommunity)
  const communitySocials = useAppSelector((state) => state.community.communitySocials)

  const [communityName, setCommunityName] = useState(selectedCommunity?.name || "")
  const [communityDescription, setCommunityDescription] = useState(
    selectedCommunity?.description || "",
  )
  const [communityShortDescription, setCommunityShortDescription] = useState(
    selectedCommunity?.short_description || "",
  )
  const [communityBannerImage, setCommunityBannerImage] = useState(
    selectedCommunity?.bg_img_url || "",
  )
  const [communityBannerImageFile, setCommunityBannerImageFile] = useState<File>()
  const [communityLogo, setCommunityLogo] = useState(selectedCommunity?.logo_url || "")
  const [communityLogoFile, setCommunityLogoFile] = useState<File>()

  const [socialLinks, setSocialLinks] = useState<ICommunityAddingSocials[]>([])
  const { isConnected } = useAccount()
  const socialLinksUpdated = useRef(false)
  const [updatingCommunity, setUpdatingCommunity] = useState(false)

  const jwt = useAppSelector((state) => state.auth.jwt)

  const dispatch = useAppDispatch()

  const updateSocialLinkValue = (socialLinkValue: string, socialLinkIndex: number) => {
    const socialLinksCopy = socialLinks.map((ele) => ({ ...ele }))
    socialLinksCopy[socialLinkIndex].socialLinkValue = socialLinkValue
    setSocialLinks([...socialLinksCopy])
  }

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files?.length) return
    const url = URL.createObjectURL(files[0])
    setCommunityBannerImage(url)
    setCommunityBannerImageFile(files[0])
  }

  const onCommunityLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files?.length) return
    const url = URL.createObjectURL(files[0])
    setCommunityLogo(url)
    setCommunityLogoFile(files[0])
  }

  const publishCommunity = async () => {
    try {
      if (!selectedCommunity || !jwt) return

      setUpdatingCommunity(true)

      const communityUpdateData: Partial<Community> = {
        name: communityName,
        description: communityDescription,
        short_description: communityShortDescription,
      }

      let uploadedBannerImageUrl: string | null = null
      if (communityBannerImageFile) {
        const fileExtension = getFileExtension(communityBannerImageFile.name)
        if (fileExtension) {
          const imageName = `${stringWithoutSpecialChars(
            selectedCommunity?.name || "other",
          )}/Banner${dayjs().unix()}.${fileExtension}`
          await uploadFileToS3(imageName, S3ImageBucketName, jwt, communityBannerImageFile)
          uploadedBannerImageUrl = `${CloudFrontImageUrl}/${imageName}`
          communityUpdateData.bg_img_url = uploadedBannerImageUrl
        }
      }
      let uploadedLogoImageUrl: string | null = null

      if (communityLogoFile) {
        const fileExtension = getFileExtension(communityLogoFile.name)
        if (fileExtension) {
          const imageName = `${stringWithoutSpecialChars(
            selectedCommunity?.name || "other",
          )}/Logo${dayjs().unix()}.${fileExtension}`
          await uploadFileToS3(imageName, S3ImageBucketName, jwt, communityLogoFile)
          uploadedLogoImageUrl = `${CloudFrontImageUrl}/${imageName}`
          communityUpdateData.logo_url = uploadedLogoImageUrl
        }
      }
      const response = await dispatch(
        updateCommunity(selectedCommunity?.name, communityUpdateData, socialLinks),
      )
      if (response.status === "success") {
        message.success("community updated successfully")
      }
      setUpdatingCommunity(false)
      // TODO: update the selected community so that it doesn't show error on overview screen
      // TODO: redirect to homepage after successful and show toast also handle error state
    } catch (err) {
      message.error("something went wrong, please try again")
      setUpdatingCommunity(false)
    }
  }

  useEffect(() => {
    dispatch(fetchCommunitySocials())
  }, [])

  useEffect(() => {
    if (communitySocials) {
      setSocialLinks([
        {
          socialLinkIcon: <TwitterIcon />,
          socialLinkName: "twitter",
          socialLinkAccountName: "",
          socialLinkValue: getSocialLinkValue("twitter", communitySocials),
          socialLinkPlaceHolder: "twitter.com/rep3gg",
          socialLinkUuid: getSocialLinkUuid("twitter", communitySocials),
        },
        {
          socialLinkIcon: <DiscordIcon />,
          socialLinkName: "discord",
          socialLinkAccountName: "",
          socialLinkValue: getSocialLinkValue("discord", communitySocials),
          socialLinkPlaceHolder: "discord.gg/gg_lemons",
          socialLinkUuid: getSocialLinkUuid("discord", communitySocials),
        },
        {
          socialLinkIcon: <WebsiteIcon />,
          socialLinkName: "website",
          socialLinkAccountName: "",
          socialLinkValue: getSocialLinkValue("website", communitySocials),
          socialLinkPlaceHolder: "OGGG.gg",
          socialLinkUuid: getSocialLinkUuid("website", communitySocials),
        },
      ])
    }
  }, [communitySocials])

  if (!isConnected || !jwt) {
    return <>Connect Wallet</>
  }

  return (
    <div className="community-space-page-container">
      <div className="community-space-page-left">
        <div className="community-space-page-heading">Community Space</div>
        <div className="community-space-page-sub-heading">
          Live at:{" "}
          <span
            onClick={() => window.open(`${ClientToolBaseUrl}/${selectedCommunity?.name}`, "_blank")}
          >
            {ClientToolBaseUrl}/{selectedCommunity?.name}
          </span>
        </div>
        <div className="community-space-content">
          <div className="community-space-input-wrapper">
            <TextInput
              value={communityName}
              label="Name"
              placeholder=""
              onChange={(e) => setCommunityName(e.target.value)}
              // onBlur={() => {}}
            />
          </div>
          <div className="community-space-input-wrapper">
            <TextArea
              value={communityShortDescription}
              label="Short Description"
              placeholder=""
              rows={3}
              onChange={(e) => setCommunityShortDescription(e.target.value)}
            />
          </div>
          <div className="community-space-input-wrapper">
            <TextArea
              value={communityDescription}
              label="Description"
              placeholder=""
              rows={7}
              onChange={(e) => setCommunityDescription(e.target.value)}
            />
          </div>
          <div className="community-space-input-wrapper">
            <div className="community-logo-label-row">
              <div className="community-logo-label">Logo</div>
              {/* <div>Optional</div> */}
            </div>
            {communityLogo.length ? (
              <div className="community-logo-preview-image-wrapper">
                <label htmlFor={`upload-file-input-community-logo`}>
                  <div className="community-logo-reupload-wrapper">
                    <ReplayIcon />
                  </div>
                </label>
                <img src={communityLogo} alt="" className="community-logo-uploaded-image-preview" />
              </div>
            ) : (
              <label htmlFor={`upload-file-input-community-logo`}>
                <div className="community-logo-upload-wrapper">
                  <div className="logo-upload-left">
                    <div className="upload-icon-wrapper">
                      <UploadIcon />
                    </div>
                    <div className="upload-text-wrapper">
                      <div className="upload-text-heading">Select media to upload</div>
                      <div className="upload-text-info">At least 1000×1000 recommended.</div>
                    </div>
                  </div>
                  <div className="logo-upload-right">
                    <img src={logoExample} alt="" />
                  </div>
                </div>
              </label>
            )}
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg, video/mp4, video/"
              id={`upload-file-input-community-logo`}
              className="upload-file-input-hidden"
              style={{ display: "none" }}
              onChange={(e) => onCommunityLogoChange(e)}
            />
          </div>
          <div className="community-space-input-wrapper">
            <div className="community-banner-label-row">
              <div className="community-banner-label">Banner</div>
              <div>Optional</div>
            </div>
            {communityBannerImage.length ? (
              <div className="community-banner-preview-image-wrapper">
                <label htmlFor={`upload-file-input-community-banner`}>
                  <div className="community-banner-reupload-wrapper">
                    <ReplayIcon />
                  </div>
                </label>
                <img
                  src={communityBannerImage}
                  alt=""
                  className="community-banner-uploaded-image-preview"
                />
              </div>
            ) : (
              <label htmlFor={`upload-file-input-community-banner`}>
                <div className="community-banner-upload-wrapper">
                  <div className="upload-icon-wrapper">
                    <UploadIcon />
                  </div>
                  <div className="upload-text-wrapper">
                    <div className="upload-text-heading">Select media to upload</div>
                    <div className="upload-text-info">At least 1500×500 recommended.</div>
                  </div>
                </div>
              </label>
            )}
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg, video/mp4, video/"
              id={`upload-file-input-community-banner`}
              className="upload-file-input-hidden"
              style={{ display: "none" }}
              onChange={(e) => onFileChange(e)}
            />
          </div>
          <div className="community-space-input-wrapper">
            <div className="community-socials-label-row">
              <div className="community-socials-label">Social Links</div>
            </div>
            <div className="community-socials-input-wrapper">
              {socialLinks.map((socialLinkDetails, index) => (
                <SocialLinkRow
                  socialLinkDetails={socialLinkDetails}
                  key={index}
                  updateSocialLinkValue={(socialLinkValue: string) =>
                    updateSocialLinkValue(socialLinkValue, index)
                  }
                />
              ))}
            </div>
          </div>
          <div className="community-space-publish-button">
            <button onClick={publishCommunity} disabled={updatingCommunity}>
              Publish
              <div className="publish-loading-wrapper">
                {updatingCommunity ? (
                  <Lottie options={lottieOptions} width={20} height={20} />
                ) : null}
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="community-space-page-right">
        <div className="community-space-demo-image-wrapper">
          <img src={communitySpaceImg} alt="" />
        </div>
      </div>
    </div>
  )
}
