import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { IUserInfo } from "../../types/user"

export interface UserState {
  userInfo: IUserInfo | null
  fetchingUserInfo: boolean
}

const initialState: UserState = {
  userInfo: null,
  fetchingUserInfo: true,
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<{ userInfo: IUserInfo | null }>) {
      state.userInfo = action.payload.userInfo
    },
    setFetchingUserInfo(state, action: PayloadAction<boolean>) {
      state.fetchingUserInfo = action.payload
    },
  },
})

export const userActions = userSlice.actions

export default userSlice
