import React from "react"
import { ReactComponent as ArrowForwardIcon } from "../../../assets/icons/arrowForwardIcon.svg"
import "./style.scss"

export default function QuestCreationNextBtn({
  questCreationStep,
  setQuestCreationStep,
  onSubmit,
  btnText,
  isDisabled,
}: {
  questCreationStep: number
  setQuestCreationStep: (value: number) => void
  onSubmit: () => void
  btnText: string
  isDisabled?: boolean
}) {
  return (
    <button
      className="quest-creation-next-btn"
      disabled={isDisabled}
      onClick={() =>
        isDisabled
          ? {}
          : questCreationStep !== 3
          ? setQuestCreationStep(questCreationStep + 1)
          : onSubmit()
      }
    >
      {btnText}
      <ArrowForwardIcon />
    </button>
  )
}
