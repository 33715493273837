/* eslint-disable  @typescript-eslint/no-explicit-any */

import { useEffect, useRef, useState } from "react"
import "./style.scss"
// import DashboardView from "../../views/DashboardView"
import insightsIcon from "../../assets/icons/insightsIcon.svg"
import refreshIcon from "../../assets/icons/refreshIcon.svg"
// import plusIcon from "../../assets/icons/plusIcon.svg"
import { ReactComponent as ArrowForwardIcon } from "../../assets/icons/arrowForwardIcon.svg"
import arrowRightIcon from "../../assets/icons/arrowRightIcon.svg"
import calendarIcon from "../../assets/icons/calendarIcon.svg"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from "chart.js"
import { Bar } from "react-chartjs-2"
import CampaignCard from "../CampaignCard"
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react"
import "swiper/css"
import FooterCard from "../FooterCard"
import { ReactComponent as FooterHelpIcon } from "../../assets/icons/footerHelpIcon.svg"
import { ReactComponent as SwapIcon } from "../../assets/icons/low_priority.svg"
import { ReactComponent as FooterContactIcon } from "../../assets/icons/footerContactIcon.svg"
import { ReactComponent as PlusIcon } from "../../assets/icons/plusIcon.svg"
import { ReactComponent as TelegramIcon } from "../../assets/icons/telegramIcon.svg"
import { Select, DatePicker, Checkbox, type CheckboxProps } from "antd"
import { BsCaretDownFill } from "react-icons/bs"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import {
  fetchActiveQuestsAndCampaigns,
  fetchCommunityOnchainGraphData,
  fetchSmartInsights,
  fetchCollections,
} from "../../store/actions/overviewAction"
import numbro from "numbro"
import OverviewOnchainActionsChart from "../OverviewOnChainActionsChart"
import { ICommunityOnChainGraphGroupedData } from "../../types/graph"
import dayjs from "dayjs"
import pluralize from "pluralize"
import { useNavigate } from "react-router-dom"
import LoadingScreen from "../LoadingScreen"
import CampaignAndQuestEmptyCard from "../CampaignAndQuestEmptyCard"
import { getGraphDataMapped } from "../../utils/graphUtils"
import NoCampaignBanner from "../Banner/NoCampaignBanner"
import WhiteListBanner from "../Banner/WhiteListBanner"
import { TelegramWhitelistLink } from "../../constant/constantValues"
import { fetchUserInfo } from "../../store/actions/userAction"
import { useAccount } from "wagmi"
import { setIsInternalNavigation } from "../../store/actions/questCreation"
import { fetchCommunityQuestsAndTier } from "../../store/actions/communityAction"
import { IoMdAdd } from "react-icons/io"
import { createPortal } from "react-dom"
import CreateCollectionModal from "../CreateCollectionModal"
import { Collection } from "../../types/collection"
import { ReactComponent as EditIcon } from "../../assets/icons/editIcon.svg"
import ReorderCollectionModal from "../ReorderCollectionModal"
import { IoChevronForwardSharp, IoChevronBackSharp } from "react-icons/io5"
import { overviewActions } from "../../store/reducers/overviewSlice"
import { questCreationActions } from "../../store/reducers/questCreationSlice"
import Lottie from "react-lottie"
import AdminLoader from "../../assets/lottie/Admin loader.json"

const { Option } = Select
const { RangePicker } = DatePicker

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip)

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: AdminLoader,
  renderer: "svg",
}

export default function OverviewPage() {
  // const [selectedIndex, setSelectedIndex] = useState(0);
  const selectedItemRef = useRef<HTMLDivElement>(null)
  const [timeRangeDropdownValue, setTimeRangeDropdownValue] = useState("4_month")
  const [isRangePickerOpen, setIsRangePickerOpen] = useState(false)
  const [showArchive, setShowArchive] = useState(false)
  const [selectedCollectionIndex, setSelectedCollectionIndex] = useState(0)
  // const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null)
  const [isEditingCollection, setIsEditingCollection] = useState(false)
  const [showCreateCollectionModal, setShowCreateCollectionModal] = useState(false)
  const [showReorderCollectionModal, setShowReorderCollectionModal] = useState(false)
  const selectedCommunity = useAppSelector((state) => state.overview.selectedCommunity)
  const campaignsAndQuests = useAppSelector((state) => state.overview.activeCampaignsAndQuests)
  const activeCampaignAndQuests = campaignsAndQuests?.filter((ele) => ele.is_active)
  const activeCampaigns = campaignsAndQuests?.filter((ele) => ele.is_campaign)
  const activeQuests = campaignsAndQuests?.filter((ele) => !ele.is_campaign)
  const communityOnChainGraphData = useAppSelector(
    (state) => state.overview.communityOnChainGraphData,
  )
  const smartInsightsData = useAppSelector((state) => state.overview.smartInsightsData)
  const userInfo = useAppSelector((state) => state.user.userInfo)
  const fetchingUserInfo = useAppSelector((state) => state.user.fetchingUserInfo)
  const allCollections = useAppSelector((state) => state.overview.collections)
  const selectedCollection = useAppSelector((state) => state.overview.selectedCollection)
  const isFetchingCampaigns = useAppSelector((state) => state.overview.isFetchingCampaigns)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { address } = useAccount()

  useEffect(() => {
    if (selectedCommunity) {
      dispatch(fetchActiveQuestsAndCampaigns(selectedCommunity.name))
      dispatch(fetchSmartInsights(selectedCommunity.uuid))
      dispatch(fetchCollections(selectedCommunity.uuid))
    }
  }, [selectedCommunity])

  useEffect(() => {
    if (selectedCollectionIndex != 0 && allCollections) {
      dispatch(overviewActions.setSelectedCollection(allCollections[selectedCollectionIndex - 1]))
    }
  }, [allCollections])

  useEffect(() => {
    if (selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      })
    }
  }, [selectedCollectionIndex])

  const handlePrev = () => {
    if (selectedCollectionIndex > 0) {
      const collectionNumber = selectedCollectionIndex - 1
      setSelectedCollectionIndex(selectedCollectionIndex - 1)
      if (allCollections) {
        if (collectionNumber !== 0) {
          dispatch(overviewActions.setSelectedCollection(allCollections[collectionNumber - 1]))
        } else {
          dispatch(overviewActions.setSelectedCollection(null))
        }
      }
    }
  }

  const handleNext = () => {
    if (allCollections && selectedCollectionIndex < allCollections?.length) {
      const collectionNumber = selectedCollectionIndex + 1
      setSelectedCollectionIndex(selectedCollectionIndex + 1)
      if (allCollections) {
        if (collectionNumber !== 0) {
          dispatch(overviewActions.setSelectedCollection(allCollections[collectionNumber - 1]))
        } else {
          dispatch(overviewActions.setSelectedCollection(null))
        }
      }
    }
  }

  const handleOptionFilterChange = (value: string) => {
    setTimeRangeDropdownValue(value)
    if (value === "custom_dates") {
      setIsRangePickerOpen(true)
    }
  }

  useEffect(() => {
    if (selectedCommunity && timeRangeDropdownValue !== "custom_dates") {
      dispatch(fetchCommunityOnchainGraphData(selectedCommunity?.uuid, timeRangeDropdownValue))
    }
  }, [selectedCommunity, timeRangeDropdownValue])

  useEffect(() => {
    if (address) {
      dispatch(fetchUserInfo(address))
    }
  }, [address])

  //   if (!isConnected || !jwt) {
  //     return (
  //       <DashboardView>
  //         <EmptyPageScreen />
  //       </DashboardView>
  //     )
  //   }

  //   if (fetchingCommunitiesStatus === "loading") {
  //     return (
  //       <DashboardView>
  //         <div>Loading Data</div>
  //       </DashboardView>
  //     )
  //   }

  if (!selectedCommunity) {
    return (
      // <DashboardView>
      <div className="overview-page-empty-container">
        <div>
          <div>No community found</div>
          <button onClick={() => navigate("/setup/community")}>Create a community</button>
        </div>
      </div>
      // </DashboardView>
    )
  }

  if (!communityOnChainGraphData) {
    return <LoadingScreen />
  }

  const groupedGraphData: ICommunityOnChainGraphGroupedData[] =
    getGraphDataMapped(communityOnChainGraphData)

  const maxValueOfMembers = Math.max(...groupedGraphData.map((ele) => ele.MEMBER_COUNT))

  const options = {
    responsive: true,
    labels: null,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    ticks: {
      display: false,
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        max: maxValueOfMembers * 2.5,
        // max: selectedCommunity?.total_member_count
        //   ? selectedCommunity?.total_member_count * 2.75
        //   : 2000,
      },
    },
    barPercentage: 1,
    categoryPercentage: 1,
  }

  const labels = groupedGraphData.map((data) => data.DATE)

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        data: groupedGraphData.map((data) => data.MEMBER_COUNT),
        borderColor: "#CBE1AF",
        backgroundColor: "#CBE1AF",
        showLine: false,
        barPercentage: 1,
      },
    ],
  }

  const countData = smartInsightsData
    ? smartInsightsData?.count
    : {
        TIER_DOWNGRADE: 0,
        TIER_CLAIMED: 0,
        TIER_UPGRADE: 0,
      }
  type CountDataKey = "TIER_DOWNGRADE" | "TIER_CLAIMED" | "TIER_UPGRADE"

  const maxCountKeyInSmartInsights = Object.keys(countData).reduce((a, b) =>
    countData[a as CountDataKey] > countData[b as CountDataKey] ? a : b,
  )

  const getSmartInsight = () => {
    switch (maxCountKeyInSmartInsights as CountDataKey) {
      case "TIER_DOWNGRADE":
        return `${countData[maxCountKeyInSmartInsights as CountDataKey]} downgrades today`
      case "TIER_CLAIMED":
        return `${countData[maxCountKeyInSmartInsights as CountDataKey]} claims today`
      case "TIER_UPGRADE":
        return `${countData[maxCountKeyInSmartInsights as CountDataKey]} upgrades today`
      default:
        return `${countData[maxCountKeyInSmartInsights as CountDataKey]} claims today`
    }
  }

  const hasCampaignOrQuest = Boolean(campaignsAndQuests?.length)

  const onCheckChange: CheckboxProps["onChange"] = (e) => {
    setShowArchive(e.target.checked)
  }

  const handleCloseCollectionModal = () => {
    // setSelectedCollection(null)
    // setSelectedCollectionIndex(-1)
    setShowCreateCollectionModal(false)
    setIsEditingCollection(false)
  }

  const EditingCollection = () => {
    setIsEditingCollection(true)
    setShowCreateCollectionModal(true)
  }

  const handleCloseReorderModal = () => {
    setShowReorderCollectionModal(false)
  }

  return (
    // <DashboardView>
    <div className="overview-page-container">
      {!hasCampaignOrQuest ? (
        <div className="overview-page-top-banner">
          <NoCampaignBanner />
        </div>
      ) : null}
      {/* {!fetchingUserInfo && userInfo?.is_whitelisted ? null : (
        <div className="overview-page-top-banner">
          <WhiteListBanner />
        </div>
      )} */}
      <div className="overview-page-heading-row">
        <div className="overview-page-heading">Summary</div>
        {/* <div className="overview-page-add-campaign-btn" onClick={() => navigate("/setup/quest")}>
          Create New Quest or Campaign
        </div> */}
      </div>
      <div className="overview-charts-filter">
        <div>
          <Select
            popupClassName="overview-filter-switch-dropdown"
            suffixIcon={
              <div>
                <BsCaretDownFill />
              </div>
            }
            onChange={handleOptionFilterChange}
            value={timeRangeDropdownValue}
          >
            <Option label="4_month" value="4_month">
              <div className="overview-filter-switch-dropdown-item">
                <div className="overview-filter-switch-dropdown-item-left">Last 4 Months</div>
              </div>
            </Option>
            <Option label="1_month" value="1_month">
              <div className="overview-filter-switch-dropdown-item">
                <div className="overview-filter-switch-dropdown-item-left">Last 1 Month</div>
              </div>
            </Option>
            <Option label="2_week" value="2_week">
              <div className="overview-filter-switch-dropdown-item">
                <div className="overview-filter-switch-dropdown-item-left">Last 2 Weeks</div>
              </div>
            </Option>
            <Option label="1_week" value="1_week">
              <div className="overview-filter-switch-dropdown-item">
                <div className="overview-filter-switch-dropdown-item-left">Last 1 Week</div>
              </div>
            </Option>

            <Option label="custom_dates" value="custom_dates">
              <div className="overview-filter-switch-dropdown-item">
                <div className="overview-filter-switch-dropdown-item-left">
                  <img src={calendarIcon} alt="" /> Custom Dates
                </div>
              </div>
            </Option>
          </Select>
        </div>
        {timeRangeDropdownValue === "custom_dates" ? (
          <div className="overview-filter-date-selector">
            <RangePicker
              open={isRangePickerOpen}
              onClick={() => setIsRangePickerOpen(true)}
              onChange={(values) => {
                dispatch(
                  fetchCommunityOnchainGraphData(
                    selectedCommunity?.uuid,
                    timeRangeDropdownValue,
                    true,
                    `${dayjs(values?.[0]).unix()}_${dayjs(values?.[1]).unix()}`,
                  ),
                )
                setIsRangePickerOpen(false)
              }}
              onCalendarChange={(values) => console.log("calendar changes", values)}
            />
          </div>
        ) : null}
        <div
          onClick={() => {
            dispatch(overviewActions.setSelectedCollection(null))
            dispatch(questCreationActions.setIsCreatingInsideCollection(false))
            dispatch(setIsInternalNavigation(true))
            navigate("setup/quest")
            dispatch(fetchCommunityQuestsAndTier())
          }}
          className="create-new-btn"
        >
          <div className="create-new-btn-title">Create New Quest</div>
          <ArrowForwardIcon />
        </div>
      </div>
      <div className="overview-charts-wrapper">
        <OverviewOnchainActionsChart />
        <div className="members-chart-wrapper">
          <div className="members-heading">Members</div>
          <div className="members-number">{selectedCommunity?.total_member_count}</div>
          <div className="chart-wrapper">
            <Bar options={options} data={data} />
          </div>
        </div>
        <div className="badges-minted-wrapper">
          <div className="badges-minted-heading">Badges Minted</div>
          <div className="badges-minted-number">{selectedCommunity?.total_member_count}</div>
          <div>
            <div className="badges-minted-row">
              {/* <div>Campaigns</div> */}
              {/* <div>
                {selectedCommunity
                  ? selectedCommunity?.campaign_tier_claimed_count > 1000
                    ? numbro(selectedCommunity?.campaign_tier_claimed_count).format({
                        average: true,
                        totalLength: 2,
                      })
                    : selectedCommunity?.campaign_tier_claimed_count
                  : 0}
              </div> */}
            </div>
            <div className="badges-minted-row">
              <div>Quests</div>
              <div>
                {selectedCommunity
                  ? selectedCommunity?.quest_tier_claimed_count > 1000
                    ? numbro(selectedCommunity?.quest_tier_claimed_count).format({
                        average: true,
                        totalLength: 2,
                      })
                    : selectedCommunity?.quest_tier_claimed_count
                  : 0}
              </div>
            </div>
          </div>
        </div>
        <div className="smart-insights-wrapper">
          <div className="smart-insights-top-row">
            <div className="smart-insight-top-left">
              <img src={insightsIcon} alt="" />
              Smart Insights
            </div>
            <div
              className="refresh-icon-wrapper"
              onClick={() => dispatch(fetchSmartInsights(selectedCommunity?.uuid))}
            >
              <img src={refreshIcon} alt="" />
            </div>
          </div>
          <div className="smart-insight-bottom">{getSmartInsight()}</div>
        </div>
      </div>
      {!hasCampaignOrQuest ? (
        <div className="empty-campaign-card-wrapper">
          <div className="empty-card-row">
            <div className="empty-card-row-left">No Active Quest </div>
            <div className="empty-card-row-right">
              {/* {userInfo?.is_whitelisted ? ( */}
              <>
                <PlusIcon />
                <span
                  onClick={() => {
                    dispatch(setIsInternalNavigation(true))
                    navigate("/setup/quest")
                  }}
                >
                  Create New
                </span>
              </>
              {/* ) : null} */}
            </div>
          </div>
          <CampaignAndQuestEmptyCard
            hasCampaigns={!!activeCampaigns?.length}
            hasQuests={!!activeQuests?.length}
          />
        </div>
      ) : null}
      {campaignsAndQuests?.length ? (
        <div className="overview-campaigns-wrapper">
          <div className="campaign-heading-row">
            <div className="campaign-heading-left">
              <span
                className={`${selectedCollectionIndex === 0 ? "selected-span" : "grey-span"}`}
                onClick={() => {
                  setSelectedCollectionIndex(0)
                  dispatch(overviewActions.setSelectedCollection(null))
                }}
                ref={selectedCollectionIndex === 0 ? selectedItemRef : null}
              >
                All Quests
              </span>
              {allCollections?.length === 0 && (
                <span className="grey-span" onClick={() => setShowCreateCollectionModal(true)}>
                  New Collection <IoMdAdd />
                </span>
              )}
              {allCollections?.length
                ? allCollections.map((collection, index) => {
                    return (
                      <span
                        className={`${
                          selectedCollectionIndex === index + 1 ? "selected-span" : "grey-span"
                        }`}
                        key={index}
                        onClick={() => {
                          dispatch(overviewActions.setSelectedCollection(collection))
                          setSelectedCollectionIndex(index + 1)
                        }}
                        ref={selectedCollectionIndex === index + 1 ? selectedItemRef : null}
                      >
                        {collection.name}
                      </span>
                    )
                  })
                : null}
            </div>
            <div className="white-tint"></div>
            {allCollections && allCollections?.length > 0 ? (
              <div className="campaign-heading-right">
                <button className="arrow-container" disabled={selectedCollectionIndex === 0}>
                  <IoChevronBackSharp onClick={handlePrev} />
                </button>
                <button
                  className="arrow-container"
                  disabled={selectedCollectionIndex === allCollections.length}
                >
                  <IoChevronForwardSharp onClick={handleNext} />
                </button>
                {allCollections?.length > 1 ? (
                  <span className="grey-span" onClick={() => setShowReorderCollectionModal(true)}>
                    Reorder Collections
                    <SwapIcon />
                  </span>
                ) : null}
                <span className="grey-span" onClick={() => setShowCreateCollectionModal(true)}>
                  New Collection <IoMdAdd />
                </span>
              </div>
            ) : null}
          </div>
          {selectedCollectionIndex === 0 ? (
            <div>
              <div className="campaigns-header-row">
                <div className="campaigns-header-row-left">
                  <span>
                    {pluralize(" All Quest", campaignsAndQuests.length)}{" "}
                    {`(${
                      showArchive ? campaignsAndQuests.length : activeCampaignAndQuests?.length
                    })`}
                  </span>
                  {/* <img
                src={arrowLeftIcon}
                alt=""
                onClick={() => swiperElRef?.current?.swiper?.slidePrev()}
              />
              <img
                src={arrowRightIcon}
                alt=""
                onClick={() => swiperElRef?.current?.swiper?.slideNext()}
              /> */}
                </div>
                {/* <div className="campaigns-header-row-right">
              <PlusIcon />              <span
                onClick={() => {
                  dispatch(setIsInternalNavigation(true))
                  navigate("/setup/quest")
                }}
              >
                Create New
              </span>
            </div> */}
              </div>
              <div className="archive-checkbox">
                <Checkbox onChange={onCheckChange}>
                  Show Inactive Quests (
                  {activeCampaignAndQuests
                    ? campaignsAndQuests.length - activeCampaignAndQuests?.length
                    : 0}
                  )
                </Checkbox>
              </div>
              <div className="campaign-cards-wrapper">
                {/* <Swiper spaceBetween={16} slidesPerView={3} ref={swiperElRef}> */}
                {isFetchingCampaigns ? (
                  <div>
                    <Lottie options={lottieOptions} />
                  </div>
                ) : showArchive ? (
                  campaignsAndQuests?.map((ele) => (
                    // <SwiperSlide key={ele.uuid}>
                    <CampaignCard campaignInfo={ele} key={ele.uuid} />
                    // </SwiperSlide>
                  ))
                ) : (
                  activeCampaignAndQuests?.map((ele) => (
                    // <SwiperSlide key={ele.uuid}>
                    <CampaignCard campaignInfo={ele} key={ele.uuid} />
                    // </SwiperSlide>
                  ))
                )}
                {/* <SwiperSlide key={campaignsAndQuests.length}> */}
                {isFetchingCampaigns ? null : (
                  <div
                    className="create-new-card-wrapper"
                    onClick={() => {
                      dispatch(setIsInternalNavigation(true))
                      navigate("/setup/quest")
                    }}
                  >
                    <IoMdAdd />
                    <div className="create-new-heading">Create New Quest</div>
                  </div>
                )}

                {/* </SwiperSlide> */}
                {/* </Swiper> */}
              </div>
            </div>
          ) : (
            <div>
              <div className="campaigns-header-row">
                <div className="campaigns-header-row-left">
                  <span>{selectedCollection?.name}</span>
                  <EditIcon onClick={() => EditingCollection()} />
                </div>
              </div>
              <div className="archive-checkbox">{selectedCollection?.description}</div>
              <div className="campaign-cards-wrapper">
                {/* <Swiper spaceBetween={16} slidesPerView={3} ref={swiperElRef}> */}
                {/* {showArchive
                  ? campaignsAndQuests?.map((ele) => (
                      // <SwiperSlide key={ele.uuid}>
                      <CampaignCard campaignInfo={ele} key={ele.uuid} />
                      // </SwiperSlide>
                    ))
                  : activeCampaignAndQuests?.map((ele) => (
                      // <SwiperSlide key={ele.uuid}>
                      <CampaignCard campaignInfo={ele} key={ele.uuid} />
                      // </SwiperSlide>
                    ))} */}
                {/* <SwiperSlide key={campaignsAndQuests.length}> */}
                {selectedCollection?.quests?.map((quest, index) => {
                  return <CampaignCard campaignInfo={quest} key={quest.uuid} />
                })}
                <div
                  className="create-new-card-wrapper"
                  onClick={() => {
                    dispatch(questCreationActions.setIsCreatingInsideCollection(true))
                    dispatch(setIsInternalNavigation(true))
                    navigate("/setup/quest")
                  }}
                >
                  <IoMdAdd />
                  <div className="create-new-heading">Create New Quest</div>
                </div>
                {/* </SwiperSlide> */}
                {/* </Swiper> */}
              </div>
            </div>
          )}
        </div>
      ) : null}

      <div className="footer-info-wrapper">
        <div className="footer-heading">Explore rep3</div>
        <div className="footer-description">
          Elevate your workflows by learning the basics, and access advanced tips and resources.
        </div>
        <div className="footer-cards-wrapper">
          <FooterCard
            Icon={<FooterHelpIcon />}
            cardHeading="Help center"
            cardDescription="Check out articles with frequently asked questions, tips and tricks."
            footerLinkElement={
              <a href="https://docs.rep3.gg" target="_blank" rel="noreferrer">
                Open Docs
              </a>
            }
          />
          <FooterCard
            Icon={<FooterContactIcon />}
            cardHeading="Contact Us"
            cardDescription="Join our discord and drop a GM message anytime and we’ll assist you with all our might."
            footerLinkElement={
              <a href="https://discord.com/invite/xK2WXUv3VG" target="_blank" rel="noreferrer">
                Open Discord
              </a>
            }
          />
        </div>
      </div>
      {/* <div className="overview-page-floating-button-wrapper">
        <button
          onClick={() => {
            dispatch(overviewActions.setSelectedCollection(null))
            dispatch(questCreationActions.setIsCreatingInsideCollection(false))
            dispatch(setIsInternalNavigation(true))
            navigate("setup/quest")
            dispatch(fetchCommunityQuestsAndTier())
          }}
        >
          Create New Quest <PlusIcon />
        </button>
      </div> */}
      <div>
        {showCreateCollectionModal
          ? createPortal(
              <CreateCollectionModal
                closeModal={() => handleCloseCollectionModal()}
                collection={isEditingCollection ? selectedCollection : null}
              />,
              document.body,
            )
          : null}
      </div>
      <div>
        {showReorderCollectionModal && allCollections
          ? createPortal(
              <ReorderCollectionModal closeModal={() => handleCloseReorderModal()} />,
              document.body,
            )
          : null}
      </div>
    </div>
    // </DashboardView>
  )
}
