import React from "react"
import "./style.scss"
import TextInput from "../../Inputs/TextInput"

export default function ReviewStep({
  communityName,
  website,
  name,
  role,
  telegramAccount,
  email,
  projectTwitterAccount,
  setSetupCommunityStep,
  communityDisplayName,
  setCommunityDisplayName,
}: {
  communityName: string
  website: string
  name: string
  role: string
  telegramAccount: string
  email: string
  projectTwitterAccount: string
  setSetupCommunityStep: (value: number) => void
  communityDisplayName: string
  setCommunityDisplayName: (value: string) => void
}) {
  return (
    <div className="review-step-container">
      <div className="review-overview-wrapper">
        <div className="review-overview-header">
          <div className="review-overview-header-left">Overview</div>
          <div className="review-overview-header-right" onClick={() => setSetupCommunityStep(1)}>
            Edit
          </div>
        </div>
        <div className="review-overview-details">
          <div className="review-overview-details-row">
            <div className="review-overview-details-left">Community name</div>
            <div className="review-overview-details-right">{communityName}</div>
          </div>
          <div className="review-overview-details-row">
            <div className="review-overview-details-left">Website</div>
            <div className="review-overview-details-right">{website}</div>
          </div>
          <div className="review-overview-details-row">
            <div className="review-overview-details-left">Twitter</div>
            <div className="review-overview-details-right">{projectTwitterAccount}</div>
          </div>
          <div className="review-overview-details-row">
            <div className="review-overview-details-left">Your Name</div>
            <div className="review-overview-details-right">{name}</div>
          </div>
          <div className="review-overview-details-row">
            <div className="review-overview-details-left">Your Telegram</div>
            <div className="review-overview-details-right">{telegramAccount}</div>
          </div>
        </div>
      </div>
      {/* <div className="community-space-name-input">
        <TextInput
          label="Community Space Name"
          value={communityDisplayName}
          onChange={(e) => setCommunityDisplayName(e.target.value)}
        />
      </div> */}

      {/* <div className="review-project-details-wrapper">
        <div className="review-project-details-header">
          <div className="review-project-details-header-left">Project Details</div>
          <div className="review-project-details-header-right">Edit</div>
        </div>
        <div className="review-project-details-content">
          <div className="review-project-details-row">
            <div className="review-project-details-row-left">Team Size</div>
            <div className="review-project-details-row-right">{"<20 Members"}</div>
          </div>
          <div className="review-project-details-row">
            <div className="review-project-details-row-left">Monthly Growth Budget</div>
            <div className="review-project-details-row-right">Less than 1k USD</div>
          </div>
        </div>
      </div> */}
    </div>
  )
}
