import "./style.scss"
import {
  Dropdown,
  Space,
  Table,
  Menu,
  DatePicker,
  Calendar,
  ConfigProvider,
  Breakpoint,
  DatePickerProps,
  MenuProps,
} from "antd"
import { IoChevronDownSharp } from "react-icons/io5"
import { BiDownload } from "react-icons/bi"
import { useAppSelector, useAppDispatch } from "../../hooks/reduxHooks"
import React, { ReactNode, useEffect, useRef, useState } from "react"
import { MdOutlineCalendarMonth } from "react-icons/md"
import { convertJsonToCsv, downloadCsv } from "../../utils/common"
import { CommunityLeaderBoard, LeaderBoardItem } from "../../types/community"
import pluralize from "pluralize"
import { FaDownload } from "react-icons/fa6"
import { Address } from "../../types/common"
import { fetchModalLeaderboardData } from "../../store/actions/overviewAction"
import { useEnsName } from "wagmi"
import dayjs, { Dayjs } from "dayjs"
import { ItemType } from "antd/es/menu/hooks/useItems"

const { RangePicker } = DatePicker
type RangeValue = [Dayjs | null, Dayjs | null] | null

const LeaderBoardAddress = ({ address }: { address: Address }) => {
  const { data, isError, isLoading } = useEnsName({
    address: address,
  })

  return <div>{data || `${address.slice(0, 5)}...${address.slice(-4)}`}</div>
}

export default function CommunityLeaderboardPage() {
  const dispatch = useAppDispatch()
  const selectedCommunity = useAppSelector((state) => state.overview.selectedCommunity)
  const communityName = selectedCommunity?.name
  const tableRef = useRef<HTMLDivElement>(null)
  const userInfo = useAppSelector((state) => state.user.userInfo)
  // const [isClient] = useIsClient()
  const jwt = useAppSelector((state) => state.auth.jwt)

  const disabledDate: DatePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current > dayjs()
  }

  const items: MenuProps["items"] = [
    {
      label: "All Time",
      key: "all-time",
    },
    {
      label: "Last 3 months",
      key: "last-three-month",
    },
    {
      label: "Last 1 Month",
      key: "last-one-month",
    },
    {
      label: "Last 1 Week",
      key: "last-one-week",
    },
    {
      label: (
        <div className="rangePickerCont">
          <MdOutlineCalendarMonth /> Custom Dates{" "}
        </div>
      ),
      key: "custom-picker",
    },
  ]

  const [selectedTime, setSelectedTime] = useState("all-time")
  const [selectedItem, setSelectedItem] = useState<
    ItemType | unknown | { label: string; key: string }
  >(items["0"])
  const [startDate, setStartDate] = useState<number | null>(null)
  const [endDate, setEndDate] = useState<number | null>(dayjs().unix())
  const [tableData, setTableData] = useState<CommunityLeaderBoard<LeaderBoardItem> | unknown>({})
  const [shouldFetch, setShouldFetch] = useState(false)
  const shouldFetchRef = useRef(shouldFetch)

  useEffect(() => {
    shouldFetchRef.current = shouldFetch
  }, [shouldFetch])

  const onClick: MenuProps["onClick"] = ({ key }) => {
    const itemSelected = items?.find((val) => {
      return key === val?.key
    })
    if (itemSelected) {
      setSelectedItem(itemSelected)
    }
    setSelectedTime(key)
  }

  const mobileModalTableColumns = [
    {
      title: "#",
      dataIndex: "rank",
      key: "rank",
      // Render function that dangerously sets HTML
      render: (text: string, record: LeaderBoardItem, index: number) => (
        <span className="rankSpan">{text}</span>
      ),
      width: "auto",
    },
    {
      title: "ADDRESS",
      dataIndex: "eoa",
      key: "address",
      // Render function that dangerously sets HTML
      render: (text: Address, record: LeaderBoardItem, index: number) => (
        <LeaderBoardAddress address={text} />
      ),
    },
    {
      title: "XP POINTS",
      dataIndex: "current_xp",
      key: "xpPoints",
      render: (xpPoints: LeaderBoardItem["current_xp"], record: LeaderBoardItem, index: number) => {
        return (
          <span className="xpSpan">
            {xpPoints} <span>XP</span>
          </span>
        )
      },
    },
  ]

  const modalTableColumns = [
    {
      title: "#",
      dataIndex: "rank",
      key: "rank",
      // Render function that dangerously sets HTML
      render: (text: string, record: LeaderBoardItem, index: number) => (
        <span className={"rankSpan"}>{text}</span>
      ),
      // width: 12,
    },
    {
      title: "ADDRESS",
      dataIndex: "eoa",
      key: "address",
      // width: "auto",
      // Render function that dangerously sets HTML
      render: (text: Address, record: LeaderBoardItem, index: number) => (
        <LeaderBoardAddress address={text} />
        // <div>
        //   {text?.length > 10 ? text.slice(0, 5) + "..." + text.slice(-4) : text}
        // </div>
      ),
    },
    {
      title: "CONTRIBUTIONS",
      dataIndex: "campaigns",
      key: "contributions",
      // width: "auto",
      responsive: ["md"] as Breakpoint[],
      // Render function to display the sum of quests and campaigns
      render: (
        contributions: LeaderBoardItem["campaign_count"],
        record: LeaderBoardItem,
        index: number,
      ) => {
        return (
          <span className="contriContainer">
            {record.campaign_count ? (
              <div className="campaignsTag">
                <div className={"textDiv"}>
                  {record.campaign_count} {pluralize("Campaign", record.campaign_count)}
                </div>
              </div>
            ) : null}
            {record.quest_count > 0 ? (
              <div className={"questsTag"}>
                <div className="textDiv">
                  {record.quest_count} {pluralize("Quest", record.quest_count)}
                </div>
                <div className={"overlaydiv"}></div>
              </div>
            ) : null}
          </span>
        )
      },
    },
    {
      title: "XP POINTS",
      dataIndex: "current_xp",
      key: "xpPoints",
      // width: "auto",
      render: (xpPoints: LeaderBoardItem["current_xp"], record: LeaderBoardItem, index: number) => {
        return (
          <span className={"xpSpan"}>
            {xpPoints} <span>XP</span>
          </span>
        )
      },
    },
  ]

  useEffect(() => {
    const tableContent = document.querySelector(".ant-table-body") as HTMLDivElement
    const handleScroll = async (event: Event) => {
      const target = event.target as HTMLDivElement
      const maxScroll = target.scrollHeight - target.clientHeight - 5
      const currentScroll = target.scrollTop
      if (currentScroll >= parseInt(maxScroll.toString()) && !shouldFetchRef.current) {
        setShouldFetch(true)
      }
    }
    if (tableContent) {
      tableContent.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (tableContent) {
        tableContent.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (
        jwt &&
        shouldFetch &&
        (tableData as CommunityLeaderBoard<LeaderBoardItem>).current_page <
          (tableData as CommunityLeaderBoard<LeaderBoardItem>).total_pages
      ) {
        const data = await fetchModalLeaderboardData(
          communityName as string,
          jwt,
          startDate,
          endDate,
          1 + (tableData as CommunityLeaderBoard<LeaderBoardItem>).current_page,
          25,
          (tableData as CommunityLeaderBoard<LeaderBoardItem>)?.leaderboard[
            (tableData as CommunityLeaderBoard<LeaderBoardItem>)?.leaderboard.length - 1
          ].rank,
        )
        if (data) {
          const dataToSet = {
            ...(tableData as object),
            current_page: data.current_page,
            leaderboard: [
              ...(tableData as CommunityLeaderBoard<LeaderBoardItem>).leaderboard,
              ...data.leaderboard,
            ],
          }
          setTableData(dataToSet)
        }
      } else {
        setShouldFetch(false)
      }
    })()
  }, [shouldFetch])

  useEffect(() => {
    setShouldFetch(false)
  }, [tableData])

  useEffect(() => {
    switch (selectedTime) {
      case "all-time":
        setStartDate(null)
        break
      case "last-one-month":
        // let date = new Date(endDate);
        // date.setMonth(date.getMonth() - 1);
        // setStartDate(date.getTime());
        setStartDate(dayjs().subtract(1, "M").unix())
        break
      case "last-three-month":
        setStartDate(dayjs().subtract(3, "M").unix())
        break
      case "last-one-week":
        setStartDate(dayjs().subtract(1, "w").unix())
        break
      case "custom-picker":
        break
      default:
        setStartDate(null)
    }
  }, [selectedTime])

  useEffect(() => {
    ;(async () => {
      const data = await fetchModalLeaderboardData(
        communityName as string,
        jwt as string,
        startDate,
        endDate,
      )
      setTableData(data)
    })()
  }, [startDate, endDate, communityName])

  const menu = <Menu items={items} className="customDropdownMenu" onClick={onClick} />
  const HandleCustomDate = (date: RangeValue, dateS: [string, string]) => {
    if (date) {
      setStartDate(dayjs(date[0]).unix())
      setEndDate(dayjs(date[1]).unix())
    }

    // date.map((dateStrng: any, index: number) => {
    //   // let newDate = new Date(dateStrng);
    //   // let timestampSeconds = newDate.getTime();
    //   index === 0
    //     ? setStartDate(timestampSeconds)
    //     : setEndDate(timestampSeconds);
    // });
  }

  const DownloadButton = async () => {
    const data = await fetchModalLeaderboardData(
      communityName as string,
      jwt as string,
      null,
      dayjs().unix(),
      1,
      (tableData as CommunityLeaderBoard<LeaderBoardItem>)?.total_records,
    )
    if (data) {
      const csvContent = convertJsonToCsv(data)
      downloadCsv(csvContent, `${communityName}_leaderboard.csv`)
    }
  }
  const PrevIcon = <MdOutlineCalendarMonth />
  return (
    <div className={"leaderBoardModal"}>
      <div className={"ModalHeaderContainer"}>
        <div className={"HeadingContainer"}>
          <div className={"HeadingMain"}>Leaderboard</div>
        </div>
      </div>
      {/* <ConfigProvider
          theme={{
            components: {
              Table: {
                borderColor: "#000",
              },
            },
          }}
        > */}
      <div className="above-table-container">
        <div>
          Total {(tableData as CommunityLeaderBoard<LeaderBoardItem>)?.total_records} participants
        </div>
        <div className={"HeaderRight"}>
          {/* {selectedTime === "custom-picker" && (
            <div className={"leaderboard-filter-date-selector"}>
              <RangePicker
                placement="bottomRight"
                className={"customRangePicker"}
                dropdownClassName={"CustomPopup"}
                onChange={(date, dateS) => HandleCustomDate(date, dateS)}
                suffixIcon={null}
                disabledDate={disabledDate}
              />
            </div>
          )} */}
          {/* <Dropdown overlay={menu} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <div className="downIconContainer">
                  {selectedItem != null
                    ? (selectedItem as { label: string; key: string })["label"]
                    : ""}
                  <IoChevronDownSharp />
                </div>
              </Space>
            </a>
          </Dropdown> */}
          {(tableData as CommunityLeaderBoard<LeaderBoardItem>)?.total_records ? (
            <div className={"downloadIcon"} onClick={DownloadButton}>
              <BiDownload />
            </div>
          ) : null}
        </div>
      </div>
      <div ref={tableRef} className={"tableContainer"}>
        <Table
          dataSource={(tableData as CommunityLeaderBoard<LeaderBoardItem>)?.leaderboard}
          pagination={false}
          columns={modalTableColumns}
          className={"customTable"}
          scroll={{ y: "500px" }}
          // bordered={true}
          // loading={shouldFetch}
          // footer={shouldFetch ? () => <div>Hi</div> : undefined}
        />
      </div>
      {/* </ConfigProvider> */}
    </div>
  )
}
