export const routes = {
  adapter: {
    baseUrl: "/adapter",
  },
  analytics: {
    rewardActionGraphStats: "/analytics/reward_action_graph_stats",
    rewardActionStats: "/analytics/reward_action_stats",
  },
  arweave: {
    metadata: "/generate_metadata",
    imageUpload: "/image_upload_arweave",
  },
  auth: {
    nonce: "/auth/nonce",
    token: "/auth/token",
  },
  common: {
    twitterServiceAccountCreation: "/twitter_service/account",
  },
  community: {
    baseUrl: "/community",
    signup: "/community/signup",
    admins: "/community/admin",
    removeAdmin: "/community/admin/remove",
    addAdmin: "/community/admin/add",
  },
  quest: {
    baseUrl: "/quest",
  },
  referral: {
    baseUrl: "/referral",
    referralSetup: "/referral/setup",
  },
  twitterService: {
    accountInfo: "/twitter_service/account",
  },
  user: {
    baseUrl: "/user",
    adminCommunities: "/user/admin/communities",
    adminSubmitForm: "/user/admin/user_form_submissions",
    adminSubmitFormUpdateAll: "/user/admin/user_form_submissions/all",
  },
  task: {
    adminCSVUpload: "/task/admin/upload_csv_adapter_file",
    baseUrl: "/task",
  },
  collection: {
    baseUrl: "/collection",
    orderCollections: "/collection/order",
  },
  leaderboard : {
    baseUrl: "/leaderboard",
  },  
}
