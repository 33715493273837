/* eslint-disable no-duplicate-imports */
import React, { useEffect, useRef, useState } from "react"
import "./style.scss"
import { ReactComponent as PlusIcon } from "../../../assets/icons/plusIcon.svg"
import { FaDiscord, FaFileContract, FaTwitter, FaTelegramPlane } from "react-icons/fa"
import {
  AdapterKeysWithValues,
  AdapterNames,
  AdapterTypes,
  TQuizAdapterQuestion,
  TQuizQA,
  isStrategyAdapter,
} from "../../../types/adapter"
import { Collapse } from "antd"
import { useAppSelector } from "../../../hooks/reduxHooks"
import { getAdapterName, getClassNameFromType, getIconFromType } from "../../../utils/adapterUtils"
import { IQuestAdapterTier } from "../../../types/quest"
import RenderAdapters from "../RenderAdapters"
import pluralize from "pluralize"
import { ReactComponent as ArrowForwardIcon } from "../../../assets/icons/arrowForwardIcon.svg"
import { ReactComponent as DeleteIcon } from "../../../assets/icons/deleteIcon.svg"

const QuestAdapterRow = ({
  adapterName,
  adapterType,
  isLast,
}: {
  adapterType: AdapterNames
  adapterName: string
  isLast: boolean
}) => {
  return (
    <div className={`quest-adapter-row-container ${isLast ? "last-quest-adapter" : ""}`}>
      <div className="quest-adapter-row-left">
        <div className={`quest-adapter-icon ${getClassNameFromType(adapterType)}`}>
          {getIconFromType(adapterType)}
        </div>
        <div className="quest-adapter-name">{adapterName}</div>
      </div>
      <div className="quest-adapter-row-right">
        <PlusIcon />
      </div>
    </div>
  )
}

const CollapseHeader = ({
  tier,
  updateTierName,
  isCollapseOpen,
  tiers,
  deleteTier,
}: {
  tier: IQuestAdapterTier
  updateTierName: (updatedTierName: string) => void
  isCollapseOpen: boolean
  tiers: IQuestAdapterTier[]
  deleteTier: () => void
}) => {
  const [editName, setEditName] = useState(false)
  const [tierName, setTierName] = useState(tier.tierName)
  const tierNameInputRef = useRef<HTMLInputElement | null>(null)
  const isEditing = useAppSelector((state) => state.questCreation.isEditing)

  useEffect(() => {
    if (editName) {
      tierNameInputRef.current?.focus()
    }
  }, [editName])

  const uniqueAdapters = tier.adapters
    .map((adapter) => adapter.adapter_name)
    .filter((value, index, array) => array.indexOf(value) === index)

  return (
    <div className="tier-collapsible-header">
      <div className="tier-collapsible-header-left">
        {editName ? (
          <input
            type="text"
            value={tierName}
            ref={tierNameInputRef}
            onChange={(e) => setTierName(e.target.value)}
            onBlur={(e) => {
              updateTierName(tierName)
              setEditName(false)
            }}
          />
        ) : (
          tier.tierName
        )}
        {isCollapseOpen && !editName ? (
          <div className="tier-header-rename-wrapper">
            &nbsp;&bull;{" "}
            <span
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setEditName(true)
              }}
            >
              Rename
            </span>
          </div>
        ) : null}
      </div>
      <div className="tier-collapsible-header-right">
        {!isCollapseOpen ? (
          <>
            <div className="collapsible-header-right-text">
              {uniqueAdapters.length} {pluralize("requirement", uniqueAdapters.length)}
            </div>
            {uniqueAdapters?.length ? (
              <div className="adapter-header-icons-wrapper">
                {uniqueAdapters.map((adapter) => (
                  <div className="adapter-header-right-icon" key={adapter}>
                    {adapter === "TWITTER" ? (
                      <FaTwitter />
                    ) : adapter === "DISCORD" ? (
                      <FaDiscord />
                    ) : (
                      <FaFileContract />
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </>
        ) : tiers.length > 1 && !tier?.uuid ? (
          <div onClick={deleteTier} className="tier-collapsible-header-delete">
            <DeleteIcon />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default function QuestAdapterStep({
  activeTier,
  addAdapter,
  addTier,
  deleteTier,
  isDisabled,
  onSubmit,
  removeAdapter,
  setActiveTier,
  tiers,
  updateAdapterInputValue,
  updateTierName,
  insertKeysToAdapter,
  updateAdapterKeysList,
}: Readonly<{
  activeTier: number | undefined
  addAdapter: (adapterInfo: AdapterTypes, tierIndex: number) => void
  addTier: () => void
  deleteTier: (tierIndex: number) => void
  isDisabled: boolean
  onSubmit: () => void
  removeAdapter: (adapterIndex: number, tierIndex: number) => void
  setActiveTier: (value: number | undefined) => void
  tiers: IQuestAdapterTier[]
  updateAdapterInputValue: (
    tierIndex: number,
    adapterIndex: number,
    adapterKeyIndex: number,
    value: number | string | TQuizQA,
  ) => void
  updateTierName: (updatedTierName: string, tierIndex: number) => void
  insertKeysToAdapter: (
    tierIndex: number,
    adapterIndex: number,
    newAdapterToAdd: AdapterKeysWithValues[],
  ) => void
  updateAdapterKeysList: (
    tierIndex: number,
    adapterIndex: number,
    keyName: string,
    value: string | number,
  ) => void
}>) {
  // const [activeTier, setActiveTier] = useState(1)
  const availableAdapters = useAppSelector((state) => state.questCreation.availableAdapters)
  const [selectedAdapterFilter, setSelectedAdapterFilter] = useState<
    "ALL" | "TWITTER" | "DISCORD" | "SMART_CONTRACT" | "MISC" | "TELEGRAM_LINK"
  >("ALL")
  const isEditing = useAppSelector((state) => state.questCreation.isEditing)
  return (
    <div className="quest-adapter-step-container">
      <div className="quest-adapter-step-left">
        <div className="quest-adapter-step-left-heading">Setup Requirements</div>
        <div className="quest-adapter-step-collapse-wrapper">
          <Collapse
            accordion
            activeKey={activeTier}
            ghost
            onChange={(key) => {
              // if (key?.length) {
              setActiveTier(key?.length ? parseInt(key[0]) : undefined)
              // }
            }}
            rootClassName="quest-adapter-step-collapse"
          >
            {tiers.map((tier, index) => (
              <Collapse.Panel
                key={index + 1}
                header={
                  <CollapseHeader
                    tier={tier}
                    updateTierName={(updatedTierName) => {
                      if (activeTier) updateTierName(updatedTierName, activeTier - 1)
                    }}
                    isCollapseOpen={activeTier ? activeTier - 1 === index : false}
                    tiers={tiers}
                    deleteTier={() => deleteTier(index)}
                  />
                }
              >
                <div
                // onClick={(e) => {
                //   e.preventDefault()
                //   e.stopPropagation()
                // }}
                >
                  <RenderAdapters
                    adapters={tier.adapters}
                    removeAdapter={(adapterIndex: number) => {
                      if (activeTier) removeAdapter(adapterIndex, activeTier - 1)
                    }}
                    updateAdapterInputValue={(
                      adapterIndex: number,
                      adapterKeyIndex: number,
                      value: number | string | TQuizQA,
                    ) => {
                      if (activeTier)
                        updateAdapterInputValue(
                          activeTier - 1,
                          adapterIndex,
                          adapterKeyIndex,
                          value,
                        )
                    }}
                    insertKeysToAdapter={(adapterIndex, value) => {
                      if (activeTier) insertKeysToAdapter(activeTier - 1, adapterIndex, value)
                    }}
                    updateAdapterKeysList={(adapterIndex, keyName, value) => {
                      if (activeTier)
                        updateAdapterKeysList(activeTier - 1, adapterIndex, keyName, value)
                    }}
                    tier={tiers[(activeTier || 1) - 1]}
                  />
                </div>
              </Collapse.Panel>
            ))}
          </Collapse>
          <div className="add-tier-wrapper" onClick={addTier}>
            <div className="add-tier-btn">
              <PlusIcon />
              Add another Tier
            </div>
            <div className="add-tier-helper-text">
              {/* <MdOutlineInfo /> */}
              Tiers will upgrade automatically, and the higher tier will be prioritized over lower
              tiers.
            </div>
          </div>
          <button className="quest-adapter-next-step-btn" onClick={onSubmit} disabled={isDisabled}>
            Setup Rewards <ArrowForwardIcon />
          </button>
        </div>
      </div>
      <div className="quest-adapter-step-right">
        <div className="quest-adapters-list-heading">
          <div>{activeTier ? tiers[activeTier - 1]?.tierName : "No tier selected"}</div>
          Select {activeTier ? "Requirements" : "Tier"}
        </div>
        <div className={`quest-adapters-wrapper ${!activeTier ? "no-active-tier" : ""}`}>
          <div className="adapters-list-filter-nav">
            <div
              className={`adapter-list-filter-nav-item ${
                selectedAdapterFilter === "ALL" ? "selected-adapter-nav-item" : ""
              }`}
              onClick={() => setSelectedAdapterFilter("ALL")}
            >
              ALL
            </div>
            <div
              className={`adapter-list-filter-nav-item ${
                selectedAdapterFilter === "TWITTER" ? "selected-adapter-nav-item" : ""
              }`}
              onClick={() => setSelectedAdapterFilter("TWITTER")}
            >
              TWITTER
            </div>
            <div
              className={`adapter-list-filter-nav-item ${
                selectedAdapterFilter === "DISCORD" ? "selected-adapter-nav-item" : ""
              }`}
              onClick={() => setSelectedAdapterFilter("DISCORD")}
            >
              DISCORD
            </div>
            <div
              className={`adapter-list-filter-nav-item ${
                selectedAdapterFilter === "SMART_CONTRACT" ? "selected-adapter-nav-item" : ""
              }`}
              onClick={() => setSelectedAdapterFilter("SMART_CONTRACT")}
            >
              ON CHAIN
            </div>
            <div
              className={`adapter-list-filter-nav-item ${
                selectedAdapterFilter === "TELEGRAM_LINK" ? "selected-adapter-nav-item" : ""
              }`}
              onClick={() => setSelectedAdapterFilter("TELEGRAM_LINK")}
            >
              TELEGRAM
            </div>
            <div
              className={`adapter-list-filter-nav-item ${
                selectedAdapterFilter === "MISC" ? "selected-adapter-nav-item" : ""
              }`}
              onClick={() => setSelectedAdapterFilter("MISC")}
            >
              MISC
            </div>
          </div>
          <div className="quest-available-adapters-list">
            {availableAdapters
              ?.filter((adapter) =>
                selectedAdapterFilter === "ALL"
                  ? true
                  : selectedAdapterFilter === "MISC"
                  ? adapter.adapter_name === "TASK_WHITELIST_ADAPTER" ||
                    adapter.adapter_name === "OPEN_LINK"
                  : adapter.adapter_name === selectedAdapterFilter,
              )
              ?.map((adapter, index) => {
                return adapter.adapter_types.map((adapter_type, idx) => (
                  <div
                    key={`${index}${idx}`}
                    onClick={() => {
                      if (activeTier) addAdapter(adapter_type, activeTier - 1)
                    }}
                  >
                    <QuestAdapterRow
                      adapterType={adapter.adapter_name}
                      adapterName={getAdapterName(
                        isStrategyAdapter(adapter_type)
                          ? adapter_type.adapter_subtype
                          : adapter_type.task_type,
                      )}
                      isLast={
                        index === availableAdapters.length - 1 &&
                        idx === adapter.adapter_types.length - 1
                      }
                    />
                  </div>
                ))
              })}
          </div>
        </div>
      </div>
    </div>
  )
}
