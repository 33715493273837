import React, { useEffect } from "react"
import QuestCreationPage from "../../components/QuestCreationPage"
// import AuthWrapper from "../../views/AuthWrapper"
import HasCommunitiesWrapper from "../../views/HasCommunitiesWrapper"
import WhiteListedUserWrapper from "../../views/WhiteListedUserWrapper"
import { useAppSelector } from "../../hooks/reduxHooks"
import { redirect, useNavigate } from "react-router-dom"

export default function QuestCreation() {
  const isInternalNavigation = useAppSelector((state) => state.questCreation.isInternalNavigation)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isInternalNavigation) navigate("/")
  }, [isInternalNavigation])

  return (
    <HasCommunitiesWrapper>
      <WhiteListedUserWrapper>
        <QuestCreationPage />
      </WhiteListedUserWrapper>
    </HasCommunitiesWrapper>
  )
}
