import { AppDispatch, RootState } from ".."
import { routes } from "../../constant/routes"
import apiClient from "../../utils/apiClient"
import { communityActions } from "../reducers/communitySlice"

export const fetchCommunitySocials = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const selectedCommunity = getState().overview.selectedCommunity
      if (!selectedCommunity) return 0
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}${routes.community.baseUrl}/${selectedCommunity?.name}/socials`,
      )
      if (response.status === 200) {
        dispatch(communityActions.setCommunitySocials(response.data))
        return 1
      }
      return 0
    } catch (err) {
      console.error("error while getting community", err)
      return 0
    }
  }
}
export const fetchCommunityQuestsAndTier = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const selectedCommunity = getState().overview.selectedCommunity
      if (!selectedCommunity) return 0
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}${routes.community.baseUrl}/${selectedCommunity?.name}/quests?is_publish=true&include=task_groups&include_custom_fields=false&sort=end_date%3Adesc%2C%20created_at%3Adesc%2C%20claimer_count%3Aasc`,
      )
      if (response.status === 200) {
        dispatch(communityActions.setCommunityQuests(response.data))
        return 1
      }
      return 0
    } catch (err) {
      console.error("error while getting community", err)
      return 0
    }
  }
}
