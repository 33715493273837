import React, { useEffect, useState } from "react"
import QuestInfoStep from "./QuestInfoStep"
import QuestAdapterStep from "./QuestAdapterStep"
import {
  Adapter,
  AdapterKeysWithValues,
  AdapterTypes,
  TQuizAdapterQuestion,
  TQuizQA,
  isNonStrategyAdapter,
  isStrategyAdapter,

} from "../../types/adapter"
import QuestCreationHeader from "./QuestCreationHeader"
import dayjs, { Dayjs } from "dayjs"
import { ReactComponent as ArrowForwardIcon } from "../../assets/icons/arrowForwardIcon.svg"
import "./style.scss"
import QuestRewardStep from "./QuestRewardStep"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import {
  createQuest,
  fetchAvailableAdapters,
  fetchQuestsInCommunity,
  publishQuestApi,
} from "../../store/actions/questCreation"
import {
  IQuestAdapterTier,
  isBadgeReward,
  isNonStrategyTask,
  isStrategyTask,
  isXpReward,
  TNonStrategyTask
} from "../../types/quest"
import QuestPublishStep from "./QuestPublishStep"
import { useWalletClient } from "wagmi"
import { useNavigate } from "react-router-dom"
import { ClientToolBaseUrl } from "../../constant/constantValues"
import { v4 } from "uuid"
import {
  getAdapterRequirement,
  transformedAdapterKeyValue,
  transformedAdapterKeyValueContractAdapter,
} from "../../utils/adapterUtils"
import { questCreationActions } from "../../store/reducers/questCreationSlice"
import { message } from "antd"

export default function QuestCreationPage() {
  const [questCreationStep, setQuestCreationStep] = useState(1)
  const [questName, setQuestName] = useState("")
  const [questDescription, setQuestDescription] = useState("")
  const [questDisplayUrl, setQuestDisplayUrl] = useState("")
  const [questDisplayUrlChanged, setQuestDisplayUrlChanged] = useState(false)
  const [questEndDateActive, setQuestEndDateActive] = useState(false)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [questShowcaseImage, setQuestShowcaseImage] = useState("")
  const [isCampaign, setIsCampaign] = useState(true)
  const [isSequential, setIsSequential] = useState("")
  const [tweetText, setTweetText] = useState("")
  const [questMetaImage, setQuestMetaImage] = useState("")
  const [questMetaImageFile, setQuestMetaImageFile] = useState<File>()
  const [tiers, setTiers] = useState<IQuestAdapterTier[]>([
    {
      tierName: "Tier 1",
      adapters: [],
      rewards: [],
    },
  ])
  const [requirementText, setRequirementText] = useState<
    {
      text: string
      adapterIndex: number
      tierIndex: number
    }[]
  >([])

  const [selectedRewardType, setSelectedRewardType] = useState("xp")
  const [activeTier, setActiveTier] = useState<number | undefined>(1)
  const selectedCommunity = useAppSelector((state) => state.overview.selectedCommunity)
  const dispatch = useAppDispatch()
  const [deployedQuestUuid, setDeployedQuestUuid] = useState<string | null>("")
  const { data: walletClient } = useWalletClient()
  const [creatingQuest, setCreatingQuest] = useState(false)
  const questsInCommunity = useAppSelector((state) => state.questCreation.quests)
  const [questCollection, setQuestCollection] = useState<{ value: string; uuid: string } | null>(
    null,
  )
  const navigate = useNavigate()

  const isCreatingQuestInsideCollection = useAppSelector(
    (state) => state.questCreation.isCreatingInsideCollection,
  )
  const isEditing = useAppSelector((state) => state.questCreation.isEditing)
  const isDuplicating = useAppSelector((state) => state.questCreation.isDuplicating)
  const selectedQuestInfo = useAppSelector((state) => state.questCreation.selectedQuestInfo)
  const selectedCollectionInfo = useAppSelector((state) => state.overview.selectedCollection)
  const collections = useAppSelector((state) => state.overview.collections)
  const availableAdapters = useAppSelector((state) => state.questCreation.availableAdapters)
  const jwt = useAppSelector((state) => state.auth.jwt)
  const QuestDisplayUrlsInUse =
    isEditing || isDuplicating
      ? questsInCommunity
          ?.filter((quest) => quest.uuid !== selectedQuestInfo?.uuid)
          ?.map((ele) => ele.display_path)
      : questsInCommunity?.map((ele) => ele.display_path)

  const onSubmit = async () => {
    if (questCreationStep < 3) {
      setQuestCreationStep(questCreationStep + 1)
    } else {
      // TODO: wait for quest creation and then move to next step - show loading as images take time to upload on arweave
      if (!walletClient) return
      setCreatingQuest(true)
      const questCreationResponse = await dispatch(
        createQuest(
          {
            questName,
            questDescription,
            questDisplayUrl,
            questEndDateActive,
            questCollection,
            questMetaImage,
            questMetaImageFile,
            tiers,
            tweetText,
            endDate,
          },
          requirementText,
          walletClient,
        ),
      )
      if (!questCreationResponse?.deployedQuestUuid) return
      if (isEditing) {
        navigate("/")
      } else {
        setDeployedQuestUuid(questCreationResponse.deployedQuestUuid)
        setQuestCreationStep(4)
        setCreatingQuest(false)
        dispatch(questCreationActions.setIsDuplicating(false))
      }
    }
  }

  const openTestLink = () => {
    window.open(
      `${ClientToolBaseUrl}/${selectedCommunity?.name}/quest/${questDisplayUrl}`,
      "_blank",
    )
  }

  const publishQuest = async () => {
    if (deployedQuestUuid) {
      const res = await dispatch(
        publishQuestApi(
          deployedQuestUuid,
          {
            questName,
            questDescription,
            questDisplayUrl,
            questEndDateActive,
            questMetaImage,
            questMetaImageFile,
            tiers: [],
            endDate,
          },
          tiers?.length > 1 ? true : false,
        ),
      )
      if (res.status === "success") {
        navigate("/")
      }
    }
    // TODO: add toast
  }

  const addAdapterToTier = (adapterInfo: AdapterTypes, tierIndex: number) => {
    const adaptersCopy = tiers[tierIndex].adapters.map((ele) => ele)
    if(tiers.map(tier => tier.adapters.find(adapter => adapter.adapter_name === "SUBMIT_FORM"))[0] && adapterInfo.adapter_name === "SUBMIT_FORM"){
      message.error("Only 1 Manual Submission allowed in a Quest")
      return;
    }
    const adapterInfoKeysWithValue = adapterInfo?.options?.keys?.map((keyObject) => ({
      ...keyObject,
      value: keyObject.key === "webhook_id" ? v4() : "",
    }))
    adaptersCopy.push({
      ...adapterInfo,
      options: {
        ...adapterInfo.options,
        keys: adapterInfoKeysWithValue,
      },
    })
    const tiersCopy = tiers.map((tier) => ({ ...tier }))
    tiersCopy[tierIndex].adapters = adaptersCopy
    setTiers(tiersCopy)
    let totalAdapterCount = 0
    tiersCopy.forEach((ele) => {
      totalAdapterCount = totalAdapterCount + ele.adapters.length
    })
    setRequirementText(
      requirementText.concat({
        text: getAdapterRequirement(
          isStrategyAdapter(adapterInfo) ? adapterInfo?.adapter_subtype : adapterInfo?.task_type,
        ),
        adapterIndex: tiers[tierIndex].adapters.length,
        tierIndex,
      }),
    )
  }

  const removeAdapterFromTier = (adapterIndex: number, tierIndex: number) => {
    const adaptersCopy = tiers[tierIndex].adapters.map((ele) => ele)
    adaptersCopy.splice(adapterIndex, 1)
    const tiersCopy = tiers.map((tier) => ({ ...tier }))
    tiersCopy[tierIndex].adapters = adaptersCopy
    setTiers(tiersCopy)
    const editedCopyOfRT = requirementText
      // .filter((x) => x.adapterIndex !== adapterIndex && x.tierIndex !== tierIndex)
      ?.map((x) => {
        if (x.tierIndex === tierIndex && x.adapterIndex > adapterIndex) {
          return { ...x, adapterIndex: x.adapterIndex - 1 }
        } else if (x.tierIndex === tierIndex && x.adapterIndex === adapterIndex) {
          return {
            text: "null",
            adapterIndex: 0,
            tierIndex: 0,
          }
        } else {
          return x
        }
      })
      .filter((x) => x.text !== "null")
    setRequirementText(editedCopyOfRT)
  }

  const addTier = () => {
    const tiersLength = tiers.length
    const rewards = []
    if (selectedRewardType === "bothSbtAndXp") {
      rewards.push({ image_url: "", image_file: null })
    }
    rewards.push({ point: 100, media_type: null, media_url: null, media_file: null })

    const newTier = {
      tierName: `Tier ${tiers.length + 1}`,
      adapters: [],
      rewards,
    }

    setTiers((tiers) => [
      ...tiers,
      {
        ...newTier,
      },
    ])
    setActiveTier(tiersLength + 1)
  }

  const deleteTier = (tierIndex: number) => {
    const tiersCopy = tiers.map((tier) => ({ ...tier }))
    tiersCopy.splice(tierIndex, 1)
    setTiers(tiersCopy)
    const editedCopyOfRT = requirementText
      .filter((x) => x.tierIndex !== tierIndex)
      ?.map((x) => {
        if (x.tierIndex > tierIndex) {
          return { ...x, tierIndex: x.tierIndex - 1 }
        } else {
          return x
        }
      })
    setRequirementText(editedCopyOfRT)
  }

  const updateTierName = (updatedTierName: string, tierIndex: number) => {
    const tiersCopy = tiers.map((tier) => ({ ...tier }))
    tiersCopy[tierIndex].tierName = updatedTierName
    setTiers(tiersCopy)
  }

  const updateAdapterInputValue = (
    tierIndex: number,
    adapterIndex: number,
    adapterKeyIndex: number,
    value: number | string | TQuizQA,
  ) => {
    const adaptersCopy = tiers[tierIndex].adapters.map((ele) => ele)
    const keysCopy = adaptersCopy[adapterIndex].options.keys.map((ele) => ({ ...ele }))
    keysCopy[adapterKeyIndex].value = value
    const tiersCopy = tiers.map((tier) => ({ ...tier }))
    tiersCopy[tierIndex].adapters[adapterIndex].options.keys = keysCopy
    setTiers(tiersCopy)
    const adapterInfo = tiersCopy[tierIndex].adapters[adapterIndex]
    updateRequirementTextToTier(
      tierIndex,
      adapterIndex,
      getAdapterRequirement(
        isStrategyAdapter(adapterInfo) ? adapterInfo?.adapter_subtype : adapterInfo?.task_type,
        adapterInfo?.options,
      ),
    )
  }
  const updateAdapterKeysList = (
    tierIndex: number,
    adapterIndex: number,
    keyName: string,
    value: number | string,
  ) => {
    const tiersCopy = tiers.map((tier) => ({ ...tier }))
    if (
      tiersCopy[tierIndex].adapters[adapterIndex].options.keys.filter((ele) => ele.key === keyName)
        .length > 0
    ) {
      const newKeyMap = tiersCopy[tierIndex].adapters[adapterIndex].options.keys.map((ele) => {
        if (ele.key === keyName) {
          return { ...ele, value: value }
        }
        return ele
      })
      tiersCopy[tierIndex].adapters[adapterIndex].options.keys = newKeyMap
      setTiers(tiersCopy)
    } else {
      tiersCopy[tierIndex].adapters[adapterIndex].options.keys = [
        ...tiersCopy[tierIndex].adapters[adapterIndex].options.keys,
        { default: "", valid_values: [], user_level: false, required: true, key: keyName, value },
      ]
      setTiers(tiersCopy)
    }
  }
  const insertKeysToAdapter = (
    tierIndex: number,
    adapterIndex: number,
    newAdapterKeysCopy: AdapterKeysWithValues[],
  ) => {
    const tiersCopy = tiers.map((tier) => ({ ...tier }))
    tiersCopy[tierIndex].adapters[adapterIndex].options.keys = newAdapterKeysCopy
    setTiers(tiersCopy)
  }

  const resetTierRewards = () => {
    const tiersCopy = tiers.map((tier, tierIndex) => ({
      ...tier,
      rewards:
        selectedRewardType === "xp"
          ? [{ point: 100, media_type: null, media_url: null, media_file: null }]
          : selectedRewardType === "bothSbtAndXp"
          ? [
              { image_url: "", image_file: null },
              { point: 100, media_type: null, media_url: null, media_file: null },
            ]
          : [{ image_url: "", image_file: null }],
    }))
    setTiers(tiersCopy)
  }

  const setXpRewardPoint = (points: number, rewardIndex: number, tierIndex: number) => {
    const rewardsCopy = tiers[tierIndex].rewards.map((reward) => ({ ...reward }))
    const selectedReward = rewardsCopy[rewardIndex]
    if (isXpReward(selectedReward)) {
      selectedReward.point = points
    }
    const tiersCopy = tiers.map((tier) => ({ ...tier }))
    tiersCopy[tierIndex].rewards[rewardIndex] = selectedReward
    setTiers(tiersCopy)
  }

  const setXpRewardImage = (
    xpRewardFile: File | null,
    xpRewardImageUrl: string,
    rewardIndex: number,
    tierIndex: number,
  ) => {
    const rewardsCopy = tiers[tierIndex].rewards.map((reward) => ({ ...reward }))
    const selectedReward = rewardsCopy[rewardIndex]
    if (isXpReward(selectedReward)) {
      selectedReward.media_url = xpRewardImageUrl
      selectedReward.media_file = xpRewardFile
      selectedReward.media_type = "image"
    }
    const tiersCopy = tiers.map((tier) => ({ ...tier }))
    tiersCopy[tierIndex].rewards[rewardIndex] = selectedReward
    setTiers(tiersCopy)
  }

  const setBadgeReward = (
    image_url: string,
    image_file: File,
    rewardIndex: number,
    tierIndex: number,
  ) => {
    const rewardsCopy = tiers[tierIndex].rewards.map((reward) => ({ ...reward }))
    const selectedReward = rewardsCopy[rewardIndex]
    if (isBadgeReward(selectedReward)) {
      selectedReward.image_url = image_url
      selectedReward.image_file = image_file
    }
    const tiersCopy = tiers.map((tier) => ({ ...tier }))
    tiersCopy[tierIndex].rewards[rewardIndex] = selectedReward
    setTiers(tiersCopy)
  }
  const updateRequirementTextToTier = (tierIndex: number, adapterIndex: number, value: string) => {
    const requirementTextCopy = requirementText?.map((ele) => {
      if (ele.adapterIndex === adapterIndex && ele.tierIndex === tierIndex) {
        // Increment the clicked counter
        return { ...ele, text: value }
      } else {
        // The rest haven't changed
        return ele
      }
    })
    setRequirementText(requirementTextCopy)
  }
  useEffect(() => {
    if (selectedCommunity) {
      dispatch(fetchAvailableAdapters())
      dispatch(fetchQuestsInCommunity(selectedCommunity?.uuid))
    }
  }, [selectedCommunity])

  useEffect(() => {
    // dont reset if editing
    if (!isEditing || !isDuplicating) {
      resetTierRewards()
    }
  }, [selectedRewardType, isEditing])

  useEffect(() => {
    if ((isEditing || isDuplicating) && selectedQuestInfo && jwt) {
      const setEditingQuestInfo = async () => {
        setQuestName(isDuplicating ? `${selectedQuestInfo?.name}-copy` : selectedQuestInfo?.name)
        setQuestDescription(selectedQuestInfo?.description)
        setQuestDisplayUrl(
          isDuplicating
            ? `${selectedQuestInfo?.display_path}-copy`
            : selectedQuestInfo?.display_path,
        )
        setQuestDisplayUrlChanged(true)
        if (selectedQuestInfo?.collection_uuid) {
          const selectedCollection = collections?.filter(
            (collection) => collection.uuid === selectedQuestInfo.collection_uuid,
          )[0]
          setQuestCollection({
            value: selectedCollection?.name as string,
            uuid: selectedCollection?.uuid as string,
          })
        }
        setQuestEndDateActive(Boolean(selectedQuestInfo?.end_date))
        if (selectedQuestInfo?.end_date) {
          setEndDate(dayjs(selectedQuestInfo?.end_date))
        }
        setTweetText(selectedQuestInfo?.tweet_text || "")
        setQuestMetaImage(selectedQuestInfo?.meta_img_url || "")
        const allAdapters: AdapterTypes[] = []
        availableAdapters?.forEach((adapter) => {
          adapter.adapter_types.forEach((adapterType) => {
            allAdapters.push({
              ...adapterType,
            })
          })
        })
        const tiers: IQuestAdapterTier[] = await Promise.all(
          selectedQuestInfo?.task_groups?.map(async (taskGroup, taskGroupIndex) => {
            const totalPreviousXpPoints =
              taskGroupIndex === 0
                ? 0
                : selectedQuestInfo?.task_groups[taskGroupIndex - 1]?.xp_point || 0
            return {
              tierName: taskGroup?.name || `Tier ${taskGroupIndex + 1}`,
              adapters: await Promise.all(
                taskGroup?.tasks?.map(async (task) => {
                  const selectedAdapter = allAdapters?.find((adapter) => {
                    if (isStrategyAdapter(adapter)) {
                      if (isStrategyTask(task)) {
                        return adapter.adapter_subtype === task.adapter.adapter_subtype
                      }
                    } else {
                      if (isNonStrategyTask(task)) {
                        return adapter.task_type === task.task_type
                      }
                    }
                  })
                  if (selectedAdapter?.adapter_name === "SMART_CONTRACT") {
                    const adapter_data = isStrategyTask(task) ? task?.adapter_data : {}
                    const updatedKeys = transformedAdapterKeyValueContractAdapter(
                      selectedAdapter?.adapter_subtype,
                      task?.adapter?.options?.keys,
                      adapter_data,
                    )
                    const selectedAdapterWithValues: AdapterTypes = {
                      ...selectedAdapter,
                      options: {
                        ...selectedAdapter.options,
                        keys: updatedKeys ?? [],
                      },
                      ...(isEditing ? { uuid: task.uuid } : {}),
                    }
                    return selectedAdapterWithValues
                  } else if (selectedAdapter?.adapter_name === "COMMUNITY_STRATEGY") {
                    const adapter_data = isStrategyTask(task) ? task?.adapter_data : {}
                    console.log(selectedAdapter,adapter_data,"adapter data", task)
                    const updatedKeys = transformedAdapterKeyValueContractAdapter(
                      selectedAdapter?.adapter_subtype,
                      (task?.adapter?.options as undefined),
                      adapter_data,
                    )
                    const selectedAdapterWithValues: AdapterTypes = {
                      ...selectedAdapter,
                      options: {
                        ...selectedAdapter.options,
                        keys: updatedKeys ?? [],
                      },
                      ...(isEditing ? { uuid: task.uuid } : {}),
                    }
                    return selectedAdapterWithValues
                  }else if(selectedAdapter?.adapter_name === "SUBMIT_FORM"){
                    let selectedAdapterKeysWithValues = selectedAdapter?.options?.keys?.map((key) => ({
                      ...key,
                      value: (task as TNonStrategyTask)?.task_data?.submit_form_uuid
                    }))
                    const dummyObjWithValues = {
                      default: "",
                      required: true,
                      user_level: false,
                      type: "string",
                      valid_values: [],
                    }
                    selectedAdapterKeysWithValues = [...selectedAdapterKeysWithValues,
                      {
                      ...dummyObjWithValues,
                        key: "title",
                        value: (task as TNonStrategyTask)?.submit_form?.title,
                        name: "title",
                      },
                      {
                      ...dummyObjWithValues,
                        key: "description",
                        value: (task as TNonStrategyTask)?.submit_form?.description,
                        name: "description",
                      },
                      {
                        ...dummyObjWithValues,
                        default: 1,
                        key: "max_user_submission",
                        required: false,
                        value: (task as TNonStrategyTask)?.submit_form?.max_user_submission,
                        type: "number",
                        name: "max_submission",
                      },
                      {
                        ...dummyObjWithValues,
                        default: 1,
                        key: "approved_submission_count",
                        required: false,
                        value: (task as TNonStrategyTask)?.task_data?.approved_submission_count,
                        type: "number",
                        name: "approved_submission",
                      },
                    ]

                    const selectedAdapterWithValues: AdapterTypes = {
                      ...selectedAdapter,
                      options: {
                        ...selectedAdapter.options,
                        keys: selectedAdapterKeysWithValues,
                      },
                      ...(isEditing ? { uuid: task.uuid } : {}),
                    }

                    return selectedAdapterWithValues
                  } 
                  else if (selectedAdapter) {
                    const selectedAdapterKeysWithValues = await Promise.all(
                      selectedAdapter?.options?.keys?.map(async (key) => ({
                        ...key,
                        value:
                          isStrategyTask(task) && key.key === "strategyOptions"
                            ? isDuplicating
                              ? ""
                              : task?.adapter_data?.[key.key].csvKey
                            : await transformedAdapterKeyValue(
                                key.key,
                                isStrategyTask(task)
                                  ? task?.adapter_data?.[key.key]
                                  : selectedAdapter?.adapter_name === "PREREQUISITE"
                                  ? task?.task_data?.["quest_uuid_tier_map"]
                                  : task?.task_data?.[key.key],
                                jwt,
                                isDuplicating,
                              ),
                      })),
                    )
                    const selectedAdapterWithValues: AdapterTypes = {
                      ...selectedAdapter,
                      options: {
                        ...selectedAdapter.options,
                        keys: selectedAdapterKeysWithValues,
                      },
                      ...(isEditing ? { uuid: task.uuid } : {}),
                    }

                    return selectedAdapterWithValues
                  }
                  const defaultAdapter: AdapterTypes = {
                    adapter_name: "OPEN_LINK",
                    adapter_subtype: "",
                    options: {
                      identities: "DISCORD",
                      keys: [
                        {
                          default: "",
                          key: "",
                          required: false,
                          user_level: false,
                          valid_values: [],
                          value: "",
                        },
                      ],
                    },
                    task_type: "",
                  }

                  return defaultAdapter
                }),
              ),
              rewards: [
                {
                  point: taskGroup?.xp_point - totalPreviousXpPoints,
                  media_type: taskGroup?.media_type,
                  media_url: taskGroup?.media_url,
                  media_file: null,
                },
              ],
              ...(isEditing ? { uuid: taskGroup?.uuid } : {}),
            }
          }),
        )
        setTiers(tiers)
        const allRequirements: { text: string; adapterIndex: number; tierIndex: number }[] = []
        selectedQuestInfo.task_groups.forEach((taskGroup, taskGroupIndex) => {
          taskGroup.tasks.forEach((task, taskIndex) => {
            allRequirements.push({
              text: task.requirement || "",
              adapterIndex: taskIndex,
              tierIndex: taskGroupIndex,
            })
          })
        })
        setRequirementText(allRequirements)
        setSelectedRewardType("xp")
      }
      setEditingQuestInfo()
    }
  }, [isEditing, selectedQuestInfo, isDuplicating])

  useEffect(() => {
    if (selectedCollectionInfo) {
      setQuestCollection({
        value: selectedCollectionInfo.name,
        uuid: selectedCollectionInfo.uuid as string,
      })
    }
  }, [selectedCollectionInfo, isCreatingQuestInsideCollection])

  const isInfoBtnDisabled =
    !questName?.length ||
    !questDescription.length ||
    !QuestDisplayUrlsInUse ||
    QuestDisplayUrlsInUse.includes(questDisplayUrl)
  // const isAdapterBtnDisabled =
  let isAdapterBtnDisabled = false
  tiers.forEach((tier) => {
    if (tier.adapters.length === 0) {
      isAdapterBtnDisabled = true
      return
    }
    tier.adapters.forEach((adapter) => {
      adapter.options.keys?.forEach((key) => {
        if (key.required && !key.user_level && !key.value) {
          isAdapterBtnDisabled = true
          return
        }
      })
    })
  })

  let isRewardBtnDisabled = false
  tiers.forEach((tier) => {
    tier.rewards.forEach((reward) => {
      if (isBadgeReward(reward)) {
        if (!reward.image_url) {
          isRewardBtnDisabled = true
          return
        }
      }
    })
  })
  requirementText.forEach((reqText) => {
    if (!reqText.text?.length) {
      isRewardBtnDisabled = true
    }
  })
  const isQuestCreationBtnDisabled =
    isInfoBtnDisabled || isAdapterBtnDisabled || isRewardBtnDisabled

  return (
    <div className="quest-creation-page-container">
      <QuestCreationHeader
        questCreationStep={questCreationStep}
        setQuestCreationStep={setQuestCreationStep}
      />
      {questCreationStep === 1 ? (
        <>
          <QuestInfoStep
            endDate={endDate}
            isDisabled={isInfoBtnDisabled}
            onSubmit={onSubmit}
            questCreationStep={questCreationStep}
            questDescription={questDescription}
            questDisplayUrl={questDisplayUrl}
            questDisplayUrlChanged={questDisplayUrlChanged}
            questEndDateActive={questEndDateActive}
            questMetaImage={questMetaImage}
            questName={questName}
            setEndDate={setEndDate}
            setQuestCreationStep={setQuestCreationStep}
            setQuestDescription={setQuestDescription}
            setQuestDisplayUrl={setQuestDisplayUrl}
            setQuestDisplayUrlChanged={setQuestDisplayUrlChanged}
            setQuestEndDateActive={setQuestEndDateActive}
            setQuestMetaImage={setQuestMetaImage}
            setQuestMetaImageFile={setQuestMetaImageFile}
            setQuestName={setQuestName}
            setTweetText={setTweetText}
            tweetText={tweetText}
            setQuestCollection={setQuestCollection}
            questCollection={questCollection}
          />
          {/* {questCreationButton("Setup Requirements")} */}
        </>
      ) : questCreationStep === 2 ? (
        <QuestAdapterStep
          activeTier={activeTier}
          addAdapter={addAdapterToTier}
          addTier={addTier}
          deleteTier={deleteTier}
          isDisabled={isAdapterBtnDisabled}
          onSubmit={onSubmit}
          removeAdapter={removeAdapterFromTier}
          setActiveTier={setActiveTier}
          tiers={tiers}
          updateAdapterInputValue={updateAdapterInputValue}
          updateTierName={updateTierName}
          insertKeysToAdapter={insertKeysToAdapter}
          updateAdapterKeysList={updateAdapterKeysList}
        />
      ) : questCreationStep === 3 ? (
        <QuestRewardStep
          creatingQuest={creatingQuest}
          isDisabled={isQuestCreationBtnDisabled}
          onSubmit={onSubmit}
          selectedRewardType={selectedRewardType}
          setActiveTier={setActiveTier}
          setBadgeReward={setBadgeReward}
          setQuestCreationStep={setQuestCreationStep}
          setSelectedRewardType={setSelectedRewardType}
          setXpRewardPoint={setXpRewardPoint}
          setXpRewardImage={setXpRewardImage}
          tiers={tiers}
          updateRequirementTextToTier={updateRequirementTextToTier}
          requirementText={requirementText}
          // resetTierRewards={resetTierRewards}
        />
      ) : questCreationStep === 4 ? (
        <QuestPublishStep
          endDate={endDate}
          openTestLink={openTestLink}
          publishQuest={publishQuest}
          questDisplayUrl={questDisplayUrl}
          questEndDateActive={questEndDateActive}
          questName={questName}
          setQuestCreationStep={setQuestCreationStep}
          tiers={tiers}
        />
      ) : (
        // TODO: add a 404 page here
        <div>This page does not exist please go back</div>
      )}
    </div>
  )
}
