import "./style.scss"

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-bottom-bar">
        <div className="footer-bottom-bar-left">
          Powered by{" "}
          <a href="https://rep3.gg" target="_blank" rel="noreferrer">
            Rep3
          </a>
        </div>
        <div className="footer-bottom-bar-right">
          <div className="footer-item">
            <a href="https://docs.rep3.gg/overview/welcome" target="_blank" rel="noreferrer">
              Docs
            </a>
          </div>
          <div className="footer-item">
            <a href="https://twitter.com/rep3gg" target="_blank" rel="noreferrer">
              Twitter
            </a>
          </div>
          <div className="footer-item">
            <a href="https://discord.com/invite/xK2WXUv3VG" target="_blank" rel="noreferrer">
              Discord
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
