import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ICommunitySocial } from "../../types/community"
import { QuestWithTaskGroups } from "../../types/quest"

interface CommunityState {
  communitySocials: ICommunitySocial[] | null
  communityQuests: QuestWithTaskGroups[] | null
}

const initialState: CommunityState = {
  communitySocials: null,
  communityQuests: null,
}

export const communitySlice = createSlice({
  name: "community",
  initialState,
  reducers: {
    setCommunitySocials: (state, action: PayloadAction<ICommunitySocial[]>) => {
      state.communitySocials = action.payload
    },
    setCommunityQuests: (state, action: PayloadAction<QuestWithTaskGroups[]>) => {
      state.communityQuests = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const communityActions = communitySlice.actions

export default communitySlice
