import React, { useRef } from "react"
import "./style.scss"

export default function ReviewStep({
  passCriteria,
  setPassCriteria,
  retryAfter,
  setRetryAfter,
}: {
  passCriteria: number
  setPassCriteria: (x: number) => void
  retryAfter: number
  setRetryAfter: (x: number) => void
}) {
  const retryInputRef = useRef<HTMLInputElement | null>(null)
  const passCriteriaInputRef = useRef<HTMLInputElement | null>(null)
  return (
    <div className="quiz-review-step-container">
      <div className="review-title">Intro to rep3</div>
      <div className="review-subtitle">5 Questions</div>
      <div className="review-description">
        Rep3 Provides gamified approach to community engagement via quests, loyalty campaigns, XP,
        leaderboards and analytics in your own space.
      </div>
      <div className="review-inputs-wrapper">
        <div className="pass-criteria-row">
          <div className="row-left">Pass Criteria</div>
          <div className="row-right" onClick={() => passCriteriaInputRef?.current?.focus()}>
            <input
              type="number"
              value={passCriteria}
              onChange={(e) => setPassCriteria(Number(e.target.value))}
              ref={passCriteriaInputRef}
              style={{ width: `calc(${String(passCriteria).length}ch + 28px)` }}
            />
            Correct Answers
          </div>
        </div>
        <div className="retry-after-row">
          <div className="row-left">Retry After</div>
          <div className="row-right" onClick={() => retryInputRef?.current?.focus()}>
            <input
              type="number"
              value={retryAfter}
              onChange={(e) => setRetryAfter(Number(e.target.value))}
              ref={retryInputRef}
              style={{ width: `calc(${String(retryAfter).length}ch + 28px)` }}
            />
            hrs
          </div>
        </div>
      </div>
    </div>
  )
}
