/* eslint-disable  @typescript-eslint/no-explicit-any */

import { AppDispatch, RootState } from ".."
import {
  CloudFrontImageUrl,
  ContractAddressTopicSignature,
  Rep3BotAddress,
  S3ImageBucketName,
  isProductionEnvironment,
} from "../../constant/constantValues"
import { routes } from "../../constant/routes"
import {
  Adapter,
  AdapterKeysWithValues,
  AdapterTypes,
  NonStrategyAdapter,
  StrategyAdapter,
  TAdapterKeysWithValuesQuiz,
  isSmartContractStrategyAdapter,
  isStrategyAdapter,
  IStrategyOptions,
  isStrategyOptions,
  ICSVStrategy,
  isCSVOptions,
} from "../../types/adapter"
import {
  IQuestAdapterTier,
  IQuestCreationInfo,
  IXpPointReward,
  QuestWithCustomFieldsAndRewards,
  TaskGroup,
  isBadgeReward,
  isXpReward,
} from "../../types/quest"
import apiClient from "../../utils/apiClient"
import { uploadFileToS3 } from "../../utils/awsUtils"
import { getFileExtension, stringWithoutSpecialChars } from "../../utils/regex"
import { questCreationActions } from "../reducers/questCreationSlice"
import dayjs from "dayjs"
import { showToast } from "./toastAction"
// import { deployNewQuestContract } from "../../utils/Rep3SdkUtils"
// import { ethers } from "ethers"
import { getStringAfterLastSlash } from "../../utils/adapterUtils"
import { createDispatchResponse, getTaskUuidsFromTaskGroupArray } from "../../utils/common"
import { Log, TransactionReceipt, WalletClient } from "viem"
import { message } from "antd"

export const fetchAvailableAdapters = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}${routes.adapter.baseUrl}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.data) {
        const adapters: any[] = []
        Object.keys(response.data?.non_strategy).forEach((key) =>
          adapters.push({
            adapter_types: response.data?.non_strategy[key].map((ele: NonStrategyAdapter) => ({
              ...ele,
              adapter_name: key,
            })),
            adapter_name: key,
          }),
        )
        Object.keys(response.data?.strategy).forEach((key) =>
          adapters.push({
            adapter_types: response.data?.strategy[key].map((ele: StrategyAdapter) => ({
              ...ele,
              adapter_name: key,
            })),
            adapter_name: key,
          }),
        )
        dispatch(questCreationActions.setAvailableAdapters(adapters))
        return 1
      }
      return 0
    } catch (err) {
      console.error("error while fetching adapter", err)
      return 0
    }
  }
}

const getContractAddressFromReceipt = (txnReceipt: TransactionReceipt) => {
  const eventWithTopic = txnReceipt.logs.filter(
    (log: Log) => log.topics[0] === ContractAddressTopicSignature,
  )?.[0]
  return eventWithTopic.address
}

const getTwitterAccountId = async (twitterAccountUsername: string, jwt: string) => {
  const response = await apiClient.post(
    `${process.env.REACT_APP_API_BASE_URL}${routes.common.twitterServiceAccountCreation}`,
    {
      account_username: twitterAccountUsername,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  )
  if (response.status === 200) {
    return response.data.id
  }
  return null
}

const extractAndSanitizeAdapterData = async (
  adapterKeysInfo: Array<AdapterKeysWithValues | TAdapterKeysWithValuesQuiz>,
  jwt: string,
) => {
  const output = await Promise.all(
    adapterKeysInfo?.map(async (keyObject) => {
      if (keyObject.key === "tweet_id") {
        return {
          ...keyObject,
          value: getStringAfterLastSlash(String(keyObject.value)),
        }
      } else if (keyObject.key === "following_account_id") {
        const accountUsername = getStringAfterLastSlash(String(keyObject.value))
        let accountId = ""
        if (accountUsername) {
          accountId = await getTwitterAccountId(accountUsername, jwt)
        }
        return {
          ...keyObject,
          value: accountId,
        }
      }
      return { ...keyObject }
    }),
  )
  return output
}
const createAdapterDataForContractStrategies = (tierAdapter: AdapterTypes) => {
  let adapterData = {}
  if (tierAdapter.adapter_subtype === "SMART_CONTRACT_erc20") {
    adapterData = {
      contractAddress: tierAdapter.options.keys.filter((x) => x.key === "contractAddress")[0]
        ?.value,
      type: "view",
      contractType: "erc20",
      chainId: tierAdapter.options.keys.filter((x) => x.key === "chainId")[0]?.value,
      balanceThreshold: parseInt(
        tierAdapter.options.keys.filter((x) => x.key === "balanceThreshold")[0]?.value.toString(),
      ),
      operator: tierAdapter.options.keys.filter((x) => x.key === "operator")[0]?.value,
      functionName: tierAdapter.options.keys.filter((x) => x.key === "functionName")[0]?.value,
    }
  } else if (tierAdapter.adapter_subtype === "SMART_CONTRACT_erc721") {
    adapterData = {
      contractAddress: tierAdapter.options.keys.filter((x) => x.key === "contractAddress")[0]
        ?.value,
      type: "view",
      contractType: "erc721",
      chainId: tierAdapter.options.keys.filter((x) => x.key === "chainId")[0]?.value,
      balanceThreshold: parseInt(
        tierAdapter.options.keys.filter((x) => x.key === "balanceThreshold")[0]?.value.toString(),
      ),
      operator: tierAdapter.options.keys.filter((x) => x.key === "operator")[0]?.value,
      functionName: tierAdapter.options.keys.filter((x) => x.key === "functionName")[0]?.value,
    }
  } else if (tierAdapter.adapter_subtype === "SMART_CONTRACT_erc1155") {
    adapterData = {
      contractAddress: tierAdapter.options.keys.filter((x) => x.key === "contractAddress")[0]
        ?.value,
      type: "view",
      contractType: "erc1155",
      chainId: tierAdapter.options.keys.filter((x) => x.key === "chainId")[0]?.value,
      balanceThreshold: parseInt(
        tierAdapter.options.keys.filter((x) => x.key === "balanceThreshold")[0]?.value.toString(),
      ),
      operator: tierAdapter.options.keys.filter((x) => x.key === "operator")[0]?.value,
      functionName: tierAdapter.options.keys.filter((x) => x.key === "functionName")[0]?.value,
      functionParam: [tierAdapter.options.keys.filter((x) => x.key === "inputParam")[0]?.value],
    }
  } else if (tierAdapter.adapter_subtype === "SMART_CONTRACT_view") {
    adapterData = {
      contractAddress: tierAdapter.options.keys.filter((x) => x.key === "contractAddress")[0]
        ?.value,
      type: "view",
      contractType: "custom",
      chainId: tierAdapter.options.keys.filter((x) => x.key === "chainId")[0]?.value,
      balanceThreshold: parseInt(
        tierAdapter.options.keys.filter((x) => x.key === "balanceThreshold")[0]?.value.toString(),
      ),
      operator: tierAdapter.options.keys.filter((x) => x.key === "operator")[0]?.value,
      functionName: tierAdapter.options.keys.filter((x) => x.key === "abi")[0]?.value,
      abi: tierAdapter.options.keys
        .filter((x) => x.key === "functionName")[0]
        ?.value?.toString()
        ?.replace(/(\r\n|\n|\r)/gm, ""),
      addressIndex: parseInt(
        tierAdapter.options.keys.filter((x) => x.key === "addressIndex")[0]?.value?.toString(),
      ),
      functionParam: tierAdapter.options.keys
        .filter((x) => x.key === "inputParam")
        ?.map((x) => x.value),
    }
  }
  return adapterData
}
export const createQuest = (
  questInfo: IQuestCreationInfo,
  requirementText: { text: string; adapterIndex: number; tierIndex: number }[],
  signer: WalletClient,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const selectedCommunity = getState().overview.selectedCommunity
      const isEditing = getState().questCreation.isEditing
      const selectedQuestInfo = getState().questCreation.selectedQuestInfo

      if (!jwt || !selectedCommunity)
        return {
          showPublishScreen: false,
          isError: true,
          deployedQuestUuid: null,
        }

      const imagesArray: any = []
      const formData = new FormData()
      const metadataObjectArray: any = []
      let metadataHashArray: any = []
      let arweaveImagesUrls: any = []
      const xpImages: { file: File; index: number; name: string }[] = []

      // TODO: edit case for badge images
      questInfo.tiers.forEach((tier, tierIndex) => {
        tier.rewards.forEach((reward) => {
          if (isBadgeReward(reward)) {
            imagesArray.push(reward.image_file)
            if (reward.image_file) formData.append("file", reward.image_file)
          } else if (isXpReward(reward) && reward?.media_file) {
            const fileExtension = getFileExtension(reward.media_file.name)
            const imageName = `${stringWithoutSpecialChars(
              selectedCommunity?.name || "other",
            )}/XpImage${dayjs().unix()}tier${tierIndex}.${fileExtension}`
            xpImages.push({ file: reward?.media_file, index: tierIndex, name: imageName })
          }
        })
      })

      if (imagesArray.length) {
        const res = await apiClient.post(
          `${process.env.REACT_APP_API_BASE_URL}${routes.arweave.imageUpload}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          },
        )
        arweaveImagesUrls = res.data
        let selectedImageIndex = 0
        questInfo.tiers.forEach((tier) => {
          tier.rewards.forEach((reward) => {
            if (isBadgeReward(reward)) {
              metadataObjectArray.push({
                name: questInfo.questName,
                description: "",
                image: arweaveImagesUrls[selectedImageIndex].image,
                level: tier.tierName,
                category: questInfo.questName,
              })
              selectedImageIndex += 1
            }
          })
        })
        if (metadataObjectArray.length) {
          const res = await apiClient.post(
            `${process.env.REACT_APP_API_BASE_URL}${routes.arweave.metadata}`,
            {
              metaData: metadataObjectArray,
            },
            {
              headers: {
                Authorization: `Bearer ${jwt}`,
              },
            },
          )
          metadataHashArray = res.data
        }
      }

      if (xpImages?.length) {
        const resp = await Promise.all(
          xpImages?.map((imageObj) => {
            return uploadFileToS3(imageObj?.name, S3ImageBucketName, jwt, imageObj.file)
          }),
        )
      }

      let uploadedFileUrl = ""

      if (questInfo?.questMetaImageFile) {
        const fileExtension = getFileExtension(questInfo?.questMetaImageFile.name)
        if (fileExtension) {
          const imageName = `${stringWithoutSpecialChars(
            selectedCommunity?.name || "other",
          )}/${questInfo?.questName}${dayjs().unix()}MetaImage.${fileExtension}`
          const res = await uploadFileToS3(
            imageName,
            S3ImageBucketName,
            jwt,
            questInfo?.questMetaImageFile,
          )
          if (res) {
            uploadedFileUrl = `${CloudFrontImageUrl}/${imageName}`
          }
        } else {
          dispatch(showToast("unsupported file name, file name should have an extension", "error"))
        }
      }

      const questCreationDetails = {
        community_uuid: selectedCommunity?.uuid,

        ...(questInfo?.questCollection?.uuid
          ? { collection_uuid: questInfo?.questCollection?.uuid }
          : {}),
        quest_info: {
          // bg_img_url: string,
          // contract_address: string, not compulsory
          description: questInfo?.questDescription,
          end_date: questInfo?.questEndDateActive ? `${dayjs(questInfo?.endDate).unix()}` : null, // not compulsory
          is_campaign: questInfo?.tiers?.length > 1 ? true : false,
          // is_featured: false,
          is_publish: isEditing ? selectedQuestInfo?.is_publish : false,
          // is_sequential: true,
          meta_img_url: uploadedFileUrl?.length ? uploadedFileUrl : null,
          name: questInfo?.questName,
          network: isProductionEnvironment ? "main" : "test",
          // showcase_img_url: string,
          // showcase_video_url: string,
          // time_interval: 0,
          tweet_text: questInfo?.tweetText,
          display_path: questInfo?.questDisplayUrl?.trim(),
        },
        task_groups: await Promise.all(
          questInfo?.tiers?.map(async (tier, tierIndex) => {
            const previousTiers = questInfo.tiers.slice(0, tierIndex)
            const totalPreviousXp = previousTiers.reduce((total, tier) => {
              const previousReward = tier.rewards[tier.rewards.length - 1]
              return total + (isXpReward(previousReward) ? previousReward.point : 0)
            }, 0)
            const xpPointReward = tier.rewards[tier.rewards.length - 1]
            return {
              rewards: tier?.rewards
                ?.filter((reward) => (isXpReward(reward) ? false : true))
                ?.map((reward) => {
                  return isXpReward(reward)
                    ? {}
                    : {
                        category: 0,
                        image_url: arweaveImagesUrls?.[tierIndex]?.image,
                        is_dynamic: false,
                        metadata_hash: metadataHashArray?.[tierIndex]?.metadata, // create from arweave {name, image_url, level: tierIndex+1, category: same}
                        name: questInfo?.questName, // compulsory
                        // video_url: string,
                        type: "badge",
                      }
                }),
              tasks: await Promise.all(
                tier.adapters.filter(adapter => adapter.adapter_name !== "COMMUNITY_STRATEGY").map(async (tierAdapter, i) => {
                  let adapterData: any = {}
                  let isAnyUserRequirement = false
                  if (tierAdapter?.adapter_name === "SMART_CONTRACT") {
                    adapterData = createAdapterDataForContractStrategies(tierAdapter)
                    isAnyUserRequirement = false
                  } else if (tierAdapter?.adapter_name === "CSV") {
                    tierAdapter?.options?.keys.map((keyObject) => {
                      if (isCSVOptions(keyObject.default)) {
                        const readOnlyOption = keyObject.default
                        const option: ICSVStrategy = structuredClone(readOnlyOption)
                        if (keyObject.value === "") {
                          adapterData[keyObject?.key] = option.strategyOptions
                        } else {
                          option.strategyOptions.csvKey = keyObject.value as string
                          adapterData[keyObject?.key] = option.strategyOptions
                        }
                      }
                    })
                  } else if (tierAdapter?.adapter_name === "PREREQUISITE") {
                    const questUuid = tierAdapter?.options?.keys?.[0]?.value
                    const questTier = tierAdapter?.options?.keys?.[1]?.value?.toString()
                    adapterData = {
                      quest_uuid_tier_map: { [questUuid]: parseInt(questTier) },
                    }
                  } else if (tierAdapter?.adapter_name === "SUBMIT_FORM") {
                    adapterData = {
                      title:tierAdapter?.options?.keys?.filter(key => key.key === "title")[0]?.value,
                      description:tierAdapter?.options?.keys?.filter(key => key.key === "description")[0]?.value,
                      max_user_submission:Number(tierAdapter?.options?.keys?.filter(key => key.key === "max_user_submission")[0]?.value) > 1 ?Number(tierAdapter?.options?.keys?.filter(key => key.key === "max_user_submission")[0]?.value) : 1,
                      approved_submission_count:Number(tierAdapter?.options?.keys?.filter(key => key.key === "approved_submission_count")[0]?.value) || Number(tierAdapter?.options?.keys?.[3]?.default),
                      ...(isEditing ? {uuid:tierAdapter?.options?.keys?.filter(key => key.key === "submit_form_uuid")[0]?.value}:{})
                    }
                  } else {
                    const extractedAdapterData = await extractAndSanitizeAdapterData(
                      tierAdapter?.options?.keys,
                      jwt,
                    )

                    extractedAdapterData?.forEach((keyObject) => {
                      if (keyObject.key === "strategyOptions") {
                        adapterData[keyObject?.key] = keyObject.value
                      } else if (
                        tierAdapter.adapter_name === "REFERRAL_count" &&
                        typeof keyObject.value === "string"
                      ) {
                        adapterData[keyObject?.key] = parseInt(keyObject.value)
                      } else {
                        if (!keyObject?.user_level) {
                          adapterData[keyObject?.key] = keyObject.value
                        } else {
                          isAnyUserRequirement = true
                        }
                      }
                    })
                  }
                  // Change here for contract
                  if (isStrategyAdapter(tierAdapter)) {
                    return {
                      adapter_data: adapterData, // { guildId: "", roleId: "" } only for those whose user level false
                      is_requirements_from_user_required: isAnyUserRequirement, // check from keys array and send true if any user level
                      strategy_uuid: tierAdapter?.strategy_uuid,
                      identities_required:
                        tierAdapter?.options?.identities === "DISCORD"
                          ? tierAdapter?.options?.identities
                          : null, // DISCORD, TWITTER, copy from the adapter array this is string or null
                      requirement: requirementText.filter(
                        (x) => x.adapterIndex === i && x.tierIndex === tierIndex,
                      )?.[0]?.text,
                      type: "strategy_task",
                      uuid: tierAdapter.uuid,
                    }
                  } else if (tierAdapter?.task_type === "TASK_WHITELIST_ADAPTER") {
                    return {
                      uuid: adapterData.webhook_id,
                      task_data: adapterData, // same like adapter data
                      task_type: tierAdapter?.task_type,
                      identities_required: tierAdapter?.options?.identities, // DISCORD, TWITTER,
                      // requirement: Stake ACX Token to claim the badge, Stake ACX,
                      requirement: requirementText.filter(
                        (x) => x.adapterIndex === i && x.tierIndex === tierIndex,
                      )?.[0]?.text,
                      type: "non_strategy_task",
                    }
                  } else if(tierAdapter?.task_type === "SUBMIT_FORM") {
                    return {
                      submit_form_data: adapterData, // same like adapter data
                      task_type: tierAdapter?.task_type,
                      identities_required: tierAdapter?.options?.identities, // DISCORD, TWITTER,
                      // requirement: Stake ACX Token to claim the badge, Stake ACX,
                      requirement: requirementText.filter(
                        (x) => x.adapterIndex === i && x.tierIndex === tierIndex,
                      )?.[0]?.text,
                      type: "non_strategy_task",
                      uuid: tierAdapter.uuid,
                    }                    
                  }else {
                    return {
                      task_data: adapterData, // same like adapter data
                      task_type: tierAdapter?.task_type,
                      identities_required: tierAdapter?.options?.identities, // DISCORD, TWITTER,
                      // requirement: Stake ACX Token to claim the badge, Stake ACX,
                      requirement: requirementText.filter(
                        (x) => x.adapterIndex === i && x.tierIndex === tierIndex,
                      )?.[0]?.text,
                      type: "non_strategy_task",
                      uuid: tierAdapter.uuid,
                    }
                  }
                }),
              ),
              // tier: tierIndex + 1,
              name: tier.tierName,
              xp_point: isXpReward(xpPointReward) ? xpPointReward?.point + totalPreviousXp : 0,
              uuid: tier.uuid,
              media_url:
                isXpReward(xpPointReward) && xpPointReward?.media_file
                  ? `${CloudFrontImageUrl}/${xpImages?.find((ele) => ele.index === tierIndex)
                      ?.name}`
                  : isXpReward(xpPointReward) && xpPointReward?.media_url
                  ? xpPointReward?.media_url
                  : null,
              media_type:
                isXpReward(xpPointReward) && xpPointReward?.media_file
                  ? "image"
                  : isXpReward(xpPointReward) && xpPointReward?.media_url
                  ? "image"
                  : null,
            }
          }),
        ),
      }
      if (imagesArray.length) {
        // let deployedQuestUuid = null
        // const txReceipt = await deployNewQuestContract(
        //   questInfo?.questName,
        //   questInfo?.questName.slice(0, 3),
        //   Rep3BotAddress,
        //   signer,
        //   async (txHash) => {
        //     console.log("txHash in txHash callback", txHash)
        //     if (!txHash) return
        //     const response = await apiClient.post(
        //       `${process.env.REACT_APP_API_BASE_URL}${routes.quest.baseUrl}`,
        //       {
        //         ...questCreationDetails,
        //         quest_info: { ...questCreationDetails?.quest_info, txn_hash: txHash },
        //       },
        //       {
        //         headers: {
        //           Authorization: `Bearer ${jwt}`,
        //         },
        //       },
        //     )
        //     console.log("response of quest creation", response.data)
        //     if (response.data) {
        //       // TODO: show antd toast and redirect to homepage can be done here or on submit call
        //       deployedQuestUuid = response.data.uuid
        //       // return response.data.uuid
        //     }
        //     return {
        //       showPublishScreen: false,
        //       isError: true,
        //       deployedQuestUuid: null,
        //     }
        //   },
        // )
        // // TODO: send all quest info
        // const contractAddress = getContractAddressFromReceipt(txReceipt)
        // console.log("contract address is", contractAddress)
        // await apiClient.put(
        //   `${process.env.REACT_APP_API_BASE_URL}${routes.quest.baseUrl}/${deployedQuestUuid}`,
        //   {
        //     community_uuid: selectedCommunity?.uuid,
        //     task_groups: [],
        //     quest_info: {
        //       ...questCreationDetails.quest_info,
        //       contract_address: contractAddress,
        //     },
        //   },
        //   {
        //     headers: {
        //       Authorization: `Bearer ${jwt}`,
        //     },
        //   },
        // )
        // return {
        //   showPublishScreen: true,
        //   isError: false,
        //   deployedQuestUuid: deployedQuestUuid,
        // }
        return {
          showPublishScreen: true,
          isError: false,
          deployedQuestUuid: null,
        }
      } else {
        let response
        if (isEditing && selectedQuestInfo) {
          // const questCreation
          const oldTaskUuids = getTaskUuidsFromTaskGroupArray(selectedQuestInfo.task_groups)
          const currentTaskUuids = questInfo.tiers.reduce(
            (acc: string[], curr: IQuestAdapterTier): string[] => {
              const uuidsInTaskGroup = curr.adapters
                .map((task) => task.uuid)
                .filter((task): task is string => task !== undefined && task !== null)
              return [...acc, ...uuidsInTaskGroup]
            },
            [],
          )

          const deletedTaskUuids = oldTaskUuids.filter(
            (oldTaskUuid) => !currentTaskUuids.includes(oldTaskUuid),
          )

          // TODO: make an delete api call

          response = await apiClient.put(
            `${process.env.REACT_APP_API_BASE_URL}${routes.quest.baseUrl}/${selectedQuestInfo?.uuid}`,
            questCreationDetails,
            {
              headers: {
                Authorization: `Bearer ${jwt}`,
              },
            },
          )

          if (deletedTaskUuids.length) {
            const deleteResponse = await apiClient.put(
              `${process.env.REACT_APP_API_BASE_URL}${routes.task.baseUrl}`,
              {
                task_uuid_arr: deletedTaskUuids,
              },
              {
                headers: {
                  Authorization: `Bearer ${jwt}`,
                },
              },
            )
          }
        } else {
          response = await apiClient.post(
            `${process.env.REACT_APP_API_BASE_URL}${routes.quest.baseUrl}`,
            questCreationDetails,
            {
              headers: {
                Authorization: `Bearer ${jwt}`,
              },
            },
          )
        }
        if (response?.data) {
          // TODO: show antd toast and redirect to homepage can be done here or on submit call
          // return response.data.uuid
          return {
            showPublishScreen: true,
            isError: false,
            deployedQuestUuid: response.data.uuid,
          }
        }
        return {
          showPublishScreen: false,
          isError: true,
          deployedQuestUuid: null,
        }
      }
    } catch (err) {
      console.error("error while creating quest", err)
      message.error("something went wrong, please try again")
      return {
        showPublishScreen: false,
        isError: true,
        deployedQuestUuid: null,
      }
    }
  }
}

export const publishQuestApi = (
  questUuid: string,
  questInfo: IQuestCreationInfo,
  isCampaign: boolean,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const selectedCommunity = getState().overview.selectedCommunity

      const questCreationDetails = {
        community_uuid: selectedCommunity?.uuid,
        quest_info: {
          // bg_img_url: string,
          // contract_address: string, not compulsory
          description: questInfo?.questDescription,
          end_date: questInfo?.questEndDateActive ? `${dayjs(questInfo?.endDate).unix()}` : null, // not compulsory
          is_campaign: isCampaign,
          // is_featured: false,
          is_publish: true,
          // is_sequential: true,
          meta_img_url: questInfo?.questMetaImage ? questInfo?.questMetaImage : null,
          name: questInfo?.questName,
          network: isProductionEnvironment ? "main" : "test",
          // showcase_img_url: string,
          // showcase_video_url: string,
          // time_interval: 0,
          tweet_text: questInfo?.tweetText,
          display_path: questInfo?.questDisplayUrl,
        },
        task_groups: [],
      }
      const response = await apiClient.put(
        `${process.env.REACT_APP_API_BASE_URL}${routes.quest.baseUrl}/${questUuid}`,
        questCreationDetails,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.data) {
        // TODO: show toast published successfully
        dispatch(showToast("published successfully", "success"))
        return createDispatchResponse("success", 1)
      }
      return createDispatchResponse("error", 0)
    } catch (err) {
      console.error("error while publishing quest", err)
      return createDispatchResponse("error", 0)
    }
  }
}

export const fetchQuestsInCommunity = (communityUuid: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}${routes.quest.baseUrl}?community_uuid=${communityUuid}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.status === 200) {
        dispatch(questCreationActions.setQuests(response.data))
        return 1
      }
      return 0
    } catch (err) {
      console.error("error while fetching quests", err)
      return 0
    }
  }
}

export const fetchQuestFromUuid = (questUuid: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}${routes.quest.baseUrl}/${questUuid}?include=task_groups,rewards,repeat_quest&include_custom_fields=true`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.status === 200) {
        return response.data
      }
      return null
    } catch (err) {
      console.error("error while fetching quest", err)
      return null
    }
  }
}

export const setIsInternalNavigation = (navigationStatus: boolean) => {
  return async (dispatch: AppDispatch) => {
    dispatch(questCreationActions.setIsInternalNavigation(navigationStatus))
  }
}

export const editQuest = (questUuid: string) => {
  return async (dispatch: AppDispatch) => {
    const res = await dispatch(fetchQuestFromUuid(questUuid))
    if (res) dispatch(questCreationActions.setSelectedQuestInfo(res))
    dispatch(questCreationActions.setIsEditing(true))
  }
}

export const duplicateQuest = (questUuid: string) => {
  return async (dispatch: AppDispatch) => {
    const res = await dispatch(fetchQuestFromUuid(questUuid))
    if (res) dispatch(questCreationActions.setSelectedQuestInfo(res))
    dispatch(questCreationActions.setIsDuplicating(true))
  }
}

export const openCsvModal = (questUuid: string) => {
  return async (dispatch: AppDispatch) => {
    const res = await dispatch(fetchQuestFromUuid(questUuid))
    if (res) dispatch(questCreationActions.setSelectedQuestInfo(res))
    dispatch(questCreationActions.setIsCsvModalOpen(true))
  }
}

export const unPublishQuestApi = (
  questUuid: string,
  questInfo: IQuestCreationInfo,
  isCampaign: boolean,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const selectedCommunity = getState().overview.selectedCommunity

      const questCreationDetails = {
        community_uuid: selectedCommunity?.uuid,
        quest_info: {
          description: questInfo?.questDescription,
          end_date: questInfo?.questEndDateActive ? `${dayjs(questInfo?.endDate).unix()}` : null, // not compulsory
          is_campaign: isCampaign,
          is_publish: false,
          meta_img_url: questInfo?.questMetaImage ? questInfo?.questMetaImage : null,
          name: questInfo?.questName,
          network: isProductionEnvironment ? "main" : "test",
          tweet_text: questInfo?.tweetText,
          display_path: questInfo?.questDisplayUrl,
        },
        task_groups: [],
      }
      const response = await apiClient.put(
        `${process.env.REACT_APP_API_BASE_URL}${routes.quest.baseUrl}/${questUuid}`,
        questCreationDetails,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.data) {
        // TODO: show toast published successfully
        dispatch(showToast("unpublished successfully", "success"))
        return createDispatchResponse("success", 1)
      }
      return createDispatchResponse("error", 0)
    } catch (err) {
      console.error("error while unpublishing quest", err)
      return createDispatchResponse("error", 0)
    }
  }
}

export const updateQuestActiveStatus = (
  questUuid: string,
  questInfo: IQuestCreationInfo,
  activeStatus: boolean,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const selectedCommunity = getState().overview.selectedCommunity

      const questCreationDetails = {
        community_uuid: selectedCommunity?.uuid,
        quest_info: {
          description: questInfo?.questDescription,
          end_date: questInfo?.questEndDateActive ? `${dayjs(questInfo?.endDate).unix()}` : null, // not compulsory
          is_campaign: questInfo?.tiers?.length > 1 ? true : false,
          is_publish: activeStatus,
          is_active: activeStatus,
          meta_img_url: questInfo?.questMetaImage ? questInfo?.questMetaImage : null,
          name: questInfo?.questName,
          network: isProductionEnvironment ? "main" : "test",
          tweet_text: questInfo?.tweetText,
          display_path: questInfo?.questDisplayUrl,
        },
        task_groups: [],
      }
      const response = await apiClient.put(
        `${process.env.REACT_APP_API_BASE_URL}${routes.quest.baseUrl}/${questUuid}`,
        questCreationDetails,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.data) {
        // TODO: show toast published successfully
        dispatch(
          showToast(
            activeStatus ? "activated successfully" : "deactivated successfully",
            "success",
          ),
        )
        return createDispatchResponse("success", 1)
      }
      return createDispatchResponse("error", 0)
    } catch (err) {
      console.error(`error while ${activeStatus ? "activating" : "deactivating"} quest`, err)
      return createDispatchResponse("error", 0)
    }
  }
}
