import { AppDispatch, RootState } from ".."
import { routes } from "../../constant/routes"
import { Address } from "../../types/common"
import { IOverviewPageQuests, IUpdateSubmitFormData } from "../../types/quest"
import apiClient from "../../utils/apiClient"
import { overviewActions } from "../reducers/overviewSlice"
import { setReferralInfo } from "./settingAction"
import { CommunityWithCustomFields, LeaderBoardItem } from "../../types/community"
import { Dayjs } from "dayjs"
import {
  Collection,
  createNewCollection,
  reorderCollections,
  UpdateCollection,
} from "../../types/collection"

export const fetchActiveQuestsAndCampaigns = (communityName: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(overviewActions.setIsFetchingCampaigns(true))
    const jwt = getState().auth.jwt
    const response = await apiClient.get(
      `${process.env.REACT_APP_API_BASE_URL}${routes.community.baseUrl}/${communityName}/quests?include_custom_fields=true`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    )

    if (Array.isArray(response.data)) {
      const filteredQuests = response.data.filter((ele) =>
        process.env.REACT_APP_NODE_ENV === "production"
          ? ele?.network === "MAIN"
          : ele?.network === "TEST",
      )
      dispatch(overviewActions.setActiveCampaignsAndQuests(filteredQuests))
      dispatch(overviewActions.setIsFetchingCampaigns(false))
    }
  }
}

export const fetchUserCommunities = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      dispatch(overviewActions.setFetchingCommunitiesStatus("loading"))
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}${routes.user.adminCommunities}?include_custom_fields=true`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.data) {
        dispatch(overviewActions.setUserCommunities(response.data))
        const selectedCommunity = getState().overview.selectedCommunity
        if (!selectedCommunity) {
          dispatch(overviewActions.setSelectedCommunity(response.data?.[0]))
          dispatch(setReferralInfo(response.data?.[0]?.referral_points))
        }
      }
      dispatch(overviewActions.setFetchingCommunitiesStatus("success"))
    } catch (err) {
      console.error("error while fetching user communities", err)
      dispatch(overviewActions.setFetchingCommunitiesStatus("error"))
    }
  }
}

export const createCollection = (collection: createNewCollection) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_BASE_URL}${routes.collection.baseUrl}`,
        collection,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "application/json",
          },
        },
      )
      if (response.status === 200) {
        dispatch(fetchCollections(collection.community_uuid))
        return true
      }
      return false
    } catch (e) {
      console.error(e, "upload error")
      return false
    }
  }
}

export const updateCollection = (collection: UpdateCollection, communityUuid: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.put(
        `${process.env.REACT_APP_API_BASE_URL}${routes.collection.baseUrl}`,
        collection,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "application/json",
          },
        },
      )
      if (response.status === 200) {
        dispatch(fetchCollections(communityUuid))
        return true
      }
      return false
    } catch (e) {
      console.error(e, "upload error")
      return false
    }
  }
}

export const resetUserCommunities = () => {
  return (dispatch: AppDispatch) => {
    dispatch(overviewActions.setUserCommunities(null))
    dispatch(overviewActions.setSelectedCommunity(null))
  }
}

export const reOrderCollection = (orderList: reorderCollections) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.put(
        `${process.env.REACT_APP_API_BASE_URL}${routes.collection.orderCollections}`,
        orderList,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "application/json",
          },
        },
      )
      if (response.status === 200) {
        dispatch(fetchCollections(orderList.community_uuid))
        return true
      }
      return false
    } catch (e) {
      console.error(e, "upload error")
      return false
    }
  }
}

export const fetchCommunityOnchainGraphData = (
  communityUuid: string,
  timeRangeValue: string,
  isCustomDate: boolean = false,
  timestampRange: string | null = null,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}${
          routes.analytics.rewardActionGraphStats
        }?community_uuid=${communityUuid}&${
          isCustomDate ? `timestamp_range=${timestampRange}` : `time_period=${timeRangeValue}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.status === 200) {
        const dates = Object.keys(response.data)
        const mappedData = dates.map((date) => ({
          ...response.data[date],
          DATE: date,
        }))
        dispatch(overviewActions.setCommunityOnChainGraphData(mappedData))
      }
    } catch (err) {
      console.error("error while fetching community onchain graph data", err)
    }
  }
}

export const fetchSmartInsights = (community_uuid: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}${routes.analytics.rewardActionStats}?community_uuid=${community_uuid}&time_period=24_hour`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.data) {
        dispatch(overviewActions.setSmartInsightsData(response.data))
      }
    } catch (err) {
      console.error("error while fetching smart insights", err)
    }
  }
}

export const fetchCollections = (community_uuid: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}${routes.collection.baseUrl}?community_uuid=${community_uuid}&quest_include_custom_fields=true&quest_custom_fields=task_group_count,participant_count,is_repeat_quest,downgrade_count,upgrade_count,collection_uuid,xp_range,submit_form_uuid,csv_task_uuid_arr`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.status === 200 && response.data.length) {
        let copiedCollection = structuredClone(response.data)
        copiedCollection = copiedCollection
          .filter((collection: Collection) => collection.name !== "Miscellaneous")
          .sort((a: Collection, b: Collection) => a.order - b.order)

        dispatch(overviewActions.setCollections(copiedCollection))
      }
    } catch (err) {
      console.error("error while fetching smart insights", err)
    }
  }
}

export const fetchSubmitForms = (
  submitFormId: string,
  status: string = "",
  pageNo: number = 1,
  limit: number = 25,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}${
          routes.user.adminSubmitForm
        }?submit_form_uuid=${submitFormId}${
          status === "" ? "" : `&status=${status}`
        }&page=${pageNo}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.status === 200) {
        const tempObj = {
          formId: submitFormId,
          formData: response.data,
        }
        dispatch(overviewActions.setSubmitFormInfo(tempObj))
      }
      return response.data
    } catch (err) {
      console.error(err, "error occured")
      return err
    }
  }
}

export const updateBulkStatus = (communityId: string, formId: string, operation: string = "") => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt

      const response = await apiClient.put(
        `${process.env.REACT_APP_API_BASE_URL}${routes.user.adminSubmitFormUpdateAll}`,
        {
          community_uuid: communityId,
          submit_form_uuid: formId,
          operation: operation,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
    } catch (e) {
      console.error(e, "error is here")
    }
  }
}

export const updateSingleEntrySubmitForms = (
  communityName: string,
  modalId: string,
  data: IUpdateSubmitFormData[],
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.put(
        `${process.env.REACT_APP_API_BASE_URL}${routes.user.adminSubmitForm}`,
        {
          community_name: communityName,
          data: data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.status === 200) {
        const tempAction = {
          modalId: modalId,
          data: data,
        }
        dispatch(overviewActions.updateSubmitFormInfo(tempAction))
        return response.status
      }
    } catch (err) {
      console.error(err)
      return err
    }
  }
}

export const updateLeaderboardInfo = (leaderboardStatus: boolean) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const selectedCommunity = getState().overview.selectedCommunity
      const {
        created_at,
        updated_at,
        uuid,
        quest_tier_claimed_count,
        total_member_count,
        ...rest
      } = selectedCommunity as CommunityWithCustomFields
      const response = await apiClient.put(
        `${process.env.REACT_APP_API_BASE_URL}${routes.community.baseUrl}/${selectedCommunity?.name}`,
        {
          ...rest,
          show_leaderboard: leaderboardStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.status === 200) {
        const tempSelected = { ...(selectedCommunity as CommunityWithCustomFields) }
        tempSelected.show_leaderboard = leaderboardStatus
        dispatch(overviewActions.setSelectedCommunity(tempSelected))
      }
    } catch (err) {
      console.error(err)
    }
  }
}

export const submitCSVForStrategy = (fileName: string, uuid: string, file: File) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_BASE_URL}${routes.task.adminCSVUpload}?file_obj_name=${fileName}&bucket_name=rep3-community-files&task_uuid=${uuid}`,
        file,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "application/octet-stream",
          },
        },
      )
      if (response.status === 200) {
        return true
      }
      return false
    } catch (e) {
      console.error(e, "upload error")
      return false
    }
  }
}

export const fetchModalLeaderboardData = async (
  communityName: string,
  jwt: string,
  startDate: number | null,
  endDate: number | null,
  pageNo: number = 1,
  limit: number = 25,
  lastRank: number = 1,
) => {
  try {
    let response = null
    if (jwt) {
      response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}${routes.community.baseUrl}/${communityName}${routes.leaderboard.baseUrl}?page=${pageNo}&limit=${limit}&start_data=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
    } else {
      response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}${routes.community.baseUrl}/${communityName}${routes.leaderboard.baseUrl}?page=${pageNo}&limit=${limit}`,
      )
    }

    if (response.status === 200) {
      const data = response?.data
      // let currentRank = 1;
      let currentXP = data.leaderboard[0]?.current_xp
      console.log(data, "data is here")
      const rankedData = data.leaderboard.map((item: LeaderBoardItem) => {
        const tempXP = currentXP
        if (tempXP !== item.current_xp) {
          lastRank = item.rank
          currentXP = item.current_xp
        }
        return {
          ...item,
          rank: tempXP === item.current_xp ? lastRank : item.rank,
        }
      })
      const resultData: {
        current_page: number
        last_record: LeaderBoardItem
        leaderboard: LeaderBoardItem[]
        total_pages: number
        total_records: number
      } = { ...data, leaderboard: rankedData }
      return resultData
    }
  } catch (error) {
    console.error(error, "info")
  }
}
