const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isValidEmail = (email: string) => {
  return emailRegex.test(email.toLowerCase())
}

export const getFileExtension = (fileName: string) => {
  const re = /(?:\.([^.]+))?$/
  return re.exec(fileName)?.[1]
}

export const stringWithoutSpecialChars = (inputString: string) => {
  return inputString.replace(/[^a-zA-Z0-9]/g, "")
}
