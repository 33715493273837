import { ConfigProvider, Select, Switch } from "antd"
import { AdapterKeysWithValues, AdapterTypes, TQuizQA } from "../../types/adapter"
import "./style.scss"
import { useAppSelector } from "../../hooks/reduxHooks"
import { FaChevronDown } from "react-icons/fa"

export default function QuestPrerequisiteAdapter({
  keyObject,
  adapterInfo,
  onSelectChange,
  isTierInput,
  setIsTierInput,
  updateAdapterInputValue,
  isEditing,
}: {
  isEditing: boolean
  isTierInput: boolean
  adapterInfo: AdapterTypes
  keyObject: AdapterKeysWithValues
  onSelectChange: (x: unknown) => void
  setIsTierInput: (x: boolean) => void
  updateAdapterInputValue: (adapterKeyIndex: number, value: number | string | TQuizQA) => void
}) {
  const communityQuests = useAppSelector((x) => x.community.communityQuests)
  const communityLabel = communityQuests?.map((x) => {
    return { value: x?.uuid, label: x?.name }
  })
  const tierLabel:
    | {
        value: string
        label: string
      }[]
    | undefined = []
  communityQuests
    ?.filter((x) => x.uuid === adapterInfo.options?.keys?.[0]?.value)
    .forEach((x) => {
      return x.task_groups?.forEach((y) => {
        tierLabel?.push({ value: y?.tier?.toString(), label: y?.tier?.toString() })
      })
    })
  console.log("here...edit", isEditing, keyObject)
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            optionFontSize: 16,
            optionHeight: 32,
            optionLineHeight: "1.5rem",
          },
        },
      }}
    >
      {keyObject.key === "quest_uuid_tier_map" ||
      (keyObject.key !== "quest_uuid_tier_map" && isTierInput) ? (
        <div className={`single-adapter-input-wrapper`}>
          <div className="single-adapter-input-text">
            {keyObject.key === "quest_uuid_tier_map" ? "Select Collection" : "Select Tier"}
          </div>
          <div className="prerequisite-adapter-select">
            <Select
              onChange={(x) => {
                onSelectChange(x)
                if (keyObject.key === "quest_uuid_tier_map") {
                  updateAdapterInputValue(
                    1,
                    communityQuests
                      ?.filter((y) => y.uuid === x.toString())?.[0]
                      ?.task_groups?.length?.toString() ?? "1",
                  )
                }
              }}
              suffixIcon={<FaChevronDown size={16} />}
              placeholder={
                keyObject.key === "quest_uuid_tier_map"
                  ? "Select from all Quests"
                  : "Select from all Tier"
              }
              options={
                keyObject.key !== "quest_uuid_tier_map" && isTierInput ? tierLabel : communityLabel
              }
              value={
                keyObject.key === "quest_uuid_tier_map"
                  ? communityLabel?.filter((x) => x.value === keyObject?.value)[0]
                  : { value: keyObject?.value, label: keyObject?.value }
              }
              style={{ flex: 1, display: "flex" }}
            />
          </div>
        </div>
      ) : null}

      {keyObject.key === "quest_uuid_tier_map" ? (
        <div className="address-switcher">
          <div className="switch-helper-text">Tier Completion</div>
          <Switch
            checked={isTierInput}
            onChange={() => {
              setIsTierInput(!isTierInput)
            }}
          />
        </div>
      ) : null}
    </ConfigProvider>
  )
}
