import React, { ReactNode } from "react"
import "./style.scss"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import { useAccount } from "wagmi"
import { useAppSelector } from "../../hooks/reduxHooks"
import EmptyPageScreen from "../../components/EmptyPageScreen"
import AuthWrapper from "../AuthWrapper"

export default function DashboardView({ children }: { children: ReactNode }) {
  // const { isConnected } = useAccount()
  // const jwt = useAppSelector((state) => state.auth.jwt)
  // const fetchingCommunitiesStatus = useAppSelector(
  //   (state) => state.overview.fetchingCommunitiesStatus,
  // )
  // const userCommunities = useAppSelector((state) => state.overview.userCommunities)

  // const isLoading = fetchingCommunitiesStatus === "loading"
  // const isSignedIn = isConnected && jwt

  // if (isLoading) {
  //   return (
  //     <div className="dashboard-view-container">
  //       <Header />
  //       <div className="dashboard-view-children-wrapper">loading</div>
  //       <Footer />
  //     </div>
  //   )
  // }

  // if (!isSignedIn || !userCommunities?.length) {
  //   return (
  //     <div className="dashboard-view-container">
  //       <Header />
  //       <div className="dashboard-view-empty-screen-wrapper">
  //         <EmptyPageScreen />
  //       </div>
  //     </div>
  //   )
  // }

  console.count("rendering")

  return (
    <AuthWrapper>
      <div className="dashboard-view-container">
        <Header />
        <div className="dashboard-view-children-wrapper">{children}</div>
        <Footer />
      </div>
    </AuthWrapper>
  )
}
