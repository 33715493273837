import { ICommunityOnChainGraphDayData, ICommunityOnChainGraphGroupedData } from "../types/graph"

export const getGraphDataMapped = (communityOnChainGraphData: ICommunityOnChainGraphDayData[]) => {
  const graphDataMapped: ICommunityOnChainGraphGroupedData[] = []
  const graphDataLength = communityOnChainGraphData?.length || 1

  const numberOfDaysInEachBar = Math.ceil(graphDataLength / 16)

  const initialGroupedData: ICommunityOnChainGraphGroupedData = {
    DATE: [],
    TIER_DOWNGRADE: 0,
    TIER_CLAIMED: 0,
    TIER_UPGRADE: 0,
    MEMBER_COUNT: 0,
  }
  let singleGroupedData = initialGroupedData

  communityOnChainGraphData?.forEach((data, index) => {
    singleGroupedData.DATE.push(data.DATE)
    singleGroupedData.TIER_DOWNGRADE += data.TIER_DOWNGRADE
    singleGroupedData.TIER_CLAIMED += data.TIER_CLAIMED
    singleGroupedData.TIER_UPGRADE += data.TIER_UPGRADE
    singleGroupedData.MEMBER_COUNT = data.MEMBER_COUNT
    // console.log("single grouped data for ", index, data, singleGroupedData)
    if ((index + 1) % numberOfDaysInEachBar === 0) {
      graphDataMapped.push(singleGroupedData)
      // singleGroupedData = { ...initialGroupedData, DATE: [] }
      singleGroupedData = {
        DATE: [],
        TIER_DOWNGRADE: 0,
        TIER_CLAIMED: 0,
        TIER_UPGRADE: 0,
        MEMBER_COUNT: 0,
      }

      // console.log("single grouped data after clearing ", index, singleGroupedData)
    }
  })

  if (
    communityOnChainGraphData &&
    communityOnChainGraphData?.length % numberOfDaysInEachBar !== 0
  ) {
    graphDataMapped.push(singleGroupedData)
  }

  return graphDataMapped
}
