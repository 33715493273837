// import styles from "./style.module.scss";
// import Image from "next/image";
import CoinGif from "../../assets/gifs/COIN.gif"
import { useAppSelector } from "../../hooks/reduxHooks"
// import NextImage from "../NextImage";
// import { TaskGroup } from "../../types/common"
import Lottie from "react-lottie"
import animationData from "../../assets/lottie/XP_badge_bg.json"
import animationDataLight from "../../assets/lottie/XP_badge_light_bg.json"
import "./style.scss"
import { Rep3PlaceHolderLogo } from "../../constant/constantValues"

export default function XpBadgeSnapShot({
  xpPoint,
  showcase,
  isLight = true,
  showLottie = true,
}: Readonly<{
  xpPoint: number
  showcase: boolean
  isLight?: boolean
  showLottie?: boolean
}>): JSX.Element {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: isLight ? animationDataLight : animationData,
    renderer: "svg",
  }
  const communityInfo = useAppSelector((state) => state.overview.selectedCommunity)
  //   console.log("task group", taskGroup)
  return (
    <div className={showcase ? "xpBadgeSnapShotContainerSmall" : "xpBadgeSnapShotContainer"}>
      <div className={showcase ? "xpBadgeSnapShotWrapperSmall" : "xpBadgeSnapShotWrapper"}>
        <div className="xpBadgeSnapShotBgWrapper">
          {showLottie ? <Lottie options={defaultOptions} height={"100%"} width={"100%"} /> : null}
        </div>
        <div className="upperDiv">
          <div className="logoImageContainerWrapper">
            <img src={communityInfo?.logo_url || Rep3PlaceHolderLogo} alt="" />
          </div>
          <div className="xpTag">
            <img alt="coinGif" src={CoinGif || ""} />
            <div className="xpFont">{xpPoint} XP</div>
          </div>
        </div>
        <div>
          <div className={showcase ? "xpPointsTitleSmall" : "xpPointsTitle"}>{xpPoint}</div>
          <div className={showcase ? "xpPointsLabelSmall" : "xpPointsLabel"}>XP POINTS</div>
        </div>
      </div>
    </div>
  )
}
