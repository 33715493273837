import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
// import reportWebVitals from "./reportWebVitals"
import store, { persistor } from "./store"
import { Provider } from "react-redux"
import * as Sentry from "@sentry/react"
import "@rainbow-me/rainbowkit/styles.css"
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit"
import { WagmiProvider } from "wagmi"
import { mainnet, polygon, sepolia, polygonMumbai } from "wagmi/chains"
// import { infuraProvider } from "wagmi/providers/infura"
// import { publicProvider } from "wagmi/providers/public"
import { isProductionEnvironment } from "./constant/constantValues"
import { PersistGate } from "redux-persist/integration/react"
import AntdToast from "./components/AntdToast"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

Sentry.init({
  dsn: "https://31adb734bdc9e5cd03ba27bfa1a7715e@o4503902930272256.ingest.sentry.io/4505918258675712",
  integrations: [new Sentry.BrowserTracing()],
  // Performance Monitoring
  sampleRate: isProductionEnvironment ? 1.0 : 0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// const { chains, provider } = configureChains(
//   [sepolia, goerli, mainnet, polygon, polygonMumbai],
//   [publicProvider()],
//   {
//     pollingInterval: 8_000,
//   },
// )
// const { connectors } = getDefaultWallets({
//   appName: "My RainbowKit App",
//   projectId: "YOUR_PROJECT_ID",
//   chains,
// })
// const wagmiConfig = createClient({
//   autoConnect: true,
//   connectors,
//   provider,
// })

const config = getDefaultConfig({
  appName: "RainbowKit demo",
  projectId: "04fee8a5af6e74e7d14797a640e325cd",
  chains: [mainnet, polygon, sepolia, polygonMumbai],
})

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
              <AntdToast />
            </PersistGate>
          </Provider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
