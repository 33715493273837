import React, { useState } from "react"
import "./style.scss"
import { MdCheck } from "react-icons/md"
import { ReactComponent as ArrowForwardIcon } from "../../assets/icons/arrowForwardIcon.svg"
import { IoMdClose } from "react-icons/io"
import Lottie from "react-lottie"
import animationLoadingData from "../../assets/lottie/buttonLoader.json"

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationLoadingData,
  renderer: "svg",
}

const InfoPopup = ({
  nextFunction,
  isDelete,
  closeModal,
}: {
  nextFunction: () => Promise<void>
  isDelete: boolean
  closeModal: () => void
}) => {
  const [isTakingAction, setIsTakingAction] = useState(false)
  return (
    <div className="InfoModalBackdrop">
      <div className="signMessageBlurLayerWrapper">
        <div className="signMessageBlurLayer"></div>
      </div>
      <div></div>
      <div className="InfoMain">
        <div className="close-modal-container">
          <IoMdClose onClick={() => closeModal()} />
        </div>
        <div className={`InfoHeading headerSignMessageHeading1`}>
          {isDelete ? "Delete" : "Unpublish"} Quest
        </div>
        <div className="message-info">
          <MdCheck />
          <div>
            {isDelete
              ? "People who have participated will lose their XP"
              : "People who have participated will retain their XP"}
          </div>
        </div>
        <div className="message-info">
          <MdCheck />
          <div>
            {isDelete
              ? "Quest will stop showing up on the community page once deleted."
              : "Quest will stop showing up on the community page once unpublished."}
          </div>
        </div>
        <div className="ActionBtn">
          <button
            onClick={() => {
              nextFunction()
              setIsTakingAction(true)
            }}
          >
            <>{isDelete ? "Delete Quest" : "Unpublish Quest"}</>
            {isTakingAction ? (
              <Lottie options={lottieOptions} width={20} height={20} style={{ margin: 0 }} />
            ) : (
              <ArrowForwardIcon />
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default InfoPopup
