import React, { useEffect, useRef, useState } from "react"
import {
  AdapterKeysWithValues,
  AdapterTypes,
  IStrategyOptions,
  TQuizAdapterQuestion,
  TQuizQA,
  isNonStrategyAdapter,
  isPrerequisite,
  isQuizNonStrategyAdapter,
  isSubmitFormNonStrategyAdapter,
  isStrategyAdapter,
} from "../../../types/adapter"
import {
  getAdapterInputHeadingFromKey,
  getAdapterName,
  getAdapterPlaceHolderFromKey,
  getClassNameFromType,
  getIconFromType,
} from "../../../utils/adapterUtils"
import "./style.scss"
import { ReactComponent as CloseIcon } from "../../../assets/icons/closeIcon.svg"
import { ReactComponent as CopyIcon } from "../../../assets/icons/ContentCopy.svg"
import ContractStrategyAdapterInputType from "../../ContractStrategyAdapterInputType"
import { MdOutlineUploadFile } from "react-icons/md"
import SubmitFormAdapter from "../../Adapters/SubmitFormAdapter"
import { Switch, message } from "antd"
import { useAppSelector } from "../../../hooks/reduxHooks"
import { IQuestAdapterTier } from "../../../types/quest"
import QuizAdapter from "../../Adapters/QuizAdapter"
import QuestTierQuizUI from "../../Adapters/QuizAdapter/QuestTierQuizUI"
import { v4 } from "uuid"
import { uploadFileToS3 } from "../../../utils/awsUtils"

import { DownLoadSampleCSV } from "../../../utils/common"
import QuestPrerequisiteAdapter from "../../QuestPrerequisiteAdapter"

const RenderPasswordRetryInput = ({
  keyObject,
  index,
  updateAdapterInputValue,
}: {
  updateAdapterInputValue: (adapterKeyIndex: number, value: number | string | TQuizQA) => void
  index: number
  keyObject: AdapterKeysWithValues
}) => {
  const isEditing = useAppSelector((state) => state.questCreation.isEditing)
  const [isTierInput, setIsTierInput] = useState(
    isEditing && keyObject.value !== "0" && keyObject.value !== 0 ? true : false,
  )
  let initialUpdate = false
  useEffect(() => {
    if (keyObject.value === "" && !initialUpdate) {
      initialUpdate = true
      updateAdapterInputValue(index, 0)
    }
  }, [])
  return (
    <>
      <div className="check-password-divider"></div>
      <div className="address-switcher">
        <div className="switch-helper-text">Enable Retry</div>
        <Switch
          checked={isTierInput}
          onChange={() => {
            if (!isTierInput) {
              setIsTierInput(!isTierInput)
            } else {
              console.log("here set to 0")
              updateAdapterInputValue(index, 0)
              setIsTierInput(!isTierInput)
            }
          }}
        />
      </div>
      {isTierInput ? (
        <div
          className={`single-adapter-input-wrapper ${
            typeof keyObject.value === "string" && !keyObject.value.length
              ? "single-adapter-empty-input-wrapper"
              : ""
          }`}
          key={index}
        >
          <div className="single-adapter-input-text">
            {getAdapterInputHeadingFromKey(keyObject.key)}
          </div>
          <div className={`single-adapter-input`}>
            <input
              type="text"
              value={keyObject.value}
              onChange={(e) => {
                updateAdapterInputValue(index, parseInt(e.target.value))
              }}
              placeholder={getAdapterPlaceHolderFromKey(keyObject.key)}
              className={""}
            />
            <div className="single-adapter-input-text">hrs</div>
          </div>
        </div>
      ) : null}
    </>
  )
}
const RenderSingleAdapter = ({
  adapterInfo,
  removeAdapter,
  updateAdapterInputValue,
  insertKeysToAdapter,
  updateAdapterKeysList,
  tier,
}: {
  adapterInfo: AdapterTypes
  removeAdapter: () => void
  updateAdapterInputValue: (adapterKeyIndex: number, value: number | string | TQuizQA) => void
  insertKeysToAdapter: (newAdapterToAdd: AdapterKeysWithValues[]) => void
  updateAdapterKeysList: (keyName: string, value: string | number) => void
  tier: IQuestAdapterTier
}) => {
  const isEditing = useAppSelector((state) => state.questCreation.isEditing)
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const jwt = useAppSelector((state) => state.auth.jwt)
  const [fileName, setFileName] = useState<string>("")
  const [isTierInput, setIsTierInput] = useState<boolean>(isEditing)
  const handleFileUploadClick = () => {
    hiddenFileInput.current?.click()
  }

  console.log(adapterInfo, "info of adapter")
  const handleUpdateFileName = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (event.target.files && jwt) {
      const file = event.target.files[0]
      setFileName(file.name)
      const fileNewName = adapterInfo.uuid
        ? `${adapterInfo.uuid}_${file.name}`
        : `${v4()}_${file.name}`
      const newFile = new File([file], fileNewName, { type: file.type })
      updateAdapterInputValue(index, newFile.name)
      uploadFileToS3(newFile.name, "rep3-community-files", jwt, file)
    }
  }

  const handleCopy = async (val: string | number) => {
    try {
      await navigator.clipboard.writeText(val as string)
      message.success("Id copied")
    } catch (err) {
      message.error("Failed to copy")
    }
  }
  return (
    <div className="single-adapter-render-container">
      <div className="single-adapter-heading-row">
        <div className="single-adapter-heading-left">
          <div
            className={`single-adapter-heading-icon ${getClassNameFromType(
              adapterInfo.adapter_name,
            )}`}
          >
            {getIconFromType(adapterInfo.adapter_name)}
          </div>
          <div className="single-adapter-heading-text">
            {getAdapterName(
              isStrategyAdapter(adapterInfo) ? adapterInfo.adapter_subtype : adapterInfo.task_type,
            )}
          </div>
        </div>
        {/* {isEditing && tier?.uuid ? null : ( */}
        <div
          className="single-adapter-heading-right"
          onClick={() => {
            adapterInfo.adapter_name === "COMMUNITY_STRATEGY"
              ? message.info("Custom strategy can not be deleted (Contact support).")
              : removeAdapter()
          }}
        >
          <CloseIcon />
        </div>
        {/* )} */}
      </div>
      {adapterInfo.adapter_name === "CSV" && (
        <div className="csv-info-container">
          <div className="csv-info-text">
            Manage tier member updates by uploading a CSV file in the provided format.
          </div>
          <div className="csv-template-download" onClick={() => DownLoadSampleCSV()}>
            Download template
          </div>
        </div>
      )}
      {isStrategyAdapter(adapterInfo) && adapterInfo?.adapter_name === "SMART_CONTRACT" ? (
        <ContractStrategyAdapterInputType
          adapterInfo={adapterInfo}
          insertKeysToAdapter={(newAdapterToAdd: AdapterKeysWithValues[]) =>
            insertKeysToAdapter(newAdapterToAdd)
          }
          updateAdapterKeysList={updateAdapterKeysList}
          updateAdapterInputValue={updateAdapterInputValue}
        />
      ) : isQuizNonStrategyAdapter(adapterInfo) && adapterInfo?.adapter_name === "QUIZ_FORM" ? (
        <QuizAdapter adapterInfo={adapterInfo} updateAdapterInputValue={updateAdapterInputValue} />
      ) : isSubmitFormNonStrategyAdapter(adapterInfo) ? (
        <SubmitFormAdapter
          adapterInfo={adapterInfo}
          updateAdapterInputValue={updateAdapterInputValue}
          insertKeysToAdapter={(newAdapterToAdd: AdapterKeysWithValues[]) =>
            insertKeysToAdapter(newAdapterToAdd)
          }
          updateAdapterKeysList={updateAdapterKeysList}
        />
      ) : isPrerequisite(adapterInfo) ? (
        <div className="single-adapter-requirements-input-wrapper">
          {adapterInfo.options?.keys.map((keyObject, index) => (
            <QuestPrerequisiteAdapter
              isEditing={isEditing}
              onSelectChange={(x) => {
                updateAdapterInputValue(index, x?.toString() ?? "")
              }}
              updateAdapterInputValue={updateAdapterInputValue}
              isTierInput={isTierInput}
              setIsTierInput={(x) => setIsTierInput(x)}
              adapterInfo={adapterInfo}
              key={index}
              keyObject={keyObject}
            />
          ))}
        </div>
      ) : (
        <div className="single-adapter-requirements-input-wrapper">
          {adapterInfo.options?.keys.map((keyObject, index) =>
            adapterInfo.adapter_name === "CHECK_PASSWORD" && keyObject.key === "retry_after" ? (
              <RenderPasswordRetryInput
                keyObject={keyObject}
                index={index}
                key={index}
                updateAdapterInputValue={updateAdapterInputValue}
              />
            ) : (
              <div
                className={`single-adapter-input-wrapper ${
                  typeof keyObject.value === "string" && !keyObject.value.length
                    ? "single-adapter-empty-input-wrapper"
                    : ""
                }`}
                key={index}
              >
                <div className="single-adapter-input-text">
                  {getAdapterInputHeadingFromKey(keyObject.key)}
                </div>
                <div
                  className={`single-adapter-input ${
                    adapterInfo.adapter_name === "TASK_WHITELIST_ADAPTER" ? "webhook-id-input" : ""
                  } `}
                >
                  <input
                    type="text"
                    disabled={
                      adapterInfo.adapter_name === "TASK_WHITELIST_ADAPTER" ||
                      adapterInfo.adapter_name === "CSV"
                    }
                    value={keyObject.value}
                    onChange={(e) => {
                      updateAdapterInputValue(index, e.target.value)
                    }}
                    placeholder={getAdapterPlaceHolderFromKey(
                      adapterInfo.adapter_name === "TELEGRAM_LINK" ? "telegram" : keyObject.key,
                    )}
                    className={adapterInfo.adapter_name === "CSV" ? "hide-this" : ""}
                  />
                  {adapterInfo.adapter_name === "CSV" ? (
                    <input
                      type="text"
                      disabled={true}
                      value={fileName}
                      placeholder={getAdapterPlaceHolderFromKey(keyObject.key)}
                    />
                  ) : null}
                </div>
                {adapterInfo.adapter_name === "TASK_WHITELIST_ADAPTER" && (
                  <div className="webhook-copy-text" onClick={() => handleCopy(keyObject.value)}>
                    <CopyIcon /> Copy
                  </div>
                )}
                {adapterInfo.adapter_name === "CSV" && (
                  <div className="upload-csv-text" onClick={() => handleFileUploadClick()}>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={(e) => {
                        handleUpdateFileName(e, index)
                      }}
                      style={{ display: "none" }} // Hide input off-screen
                      accept="text/csv"
                    />{" "}
                    Upload <MdOutlineUploadFile />
                  </div>
                )}
              </div>
            ),
          )}
        </div>
      )}
      {adapterInfo.adapter_name === "REFERRAL_count" && (
        <div className="webhook-support-text-wrapper">
          <div
            className="webhook-support-text"
            onClick={() =>
              window.open(
                "https://rep3.notion.site/How-referrals-work-97d4eaa15da1432b98dad64f4666aa4f?pvs=4",
                "_blank",
              )
            }
          >
            How referral works
          </div>
        </div>
      )}
      {adapterInfo.adapter_name === "TASK_WHITELIST_ADAPTER" && (
        <div className="webhook-support-text-wrapper">
          <div
            className="webhook-support-text"
            onClick={() =>
              window.open(
                "https://rep3.notion.site/How-to-use-webhooks-5f0f9338d1954ae0a1e42708797ec3d8?pvs=4",
                "_blank",
              )
            }
          >
            How to use webhook?
          </div>
        </div>
      )}
    </div>
  )
}

export default function RenderAdapters({
  adapters,
  removeAdapter,
  updateAdapterInputValue,
  insertKeysToAdapter,
  updateAdapterKeysList,
  tier,
}: {
  adapters: AdapterTypes[]
  removeAdapter: (adapterIndex: number) => void
  updateAdapterInputValue: (
    adapterIndex: number,
    adapterKeyIndex: number,
    value: number | string | TQuizQA,
  ) => void
  insertKeysToAdapter: (adapterIndex: number, newAdapterToAdd: AdapterKeysWithValues[]) => void
  updateAdapterKeysList: (adapterIndex: number, keyName: string, value: number | string) => void
  tier: IQuestAdapterTier
}) {
  return (
    <div className={`render-adapter-container ${adapters.length ? "" : "empty-adapter-container"}`}>
      {adapters?.length ? (
        adapters.map((adapter, adapterIndex) => (
          <div key={adapterIndex}>
            <RenderSingleAdapter
              adapterInfo={adapter}
              removeAdapter={() => removeAdapter(adapterIndex)}
              updateAdapterInputValue={(
                adapterKeyIndex: number,
                value: number | string | TQuizQA,
              ) => updateAdapterInputValue(adapterIndex, adapterKeyIndex, value)}
              insertKeysToAdapter={(value) => insertKeysToAdapter(adapterIndex, value)}
              updateAdapterKeysList={(keyName, value) =>
                updateAdapterKeysList(adapterIndex, keyName, value)
              }
              tier={tier}
            />
            {adapterIndex !== adapters.length - 1 ? (
              <div className="adapters-separator"> </div>
            ) : null}
          </div>
        ))
      ) : (
        <div className="add-adapters-text">Select Requirements from window on right</div>
      )}
      {/* <QuizAdapter /> */}
    </div>
  )
}
