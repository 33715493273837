import { AppDispatch, RootState } from ".."
import { settingActions } from "../reducers/settingSlice"
import apiClient from "../../utils/apiClient"
import { routes } from "../../constant/routes"
import { Address } from "../../types/common"
import { message } from "antd"
import { AxiosError } from "axios"

export const setReferralInfo = (points: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(
        settingActions.setReferralPoints({
          isActive: Boolean(points),
          points: points,
        }),
      )
    } catch (err) {
      console.error(err)
    }
  }
}

export const updateReferralInfo = (isActive: boolean, points: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const selectedCommunity = getState().overview.selectedCommunity
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_BASE_URL}${routes.referral.referralSetup}`,
        {
          community_uuid: selectedCommunity?.uuid,
          points: isActive ? points : 0,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.status === 200) {
        dispatch(
          settingActions.setReferralPoints({
            isActive,
            points,
          }),
        )
      }
    } catch (err) {
      console.error(err)
    }
  }
}

export const getAllAdmins = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const selectedCommunityUuid = getState().overview.selectedCommunity?.uuid
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}${routes.community.admins}?community_uuid=${selectedCommunityUuid}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.status === 200) {
        dispatch(settingActions.setAllAdmins(response.data.admins))
      }
    } catch (err) {
      console.error(err)
    }
  }
}

export const removeAdmin = (address: Address) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const selectedCommunityUuid = getState().overview.selectedCommunity?.uuid
      const response = await apiClient.put(
        `${process.env.REACT_APP_API_BASE_URL}${routes.community.removeAdmin}`,
        {
          community_uuid: selectedCommunityUuid,
          eoa: address,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.status === 200) {
        dispatch(settingActions.removeAdmin(address))
      }
    } catch (err) {
      console.error(err)
    }
  }
}

export const addAdmin = (address: Address) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const selectedCommunityUuid = getState().overview.selectedCommunity?.uuid
      const response = await apiClient.put(
        `${process.env.REACT_APP_API_BASE_URL}${routes.community.addAdmin}`,
        {
          community_uuid: selectedCommunityUuid,
          eoa: address,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.status === 200) {
        dispatch(settingActions.addAdmin(address))
      }
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        // Handle Axios specific error properties
        const axiosError = err as AxiosError
        console.error("Axios error:", axiosError)
        message.error(`${(axiosError.response?.data as Error)?.message}`)
      } else {
        // Handle other types of errors in some generic way
        console.error("Unknown error:", err)
        message.error("An unknown error occurred")
      }
    }
  }
}
