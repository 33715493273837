import React from "react"
import OverviewPage from "../../components/OverviewPage"
import DashboardView from "../../views/DashboardView"
import HasCommunitiesWrapper from "../../views/HasCommunitiesWrapper"

export default function Overview() {
  return (
    <DashboardView>
      <HasCommunitiesWrapper>
        <OverviewPage />
      </HasCommunitiesWrapper>
    </DashboardView>
  )
}
