import React, { useEffect, useRef, useState } from "react"
import "./style.scss"
import HomeIcon from "../../assets/icons/homeIcon.svg"
import plusIcon from "../../assets/icons/plusIcon.svg"
import { Select } from "antd"
// import { ConnectButton } from "@rainbow-me/rainbowkit"
import { BsCaretDownFill } from "react-icons/bs"
import { useAccount } from "wagmi"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
// import { authWithSign, setJwt } from "../../store/actions/authAction"
import { fetchUserCommunities, resetUserCommunities } from "../../store/actions/overviewAction"
import Image from "../Image"
import { overviewActions } from "../../store/reducers/overviewSlice"
import { NavLink, useNavigate } from "react-router-dom"
// import { initializeRep3Package } from "../../utils/Rep3SdkUtils"
import WalletConnectButton from "../ConnectButton"
import { setReferralInfo } from "../../store/actions/settingAction"
import { Address } from "viem"
import {
  setJwt,
  setPrevAddress,
  setPrevChainId,
  setShowSignMessage,
} from "../../store/actions/authAction"
const { Option } = Select

export default function Header() {
  const { isConnected, chainId, address } = useAccount()
  // const { data: signer } = useSigner()
  const dispatch = useAppDispatch()
  // const [isSignedWithBackend, setIsSignedWithBackend] = useState(false)
  // const [signMessageDisabled, setSignMessageDisabled] = useState(false)
  const jwt = useAppSelector((state) => state.auth.jwt)
  const userCommunities = useAppSelector((state) => state.overview.userCommunities)
  const selectedCommunity = useAppSelector((state) => state.overview.selectedCommunity)
  // const { disconnect } = useDisconnect()
  const navigate = useNavigate()
  // const prevChain = useRef<number | null>(null)
  // const prevAddress = useRef<Address | null>(null)
  const prevChainId = useAppSelector((state) => state.auth.prevChainId)
  const prevAddress = useAppSelector((state) => state.auth.prevAddress)

  useEffect(() => {
    if (jwt && isConnected) {
      dispatch(fetchUserCommunities())
    }
  }, [jwt, isConnected])

  const handleDisconnect = () => {
    console.log("disconnecting")
    // dispatch(refreshUserIdentities())
    // dispatch(questAction.setQuestClaimedRewardInfo(null))
    // Sentry.configureScope((scope) => scope.setUser(null))
    // setIsSignedWithBackend(false)
    dispatch(resetUserCommunities())
    localStorage.clear()
    dispatch(setJwt(null))
    dispatch(setShowSignMessage(false))
  }

  useEffect(() => {
    console.log("running useEffect with", chainId, prevChainId)
    if (chainId) {
      if (prevChainId && prevChainId !== chainId) {
        console.log("disconnecting from chain")
        handleDisconnect()
        dispatch(setPrevChainId(null))
        dispatch(setPrevAddress(null))
        // prevChain = null
        // prevAddress = null
      } else dispatch(setPrevChainId(chainId))
    }
  }, [chainId])

  useEffect(() => {
    // if (address) {
    if (prevAddress && prevAddress !== address) {
      console.log("disconnecting from address")
      handleDisconnect()
      dispatch(setPrevChainId(null))
      dispatch(setPrevAddress(null))
      // prevAddress = null
      // prevChain = null
    } else dispatch(setPrevAddress(address || null))
    // }
  }, [address])

  // const signMessage = async () => {
  //   try {
  //     if (!signMessageDisabled && address && signer) {
  //       setSignMessageDisabled(true)
  //       const res = await dispatch(authWithSign(address, signer))
  //       if (res) {
  //         setIsSignedWithBackend(true)
  //         initializeRep3Package(signer)
  //       }
  //       setSignMessageDisabled(false)
  //     }
  //   } catch (err) {
  //     console.error("in catch err", err)
  //     setSignMessageDisabled(false)
  //   }
  // }

  const handleCommunityChange = (value: string) => {
    const pickedCommunity = userCommunities?.filter((community) => community.name === value)
    if (pickedCommunity?.length) {
      dispatch(overviewActions.setSelectedCommunity(pickedCommunity[0]))
      dispatch(setReferralInfo(pickedCommunity[0]?.referral_points))
      navigate("/")
    }
    // setTimeRangeDropdownValue(value)
    // if (value === "custom_dates") {
    //   setIsRangePickerOpen(true)
    // }
  }

  return (
    <>
      <div className="header-container">
        <div className="header-left-wrapper">
          <div className="header-home-logo">
            <img src={HomeIcon} alt="" />
          </div>
          {isConnected && jwt ? (
            <div className="header-nav-wrapper">
              <NavLink to="/">
                <div className="nav-item">Overview</div>
              </NavLink>
              {/* <div className="nav-item">Quest and Campaigns</div> */}
              {selectedCommunity ? (
                <NavLink to="/leaderboard">
                  <div className="nav-item">Leaderboard</div>
                </NavLink>
              ) : null}
              {selectedCommunity ? (
                <NavLink to="/community">
                  <div className="nav-item">Community Space</div>
                </NavLink>
              ) : null}
              {selectedCommunity ? (
                <NavLink to="/settings">
                  <div className="nav-item">Settings</div>
                </NavLink>
              ) : null}

              {/* <div className="nav-item">Settings</div> */}
            </div>
          ) : null}
        </div>
        <div className="header-right-wrapper">
          {userCommunities?.length && isConnected ? (
            <div className="header-community-switch">
              <Select
                popupClassName="header-community-switch-dropdown"
                suffixIcon={
                  <div style={{ background: "#ebebeb" }}>
                    <BsCaretDownFill />
                  </div>
                }
                style={{ width: "12.5rem" }}
                // open={true}
                defaultValue={userCommunities[0]?.name}
                onChange={handleCommunityChange}
                value={selectedCommunity?.name}
              >
                <Option value="admin" label="admin" disabled={true}>
                  Admin
                </Option>
                {userCommunities?.map((community) => (
                  <Option value={community.name} label={community.name} key={community.uuid}>
                    <div className="header-community-switch-dropdown-item">
                      <div className="header-community-switch-dropdown-item-left">
                        {community?.logo_url ? <Image src={community.logo_url} width={20} /> : null}
                        {community.name}
                      </div>
                    </div>
                  </Option>
                ))}
                <Option value="india1" label="india1">
                  <div
                    className="header-community-switch-dropdown-item"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      navigate("/setup/community")
                    }}
                  >
                    <div className="header-community-switch-dropdown-item-left">
                      <img src={plusIcon} alt="" /> New Community
                    </div>
                  </div>
                </Option>
              </Select>
            </div>
          ) : null}
          <WalletConnectButton />
          {/* <ConnectButton.Custom>
            {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
              const connected = mounted && account && chain
              return (
                <div
                  {...(!mounted && {
                    "aria-hidden": true,
                    style: {
                      opacity: 0,
                      pointerEvents: "none",
                      userSelect: "none",
                    },
                  })}
                >
                  {(() => {
                    if (!connected) {
                      return (
                        <button
                          onClick={openConnectModal}
                          type="button"
                          className="connect-wallet-btn"
                        >
                          Connect
                        </button>
                      )
                    }

                    if (chain.unsupported) {
                      return (
                        <button
                          onClick={openChainModal}
                          type="button"
                          className="connect-wallet-btn"
                        >
                          Wrong network
                        </button>
                      )
                    }

                    return (
                      <div style={{ display: "flex", gap: 12 }}>
                        <button
                          onClick={openAccountModal}
                          type="button"
                          className="connect-wallet-btn"
                        >
                          {account.displayName}
                        </button>
                      </div>
                    )
                  })()}
                </div>
              )
            }}
          </ConnectButton.Custom>{" "} */}
        </div>
      </div>
      {/* {!jwt && !isSignedWithBackend && isConnected && (
        <div className="signMessageBackdrop">
          <div className="signMessageMain">
            <div className={`signMessageHeading headerSignMessageHeading1`}>
              Sign the message in your wallet to continue
            </div>
            <div className="headerSignMessageHeading2">
              rep3 uses this signature to verify that you’re the owner of this Ethereum address.
            </div>
            <div className={`continueSignBtn headerSignMessageButton`}>
              <button onClick={() => signMessage()}>Continue</button>
            </div>
            <div className="disconnectBtn" onClick={() => disconnect()}>
              disconnect
            </div>
          </div>
        </div>
      )} */}
    </>
  )
}
