// import noCampaignIcon from "../../assets/icons/noCampaignIcon.svg"
import noCampaignImage from "../../assets/images/noCampaignImage.png"
import {ReactComponent as ArrowForwardIcon} from "../../assets/icons/arrowForwardIcon.svg"
import { ReactComponent as TelegramIcon } from "../../assets/icons/telegramIcon.svg"
import { ReactComponent as NoCampaignIcon } from "../../assets/icons/noCampaignIcon.svg"
import "./style.scss"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import { setIsInternalNavigation } from "../../store/actions/questCreation"

// const questTemplates = [
//   {
//     templateHeading: "Follow On twitter",
//     templateDescription: "Upgrade badges based on amount staked by a person",
//   },
//   {
//     templateHeading: "Like and RT a tweet",
//     templateDescription: "Upgrade badges based on amount staked by a person",
//   },
//   {
//     templateHeading: "Join Discord",
//     templateDescription: "Upgrade badges based on amount staked by a person",
//   },
//   {
//     templateHeading: "Like and RT a tweet",
//     templateDescription: "Upgrade badges based on amount staked by a person",
//   },
// ]

export default function CampaignAndQuestEmptyCard({
  hasCampaigns = false,
  hasQuests = false,
}: {
  hasCampaigns: boolean
  hasQuests: boolean
}) {
  // const templateSwiperElRef = useRef<SwiperRef>(null)
  // const [selectedNav, setSelectedNav] = useState<"campaigns" | "quests">(
  //   !hasCampaigns ? "campaigns" : "quests",
  // )
  const userInfo = useAppSelector((state) => state.user.userInfo)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  if (hasCampaigns || hasQuests) {
    return null
  }

  return (
    <div className="campaign-and-quest-empty-card-container">
      <div className="empty-card-left">
        <div className="empty-card-left-top">
          <NoCampaignIcon />
          {/* <img src={noCampaignIcon} alt="" /> */}
          <div className="no-campaign-heading">
            Set it up in <br />3 min or less
            {/* {!hasCampaigns && !hasQuests
              ? "No Active Quests or Campaigns"
              : hasCampaigns
              ? "No Active Quests"
              : "No Active Campaigns"} */}
          </div>
          {!hasCampaigns && !hasQuests ? null : (
            <div className="no-campaign-subheading">
              Get people active in your community with more and more{" "}
              {hasCampaigns ? "quests" : "campaigns"}
            </div>
          )}
        </div>
        <div className="empty-card-left-bottom">
          {/* {userInfo?.is_whitelisted ? ( */}
          <button
            onClick={() => {
              console.log("start please")

              dispatch(setIsInternalNavigation(true))
              navigate("/setup/quest")
            }}
          >
            Setup Quest <ArrowForwardIcon/>
          </button>
          {/* ) : (
            <button
              onClick={() => {
                window.open(TelegramWhitelistLink, "_blank")
              }}
            >
              Reach out to get Whitelisted <TelegramIcon />
            </button>
          )} */}
        </div>
      </div>
      <div className="empty-card-right">
        <img src={noCampaignImage} alt="" />
        {/* <div className="empty-card-right-heading">Select From Templates</div>
        <div className="templates-nav-row">
          <div className="templates-nav-left">
            {!hasCampaigns ? (
              <div
                className={`templates-nav-item ${
                  selectedNav === "campaigns" ? "selected-nav-item" : ""
                }`}
                onClick={() => setSelectedNav("campaigns")}
              >
                Campaign
              </div>
            ) : null}
            {!hasQuests ? (
              <div
                className={`templates-nav-item ${
                  selectedNav === "quests" ? "selected-nav-item" : ""
                }`}
                onClick={() => setSelectedNav("quests")}
              >
                Quest
              </div>
            ) : null}
          </div>
          <div className="templates-nav-right">
            <ArrowLeftIcon onClick={() => templateSwiperElRef?.current?.swiper?.slidePrev()} />
            <ArrowRightIcon onClick={() => templateSwiperElRef?.current?.swiper?.slideNext()} />
          </div>
        </div>
        <div className="templates-wrapper">
          <Swiper spaceBetween={8} slidesPerView={3} ref={templateSwiperElRef}>
            {questTemplates.map((quest, index) => (
              <SwiperSlide key={index}>
                <CampaignTemplateCard
                  isCampaign={selectedNav === "campaigns"}
                  templateHeading={quest.templateHeading}
                  templateDescription={quest.templateDescription}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div> */}
      </div>
    </div>
  )
}
