import React from "react"
import "./App.scss"
import Router from "./router"

function App() {
  return (
    <div className="App">
      <Router />
      {/* <header className="App-header">
        <button onClick={() => dispatch(incrementValue())}>Increment {count}</button>
      </header> */}
    </div>
  )
}

export default App
