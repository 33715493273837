import { combineReducers, configureStore } from "@reduxjs/toolkit"
import rootSlice from "./reducers/rootSlice"
import storage from "redux-persist/lib/storage"
import { persistReducer, persistStore } from "redux-persist"
import authSlice from "./reducers/authSlice"
import overviewSlice from "./reducers/overviewSlice"
import questCreationSlice from "./reducers/questCreationSlice"
import toastSlice from "./reducers/toastSlice"
import userSlice from "./reducers/userSlice"
import communitySlice from "./reducers/communitySlice"
import settingSlice from "./reducers/settingSlice"

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
}

const rootReducer = combineReducers({
  root: rootSlice.reducer,
  auth: authSlice.reducer,
  community: communitySlice.reducer,
  overview: overviewSlice.reducer,
  questCreation: questCreationSlice.reducer,
  toast: toastSlice.reducer,
  user: userSlice.reducer,
  setting: settingSlice.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
})

export const persistor = persistStore(store)
export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
