import React from "react"

export default function Image({ src, width }: { src: string; width?: number }) {
  return (
    <picture>
      <source srcSet={`${src}?format=avif${width ? `&width=${width}` : ""}`} type="image/avif" />
      <source srcSet={`${src}?format=webp${width ? `&width=${width}` : ""}`} type="image/webp" />
      <img src={src} alt="" />
    </picture>
  )
}
