import { AppDispatch } from ".."
import { TToastTypes, toastActions } from "../reducers/toastSlice"

export const setShowToast = (status: boolean) => {
  return (dispatch: AppDispatch) => {
    dispatch(toastActions.setShowToast({ status }))
  }
}

export const showToast = (toastMessage: string, toastType: TToastTypes) => {
  return (dispatch: AppDispatch) => {
    dispatch(
      toastActions.setToastInfo({
        toastInfo: {
          toastContent: toastMessage,
          toastType,
        },
      }),
    )
    dispatch(toastActions.setShowToast({ status: true }))
  }
}
