import React, { useEffect, useState, useRef } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import "./style.scss"
import {
  Tabs,
  ConfigProvider,
  Table,
  TableProps,
  Button,
  message,
  Popconfirm,
  type PopconfirmProps,
} from "antd"
import { ColumnsType } from "antd/es/table"
import { ReactComponent as Captive } from "../../assets/icons/captive_portal.svg"
import { ISubmitFormData } from "../../types/quest"
import { useEnsName } from "wagmi"
import { ReactComponent as Close } from "../../assets/icons/closeIcon.svg"
import { ReactComponent as Check } from "../../assets/icons/checkIcon.svg"
import { Address } from "../../types/common"
import dayjs, { Dayjs } from "dayjs"
import {
  fetchSubmitForms,
  updateSingleEntrySubmitForms,
  updateBulkStatus,
} from "../../store/actions/overviewAction"
import minMax from "dayjs/plugin/minMax"
import isBetween from "dayjs/plugin/isBetween"
import Lottie from "react-lottie"
import animationLoadingData from "../../assets/lottie/buttonLoader.json"
import blackAnimationLoadingData from "../../assets/lottie/blackButtonLoader.json"
import { overviewActions } from "../../store/reducers/overviewSlice"

dayjs.extend(isBetween)
dayjs.extend(minMax)

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationLoadingData,
  renderer: "svg",
}
const blackLottieOption = {
  loop: true,
  autoplay: true,
  animationData: blackAnimationLoadingData,
  renderer: "svg",
}

type ExpandIconProps<T> = NonNullable<TableProps<T>["expandable"]>["expandIcon"]

interface MainTableRow {
  key: number
  week: string
  submissions: ISubmitFormData[]
}

interface UpdateStatusRow {
  uuid: string
  status: string
}

const ModalAddress = ({ address }: { address: Address }) => {
  const { data, isError, isLoading } = useEnsName({
    address: address,
  })

  return <div>{data || `${address.slice(0, 5)}...${address.slice(-4)}`}</div>
}

const SubmitAdapterModal = ({
  modalId,
  isRepeatable,
  questUuid,
  handleCloseModal,
}: {
  modalId: string | null | undefined
  isRepeatable: boolean
  questUuid: string | null | undefined
  handleCloseModal: () => void
}) => {
  const [activeTab, setActiveTab] = useState("new")
  // const [mainNestedData, setMainNestedData] = useState<MainTableRow[] | []>([])
  // const [newNestedData, setNewNestedData] = useState<MainTableRow[] | []>([])
  // const [pastNestedData, setPastNestedData] = useState<MainTableRow[] | []>([])
  const allSubmitFormInfo = useAppSelector((state) => state.overview.submitFormInfo)
  const [pendingPageNo, setPendingPageNo] = useState<number>(1)
  const [actionedPageNo, setactionedPageNo] = useState<number>(0)
  const [shouldFetchPast, setShouldFetchPast] = useState(false)
  const [shouldFetchNew, setShouldFetchNew] = useState(false)

  const [loading, setLoading] = useState<string[]>([])
  const [loadingAll, setLoadingAll] = useState(false)
  const dispatch = useAppDispatch()
  const sortByDate = (date1: ISubmitFormData, date2: ISubmitFormData) =>
    dayjs(date1.created_at).isAfter(dayjs(date2.created_at)) ? 1 : -1
  const modalData = allSubmitFormInfo && modalId ? allSubmitFormInfo[modalId] : []
  const communityInfo = useAppSelector((state) => state.overview.selectedCommunity)
  const newSubmissions =
    modalData &&
    modalData
      ?.filter((data) => data.status === "PENDING")
      ?.sort((a, b) => sortByDate(a, b))
      ?.reverse()

  const pastSubmissions =
    modalData &&
    modalData
      ?.filter((data) => !(data.status === "PENDING"))
      ?.sort((a, b) => sortByDate(a, b))
      ?.reverse()
  const AllActiveQuests = useAppSelector((state) => state.overview.activeCampaignsAndQuests)
  const QuestInfo = AllActiveQuests?.filter((data) => data.uuid === questUuid)[0]

  const shouldFetchPastRef = useRef(shouldFetchPast)
  const shouldFetchNewRef = useRef(shouldFetchNew)

  useEffect(() => {
    shouldFetchPastRef.current = shouldFetchPast
  }, [shouldFetchPast])

  useEffect(() => {
    shouldFetchNewRef.current = shouldFetchNew
  }, [shouldFetchNew])

  useEffect(() => {
    if (communityInfo && modalId) {
      dispatch(updateBulkStatus(communityInfo?.uuid, modalId as string))
    }
  }, [modalId])

  useEffect(() => {
    if (activeTab === "new" && pendingPageNo === 0) {
      dispatch(fetchSubmitForms(modalId as string, "PENDING")).then(() => setPendingPageNo(1))
    } else if (activeTab === "past" && actionedPageNo === 0) {
      dispatch(fetchSubmitForms(modalId as string, "APPROVED,REJECTED")).then(() =>
        setactionedPageNo(1),
      )
    }
  }, [activeTab])

  useEffect(() => {
    const tableContent = document?.querySelector("#pastTable .ant-table-body")
    const handleScroll = async (event: Event) => {
      const target = event.target as HTMLElement
      const maxScroll = target.scrollHeight - target.clientHeight - 5
      const currentScroll = target.scrollTop
      if (currentScroll >= parseInt(maxScroll.toString()) && !shouldFetchPastRef.current) {
        setShouldFetchPast(true)
      }
    }
    if (tableContent) {
      tableContent.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (tableContent) {
        tableContent.removeEventListener("scroll", handleScroll)
      }
    }
  }, [activeTab, isRepeatable])

  useEffect(() => {
    const tableContent =  document?.querySelector("#currentTable .ant-table-body")
    const handleScroll = (event: Event) => {
      const target = event.target as HTMLElement
      
      const maxScroll = target.scrollHeight - target.clientHeight - 5
      const currentScroll = target.scrollTop
      if (currentScroll >= parseInt(maxScroll.toString()) && !shouldFetchNewRef.current) {
        setShouldFetchNew(true)
      }
    }
    if (tableContent) {
      tableContent.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (tableContent) {
        tableContent.removeEventListener("scroll", handleScroll)
      }
    }
  }, [activeTab, isRepeatable])


  useEffect(() => {
    if (shouldFetchNew && pendingPageNo !== 0) {
      dispatch(fetchSubmitForms(modalId as string, "PENDING", pendingPageNo + 1))
        .then(() => {
          setShouldFetchNew(false)
          setPendingPageNo((prev) => prev + 1)
        })
        .catch((e) => console.error(e))
    } else {
      setShouldFetchNew(false)
    }
  }, [shouldFetchNew])

  useEffect(() => {
    if (shouldFetchPast && actionedPageNo !== 0) {
      dispatch(fetchSubmitForms(modalId as string, "APPROVED,REJECTED", actionedPageNo + 1))
        .then(() => {
          setShouldFetchPast(false)
          setactionedPageNo((prev) => prev + 1)
        })
        .catch((e) => console.error(e))
    } else {
      setShouldFetchPast(false)
    }
  }, [shouldFetchPast])

  // useEffect(() => {
  //   if (isRepeatable && QuestInfo) {
  //     switch (QuestInfo?.repeat_quest?.reconcile_days) {
  //       case 1:
  //         GenerateRepeatData(1, "Day")
  //         break
  //       case 30:
  //         GenerateRepeatData(30, "Month")
  //         break
  //       default:
  //         GenerateRepeatData(7, "Week")
  //     }
  //   }
  // }, [modalData])

  // const GenerateRepeatData = (interval: number, intervalText: string) => {
  //   let tempGenerateData = modalData
  //   const repeatInfo = QuestInfo && QuestInfo.repeat_quest
  //   let intervalStartingDate: Dayjs | null = dayjs(repeatInfo?.created_at)
  //   let intervalEndingDate: Dayjs | null = dayjs(repeatInfo?.created_at).add(interval, "day")
  //   let NestedData: MainTableRow[] = [],
  //     key = 1
  //   while (tempGenerateData?.length > 0) {
  //     const weekText = `${intervalText}-${key}(${dayjs(intervalStartingDate).format(
  //       "DD MMM",
  //     )}-${dayjs(intervalEndingDate).format("DD MMM")})`
  //     const filteredData = tempGenerateData
  //       ?.filter((data) => {
  //         return (
  //           dayjs(data.created_at).isBetween(intervalStartingDate, intervalEndingDate) ||
  //           (intervalStartingDate && intervalStartingDate.diff(dayjs(data.created_at), "day") >= 0)
  //         )
  //       })
  //       .sort((a, b) => sortByDate(a, b))
  //       .reverse()
  //     tempGenerateData = tempGenerateData?.filter(
  //       (data) =>
  //         !(
  //           dayjs(data.created_at).isBetween(intervalStartingDate, intervalEndingDate) ||
  //           !(intervalStartingDate && intervalStartingDate.diff(dayjs(data.created_at), "day") < 0)
  //         ),
  //     )
  //     const NewDataRow = {
  //       key: key,
  //       week: weekText,
  //       submissions: filteredData,
  //     }
  //     NestedData = [...NestedData, NewDataRow]
  //     key = key + 1
  //     intervalStartingDate = intervalEndingDate
  //     if (intervalEndingDate) {
  //       intervalEndingDate = intervalEndingDate.add(interval, "day")
  //     }
  //   }
  //   NestedData = NestedData?.filter((nestedRow) => nestedRow.submissions.length > 0)
  //   let tempNewData: MainTableRow[] = [],
  //     tempPastData: MainTableRow[] = []
  //   NestedData.map((nestedRow) => {
  //     const newSub = nestedRow?.submissions?.filter((sub) => sub.status === "PENDING")
  //     const pastSub = nestedRow?.submissions?.filter((sub) => sub.status !== "PENDING")
  //     if (newSub.length > 0) {
  //       tempNewData = [
  //         ...tempNewData,
  //         {
  //           key: nestedRow.key,
  //           submissions: newSub,
  //           week: nestedRow.week,
  //         },
  //       ]
  //     }
  //     if (pastSub.length > 0) {
  //       tempPastData = [
  //         ...tempPastData,
  //         {
  //           key: nestedRow.key,
  //           submissions: pastSub,
  //           week: nestedRow.week,
  //         },
  //       ]
  //     }
  //   })
  //   setNewNestedData(tempNewData)
  //   setPastNestedData(tempPastData)
  //   setMainNestedData(NestedData)
  // }

  const handleCloseAndDelete = () => {
    dispatch(overviewActions.deleteSubmitFormInfo(modalId as string))
    handleCloseModal()
  }
  const confirmApprove: PopconfirmProps["onConfirm"] = (e) => {
    handleUpdateBulkStatus("approve")
  }

  const confirmReject: PopconfirmProps["onConfirm"] = (e) => {
    handleUpdateBulkStatus("reject")
  }

  // const currentSubmitForm = allSubmitFormInfo[]
  const items = [
    {
      label: "New Submissions",
      key: "new",
    },
    {
      label: "Past Submissions",
      key: "past",
    },
  ]

  const onTabChange = (key: string) => {
    setActiveTab(key)
  }

  const updateSingleStatus = ({ uuid, status }: UpdateStatusRow) => {
    setLoading([...loading, uuid])
    const temp: UpdateStatusRow[] = [
      {
        uuid: uuid,
        status: status,
      },
    ]
    if (!communityInfo?.name) return
    if (!modalId) return
    dispatch(updateSingleEntrySubmitForms(communityInfo?.name, modalId, temp)).then(() => {
      setLoading(loading?.filter((id) => id !== uuid))
    })
  }

  const handleUpdateBulkStatus = (statusText: string) => {
    if (communityInfo?.uuid && modalId) {
      setLoadingAll(true)
      dispatch(updateBulkStatus(communityInfo?.uuid, modalId, statusText)).then(() => {
        setLoadingAll(false), message.success("Successfully Done")
        setactionedPageNo(0)
        setPendingPageNo(0)
        dispatch(overviewActions.deleteSubmitFormInfo(modalId as string))
      })
    }
  }

  const columns: ColumnsType<ISubmitFormData> = [
    {
      title: <div></div>,
      dataIndex: ["user", "eoa"],
      key: "user",
      render: (text: Address, record: ISubmitFormData) => {
        return (
          <div>
            <ModalAddress address={text} />
            <div className="grayed-text">
              {dayjs(record.created_at).format("DD MMM YY, HH:mm:ss")}
            </div>
          </div>
        )
      },
      width: "32%",
    },
    {
      title: "",
      dataIndex: "submissionLink",
      key: "submissions",
      render: (text: string, record: ISubmitFormData) => (
        <div>
          <span className="submit-span">
            Submissions <Captive width="18px" height="18px" />
          </span>
          <a
            href={record.submit_data}
            target="_blank"
            rel="noopener noreferrer"
            className="grayed-link"
          >
            {record.submit_data}
          </a>
        </div>
      ),
      width: "32 %",
    },
    {
      title: <div className="action-title-container"></div>,
      dataIndex: "uuid",
      key: "uuid",
      render: (text: string, record: ISubmitFormData) => {
        return (
          <div className="action-container">
            <button
              onClick={() => updateSingleStatus({ uuid: record.uuid, status: "APPROVED" })}
              className="approve-button"
              disabled={loadingAll || loading.includes(record.uuid)}
            >
              Approve
              {loading.includes(record.uuid) ? (
                <Lottie options={lottieOptions} width={17} height={17} />
              ) : null}
            </button>
            <button
              onClick={() => updateSingleStatus({ uuid: record.uuid, status: "REJECTED" })}
              className="reject-button"
              disabled={loadingAll || loading.includes(record.uuid)}
            >
              Reject
              {loading.includes(record.uuid) ? (
                <Lottie options={blackLottieOption} width={24} height={24} />
              ) : null}
            </button>
          </div>
        )
      },
      width: "36%",
    },
  ]

  const pastColumns: ColumnsType<ISubmitFormData> = [
    {
      title: "name",
      dataIndex: ["user", "eoa"],
      key: "domain",
      render: (text: Address, record: ISubmitFormData) => (
        <div>
          <ModalAddress address={text} />
          <div className="grayed-text">
            {dayjs(record.created_at).format("DD MMM YY, HH:mm:ss")}
          </div>
        </div>
      ),
      width: "32%",
    },
    {
      title: "Submission",
      dataIndex: "link",
      key: "link",
      render: (text: string, record: ISubmitFormData) => (
        <div>
          <span className="submit-span">
            Submissions <Captive width="18px" height="18px" />
          </span>
          <a
            href={record.submit_data}
            target="_blank"
            rel="noopener noreferrer"
            className="grayed-link"
          >
            {record.submit_data}
          </a>
        </div>
      ),
      width: "32%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, record: ISubmitFormData) => {
        return (
          <div>
            <div className={`status-icon-section ${status === "APPROVED" ? "" : "grayed-text"}`}>
              {status}
              <span
                className={`icon-container ${status === "APPROVED" ? "" : "grayed-background"}`}
              >
                {status === "APPROVED" ? <Check /> : <Close />}
              </span>
            </div>
            <div className="grayed-text">
              {dayjs(record.last_status_update).format("DD MMM YY, HH:mm:ss")}
            </div>
          </div>
        )
      },
      width: "36%",
    },
  ]

  // const NestedTable: React.FC = () => {
  //   const mainTableColumns: ColumnsType<MainTableRow> = [
  //     { title: "Week", dataIndex: "week", key: "week", render: (text) => <div></div>, width: "0%" },
  //   ]

  //   const expandIcon: ExpandIconProps<MainTableRow> = ({ expanded, onExpand, record }) => (
  //     <a>
  //       <div
  //         onClick={(e) => {
  //           e.preventDefault()
  //           onExpand(record, e)
  //         }}
  //       >
  //         {record.week}
  //       </div>
  //     </a>
  //   )

  //   const expandable = {
  //     expandedRowRender: (record: MainTableRow) =>
  //       activeTab === "new" ? (
  //         <Table
  //           columns={columns}
  //           dataSource={record.submissions}
  //           pagination={false}
  //           showHeader={false}
  //           className="custom-submission-table"
  //           tableLayout="fixed"
  //         />
  //       ) : (
  //         <Table
  //           columns={pastColumns}
  //           dataSource={record.submissions}
  //           pagination={false}
  //           showHeader={false}
  //           tableLayout="fixed"
  //           className="custom-submission-table"
  //         />
  //       ),
  //     rowExpandable: (record: MainTableRow) => record.submissions && record.submissions.length > 0,
  //     expandIcon: expandIcon,
  //     defaultExpandAllRows: true,
  //   }
  //   return activeTab === "new" ? (
  //     <Table<MainTableRow>
  //       columns={mainTableColumns}
  //       dataSource={newNestedData.sort(function (a, b) {
  //         return b.key - a.key
  //       })}
  //       expandable={expandable}
  //       showHeader={false}
  //       pagination={false}
  //       scroll={{ y: 500 }}
  //       className="custom-nested-main-table"
  //       tableLayout="fixed"
  //       id="nestedCurrentTable"
  //     />
  //   ) : (
  //     <Table<MainTableRow>
  //       columns={mainTableColumns}
  //       dataSource={pastNestedData.sort(function (a, b) {
  //         return b.key - a.key
  //       })}
  //       expandable={expandable}
  //       showHeader={false}
  //       pagination={false}
  //       scroll={{ y: 500 }}
  //       tableLayout="fixed"
  //       id="nestedPastTable"
  //       className="custom-nested-main-table"
  //     />
  //   )
  // }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Inter",
        },
        components: {
          Tabs: {
            inkBarColor: "rgba(0,0,0,1)",
            itemSelectedColor: "rgba(0,0,0,1)",
            itemColor: "rgba(0,0,0,0.4)",
            itemHoverColor: "rgba(0,0,0,1)",
            titleFontSize: 17,
            lineHeight: 1.5,
            horizontalItemPadding: "20px 0px 16px 0px",
          },
          Table: {
            headerBg: "#FFF",
            colorLink: "rgba(0,0,0,0.4)",
            headerSplitColor: "none",
          },
        },
      }}
    >
      <div className="submission-modal">
        <div className="submission-modal-container">
          <div className="submission-modal-header">
            <div className="header-text-container">
              {QuestInfo && <div className="header-support-text">{QuestInfo?.name}</div>}
              <div>Submissions</div>
            </div>
            <div
              className="modal-close-icon"
              onClick={() => {
                handleCloseAndDelete()
              }}
            >
              <Close />
            </div>
          </div>
          <Tabs
            items={items}
            className="custom-tabs"
            style={{ letterSpacing: "-0.34px" }}
            onChange={onTabChange}
            activeKey={activeTab}
          />
          {activeTab === "new" ? (
            <div className="table-header-container">
              <div className="table-header-text">Total {newSubmissions?.length} submissions</div>
              <div className="action-title-container">
                <Popconfirm
                  title="Approve all"
                  description="This action will approve all the pending submissions"
                  onConfirm={confirmApprove}
                  okText="Sure"
                  cancelText="Cancel"
                >
                  <div className="action-title">
                    Approve All{" "}
                    {loadingAll && <Lottie options={blackLottieOption} width={24} height={24} />}
                  </div>
                </Popconfirm>
                <div>|</div>
                <Popconfirm
                  title="Reject all"
                  description="This action will reject all the pending submissions"
                  onConfirm={confirmReject}
                  okText="Sure"
                  cancelText="Cancel"
                  placement="topRight"
                >
                  <div className="action-title">
                    Reject All{" "}
                    {loadingAll && <Lottie options={blackLottieOption} width={24} height={24} />}
                  </div>
                </Popconfirm>
              </div>
            </div>
          ) : (
            <div className="past-support-text">
              Total {pastSubmissions?.length} submissions / Approved / Rejected
            </div>
          )}
          { activeTab === "new" ? (
            <div>
              <Table
                columns={columns}
                dataSource={newSubmissions}
                pagination={false}
                scroll={{ y: 500, x: 0 }}
                showHeader={false}
                className="custom-submission-table"
                tableLayout="fixed"
                id="currentTable"
              />
            </div>
          ) : (
            <div>
              <Table
                columns={pastColumns}
                dataSource={pastSubmissions}
                pagination={false}
                showHeader={false}
                scroll={{ y: 500, x: 0 }}
                className="custom-submission-table"
                tableLayout="fixed"
                id="pastTable"
              />
            </div>
          )}
        </div>
      </div>
    </ConfigProvider>
  )
}

export default SubmitAdapterModal
