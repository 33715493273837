import React, { useState } from "react"
import "./style.scss"
import TextInput from "../../../Inputs/TextInput"

export default function OverviewStep({
  quizTitle,
  setQuizTitle,
  url,
  setUrl,
  linkTitle,
  setLinkTitle,
}: {
  quizTitle: string
  setQuizTitle: (x: string) => void
  url: string
  setUrl: (x: string) => void
  linkTitle: string
  setLinkTitle: (x: string) => void
}) {
  return (
    <div className="quiz-overview-step-container">
      <div className="text-input-wrapper">
        <TextInput
          value={quizTitle}
          label="Quiz Title"
          placeholder="quiz"
          onChange={(e) => {
            setQuizTitle(e.target.value)
          }}
        />
      </div>
      <div className="text-input-wrapper">
        <div className="reading-material-input-wrapper">
          <label>Reading Material</label>
          <div className="reading-material-input-wrapper">
            <div className="reading-material-input-row">
              <div className="reading-material-input-row-left">URL</div>
              <input
                type="text"
                className={`url-input ${url.length ? "text-input-filled" : ""}`}
                placeholder="https://rep3.gg/docs"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
            <div className="reading-material-input-row">
              <div className="reading-material-input-row-left">Title</div>
              <input
                type="text"
                className={`link-input ${linkTitle.length ? "text-input-filled" : ""}`}
                placeholder="rep3 docs"
                value={linkTitle}
                onChange={(e) => setLinkTitle(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
