// import { Walle } from "@wagmi/core"
import { Address } from "../../types/common"
import { AppDispatch } from ".."
import apiClient from "../../utils/apiClient"
import { routes } from "../../constant/routes"
import { authActions } from "../reducers/authSlice"
import { WalletClient } from "viem"

export const setJwt = (jwt: string | null) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(
      authActions.setJwt({
        jwt,
      }),
    )
  }
}

// export const authWithSign = (address: Address, client: WalletClient) => {
//   return async (dispatch: AppDispatch): Promise<number> => {
//     try {
//       const responseNonce = await apiClient.get(
//         `${process.env.REACT_APP_API_BASE_URL}${routes.auth.nonce}?eoa=${address}`,
//       )
//       const signature = await client.signMessage({
//         account: address,
//         message: `Signing in to rep3.gg with nonce ${responseNonce.data.nonce}`,
//       })
//       try {
//         const responseToken = await apiClient.get(
//           `${process.env.REACT_APP_API_BASE_URL}${routes.auth.token}?eoa=${address}&signature=${signature}`,
//         )
//         if (responseToken.status === 200) {
//           dispatch(setJwt(responseToken.data.token))
//           return 1
//         }
//         return 0
//       } catch (error) {
//         return 0
//       }
//     } catch (error) {
//       return 0
//     }
//   }
// }

export const setShowSignMessage = (showSignMessageStatus: boolean) => {
  return async (dispatch: AppDispatch) => {
    dispatch(authActions.setShowSignMessage(showSignMessageStatus))
  }
}

export const setPrevChainId = (prevChainId: number | null) => {
  return async (dispatch: AppDispatch) => {
    dispatch(authActions.setPrevChainId(prevChainId))
  }
}

export const setPrevAddress = (prevAddress: Address | null) => {
  return async (dispatch: AppDispatch) => {
    dispatch(authActions.setPrevAddress(prevAddress))
  }
}
