import React from "react"
import "./style.scss"
import { TQuizAdapterQuestion, TQuizQA } from "../../../../types/adapter"
import { Switch } from "antd"
import { ReactComponent as CheckIcon } from "../../../../assets/icons/checkIcon.svg"
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/deleteIcon.svg"
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plusIcon.svg"
import TextInput from "../../../Inputs/TextInput"

export default function QuestionStep({
  questions,
  setQuestionOption,
  setQuestionTitle,
  setQuestionAnswer,
  addQuestion,
  addOption,
  deleteOption,
}: {
  questions: TQuizQA
  setQuestionOption: (questionIndex: number, optionIndex: number, optionValue: string) => void
  setQuestionTitle: (questionIndex: number, questionValue: string) => void
  setQuestionAnswer: (questionIndex: number, answerValue: number) => void
  addQuestion: () => void
  addOption: (questionIndex: number) => void
  deleteOption: (questionIndex: number, optionKey: number) => void
}) {
  return (
    <div className="question-step-container">
      {Object.keys(questions).map((questionKey, index) => (
        <div className="single-question-wrapper" key={index}>
          {/* <div className="question-label">Question {index + 1}</div> */}
          {/* <input className="question-text-input" value={question.questionText} /> */}
          <TextInput
            value={questions[Number(questionKey)].question}
            label={`Question ${index + 1}`}
            onChange={(e) => setQuestionTitle(index + 1, e.target.value)}
          />
          <div className="question-options-wrapper">
            {Object.keys(questions[Number(questionKey)].options).map((option, optionIndex) => (
              <div className="question-option-row" key={optionIndex}>
                <div
                  className={`question-option-content ${
                    questions[Number(questionKey)].options[Number(option)]?.length
                      ? "question-option-filled"
                      : ""
                  }`}
                >
                  <div className="question-option-row-left">
                    {questions[Number(questionKey)].answer_id === Number(option) ? (
                      <div className="correct-answer-text">Correct Answer:</div>
                    ) : null}
                    <input
                      type="text"
                      value={questions[Number(questionKey)].options[Number(option)]}
                      placeholder={`Enter Option ${optionIndex + 1}`}
                      onChange={(e) =>
                        setQuestionOption(index + 1, optionIndex + 1, e.target.value)
                      }
                    />
                  </div>
                  <div className="question-option-row-right">
                    <Switch
                      checked={questions[Number(questionKey)].answer_id === Number(option)}
                      onChange={(checked) =>
                        setQuestionAnswer(index + 1, checked ? Number(option) : 0)
                      }
                    />
                    <div
                      className={`correct-answer-icon ${
                        questions[Number(questionKey)].answer_id === Number(option)
                          ? "checked-icon"
                          : ""
                      }`}
                    >
                      <CheckIcon />
                    </div>
                  </div>
                </div>
                <div
                  className="question-option-row-delete"
                  onClick={() => deleteOption(index + 1, Number(option))}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
          </div>
          <button className="question-options-add-option" onClick={() => addOption(index + 1)}>
            <PlusIcon />
            Add Another Option
          </button>
        </div>
      ))}
      <button className="question-step-add-question" onClick={addQuestion}>
        <PlusIcon />
        Add Another Question
      </button>
    </div>
  )
}
