import { ReactNode } from "react"
import axios from "axios"
import {
  AdapterKeys,
  AdapterKeysWithValues,
  AdapterNames,
  AdapterOptions,
  AdapterTypes,
  TTwitterAccountInfo,
  IStrategyOptions,
  isStrategyOptions,
  TAdapterOptionsQuiz,
} from "../types/adapter"
import { FaDiscord, FaFileContract, FaTwitter, FaTelegramPlane } from "react-icons/fa"
import { MdOutlineWebhook } from "react-icons/md"
import { IoLinkSharp } from "react-icons/io5"
import apiClient from "./apiClient"
import { routes } from "../constant/routes"
import { ReactComponent as CSVIcon } from "../assets/icons/csv.svg"
import { ReactComponent as ReferralIcon } from "../assets/icons/referral.svg"
import { ReactComponent as SubmitIcon } from "../assets/icons/contract_edit.svg"
import { v4 } from "uuid"

export const getAdapterName = (adapterKey: string) => {
  switch (adapterKey) {
    case "DISCORD_isMember":
      return "Member of Discord"
    case "DISCORD_haveRole":
      return "Role on Discord"
    case "TWITTER_likeTweet":
      return "Like a Tweet"
    case "CSV_whitelist":
      return "CSV Upload"
    case "TWITTER_followAccount":
      return "Follow on Twitter"
    case "TWITTER_retweet":
      return "Retweet a Tweet"
    case "TWITTER_likeTweetIntent":
      return "Like a Tweet"
    case "TWITTER_followAccountIntent":
      return "Follow on Twitter"
    case "TWITTER_replyIntent":
      return "Reply a tweet"
    case "TWITTER_retweetIntent":
      return "Retweet a Tweet"
    case "TWITTER_quoteTweet":
      return "Quote tweet a tweet"
    case "SMART_CONTRACT_view":
      return "Smart Contract Query"
    case "SMART_CONTRACT_erc20":
      return "ERC20 Token Holding"
    case "SMART_CONTRACT_erc721":
      return "NFT Holding (ERC 721)"
    case "SMART_CONTRACT_erc1155":
      return "NFT Holding (ERC 1155)"
    case "TASK_WHITELIST_ADAPTER":
      return " API Webhook"
    case "OPEN_LINK":
      return "Open URL"
    case "TELEGRAM_LINK":
      return "Join Telegram"
    case "QUIZ_FORM":
      return "Setup Quiz"
    case "REFERRAL_count":
      return "Referral"
    case "PREREQUISITE_quests":
      return "Quest Completion"
    case "CHECK_PASSWORD":
      return "Password"
    case "SUBMIT_FORM":
      return "Manual Submission"
    case "COMMUNITY_STRATEGY":
      return "Community Custom Adapter"
    case "TWITTER_retweetVerified":
      return "Verify Retweet"
    case "TWITTER_likeTweetVerified":
      return "Verify Like"
    case "TWITTER_followAccountVerified":
      return "Verify Follow"
    case "TWITTER_replyVerified":
      return "Verify Reply"
    case "TWITTER_retweetCommentVerified":
      return "Verify Retweet and Reply"
    default:
      return "Something went wrong"
  }
}

export const getAdapterInputHeadingFromKey = (adapterInputKey: string, name?: string) => {
  switch (adapterInputKey) {
    case "tweet_id":
      return "Enter Tweet link"
    case "strategyOptions":
      return "Upload CSV"
    case "following_account_id":
      return "Enter Twitter account link"
    case "guildId":
      return "Enter Server ID"
    case "roleId":
      return "Role ID"
    case "roleName":
      return "Role Name"
    case "TWITTER_quoteTweet":
      return "QT on Twitter"
    case "min_referrals":
      return "Successful Referral"
    case "contractType":
      return "Contract Address"
    case "chainId":
      return "Chain"
    case "functionName":
      return "Function"
    case "inputParam":
      return name
    case "webhook_id":
      return "Webhook ID"
    case "link":
      return "URL"
    case "telegram":
      return "Telegram link"
    case "password":
      return "Correct Password"
    case "submit_form_uuid":
      return "Submission Type"
    case "retry_after":
      return "Retry After"
    case "COMMUNITY_STRATEGY":
      return "Community Custom Strategy"
    default:
      return "Something went wrong"
  }
}

export const getAdapterPlaceHolderFromKey = (adapterInputKey: string, inputType?: string) => {
  switch (adapterInputKey) {
    case "tweet_id":
      return "https://twitter.com/rep3gg/status/1684555016173146113"
    case "strategyOptions":
      return "Upload File"
    case "following_account_id":
      return "https://twitter.com/rep3gg"
    case "guildId":
      return "920352425175560262"
    case "roleId":
      return "Enter Role ID"
    case "roleName":
      return "Enter Role Name"
    case "min_referrals":
      return "Minimum Number of Successful Referrals"
    case "TWITTER_quoteTweet":
      return "https://twitter.com/rep3gg/status/1684555016173146113"
    case "contractType":
      return "Contract Address"
    case "chainId":
      return "Select Chain"
    case "functionName":
      return "Paste ABI"
    case "inputParam":
      return inputType
    case "outputParam":
      return "Number"
    case "link":
      return "eg. https://rep3.gg"
    case "webhook_id":
      return "hook-id"
    case "telegram":
      return "https://t.me/+aZ-7kPCKvbo1NGY1"
    case "password":
      return "Enter Correct Password text"
    case "submit_form_uuid":
      return "Select submit type"
    case "retry_after":
      return "Retry After"
    default:
      return "Something went wrong"
  }
}

export const getAdapterRequirement = (
  adapterKey: string,
  adapterOptions?: AdapterOptions | TAdapterOptionsQuiz,
) => {
  switch (adapterKey) {
    case "DISCORD_isMember":
      return "Join our discord"
    case "DISCORD_haveRole": {
      const roleName = adapterOptions?.keys.filter((x) => x.key === "roleName")?.[0]?.value
      if (roleName) return `Get ${roleName} in our discord`
      return "Have role in our discord"
    }
    case "TWITTER_likeTweetIntent":
      return "Like this post"
    case "TWITTER_followAccountIntent": {
      const accountInfo = adapterOptions?.keys?.find((ele) => ele.key === "following_account_id")
        ?.value
      if (!accountInfo) return "Follow on X"
      const accountName = getStringAfterLastSlash(accountInfo.toString())
      return `Follow ${accountName} on X`
    }
    case "TWITTER_retweetIntent":
      return "Repost this tweet"
    case "TWITTER_quoteTweet":
      return "Quote this tweet"
    case "TWITTER_replyIntent":
      return "Reply this tweet"
    case "CSV_whitelist":
      return "Be a part of allowlist"
    case "TELEGRAM_LINK":
      return "Join our telegram channel"
    case "REFERRAL_count": {
      const referralCount = adapterOptions?.keys?.find((ele) => ele.key === "min_referrals")?.value
      if (!referralCount) return "Refer Friends"
      return `Refer min ${referralCount} friends`
    }
    case "OPEN_LINK":
      return ""
    case "TASK_WHITELIST_ADAPTER":
      return ""
    case "SMART_CONTRACT_view":
      return ""
    case "SMART_CONTRACT_erc1155":
      return ""
    case "SMART_CONTRACT_erc20":
      return ""
    case "SMART_CONTRACT_erc721":
      return ""
    case "CHECK_PASSWORD":
      return "Enter Password"
    case "QUIZ_FORM":
      return "Complete this quiz"
    case "SUBMIT_FORM":
      return "Submit Your Entry"
    case "TWITTER_retweetVerified":
      return "Repost this tweet"
    case "TWITTER_likeTweetVerified":
      return "Like this tweet"
    case "TWITTER_followAccountVerified": {
      const accountInfo = adapterOptions?.keys?.find((ele) => ele.key === "following_account_id")
        ?.value
      if (!accountInfo) return "Follow on X"
      const accountName = getStringAfterLastSlash(accountInfo.toString())
      return `Follow ${accountName} on X`
    }
    case "TWITTER_replyVerified":
      return "Reply this tweet"
    case "TWITTER_retweetCommentVerified":
      return "Retweet and Reply on this tweet"
    default:
      return "Something went wrong"
  }
}

export const getStringAfterLastSlash = (inputString: string) => {
  if (!inputString.length) return null
  const inputSplitWithSlash = inputString.split("/")
  if (inputSplitWithSlash.length === 1) return null
  const lastString = inputSplitWithSlash[inputSplitWithSlash.length - 1]
  const lastStringWithoutParameter = lastString.split("?")[0]
  if (!lastStringWithoutParameter.length) return null
  return lastStringWithoutParameter
}

export const getContractQueryInputType = (adapterInputKey: string) => {
  switch (adapterInputKey) {
    case "contractType":
      return "textInput"
    case "chainId":
      return "textSelect"
    case "outputParam":
      return "outputInput"
    case "functionName":
      return "textSelect"
    default:
      return "textInput"
  }
}
export const networkInfos = [
  {
    chainId: 1,
    baseUrl: "https://api.etherscan.io/api",
    apiKey: "57KZ9B6EN3P1FZMRXHAWM4SH1C1D3XR8F8",
    value: "1",
    label: "Ethereum",
  },
  {
    chainId: 137,
    baseUrl: "https://api.polygonscan.com/api",
    apiKey: "N9PXU5Y647RUTB8HNZT5BYNIPRVBJI775X",
    value: "137",
    label: "Polygon",
  },
  {
    chainId: 56,
    baseUrl: "https://api.polygonscan.com/api",
    apiKey: "N9PXU5Y647RUTB8HNZT5BYNIPRVBJI775X",
    value: "56",
    label: "BNB Chain",
  },
  {
    chainId: 43114,
    baseUrl: "",
    apiKey: "",
    value: "43114",
    label: "Avalanche C-Chain",
  },
  {
    chainId: 80001,
    baseUrl: "",
    apiKey: "",
    value: "80001",
    label: "Polygon-Mumbai",
  },
  {
    chainId: 8453,
    baseUrl: "",
    apiKey: "",
    value: "8453",
    label: "Base",
  },
  {
    chainId: 84532,
    baseUrl: "",
    apiKey: "",
    value: "84532",
    label: "Base Sepolia",
  },
  {
    chainId: 421614,
    baseUrl: "",
    apiKey: "",
    value: "421614",
    label: "Arbitrum Sepolia",
  },
  {
    chainId: 42161,
    baseUrl: "",
    apiKey: "",
    value: "42161",
    label: "Arbitrum",
  },
  {
    chainId: 11155420,
    baseUrl: "",
    apiKey: "",
    value: "11155420",
    label: "Optimism Sepolia",
  },
  {
    chainId: 10,
    baseUrl: "",
    apiKey: "",
    value: "10",
    label: "Optimism",
  },
  {
    chainId: 11155111,
    baseUrl: "",
    apiKey: "",
    value: "11155111",
    label: "Sepolia",
  },
  {
    chainId: 1442,
    baseUrl: "",
    apiKey: "",
    value: "1442",
    label: "Polygon zkEvm Testnet",
  },
  {
    chainId: 1101,
    baseUrl: "",
    apiKey: "",
    value: "1101",
    label: "Polygon zkEvm",
  },
  {
    chainId: 1225,
    baseUrl: "",
    apiKey: "",
    value: "1225",
    label: "Hybrid Testnet",
  },
]
export const getAbiForVerifiedContract = async (
  contractAddress: string | number,
  network: string | number,
) => {
  const currentNetworkInfo = networkInfos.filter((x) => x.value === network)[0]
  const response = await axios.get(
    `${currentNetworkInfo?.baseUrl}?module=contract&action=getabi&address=${contractAddress}&apikey=${currentNetworkInfo?.apiKey}`,
  )
  return response?.data
}

export const getClassNameFromType = (adapterType: AdapterNames) => {
  switch (adapterType) {
    case "TWITTER":
      return "twitter-icon"
    case "CSV":
      return "csv-icon"
    case "TELEGRAM_LINK":
      return "twitter-icon"
    case "DISCORD":
      return "discord-icon"
    case "OPEN_LINK":
      return "open-icon"
    case "TASK_WHITELIST_ADAPTER":
      return "open-icon"
    case "REFERRAL_count":
      return "open-icon"
    case "CHECK_PASSWORD":
      return "open-icon"
    case "SUBMIT_FORM":
      return "open-icon"
    default:
      return "contract-icon"
  }
}

export const getIconFromType = (adapterType: AdapterNames): ReactNode => {
  switch (adapterType) {
    case "CSV":
      return <CSVIcon />
    case "TWITTER":
      return <FaTwitter />
    case "TELEGRAM_LINK":
      return <FaTelegramPlane />
    case "DISCORD":
      return <FaDiscord />
    case "OPEN_LINK":
      return <IoLinkSharp />
    case "SMART_CONTRACT":
      return <FaFileContract />
    case "REFERRAL_count":
      return <ReferralIcon />
    case "CHECK_PASSWORD":
      return <SubmitIcon />
    case "SUBMIT_FORM":
      return <SubmitIcon />
    default:
      return <MdOutlineWebhook />
  }
}

export const getTwitterAccountDetails = async (accountId: string, jwt: string) => {
  try {
    const resp = await apiClient.get<TTwitterAccountInfo>(
      `${process.env.REACT_APP_API_BASE_URL}${routes.twitterService.accountInfo}?account_id=${accountId}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    )
    if (resp.status === 200) {
      return resp.data
    }
    return null
  } catch (err) {
    console.error(err)
    return null
  }
}

export const transformedAdapterKeyValue = async (
  adapterKey: string,
  adapterValue: string,
  jwt: string,
  isDuplicating?: boolean,
) => {
  switch (adapterKey) {
    case "tweet_id":
      return `https://twitter.com/_/status/${adapterValue}`
    case "following_account_id":
      return `https://twitter.com/${
        (await getTwitterAccountDetails(adapterValue as string, jwt))?.username || ""
      }`
    case "quest_uuid_tier_map":
      return Object.keys(adapterValue)[0]
    case "repeat_quest_uuid_tier_map":
      return Object.entries(adapterValue)[0][1]
    case "webhook_id":
      return isDuplicating ? v4() : adapterValue
    default:
      return adapterValue
  }
}
const addValueToKeyOfContractStrategy = (
  keyName: string,
  adapterType: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  adapterData: any,
) => {
  switch (keyName) {
    case "contractType":
      return `${adapterData?.contractAddress}`
    case "chainId":
      return `${adapterData?.chainId}`
    case "functionName":
      return adapterType === "SMART_CONTRACT_view" ? adapterData?.abi : `balanceOf`
    case "balanceThreshold":
      return `${adapterData?.balanceThreshold}`
    case "operator":
      return `${adapterData?.operator}`
    case "abi":
      return adapterType === "SMART_CONTRACT_view"
        ? adapterData?.functionName
        : `${adapterData?.abi ?? "[object Object]"}`
    case "functionParam":
      return adapterData?.functionParam ?? []
    case "outputParam":
      return `${adapterData?.balanceThreshold}`
    case "inputParam":
      return `${adapterData?.functionParam[0]}`
    case "contractAddress":
      return `${adapterData?.contractAddress}`
  }
}
const extractingOutputAndInputKeysFromABI = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  adapterData: any,
) => {
  const functionCheck = JSON.parse(adapterData?.abi)
  let addressPrefillDone = false
  const totalInputKeysOptions = functionCheck?.[0]?.inputs?.map(
    (x: { name: string; type: string }, i: number) => {
      if (x.type === "address" && adapterData?.addressIndex === i && !addressPrefillDone) {
        addressPrefillDone = true
        return {
          default: "",
          key: "inputParam",
          required: true,
          user_level: false,
          value: "<USER_ADDRESS>",
          type: x.type,
          valid_values: [],
          name: x?.name,
        }
      } else {
        return {
          default: "",
          key: "inputParam",
          required: true,
          user_level: false,
          value: adapterData?.functionParam[i],
          type: x.type,
          valid_values: [],
          name: x?.name,
        }
      }
    },
  )
  const totalOutputKeysOptions = functionCheck?.[0]?.outputs?.map(
    (x: { name: string; type: string }) => {
      return {
        default: "",
        key: "outputParam",
        required: true,
        user_level: false,
        value: "",
        type: x.type,
        valid_values: [],
        name: x?.name,
      }
    },
  )
  return {
    totalOutputKeysOptions: totalOutputKeysOptions.length > 0 ? totalOutputKeysOptions : [],
    totalInputKeysOptions: totalInputKeysOptions.length > 0 ? totalInputKeysOptions : [],
    abi: adapterData?.abi,
    addressIndexArray:
      adapterData?.addressIndex >= 0
        ? [
            {
              default: "",
              key: "addressIndex",
              required: false,
              user_level: false,
              value: adapterData?.addressIndex,
              valid_values: [],
            },
          ]
        : [],
  }
}

export const transformedAdapterKeyValueContractAdapter = (
  adapter_subtype: string,
  keyValueObj: AdapterKeysWithValues[] | undefined,
  adapterData: object,
) => {
  switch (adapter_subtype) {
    case "SMART_CONTRACT_erc721":
    case "SMART_CONTRACT_erc20": {
      let copyOfKeyValueArray = keyValueObj?.concat([
        {
          default: "",
          key: "outputParam",
          required: true,
          user_level: false,
          type: "uint256",
          valid_values: [],
          value: "",
          name: "balance",
        },
        {
          default: "",
          valid_values: [],
          user_level: false,
          required: true,
          value: "",
          key: "contractAddress",
        },
      ])
      copyOfKeyValueArray = copyOfKeyValueArray?.map((key) => {
        return {
          ...key,
          value: addValueToKeyOfContractStrategy(key?.key, adapter_subtype, adapterData),
        }
      })
      return copyOfKeyValueArray
    }
    case "SMART_CONTRACT_erc1155": {
      let copyOfKeyValueArray = keyValueObj?.concat([
        {
          default: "",
          key: "inputParam",
          required: true,
          user_level: false,
          type: "uint256",
          valid_values: [],
          name: "id",
          value: "",
        },
        {
          default: "",
          valid_values: [],
          user_level: false,
          required: true,
          key: "contractAddress",
          value: "",
        },
        {
          default: "",
          key: "outputParam",
          required: true,
          user_level: false,
          type: "uint256",
          valid_values: [],
          name: "balance",
          value: "",
        },
      ])
      copyOfKeyValueArray = copyOfKeyValueArray?.map((key) => {
        return {
          ...key,
          value: addValueToKeyOfContractStrategy(key?.key, adapter_subtype, adapterData),
        }
      })
      return copyOfKeyValueArray
    }
    case "SMART_CONTRACT_view": {
      const { totalOutputKeysOptions, totalInputKeysOptions, addressIndexArray } =
        extractingOutputAndInputKeysFromABI(adapterData)
      let copyOfKeyValueArray = keyValueObj
        ?.concat(totalInputKeysOptions)
        .concat(totalOutputKeysOptions)
        .concat([
          {
            default: "",
            valid_values: [],
            user_level: false,
            required: true,
            key: "contractAddress",
            value: "",
          },
        ])
      if (addressIndexArray.length) {
        copyOfKeyValueArray = copyOfKeyValueArray?.concat(addressIndexArray)
      }
      copyOfKeyValueArray = copyOfKeyValueArray?.map((key) => {
        if (key?.key !== "inputParam" && key?.key !== "addressIndex") {
          return {
            ...key,
            value: addValueToKeyOfContractStrategy(key?.key, adapter_subtype, adapterData),
          }
        } else {
          return {
            ...key,
          }
        }
      })
      return copyOfKeyValueArray
    }
    default:
      return keyValueObj
  }
}
