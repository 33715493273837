import axios from "axios"

export const uploadFileToS3 = async (
  fileObjectName: string,
  s3BucketName: string,
  jwt: string,
  file: File,
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/upload_file?file_obj_name=${fileObjectName}&bucket_name=${s3BucketName}`,
      file,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/octet-stream",
        },
      },
    )
    return true
  } catch (err) {
    return false
  }
}
