import { TaskGroup } from "../types/quest"
import { LeaderBoardItem } from "../types/community"

export const createDispatchResponse = (
  responseType: "success" | "error",
  responseValue: unknown,
) => {
  return {
    status: responseType,
    value: responseValue,
  }
}

export const DownLoadSampleCSV = () => {
  const csvData = [
    ["0x2fd798a8fcc64ba1bc62bf363a6a28f63e93d5aa"],
    ["0x1723d4572eb1fa8a843a16763499319eb500320e"],
    ["0x33c844be2b0a6dcba581bd5f736bab66d25f8b23"],
    ["0x13ea706f58fef9e56059012e7b6a593c44a5d488"],
  ]

  const generateCSVContent = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + csvData.map((row) => row.join(",")).join("\n")
    return csvContent
  }

  const csvContent = generateCSVContent()
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement("a")
  link.setAttribute("href", encodedUri)
  link.setAttribute("download", "template.csv")
  document.body.appendChild(link) // Required for FireFox

  link.click() // This will download the data file named "my_data.csv".
  document.body.removeChild(link) // Clean up
}

export const getTaskUuidsFromTaskGroupArray = (taskGroupArr: TaskGroup[]): string[] => {
  return taskGroupArr.reduce((acc: string[], curr: TaskGroup): string[] => {
    const uuidsInTaskGroup = curr.tasks.map((task) => task.uuid)
    return [...acc, ...uuidsInTaskGroup]
  }, [])
}

export const downloadCsv = (csvContent: ArrayBuffer | ArrayBufferView | Blob | string, filename: string) => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
  const link = document.createElement("a")
  const url = URL.createObjectURL(blob)
  link.setAttribute("href", url)
  link.setAttribute("download", filename)
  link.style.visibility = "hidden"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const convertJsonToCsv = (json:{
  current_page: number;
  last_record: LeaderBoardItem;
  leaderboard: LeaderBoardItem[];
  total_pages: number;
  total_records: number;
}) => {
  if (!json) {
    return ""
  }
  const csvRows = []
  const mappedArray= json.leaderboard.map((item: LeaderBoardItem) => ({
    rank: item.rank,
    eoa: item.eoa,
    campaign_count: item.campaign_count,
    quest_count: item.quest_count,
    current_xp: item.current_xp,
  }))
  const headers = Object.keys(mappedArray[0]) as (keyof LeaderBoardItem)[];
  csvRows.push(headers.join(",")) // Add headers row

  mappedArray.forEach((item:LeaderBoardItem) => {
    const values = headers.map((header) => {
      const escaped = ("" + item[header]).replace(/"/g, '\\"')
      return `"${escaped}"`
    })
    csvRows.push(values.join(","))
  })
  return csvRows.join("\n")
}
