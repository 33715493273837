import { createBrowserRouter, RouterProvider } from "react-router-dom"
import SetupCommunity from "./pages/SetupCommunity"
import QuestCreation from "./pages/QuestCreation"
import CommunitySpace from "./pages/CommunitySpace"
import Overview from "./pages/overview"
import Settings from "./pages/Settings"
import CommunityLeaderboard from "./pages/CommunityLeaderboard"

export default function Router() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Overview />,
    },
    {
      path: "/setup/community",
      element: <SetupCommunity />,
    },
    {
      path: "/setup/quest",
      element: <QuestCreation />,
    },
    {
      path: "/community",
      element: <CommunitySpace />,
    },
    {
      path: "/leaderboard",
      element: <CommunityLeaderboard />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
  ])
  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}
