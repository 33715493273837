import { message } from "antd"
import React, { useEffect } from "react"

import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import { setShowToast } from "../../store/actions/toastAction"

export default function AntdToast() {
  const toastInfo = useAppSelector((x) => x.toast.toastInfo)
  const showToast = useAppSelector((x) => x.toast.showToast)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (showToast) {
      switch (toastInfo.toastType) {
        case "success":
          message.success(toastInfo.toastContent)
          break
        case "error":
          message.error(toastInfo.toastContent)
          break
        default:
          message.success(toastInfo.toastContent)
          break
      }
      setTimeout(() => {
        dispatch(setShowToast(false))
      }, 0)
    }
  }, [showToast])
  return <></>
}
