import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Address } from "../../types/common"

export interface AuthState {
  jwt: string | null
  authAddress: Address | null
  showSignMessage: boolean
  prevChainId: number | null
  prevAddress: Address | null
}

const initialState: AuthState = {
  jwt: null,
  authAddress: null,
  showSignMessage: false,
  prevChainId: null,
  prevAddress: null,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setJwt(state, action: PayloadAction<{ jwt: string | null }>) {
      state.jwt = action.payload.jwt
    },
    setAuthAddress(state, action: PayloadAction<{ authAddress: Address | null }>) {
      state.authAddress = action.payload.authAddress
    },
    setShowSignMessage(state, action: PayloadAction<boolean>) {
      state.showSignMessage = action.payload
    },
    setPrevChainId(state, action: PayloadAction<number | null>) {
      state.prevChainId = action.payload
    },
    setPrevAddress(state, action: PayloadAction<Address | null>) {
      state.prevAddress = action.payload
    },
  },
})

export const authActions = authSlice.actions

export default authSlice
