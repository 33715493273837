import React from "react"
import CommunitySpacePage from "../../components/CommunitySpacePage"
import DashboardView from "../../views/DashboardView"
import HasCommunitiesWrapper from "../../views/HasCommunitiesWrapper"
import CommunityLeaderboardPage from "../../components/CommunityLeaderboardPage"

export default function CommunityLeaderboard() {
  return (
    <DashboardView>
      <HasCommunitiesWrapper>
        <CommunityLeaderboardPage />
      </HasCommunitiesWrapper>
    </DashboardView>
  )
}
