import React, { ReactNode, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
// import Header from "../../components/Header"
import EmptyPageScreen from "../../components/EmptyPageScreen"
import "./style.scss"
import LoadingScreen from "../../components/LoadingScreen"
import { fetchUserCommunities } from "../../store/actions/overviewAction"
// import Footer from "../../components/Footer"

export default function HasCommunitiesWrapper({ children }: { children: ReactNode }) {
  const userCommunities = useAppSelector((state) => state.overview.userCommunities)
  const fetchingCommunitiesStatus = useAppSelector(
    (state) => state.overview.fetchingCommunitiesStatus,
  )

  const dispatch = useAppDispatch()

  const isLoading = fetchingCommunitiesStatus === "loading"
  console.count("render in has communities")

  useEffect(() => {
    dispatch(fetchUserCommunities())
  }, [])

  if (isLoading) {
    return (
      <div className="has-communities-wrapper">
        <LoadingScreen />
      </div>
    )
  }

  if (!userCommunities?.length) {
    return (
      <div className="zero-communities-wrapper">
        <EmptyPageScreen />
      </div>
    )
  }

  return <div className="has-communities-wrapper">{children}</div>
}
