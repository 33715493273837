import { useState } from "react"
import TextInput from "../../Inputs/TextInput"
import "./style.scss"
import { isValidEmail } from "../../../utils/regex"
import { useAppSelector } from "../../../hooks/reduxHooks"

export default function OverViewStep({
  communityName,
  setCommunityName,
  website,
  setWebsite,
  name,
  setName,
  role,
  setRole,
  telegramAccount,
  setTelegramAccount,
  email,
  setEmail,
  projectTwitterAccount,
  setProjectTwitterAccount,
}: {
  communityName: string
  setCommunityName: (value: string) => void
  website: string
  setWebsite: (value: string) => void
  name: string
  setName: (value: string) => void
  role: string
  setRole: (value: string) => void
  telegramAccount: string
  setTelegramAccount: (value: string) => void
  email: string
  setEmail: (value: string) => void
  projectTwitterAccount: string
  setProjectTwitterAccount: (value: string) => void
}) {
  // const [isEmailError, setIsEmailError] = useState(false)

  // const validateEmail = (email: string) => {
  //   setIsEmailError(email.length ? !isValidEmail(email) : false)
  //   return
  // }

  const userInfo = useAppSelector((state) => state.user.userInfo)

  return (
    <div className="overview-step-container">
      <TextInput
        value={communityName}
        label="Project name"
        onChange={(e) => setCommunityName(e.target.value)}
      />
      <div className="text-input-wrapper">
        <TextInput
          value={website}
          label="Project website"
          onChange={(e) => setWebsite(e.target.value)}
          placeholder="https://rep3.gg"
        />
      </div>
      <div className="text-input-wrapper">
        <TextInput
          value={projectTwitterAccount}
          label="Project twitter (X) Profile"
          onChange={(e) => setProjectTwitterAccount(e.target.value)}
          placeholder="https://twitter.com/rep3gg"
        />
      </div>
      <div className={`text-input-wrapper ${userInfo?.name ? "text-input-wrapper-disabled" : ""}`}>
        <TextInput
          value={name}
          label="Your name (alias)"
          //   isError
          onChange={(e) => setName(e.target.value)}
          placeholder="Type Here"
          isDisabled={Boolean(userInfo?.name)}
        />
      </div>
      <div
        className={`text-input-wrapper ${userInfo?.telegram ? "text-input-wrapper-disabled" : ""}`}
      >
        <TextInput
          value={telegramAccount}
          label="Your telegram username"
          // optional
          onChange={(e) => setTelegramAccount(e.target.value)}
          placeholder="@rep3gg"
          isDisabled={Boolean(userInfo?.telegram)}
        />
      </div>
      {/* <div className="text-input-wrapper">
        <TextInput
          value={email}
          label="Your Email"
          onChange={(e) => setEmail(e.target.value)}
          isError={isEmailError}
          onBlur={() => validateEmail(email)}
          placeholder="Type Here"
        />
      </div> */}
      {/* <div className="text-input-wrapper">
        <TextInput
          value={role}
          label="Role"
          onChange={(e) => setRole(e.target.value)}
          placeholder="Type Here"
        />
      </div> */}
    </div>
  )
}
