import { ConfigProvider, Select, Switch } from "antd"
import {
  AdapterKeysWithValues,
  AdapterTypes,
  StrategyAdapterWithAdapterName,
} from "../../types/adapter"
import {
  getAbiForVerifiedContract,
  getAdapterInputHeadingFromKey,
  getAdapterPlaceHolderFromKey,
  getContractQueryInputType,
  networkInfos,
} from "../../utils/adapterUtils"
import "./style.scss"
import { useState } from "react"
import accountCircleIcon from "../../assets/icons/accountCircleIcon.svg"
import { useAppSelector } from "../../hooks/reduxHooks"
const RenderInputOnKeyType = ({
  keyObject,
  inputType,
  index,
  updateAdapterInputValue,
  inputUserAddressArray,
  setInputUserAddressIndex,
  inputUserAddressIndex,
  updateAdapterKeysList,
  texSelectOptions,
  overRideInputHeading,
  overRideInputLabel,
  adapterInfoWithValueToPrefill,
}: {
  keyObject: AdapterKeysWithValues
  inputType: string
  index: number
  adapterInfoWithValueToPrefill: AdapterKeysWithValues[]
  updateAdapterInputValue: (adapterKeyIndex: number, value: number | string) => void
  inputUserAddressIndex: number
  inputUserAddressArray?: AdapterKeysWithValues[]
  setInputUserAddressIndex?: (addressIndex: number) => void
  updateAdapterKeysList?: (keyName: string, value: string | number) => void
  texSelectOptions?: { value: string; label: string }[]
  overRideInputHeading?: string | null
  overRideInputLabel?: string | null
}) => {
  const onChange = () => {
    if (inputUserAddressArray && setInputUserAddressIndex && updateAdapterKeysList) {
      console.log(inputUserAddressArray)
      updateAdapterInputValue(inputUserAddressArray[inputUserAddressIndex]?.index ?? 0, "")
      inputUserAddressArray.forEach((x, i) => {
        if (x.index === index) {
          updateAdapterInputValue(x.index, "<USER_ADDRESS>")
          updateAdapterKeysList("addressIndex", i.toString())
          setInputUserAddressIndex(i)
        }
      })
    }
  }
  console.log(
    "valuesss oeprator",
    adapterInfoWithValueToPrefill.filter((x) => x.key === "operator"),
  )
  const operators = [
    { value: "===", label: "=" },
    { value: ">", label: ">" },
    { value: "<", label: "<" },
    { value: "<=", label: "<=" },
    { value: ">=", label: ">=" },
  ]
  console.log(keyObject?.key, "========", keyObject?.value)
  switch (inputType) {
    case "textInput":
      return (
        <div
          className={`single-adapter-input-wrapper ${
            typeof keyObject.value === "string" && !keyObject.value.length
              ? "single-adapter-empty-input-wrapper"
              : ""
          }`}
          key={index}
        >
          <div className="single-adapter-input-text">
            {overRideInputHeading ?? getAdapterInputHeadingFromKey(keyObject.key, keyObject?.name)}
          </div>
          <div className="single-adapter-input">
            <input
              disabled={
                keyObject.key === "inputParam" &&
                keyObject.type === "address" &&
                inputUserAddressArray &&
                inputUserAddressArray[inputUserAddressIndex]?.index === index
              }
              type="text"
              value={keyObject.value}
              onChange={(e) => updateAdapterInputValue(index, e.target.value)}
              placeholder={overRideInputLabel ?? getAdapterPlaceHolderFromKey(keyObject.key)}
            />
            {keyObject.key === "inputParam" && keyObject.type === "address" ? (
              <div className="address-switcher">
                {inputUserAddressArray ? (
                  <Switch
                    checked={inputUserAddressArray[inputUserAddressIndex]?.index === index}
                    onChange={onChange}
                  />
                ) : null}
                <img className="accountCircleIcon" src={accountCircleIcon} alt="" />
              </div>
            ) : null}
          </div>
        </div>
      )
    case "outputInput":
      return (
        <div className={`single-output-adapter-input-wrapper`} key={index}>
          <div className="single-adapter-select-operator">
            <Select
              style={{ width: "100%" }}
              defaultValue={operators[0]}
              value={
                operators.filter(
                  (x) =>
                    x.value ===
                    adapterInfoWithValueToPrefill.filter((y) => y.key === "operator")[0].value,
                )[0]
              }
              onChange={(selection) => {
                if (updateAdapterKeysList) {
                  updateAdapterKeysList("operator", selection?.value ?? selection)
                }
              }}
              placeholder={getAdapterPlaceHolderFromKey(keyObject.key)}
              options={operators}
            />
          </div>
          <div className="single-adapter-output">
            <input
              type="text"
              value={keyObject.value}
              onChange={(e) => updateAdapterInputValue(index, e.target.value)}
              placeholder={getAdapterPlaceHolderFromKey(keyObject.key)}
            />
          </div>
        </div>
      )
    case "textSelect":
      return (
        <div
          className={`single-adapter-input-wrapper ${
            typeof keyObject.value === "string" && !keyObject.value.length
              ? "single-adapter-empty-input-wrapper"
              : ""
          }`}
          key={index}
        >
          <div className="single-adapter-input-text">
            {overRideInputHeading ?? getAdapterInputHeadingFromKey(keyObject.key, keyObject?.name)}
          </div>
          <div className="single-adapter-select">
            <Select
              style={{ width: "100%" }}
              defaultValue={
                keyObject?.value
                  ? {
                      value:
                        keyObject.name === "functionName" && texSelectOptions
                          ? texSelectOptions[0]?.value
                          : keyObject?.value,
                      label: texSelectOptions?.filter((x) => x.value === keyObject?.value)?.[0]
                        ?.label,
                    }
                  : null
              }
              onChange={(selection) => {
                console.log("Function name", keyObject)
                // if (keyObject?.key === "functionName") {
                //   functionSelectFromAbi((selection?.value ?? selection).toString(), index)
                // } else {
                updateAdapterInputValue(index, selection?.value ?? selection)
                // }
              }}
              placeholder={overRideInputLabel ?? getAdapterPlaceHolderFromKey(keyObject.key)}
              options={texSelectOptions}
            />
          </div>
        </div>
      )
    default:
      return <></>
  }
}
const RenderSmartContractQuery = ({
  adapterInfo,
  updateAdapterInputValue,
  insertKeysToAdapter,
  updateAdapterKeysList,
  isEditing,
}: {
  adapterInfo: StrategyAdapterWithAdapterName
  updateAdapterInputValue: (adapterKeyIndex: number, value: number | string) => void
  insertKeysToAdapter: (newAdapterToAdd: AdapterKeysWithValues[]) => void
  updateAdapterKeysList: (keyName: string, value: string | number) => void
  isEditing: boolean
}) => {
  const [adapterFormInputIndex, setAdapterFormInputIndex] = useState(
    !isEditing
      ? adapterInfo.options.keys.filter((x) => x.key === "inputParam").length > 0 &&
        adapterInfo.options.keys.filter((x) => x.key === "outputParam").length > 0
        ? adapterInfo.options.keys.filter((x) => x.key === "inputParam").length +
          adapterInfo.options.keys.filter((x) => x.key === "outputParam").length +
          3
        : 2
      : 12,
  )

  const [isFetchingAbi, setIsFetchingAbi] = useState(false)
  const [abiInputType, setAbiInputType] = useState(isEditing ? "textInput" : "textSelect")
  const [abiOptions, setAbiOptions] = useState<{ value: string; label: string }[]>([])
  const [isContractVerified, setContractVerified] = useState(true)
  const [inputUserAddressIndex, setInputUserAddressIndex] = useState(0)
  const inputUserAddressArray: AdapterKeysWithValues[] = adapterInfo.options.keys
    .map((x, i) => {
      return { ...x, index: i }
    })
    .filter((x) => x.key === "inputParam" && x.type === "address")

  const createTextSelectOptionsFromAbi = (abi: string) => {
    try {
      const functionCheck = JSON.parse(abi)
      console.log("parsed abi", functionCheck)
      const functionOption = functionCheck
        ?.filter((x: { stateMutability: string }) => x?.stateMutability === "view")
        ?.map((x: { name: string }) => {
          // check this name remain same for all
          return { label: x?.name, value: JSON.stringify([x]) }
        })
      console.log("text select options abi", functionOption)
      setAbiOptions(functionOption)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchABI = async () => {
    if (
      adapterInfo.options.keys.filter((x) => x.key === "contractType")[0]?.value &&
      adapterInfo.options.keys.filter((x) => x.key === "chainId")[0]?.value &&
      !adapterInfo.options.keys.filter((x) => x.key === "functionName")[0]?.value
    ) {
      setIsFetchingAbi(true)
      try {
        const res = await getAbiForVerifiedContract(
          adapterInfo.options.keys.filter((x) => x.key === "contractType")[0]?.value,
          adapterInfo.options.keys.filter((x) => x.key === "chainId")[0]?.value,
        )
        if (res?.status === "1") {
          createTextSelectOptionsFromAbi(res?.result)
          setIsFetchingAbi(false)
          setAbiInputType("textSelect")
          setAdapterFormInputIndex(3)
        } else {
          setContractVerified(false)
          setIsFetchingAbi(false)
          setAbiInputType("textInput")
          setAdapterFormInputIndex(3)
          console.log("ran error", abiInputType)
        }
      } catch (error) {
        setContractVerified(false)
        setIsFetchingAbi(false)
        setAbiInputType("textInput")
        setAdapterFormInputIndex(3)
        console.log("ran error", abiInputType)
      }
    }
  }
  const extractingOutputAndInputKeysFromABI = (adapterIndex: number) => {
    if (adapterInfo.options.keys[adapterIndex]?.key === "functionName") {
      try {
        const functionCheck = JSON.parse(adapterInfo.options.keys[adapterIndex]?.value?.toString())
        setAdapterFormInputIndex(3)
        insertKeysToAdapter(
          adapterInfo.options.keys.filter((x) => x.key !== "inputParam" && x.key !== "outputParam"),
        )
        let addressPrefillDone = false
        const totalInputKeysOptions = functionCheck?.[0]?.inputs?.map(
          (x: { name: string; type: string }) => {
            if (x.type === "address" && !addressPrefillDone) {
              addressPrefillDone = true
              console.log("here address", addressPrefillDone)
              return {
                default: "",
                key: "inputParam",
                required: true,
                user_level: false,
                value: "<USER_ADDRESS>",
                type: x.type,
                valid_values: [],
                name: x?.name,
              }
            } else {
              return {
                default: "",
                key: "inputParam",
                required: true,
                user_level: false,
                value: "",
                type: x.type,
                valid_values: [],
                name: x?.name,
              }
            }
          },
        )
        const totalOutputKeysOptions = functionCheck?.[0]?.outputs?.map(
          (x: { name: string; type: string }) => {
            return {
              default: "",
              key: "outputParam",
              required: true,
              user_level: false,
              value: "",
              type: x.type,
              valid_values: [],
              name: x?.name,
            }
          },
        )
        updateAdapterKeysList("abi", functionCheck[0]?.name)
        insertKeysToAdapter([
          ...adapterInfo.options.keys.slice(0, 3),
          ...totalInputKeysOptions,
          totalOutputKeysOptions[0],
          ...adapterInfo.options.keys.slice(3 - adapterInfo.options.keys.length),
          {
            default: "",
            key: "addressIndex",
            required: true,
            user_level: false,
            value: "0",
            valid_values: [],
          },
        ])
        setAdapterFormInputIndex(
          adapterFormInputIndex + totalInputKeysOptions.length + totalOutputKeysOptions.length,
        )
      } catch (error) {
        insertKeysToAdapter(
          adapterInfo.options.keys.filter((x) => x.key !== "inputParam" && x.key !== "outputParam"),
        )
        console.log(error)
      }
    }
  }

  const updateExtraKeysOnInput = (
    keyObject: AdapterKeysWithValues,
    adapterIndex: number,
    value: string | number,
  ) => {
    console.log("adapter index", adapterIndex)
    if (keyObject.key === "contractType") {
      updateAdapterInputValue(adapterIndex, value)
      updateAdapterKeysList("contractAddress", value)
      updateAdapterKeysList("abi", "")
      // resetting the abi flow to fetch again
      updateAdapterInputValue(2, "")
      setAdapterFormInputIndex(2)
    } else if (keyObject.key === "functionName") {
      updateAdapterInputValue(adapterIndex, value?.toString().replace(/(\r\n|\n|\r)/gm, ""))
      updateAdapterKeysList("operator", "===")
    } else if (keyObject.key === "outputParam") {
      updateAdapterInputValue(adapterIndex, value)
      updateAdapterKeysList("balanceThreshold", value)
      updateAdapterKeysList("functionParam", "[]")
    } else {
      updateAdapterInputValue(adapterIndex, value)
    }
  }
  const networkOptions = networkInfos.map((x) => {
    return { label: x.label, value: x.value }
  })
  const keysInputToBeVisible = [
    "contractType",
    "chainId",
    "functionName",
    "inputParam",
    "outputParam",
  ]
  return (
    <div className="single-adapter-requirements-input-wrapper">
      {adapterInfo.options?.keys.slice(0, adapterFormInputIndex).map((keyObject, index) => (
        <>
          {keyObject.key === "outputParam" && <div className="input-section-divider" />}
          {keyObject.key === "outputParam" && <div className="input-secondary-heading">Output</div>}
          {keysInputToBeVisible.includes(keyObject?.key) ? (
            <RenderInputOnKeyType
              keyObject={keyObject}
              adapterInfoWithValueToPrefill={adapterInfo.options?.keys}
              index={index}
              key={index.toString()}
              inputUserAddressArray={inputUserAddressArray}
              inputType={
                keyObject.key === "functionName"
                  ? abiInputType
                  : getContractQueryInputType(keyObject?.key)
              }
              updateAdapterKeysList={updateAdapterKeysList}
              setInputUserAddressIndex={setInputUserAddressIndex}
              inputUserAddressIndex={inputUserAddressIndex}
              updateAdapterInputValue={async (adapterIndex, value) => {
                updateExtraKeysOnInput(keyObject, adapterIndex, value)
                await fetchABI()
                extractingOutputAndInputKeysFromABI(adapterIndex)
              }}
              texSelectOptions={
                keyObject.key === "functionName" && abiInputType === "textSelect"
                  ? abiOptions
                  : networkOptions
              }
              overRideInputLabel={
                keyObject.key === "functionName" && abiInputType === "textSelect"
                  ? "Select Function"
                  : keyObject.key === "functionName" && abiInputType === "textInput"
                  ? "Paste ABI"
                  : null
              }
            />
          ) : null}
          {keyObject.key === "functionName" && keyObject.value && (
            <div className="input-section-divider" />
          )}
          {!isContractVerified && adapterFormInputIndex > 2 && keyObject.key === "chainId" && (
            <div className="input-secondary-text">
              Contract unverified, please manually enter ABI fragment
            </div>
          )}
          {isFetchingAbi && keyObject.key === "chainId" && (
            <div className="input-secondary-text">Fetching ABIs</div>
          )}
        </>
      ))}
    </div>
  )
}
const RenderErc20TokenBalance = ({
  adapterInfo,
  updateAdapterInputValue,
  insertKeysToAdapter,
  updateAdapterKeysList,
}: {
  adapterInfo: StrategyAdapterWithAdapterName
  updateAdapterInputValue: (adapterKeyIndex: number, value: number | string) => void
  insertKeysToAdapter: (newAdapterToAdd: AdapterKeysWithValues[]) => void
  updateAdapterKeysList: (keyName: string, value: string | number) => void
}) => {
  const [adapterFormInputIndex, setAdapterFormInputIndex] = useState(
    adapterInfo.options.keys.length,
  )
  const erc20Abi = [
    {
      constant: true,
      inputs: [
        {
          name: "_owner",
          type: "address",
        },
      ],
      name: "balanceOf",
      outputs: [
        {
          name: "balance",
          type: "uint256",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
  ]
  const networkOptions = networkInfos.map((x) => {
    return { label: x.label, value: x.value }
  })
  const extractingOutputAndInputKeysFromABI = () => {
    if (adapterInfo.options.keys.filter((x) => x.key === "outputParam").length === 0) {
      try {
        const totalOutputKeysOptions = {
          default: "",
          key: "outputParam",
          required: true,
          user_level: false,
          value: "",
          type: "uint256",
          valid_values: [],
          name: "balance",
        }

        insertKeysToAdapter([...adapterInfo.options.keys, totalOutputKeysOptions])
        setAdapterFormInputIndex([...adapterInfo.options.keys, totalOutputKeysOptions].length)
      } catch (error) {
        console.log(error)
      }
    }
  }
  const updateExtraKeysOnInput = (
    keyObject: AdapterKeysWithValues,
    adapterIndex: number,
    value: string | number,
  ) => {
    if (keyObject.key === "contractType") {
      updateAdapterInputValue(adapterIndex, value)
      updateAdapterKeysList("contractAddress", value)
      updateAdapterKeysList("abi", erc20Abi.toString())
      updateAdapterKeysList("functionName", "balanceOf")
      updateAdapterKeysList("operator", "===")
      extractingOutputAndInputKeysFromABI()
    } else if (keyObject.key === "outputParam") {
      updateAdapterInputValue(adapterIndex, value)
      updateAdapterKeysList("balanceThreshold", value)
      updateAdapterKeysList("functionParam", "[]")
    } else {
      updateAdapterInputValue(adapterIndex, value)
    }
  }
  const keysInputToBeVisible = ["contractType", "chainId", "outputParam"]
  return (
    <div className="single-adapter-requirements-input-wrapper">
      {adapterInfo.options?.keys.slice(0, adapterFormInputIndex).map((keyObject, index) => (
        <>
          {keyObject.key === "outputParam" && <div className="input-section-divider" />}
          {keyObject.key === "outputParam" && <div className="input-secondary-heading">Output</div>}
          {keysInputToBeVisible.includes(keyObject?.key) ? (
            <RenderInputOnKeyType
              keyObject={keyObject}
              inputUserAddressIndex={0}
              adapterInfoWithValueToPrefill={adapterInfo.options?.keys}
              index={index}
              key={index.toString()}
              inputType={getContractQueryInputType(keyObject?.key)}
              texSelectOptions={networkOptions}
              updateAdapterKeysList={updateAdapterKeysList}
              updateAdapterInputValue={async (adapterIndex, value) => {
                updateExtraKeysOnInput(keyObject, adapterIndex, value)
              }}
            />
          ) : null}
        </>
      ))}
    </div>
  )
}
const RenderErc721TokenBalance = ({
  adapterInfo,
  updateAdapterInputValue,
  insertKeysToAdapter,
  updateAdapterKeysList,
  isEditing,
}: {
  adapterInfo: StrategyAdapterWithAdapterName
  updateAdapterInputValue: (adapterKeyIndex: number, value: number | string) => void
  insertKeysToAdapter: (newAdapterToAdd: AdapterKeysWithValues[]) => void
  updateAdapterKeysList: (keyName: string, value: string | number) => void
  isEditing: boolean
}) => {
  const [adapterFormInputIndex, setAdapterFormInputIndex] = useState(isEditing ? 9 : 3)
  const erc721Abi = [
    {
      inputs: [{ internalType: "address", name: "owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ internalType: "uint256", name: "balance", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
  ]
  const erc721FunctionOptions = [{ value: "balanceOf", label: "Amount" }]
  const extractingOutputAndInputKeysFromABI = () => {
    if (adapterInfo.options.keys.filter((x) => x.key === "outputParam").length === 0) {
      try {
        const totalOutputKeysOptions = erc721Abi?.[0]?.outputs?.map(
          (x: { name: string; type: string }) => {
            return {
              default: "",
              key: "outputParam",
              required: true,
              user_level: false,
              value: "",
              type: x.type,
              valid_values: [],
              name: x?.name,
            }
          },
        )
        insertKeysToAdapter([...adapterInfo.options.keys, ...totalOutputKeysOptions])
        setAdapterFormInputIndex([...adapterInfo.options.keys, ...totalOutputKeysOptions].length)
      } catch (error) {
        console.log(error)
      }
    }
  }
  const networkOptions = networkInfos.map((x) => {
    return { label: x.label, value: x.value }
  })
  const updateExtraKeysOnInput = (
    keyObject: AdapterKeysWithValues,
    adapterIndex: number,
    value: string | number,
  ) => {
    if (keyObject.key === "contractType") {
      updateAdapterInputValue(adapterIndex, value)
      updateAdapterKeysList("contractAddress", value)
      updateAdapterKeysList("operator", "===")
    } else if (keyObject.key === "functionName") {
      updateAdapterKeysList("functionName", "balanceOf")
      updateAdapterKeysList("abi", erc721Abi.toString())
      extractingOutputAndInputKeysFromABI()
    } else if (keyObject.key === "outputParam") {
      updateAdapterInputValue(adapterIndex, value)
      updateAdapterKeysList("balanceThreshold", value)
      updateAdapterKeysList("functionParam", "[]")
    } else {
      updateAdapterInputValue(adapterIndex, value)
    }
  }
  const keysInputToBeVisible = ["contractType", "chainId", "functionName", "outputParam"]
  return (
    <div className="single-adapter-requirements-input-wrapper">
      {adapterInfo.options?.keys.slice(0, adapterFormInputIndex).map((keyObject, index) => (
        <>
          {keyObject.key === "outputParam" && <div className="input-section-divider" />}
          {keyObject.key === "outputParam" && <div className="input-secondary-heading">Output</div>}
          {keysInputToBeVisible.includes(keyObject?.key) ? (
            <RenderInputOnKeyType
              keyObject={keyObject}
              inputUserAddressIndex={0}
              adapterInfoWithValueToPrefill={adapterInfo.options?.keys}
              index={index}
              key={index.toString()}
              inputType={getContractQueryInputType(keyObject?.key)}
              updateAdapterKeysList={updateAdapterKeysList}
              updateAdapterInputValue={async (adapterIndex, value) => {
                updateExtraKeysOnInput(keyObject, adapterIndex, value)
              }}
              texSelectOptions={
                keyObject?.key === "chainId" ? networkOptions : erc721FunctionOptions
              }
              overRideInputHeading={keyObject?.key === "functionName" ? "Requirement type" : null}
              overRideInputLabel={keyObject?.key === "functionName" ? "Select Requirement" : null}
            />
          ) : null}
        </>
      ))}
    </div>
  )
}
const RenderErc1155TokenBalance = ({
  adapterInfo,
  updateAdapterInputValue,
  insertKeysToAdapter,
  updateAdapterKeysList,
  isEditing,
}: {
  adapterInfo: StrategyAdapterWithAdapterName
  updateAdapterInputValue: (adapterKeyIndex: number, value: number | string) => void
  insertKeysToAdapter: (newAdapterToAdd: AdapterKeysWithValues[]) => void
  updateAdapterKeysList: (keyName: string, value: string | number) => void
  isEditing: boolean
}) => {
  const [adapterFormInputIndex, setAdapterFormInputIndex] = useState(isEditing ? 10 : 3)
  const erc1155Abi = [
    {
      inputs: [
        {
          internalType: "address",
          name: "account",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "id",
          type: "uint256",
        },
      ],
      name: "balanceOf",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
  ]
  const erc721FunctionOptions = [{ value: "balanceOf", label: "Amount" }]
  const extractingOutputAndInputKeysFromABI = () => {
    if (adapterInfo.options.keys.filter((x) => x.key === "outputParam").length === 0) {
      try {
        const totalInputKeysOptions = {
          default: "",
          key: "inputParam",
          required: true,
          user_level: false,
          value: "",
          type: "uint256",
          valid_values: [],
          name: "id",
        }
        const totalOutputKeysOptions = {
          default: "",
          key: "outputParam",
          required: true,
          user_level: false,
          value: "",
          type: "uint256",
          valid_values: [],
          name: "balance",
        }
        insertKeysToAdapter([
          ...adapterInfo.options.keys,
          totalInputKeysOptions,
          totalOutputKeysOptions,
        ])
        setAdapterFormInputIndex(
          [...adapterInfo.options.keys, totalInputKeysOptions, totalOutputKeysOptions].length,
        )
      } catch (error) {
        console.log(error)
      }
    }
  }
  const networkOptions = networkInfos.map((x) => {
    return { label: x.label, value: x.value }
  })
  const updateExtraKeysOnInput = (
    keyObject: AdapterKeysWithValues,
    adapterIndex: number,
    value: string | number,
  ) => {
    if (keyObject.key === "contractType") {
      updateAdapterInputValue(adapterIndex, value)
      updateAdapterKeysList("contractAddress", value)
      updateAdapterKeysList("operator", "===")
    } else if (keyObject.key === "functionName") {
      updateAdapterKeysList("functionName", "balanceOf")
      updateAdapterKeysList("abi", erc1155Abi.toString())
      extractingOutputAndInputKeysFromABI()
    } else if (keyObject.key === "outputParam") {
      updateAdapterInputValue(adapterIndex, value)
      updateAdapterKeysList("balanceThreshold", value)
      updateAdapterKeysList("functionParam", "[]")
    } else {
      updateAdapterInputValue(adapterIndex, value)
    }
  }
  const keysInputToBeVisible = [
    "contractType",
    "chainId",
    "functionName",
    "inputParam",
    "outputParam",
  ]
  console.log("Adapter info......", adapterInfo)
  return (
    <div className="single-adapter-requirements-input-wrapper">
      {adapterInfo.options?.keys.slice(0, adapterFormInputIndex).map((keyObject, index) => (
        <>
          {keyObject.key === "outputParam" && <div className="input-section-divider" />}
          {keyObject.key === "outputParam" && <div className="input-secondary-heading">Output</div>}
          {keysInputToBeVisible.includes(keyObject?.key) ? (
            <RenderInputOnKeyType
              keyObject={keyObject}
              inputUserAddressIndex={0}
              adapterInfoWithValueToPrefill={adapterInfo.options?.keys}
              index={index}
              key={index.toString()}
              inputType={getContractQueryInputType(keyObject?.key)}
              updateAdapterKeysList={updateAdapterKeysList}
              updateAdapterInputValue={async (adapterIndex, value) => {
                updateExtraKeysOnInput(keyObject, adapterIndex, value)
              }}
              texSelectOptions={
                keyObject?.key === "chainId" ? networkOptions : erc721FunctionOptions
              }
              overRideInputHeading={keyObject?.key === "functionName" ? "Requirement type" : null}
              overRideInputLabel={keyObject?.key === "functionName" ? "Select Requirement" : null}
            />
          ) : null}
          {keyObject.key === "functionName" && keyObject.value && (
            <div className="input-section-divider" />
          )}
        </>
      ))}
    </div>
  )
}
const RenderContractAdapterFormOnSubType = ({
  adapterInfo,
  updateAdapterInputValue,
  insertKeysToAdapter,
  updateAdapterKeysList,
  adapterSubtype,
}: Readonly<{
  adapterInfo: StrategyAdapterWithAdapterName
  updateAdapterInputValue: (adapterKeyIndex: number, value: number | string) => void
  insertKeysToAdapter: (newAdapterToAdd: AdapterKeysWithValues[]) => void
  updateAdapterKeysList: (keyName: string, value: string | number) => void
  adapterSubtype: string
}>) => {
  const isEditing = useAppSelector((state) => state.questCreation.isEditing)
  switch (adapterSubtype) {
    case "SMART_CONTRACT_view":
      return (
        <RenderSmartContractQuery
          adapterInfo={adapterInfo}
          updateAdapterInputValue={updateAdapterInputValue}
          insertKeysToAdapter={insertKeysToAdapter}
          updateAdapterKeysList={updateAdapterKeysList}
          isEditing={isEditing}
        />
      )
    case "SMART_CONTRACT_erc20":
      return (
        <RenderErc20TokenBalance
          adapterInfo={adapterInfo}
          updateAdapterInputValue={updateAdapterInputValue}
          insertKeysToAdapter={insertKeysToAdapter}
          updateAdapterKeysList={updateAdapterKeysList}
        />
      )
    case "SMART_CONTRACT_erc721":
      return (
        <RenderErc721TokenBalance
          adapterInfo={adapterInfo}
          updateAdapterInputValue={updateAdapterInputValue}
          insertKeysToAdapter={insertKeysToAdapter}
          updateAdapterKeysList={updateAdapterKeysList}
          isEditing={isEditing}
        />
      )
    case "SMART_CONTRACT_erc1155":
      return (
        <RenderErc1155TokenBalance
          adapterInfo={adapterInfo}
          updateAdapterInputValue={updateAdapterInputValue}
          insertKeysToAdapter={insertKeysToAdapter}
          updateAdapterKeysList={updateAdapterKeysList}
          isEditing={isEditing}
        />
      )
    default:
      return <></>
  }
}
export default function ContractStrategyAdapterInputType({
  adapterInfo,
  updateAdapterInputValue,
  insertKeysToAdapter,
  updateAdapterKeysList,
}: Readonly<{
  adapterInfo: StrategyAdapterWithAdapterName
  updateAdapterInputValue: (adapterKeyIndex: number, value: number | string) => void
  insertKeysToAdapter: (newAdapterToAdd: AdapterKeysWithValues[]) => void
  updateAdapterKeysList: (keyName: string, value: string | number) => void
}>) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            optionFontSize: 16,
            optionHeight: 32,
            optionLineHeight: "1.5rem",
          },
        },
      }}
    >
      <div className="adpater-input-type-container">
        <RenderContractAdapterFormOnSubType
          adapterInfo={adapterInfo}
          updateAdapterInputValue={updateAdapterInputValue}
          insertKeysToAdapter={insertKeysToAdapter}
          updateAdapterKeysList={updateAdapterKeysList}
          adapterSubtype={adapterInfo?.adapter_subtype}
        />
      </div>
    </ConfigProvider>
  )
}
