import { ConnectButton } from "@rainbow-me/rainbowkit"
import React, { useEffect, useRef, useState } from "react"
import "./style.scss"
import { setJwt, setShowSignMessage } from "../../store/actions/authAction"
// import { initializeRep3Package } from "../../utils/Rep3SdkUtils"
import { useAccount, useAccountEffect, useDisconnect, useSignMessage, useWalletClient } from "wagmi"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import { resetUserCommunities } from "../../store/actions/overviewAction"
import { fetchUserInfo } from "../../store/actions/userAction"
import { routes } from "../../constant/routes"
import apiClient from "../../utils/apiClient"
import { authActions } from "../../store/reducers/authSlice"
import { Address } from "viem"

export default function WalletConnectButton() {
  const dispatch = useAppDispatch()
  const { isConnected, address, chainId } = useAccount()
  const authAddress = useAppSelector((state) => state.auth.authAddress)
  const showSignMessage = useAppSelector((state) => state.auth.showSignMessage)
  const { data: walletClient } = useWalletClient()
  const [isSignedWithBackend, setIsSignedWithBackend] = useState(false)
  const [signMessageDisabled, setSignMessageDisabled] = useState(false)
  const jwt = useAppSelector((state) => state.auth.jwt)
  const { disconnect } = useDisconnect()
  const { signMessageAsync: wagmiSignMessage } = useSignMessage({
    mutation: {
      onSuccess(data, variables, context) {},
    },
  })

  useAccountEffect({
    onConnect({ address }) {
      if (address) {
        console.log("in connect")
        if (authAddress && authAddress !== address) {
          setIsSignedWithBackend(false)
          localStorage.clear()
          dispatch(setJwt(null))
          dispatch(setShowSignMessage(false))
        }
        // Sentry.setUser({ id: address });
        // dispatch(fetchUserInfo(address))
      }
    },
    onDisconnect() {
      console.log("disconnecting from on")
      // dispatch(refreshUserIdentities());
      // dispatch(questAction.setQuestClaimedRewardInfo(null));
      // Sentry.configureScope((scope) => scope.setUser(null));
      dispatch(resetUserCommunities())
      setIsSignedWithBackend(false)
      localStorage.clear()
      dispatch(setJwt(null))
    },
  })

  const signMessage = async () => {
    try {
      if (!signMessageDisabled && address && walletClient) {
        setSignMessageDisabled(true)
        {
          try {
            const responseNonce = await apiClient.get(
              `${process.env.REACT_APP_API_BASE_URL}${routes.auth.nonce}?eoa=${address}`,
            )
            const signature = await wagmiSignMessage({
              account: address,
              message: `Signing in to rep3.gg with nonce ${responseNonce.data.nonce}`,
            })
            try {
              const responseToken = await apiClient.get(
                `${process.env.REACT_APP_API_BASE_URL}${routes.auth.token}?eoa=${address}&signature=${signature}`,
              )
              if (responseToken.status === 200) {
                dispatch(setJwt(responseToken.data.token))
                dispatch(authActions.setAuthAddress({ authAddress: address }))

                return 1
              }
              return 0
            } catch (error) {
              return 0
            }
          } catch (error) {
            return 0
          }
        }
        // const res = await dispatch(authWithSign(address, walletClient))
        // if (res) {
        //   setIsSignedWithBackend(true)
        //   // initializeRep3Package(signer)
        //   dispatch(fetchUserInfo(address))
        // }
        // setSignMessageDisabled(false)
      }
    } catch (err) {
      console.error("in catch err", err)
      setSignMessageDisabled(false)
    }
  }

  return (
    <>
      <div className="connect-button-custom-container">
        <ConnectButton.Custom>
          {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
            const connected = mounted && account && chain
            return (
              <div
                {...(!mounted && {
                  "aria-hidden": true,
                  style: {
                    opacity: 0,
                    pointerEvents: "none",
                    userSelect: "none",
                  },
                })}
              >
                {(() => {
                  if (!connected || !jwt) {
                    return (
                      <button
                        onClick={() => {
                          openConnectModal()
                          dispatch(setShowSignMessage(true))
                        }}
                        type="button"
                        className="connect-wallet-btn"
                      >
                        Connect
                      </button>
                    )
                  }

                  if (chain.unsupported) {
                    return (
                      <button onClick={openChainModal} type="button" className="connect-wallet-btn">
                        Wrong network
                      </button>
                    )
                  }

                  return (
                    <div style={{ display: "flex", gap: 12 }}>
                      <button
                        onClick={openAccountModal}
                        type="button"
                        className="connect-wallet-btn"
                      >
                        {account.displayName}
                      </button>
                    </div>
                  )
                })()}
              </div>
            )
          }}
        </ConnectButton.Custom>
      </div>
      {!jwt && !isSignedWithBackend && isConnected && showSignMessage && (
        <div className="signMessageBackdrop">
          <div className="signMessageBlurLayerWrapper">
            <div className="signMessageBlurLayer"></div>
          </div>
          <div className="signMessageMain">
            <div className={`signMessageHeading headerSignMessageHeading1`}>
              Sign the message in your wallet to continue
            </div>
            <div className="headerSignMessageHeading2">
              rep3 uses this signature to verify that you’re the owner of this Ethereum address.
            </div>
            <div className={`continueSignBtn headerSignMessageButton`}>
              <button onClick={() => signMessage()}>Continue</button>
            </div>
            <div className="disconnectBtn" onClick={() => disconnect()}>
              disconnect
            </div>
          </div>
        </div>
      )}
    </>
  )
}
