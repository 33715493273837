/* eslint-disable  @typescript-eslint/no-explicit-any */

import { Overwrite } from "./common"

export type AdapterNames =
  | "TWITTER"
  | "DISCORD"
  | "SMART_CONTRACT"
  | "OPEN_LINK"
  | "TASK_WHITELIST_ADAPTER"
  | "TELEGRAM_LINK"
  | "QUIZ_FORM"
  | "CSV"
  | "REFERRAL_count"
  | "PREREQUISITE"
  | "CHECK_PASSWORD"
  | "PREREQUISITE_quests"
  | "COMMUNITY_STRATEGY"
  | "SUBMIT_FORM"
export type SmartContractAdapterSubtypeNames =
  | "SMART_CONTRACT_view"
  | "SMART_CONTRACT_erc20"
  | "SMART_CONTRACT_erc721"
  | "SMART_CONTRACT_erc1155"
export type AdapterIdentities = "DISCORD" | "TWITTER"
export type NumberOrString = number | string

export type TQuizAdapterOption = {
  [key: number]: string
}

export interface IStrategyOptions {
  csvBucketName: string
  csvKey: string
  questType: string
}

export type TQuizAdapterQuestion = {
  question: string
  options: TQuizAdapterOption
  answer_id: number
}

export interface AdapterKeys {
  default: number | string | IStrategyOptions
  key: string
  required: boolean
  user_level: boolean
  type?: string
  name?: string
  valid_values: NumberOrString[]
}

export type AdapterKeysWithValues = AdapterKeys & {
  value: number | string
  index?: number
}

export type TQuizQA = { [key: number]: TQuizAdapterQuestion }

export type TAdapterKeysWithValuesQuiz = Overwrite<
  AdapterKeys,
  {
    value: TQuizQA
    key: "q/a"
    index?: number
  }
>

export interface AdapterOptions {
  identities: AdapterIdentities
  keys: AdapterKeysWithValues[]
}

export type TAdapterOptionsQuiz = {
  identities: AdapterIdentities
  keys: Array<TAdapterKeysWithValuesQuiz | AdapterKeysWithValues>
}

export interface NonStrategyAdapter {
  options: AdapterOptions
  task_type: string
  uuid?: string
}

export type TNonStrategyQuizAdapterWithName = Overwrite<
  NonStrategyAdapter,
  {
    adapter_name: "QUIZ_FORM"
    adapter_subtype: string
    options: TAdapterOptionsQuiz
  }
>

export type NonStrategyAdapterWithAdapterName =
  | (NonStrategyAdapter & {
      adapter_name: AdapterNames
      adapter_subtype: string
    })
  | TNonStrategyQuizAdapterWithName

export type StrategyAdapter = {
  adapter_subtype: string
  strategy_uuid: string
  options: AdapterOptions
  uuid?: string
}

export type StrategyAdapterWithAdapterName = StrategyAdapter & {
  adapter_name: AdapterNames
  adapter_subtype: string
}

export interface ICSVStrategy {
  strategyOptions: IStrategyOptions
}

export type AdapterTypes = StrategyAdapterWithAdapterName | NonStrategyAdapterWithAdapterName

export type AdapterTypesWithInputs = AdapterTypes & {
  adapter_inputs: object
}

export interface Adapter {
  adapter_name: AdapterNames
  adapter_types: AdapterTypes[]
}

export const isStrategyAdapter = (x: any): x is StrategyAdapterWithAdapterName => x.adapter_subtype
export const isSmartContractStrategyAdapter = (x: any): x is SmartContractAdapterSubtypeNames =>
  x.adapter_subtype
export const isNonStrategyAdapter = (x: any): x is NonStrategyAdapterWithAdapterName => x.task_type
export const isQuizNonStrategyAdapter = (x: any): x is TNonStrategyQuizAdapterWithName =>
  x.adapter_name === "QUIZ_FORM"
export const isSubmitFormNonStrategyAdapter = (x: any): x is TNonStrategyQuizAdapterWithName =>
  x.adapter_name === "SUBMIT_FORM"
export const isPrerequisite = (x: any): x is TNonStrategyQuizAdapterWithName =>
  x.adapter_name === "PREREQUISITE"
export function isStrategyOptions(object: any): object is IStrategyOptions {
  return (object as IStrategyOptions).csvKey !== undefined
}
export function isCSVOptions(object: any): object is ICSVStrategy {
  return (object as ICSVStrategy).strategyOptions !== undefined
}
export interface NetworkApiSecrets {
  string: { api: string; keys: string }
}

export type TTwitterAccountInfo = {
  id: string
  profile_pic: string
  username: string
}
