import "./style.scss"
import { MdClose } from "react-icons/md"
import { useAppSelector, useAppDispatch } from "../../hooks/reduxHooks"
import { RiArrowUpDownLine } from "react-icons/ri"
import { ReactComponent as ArrowForwardIcon } from "../../assets/icons/arrowForwardIcon.svg"
import { reOrderCollection } from "../../store/actions/overviewAction"
import { useEffect, useState } from "react"
import { message } from "antd"
import Lottie from "react-lottie"
import animationLoadingData from "../../assets/lottie/buttonLoader.json"

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationLoadingData,
  renderer: "svg",
}

const ReorderCollectionModal = ({ closeModal }: { closeModal: () => void }) => {
  const dispatch = useAppDispatch()
  const communityInfo = useAppSelector((state) => state.overview.selectedCommunity)
  const collectionsStore = useAppSelector((state) => state.overview.collections)
  const FilteredCollections = collectionsStore?.map<
    { value: string; order: number; uuid?: string }
  >((curr) => {
    return { value: curr.name, order: curr.order, uuid: curr.uuid }
  })
  const [collections, setCollection] = useState(FilteredCollections)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleClickClose = () => {
    closeModal()
  }
  const swapValues = (index: number) => {
    console.log(index, "here in side")
    if (collections && index < collections.length - 1) {
      const newCollections = [...collections]
      const temp = newCollections[index].value
      const tempUuid = newCollections[index].uuid
      newCollections[index].value = newCollections[index + 1].value
      newCollections[index].uuid = newCollections[index + 1].uuid
      newCollections[index + 1].value = temp
      newCollections[index + 1].uuid = tempUuid
      setCollection(newCollections)
    }
  }

  const handleActionButtonClick = async () => {
    if (collections) {
      setIsSubmitting(true)
      const newCollectionsOrder: { order: number; collection_uuid: string | undefined }[] =
        collections?.reduce<{ order: number; collection_uuid: string | undefined }[]>(
          (acc, curr, index) => {
            return [...acc, { order: index+1, collection_uuid: curr.uuid as string }]
          },
          [],
        )
      const newCollectionsPayload = {
        community_uuid: communityInfo?.uuid as string,
        collection_orders: newCollectionsOrder,
      }
      const res = await dispatch(reOrderCollection(newCollectionsPayload))
      if (res) {
        message.success("Collections order changed successfully!!")
        closeModal();
      }else{
        message.error("Something went wrong, try again")
      }
      setIsSubmitting(false)
    }
  }

  return (
    <div className="reorder-collection-modal">
      <div className="reorder-collection-modal-container">
        <div className="reorder-collection-modal-header">
          <div className="reorder-collection-modal-main-heading">
            <div>Reorder Collection</div>
            <MdClose onClick={() => handleClickClose()} />
          </div>
        </div>
        <div className="reorder-collection-main-body">
          <div className="reorder-collection-list-main-body-container">
            <div className="reorder-collection-list-heading">Collection Order</div>
            {collections ? (
              <div className="select-quest-row">
                {collections.map((collection, index) => (
                  <div className={`select-and-cross`} key={index}>
                    {collection.value}
                    {collections?.length && index < collections?.length - 1 && (
                      <div className="swap-btn-container" onClick={() => swapValues(index)}>
                        <RiArrowUpDownLine />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        <div className="reorder-collection-footer">
          <button className="action-button" onClick={() => handleActionButtonClick()}>
            Save Changes
            {isSubmitting ? (
              <Lottie options={lottieOptions} width={20} height={20} />
            ) : (
              <ArrowForwardIcon />
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ReorderCollectionModal
