import React from "react"
import doneAllIcon from "../../assets/icons/doneAllIcon.svg"
import WalletConnectButton from "../ConnectButton"
import "./style.scss"
import { useAccount } from "wagmi"
import { useAppSelector } from "../../hooks/reduxHooks"
import { useNavigate } from "react-router-dom"
import connectWalletImage from "../../assets/images/connectWalletImage.png"
import Image from "../Image"

export default function EmptyPageScreen() {
  const { isConnected } = useAccount()
  const jwt = useAppSelector((state) => state.auth.jwt)
  const navigate = useNavigate()

  return (
    <div className="empty-page-screen-container">
      <div className="empty-page-screen-left">
        <Image src={connectWalletImage} />
      </div>
      <div className="empty-page-screen-right">
        <div className="empty-page-screen-heading">Exceptional community experience</div>
        <div className="empty-page-offering-wrapper">
          <div className="empty-page-offering-row">
            <img src={doneAllIcon} alt="" />
            Build quests, campaigns and loyalty programs to reach and engage a huge web3 audience{" "}
          </div>
          <div className="empty-page-offering-row">
            <img src={doneAllIcon} alt="" />
            Reward community members for participation with XPs, NFTs and SBTs. No coding is
            required.
          </div>
          <div className="empty-page-offering-row">
            <img src={doneAllIcon} alt="" />
            Design a home for your members as per your brand guidelines with gamified plugins
            including a leaderboard, collections and dynamic badges
          </div>
        </div>
        <div className="empty-page-cta-wrapper">
          {!isConnected || !jwt ? (
            <WalletConnectButton />
          ) : (
            <button className="empty-page-cta-btn" onClick={() => navigate("/setup/community")}>
              Create Community
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
