import React,{ useEffect } from 'react';

function useOutsideClick<T extends HTMLElement>(ref: React.RefObject<T>, onOutsideClick: () => void): void {
    useEffect(() => {
        const listener = (event: MouseEvent): void => {
            // Do nothing if clicking ref's element or descendant elements
            if (!ref.current || ref.current.contains(event.target as Node)) {
                return;
            }
            onOutsideClick();
        };

        document.addEventListener('mousedown', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, [ref, onOutsideClick]); // Rebind if ref or onOutsideClick changes
}

export default useOutsideClick;