import React, { useEffect, useState, useRef } from "react"
import "./style.scss"
import { MdClose } from "react-icons/md"
import TextInput from "../Inputs/TextInput"
import TextArea from "../Inputs/TextArea"
import { ReactComponent as UploadIcon } from "../../assets/icons/uploadIcon.svg"
import { ReactComponent as ArrowForwardIcon } from "../../assets/icons/arrowForwardIcon.svg"
import { Radio, Tooltip, type RadioChangeEvent, Typography, Select, message } from "antd"
import { BsCaretDownFill } from "react-icons/bs"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import { IoMdClose, IoMdAdd } from "react-icons/io"
import { RiArrowUpDownLine } from "react-icons/ri"
import { Collection } from "../../types/collection"
import { DefaultOptionType } from "antd/es/select"
import { createCollection, updateCollection } from "../../store/actions/overviewAction"
import { uploadFileToS3 } from "../../utils/awsUtils"
import { getFileExtension, stringWithoutSpecialChars } from "../../utils/regex"
import dayjs from "dayjs"
import { CloudFrontImageUrl, S3ImageBucketName } from "../../constant/constantValues"
import { ReactComponent as InfoIcon } from "../../assets/icons/infoIcon.svg"
import inputErrorIcon from "../../assets/icons/inputErrorIcon.svg"
import Lottie from "react-lottie"
import animationLoadingData from "../../assets/lottie/buttonLoader.json"

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationLoadingData,
  renderer: "svg",
}
const { Text, Link } = Typography
const { Option } = Select

interface QuestSelected {
  id: number
  value?: string
  uuid?: string
}

const CreateCollectionModal = ({
  closeModal,
  collection,
}: {
  closeModal: () => void
  collection?: Collection | null
}) => {
  const dispatch = useAppDispatch()
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const [collectionName, setCollectionName] = useState(collection ? collection.name : "")
  const [collectionDescription, setCollectionDescription] = useState(
    collection ? collection.description : "",
  )
  const [collectionDisplayUrl, setCollectionDisplayUrl] = useState(
    collection ? collection.display_path : "",
  )
  const [selectedNavItem, setSelectedNavItem] = useState<number>(0)
  const [collectionImage, setCollectionImage] = useState(
    collection ? (collection.banner_image_url ? collection.banner_image_url : "") : "",
  )
  const [collectionImageFile, setCollectionImageFile] = useState<File>()
  const [template, setTemplate] = useState<number>(0)
  const [collectionDisplayUrlChanged, setCollectionDisplayUrlChanged] = useState(false)
  const [questSelected, setQuestSelected] = useState<QuestSelected[]>([
    { id: 0, value: undefined, uuid: undefined },
  ])
  const [deletedQuest, setDeletedQuest] = useState<QuestSelected[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const quests = useAppSelector((state) => state.overview.activeCampaignsAndQuests)
  const community = useAppSelector((state) => state.overview.selectedCommunity)
  const jwt = useAppSelector((state) => state.auth.jwt)
  const selectedCommunity = useAppSelector((state) => state.overview.selectedCommunity)
  const collectionsInCommunity = useAppSelector((state) => state.overview.collections)

  let questsInOtherCollection =
    collectionsInCommunity &&
    collectionsInCommunity.reduce<string[] | []>((acc, curr) => {
      const questsUuid = curr.quests.map((quest) => {
        return quest.uuid
      }, [])
      return [...acc, ...questsUuid]
    }, [])

  if (questSelected) {
    const allSelectedQuest = questSelected.reduce<string[] | []>((acc, curr) => {
      return [...acc, curr.uuid as string]
    }, [])
    if (questsInOtherCollection) {
      questsInOtherCollection = [...questsInOtherCollection, ...(allSelectedQuest as string[])]
    } else {
      questsInOtherCollection = allSelectedQuest
    }
  }

  function areArraysEqual(array1: QuestSelected[], array2: QuestSelected[]) {
    return JSON.stringify(array1) === JSON.stringify(array2)
  }

  const filteredQuests =
    quests &&
    quests.filter(
      (quest) =>
        questsInOtherCollection && !(questsInOtherCollection as string[]).includes(quest.uuid),
    )

  const CollectionDisplayUrlInUse = collection
    ? collectionsInCommunity
        ?.filter((tempcollection) => tempcollection.uuid !== collection?.uuid)
        ?.map((ele) => ele.display_path)
    : collectionsInCommunity?.map((ele) => ele.display_path)

  const isError = CollectionDisplayUrlInUse?.includes(collectionDisplayUrl)

  useEffect(() => {
    if (collection) {
      let tempQuestSelected: QuestSelected[] | [] = []
      collection.quests.map((quest, index) => {
        if (index === 0) {
          tempQuestSelected = [{ id: index, value: quest.name, uuid: quest.uuid }]
        } else {
          ;(tempQuestSelected as QuestSelected[]).push({
            id: index,
            value: quest.name,
            uuid: quest.uuid,
          })
        }
      })
      setQuestSelected(tempQuestSelected)
      if (collection.template === "FULL_PAGE") {
        setTemplate(1)
      } else {
        setTemplate(2)
      }
    }
  }, [collection])

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files?.length) return
    const url = URL.createObjectURL(files[0])
    setCollectionImage(url)
    setCollectionImageFile(files[0])
  }

  const onTemplateChange = (e: RadioChangeEvent) => {
    setTemplate(e.target.value)
  }

  const handleOptionFilterChange = (value: string, id: number, uuid: string | undefined) => {
    const updatedQuests = questSelected.map((quest) =>
      quest.id === id ? { ...quest, value, uuid } : quest,
    )
    setQuestSelected(updatedQuests)
  }

  const handleAddQuest = () => {
    setQuestSelected([
      { id: 0, value: undefined, uuid: undefined },
      ...questSelected.map((quest) => {
        quest.id = quest.id + 1;
        return quest
      }),
    ])
  }

  const swapValues = (index: number) => {
    if (index < questSelected.length - 1) {
      const newQuests = [...questSelected]
      const temp = newQuests[index].value
      const tempUuid = newQuests[index].uuid
      newQuests[index].value = newQuests[index + 1].value
      newQuests[index].uuid = newQuests[index + 1].uuid
      newQuests[index + 1].value = temp
      newQuests[index + 1].uuid = tempUuid
      setQuestSelected(newQuests)
    }
  }

  const removeQuest = (id: number) => {
    if (questSelected.length > 1) {
      setDeletedQuest([...deletedQuest, ...questSelected.filter((quest) => quest.id === id)])
      setQuestSelected(questSelected.filter((quest) => quest.id !== id))
    }
  }

  const handleActionButtonClick = async () => {
    let reqResult = false
    if (selectedNavItem === 0) {
      setSelectedNavItem(1)
    } else {
      setIsSubmitting(true)
      let imageName = ""
      if (collectionImage !== collection?.banner_image_url && collectionImageFile && jwt) {
        const fileExtension = getFileExtension(collectionImageFile.name)
        if (fileExtension) {
          imageName = `${stringWithoutSpecialChars(
            collectionName || "other",
          )}/Banner${dayjs().unix()}.${fileExtension}`
          await uploadFileToS3(imageName, S3ImageBucketName, jwt, collectionImageFile)
          imageName = `${CloudFrontImageUrl}/${imageName}`
          setCollectionImage(`${CloudFrontImageUrl}/${imageName}`)
        }
      }
      const transformedQuests: { order: number; quest_uuid: string }[] = questSelected.map(
        (currentObject, index) => {
          let tempQuestSelected
          if (currentObject.uuid) {
            tempQuestSelected = {
              order: index + 1, // This ensures the order starts at 1 and increments
              quest_uuid: currentObject?.uuid,
            }
          }
          return tempQuestSelected as { order: number; quest_uuid: string }
        },
      )
      if (collection?.uuid) {
        const transformedDeletedQuests: string[] = deletedQuest
          ? deletedQuest.map((currentObject, index) => {
              return currentObject.uuid as string
            })
          : []
        const tempQuestSelected: QuestSelected[] | [] = []
        collection.quests.map((quest, index) => {
          ;(tempQuestSelected as QuestSelected[]).push({
            id: index,
            value: quest.name,
            uuid: quest.uuid,
          })
        })
        const UpdatedCollection = {
          collection: {
            name: collectionName,
            description: collectionDescription,
            template: template === 1 ? "FULL_PAGE" : "COMPACT",
            banner_image_url:
              template === 1 ? (imageName === "" ? collectionImage : imageName) : "",
            uuid: collection.uuid,
          },
          collection_quests: areArraysEqual(questSelected, tempQuestSelected)
            ? []
            : transformedQuests,
          remove_quest_uuid_arr: transformedDeletedQuests?.length ? transformedDeletedQuests : [],
        }
        const result = await dispatch(
          updateCollection(UpdatedCollection, selectedCommunity?.uuid as string),
        )
        reqResult = result
      } else {
        const newCollection = {
          name: collectionName,
          description: collectionDescription,
          template: template === 1 ? "FULL_PAGE" : "COMPACT",
          banner_image_url: imageName,
          collection_quests: transformedQuests,
          display_path: collectionDisplayUrl,
          community_uuid: community?.uuid as string,
        }
        const result = await dispatch(createCollection(newCollection))
        reqResult = result
      }
      if (reqResult === true) {
        message.success("Sucessfully Done")
        closeModal()
      } else {
        message.error("Something went wrong, try again")
      }
      setIsSubmitting(false)
    }
  }

  const handleClickClose = () => {
    closeModal()
  }

  useEffect(() => {
    if (template === 1 && scrollContainerRef.current) {
      scrollContainerRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [template])

  return (
    <div className="collection-modal">
      <div className="collection-modal-container">
        <div className="collection-modal-header">
          <div className="collection-modal-main-heading">
            <div>{collection?.uuid ? "Update Collection" : "Create New Collection"}</div>
            <MdClose onClick={() => handleClickClose()} />
          </div>
          <div className="create-collection-nav">
            <div
              className={`create-collection-nav-item ${
                selectedNavItem === 0 ? "create-collection-nav-item-selected" : ""
              }`}
              onClick={() => setSelectedNavItem(0)}
            >
              Overview
            </div>
            <div
              className={`create-collection-nav-item ${
                selectedNavItem === 1 ? "create-collection-nav-item-selected" : ""
              }`}
              onClick={() => setSelectedNavItem(1)}
            >
              Quests
            </div>
          </div>
        </div>
        <div className="create-collection-main-body">
          {selectedNavItem === 0 ? (
            <div>
              <div className="create-collection-info-input-wrapper">
                <TextInput
                  value={collectionName}
                  label="Collection name"
                  placeholder=""
                  onChange={(e) => setCollectionName(e.target.value)}
                  // shouldChangeBackground={true}
                  onBlur={() => {
                    if (collectionDisplayUrlChanged) return
                    setCollectionDisplayUrl(collectionName)
                    // TODO: check if valid or not
                  }}
                />
              </div>
              <div className="create-collection-info-input-wrapper">
                <TextArea
                  value={collectionDescription}
                  label="Collection description"
                  placeholder=""
                  rows={7}
                  onChange={(e) => setCollectionDescription(e.target.value)}
                  // shouldChangeBackground={true}
                />
              </div>
              <div className="create-collection-info-input-wrapper">
                <div className="display-path-input-container">
                  <label className="display-path-input-label">
                    Display URL
                    <span>
                      <Tooltip title="collection display url">
                        <InfoIcon />
                      </Tooltip>
                    </span>
                  </label>
                  <div
                    className={`display-path-fixed-text-wrapper ${
                      collectionDisplayUrl.length ? "display-path-input-wrapper-filled" : ""
                    } ${isError ? "display-path-input-wrapper-error" : ""}`}
                  >
                    {`${selectedCommunity?.name
                      .replace(/ /g, "")
                      .toLocaleLowerCase()}.rep3.gg/collection/`}
                    <div className="display-path-input-wrapper">
                      <input
                        type="text"
                        className={`display-path-input ${
                          collectionDisplayUrl.length ? "display-path-input-filled" : ""
                        } ${isError ? "display-path-input-error" : ""}`}
                        placeholder=""
                        value={collectionDisplayUrl}
                        onChange={(e) => {
                          setCollectionDisplayUrl(e.target.value)
                          setCollectionDisplayUrlChanged(true)
                        }}
                        // onBlur={onBlur}
                        // TODO: check if valid or not on blur
                      />
                      <div className="display-path-input-right-wrapper">
                        {isError ? (
                          <div className="display-path-input-error-wrapper">
                            <div className="error-tooltip">this display url is already used</div>
                            <img src={inputErrorIcon} alt="" />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="create-collection-info-input-wrapper">
                <div className="template-label">Template</div>
                <div className={`collection-type-container`}>
                  <Radio.Group onChange={onTemplateChange} value={template}>
                    <div className="radio-wrapper">
                      <Radio value={1}>
                        <Text strong>Full page view</Text>
                        <Text>
                          Collection will have its dedicated page with banner and quests,{" "}
                          <a
                            href="https://app.rep3.gg/Collection%20%7C%20Full%20Page"
                            target="_blank"
                            rel="noreferrer"
                          >
                            example link
                          </a>
                          .
                        </Text>
                      </Radio>
                    </div>
                    <div className="radio-wrapper">
                      <Radio value={2}>
                        <Text strong>Compact View</Text>
                        <Text>
                          Collection will group the quests on community space without a page,{" "}
                          <Link
                            href="https://app.rep3.gg/Collection%20%7C%20Compact"
                            target="_blank"
                          >
                            example link
                          </Link>
                          .
                        </Text>
                      </Radio>
                    </div>
                  </Radio.Group>
                </div>
              </div>
              {template == 1 && (
                <div className="create-collection-info-input-wrapper" ref={scrollContainerRef}>
                  <div className="quest-post-banner-label-row">
                    <div className="quest-post-banner-label">Collection Banner</div>
                    <div>Optional</div>
                  </div>
                  <label htmlFor={`upload-file-input-post-banner`}>
                    {collectionImage && collectionImage?.length ? (
                      <img
                        src={collectionImage}
                        alt=""
                        className="quest-banner-uploaded-image-preview"
                      />
                    ) : (
                      <div className="post-banner-upload-wrapper">
                        <div className="upload-icon-wrapper">
                          <UploadIcon />
                        </div>
                        <div className="upload-text-wrapper">
                          <div className="upload-text-heading">Upload Asset</div>
                          <div className="upload-text-info">Recommended resolution 800*450</div>
                        </div>
                      </div>
                    )}
                  </label>
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    id={`upload-file-input-post-banner`}
                    className="upload-file-input-hidden"
                    style={{ display: "none" }}
                    onChange={(e) => onFileChange(e)}
                  />
                </div>
              )}
            </div>
          ) : null}
          {selectedNavItem === 1 ? (
            <div className="quest-list-main-body-container" ref={scrollContainerRef}>
              <div className="add-quest-heading">
                {questSelected?.[0].value ? "Select Quests" : "Add Quests to collection"}
              </div>
              <button className="add-more-button" onClick={() => handleAddQuest()}>
                <IoMdAdd />
                Add Another Quest
              </button>
              <div className="select-quest-row">
                {questSelected.map((selectQuest, index) => (
                  <div
                    className={`select-and-cross ${
                      selectQuest.value === undefined ? "select-and-cross-empty" : ""
                    }`}
                    key={index}
                  >
                    <Select
                      popupClassName="overview-filter-switch-dropdown"
                      suffixIcon={
                        <div>
                          <BsCaretDownFill />
                        </div>
                      }
                      onChange={(value, option: DefaultOptionType | DefaultOptionType[]) => {
                        handleOptionFilterChange(
                          value,
                          selectQuest.id,
                          (option as DefaultOptionType)?.key,
                        )
                      }}
                      value={selectQuest.value}
                      placeholder="Select Quest"
                    >
                      {filteredQuests?.map((quest, id) => {
                        return (
                          <Option label={`quest_name-${id}`} value={quest.name} key={quest.uuid}>
                            <div className="overview-filter-switch-dropdown-item">
                              <div className="overview-filter-switch-dropdown-item-left">
                                {quest.name}
                              </div>
                            </div>
                          </Option>
                        )
                      })}
                    </Select>
                    {index < questSelected.length - 1 &&
                      questSelected[index]?.value &&
                      questSelected[index + 1]?.value && (
                        <div
                          className="swap-btn-container"
                          onClick={() => swapValues(selectQuest.id)}
                        >
                          <RiArrowUpDownLine />
                        </div>
                      )}
                    <IoMdClose className="close-svg" onClick={() => removeQuest(selectQuest.id)} />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
        <div className="create-collection-footer">
          <button
            className="action-button"
            disabled={
              isError ||
              !collectionDisplayUrl ||
              !collectionName ||
              !collectionDescription ||
              template === 0 ||
              (selectedNavItem === 1 &&
                questSelected.filter((quest) => quest.value === undefined).length !== 0)
            }
            onClick={() => handleActionButtonClick()}
          >
            {selectedNavItem === 0
              ? "Select Quests"
              : collection?.uuid
              ? "Update Collection"
              : "Create Collection"}
            {isSubmitting ? (
              <Lottie options={lottieOptions} width={20} height={20} />
            ) : (
              <ArrowForwardIcon />
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreateCollectionModal
