import React from "react"
import "./style.scss"
import { ReactComponent as NoCampaignIcon } from "../../../assets/icons/noCampaignIcon.svg"
import { ReactComponent as ArrowForwardIcon } from "../../../assets/icons/arrowForwardIcon.svg"
import { useNavigate } from "react-router-dom"
import { setIsInternalNavigation } from "../../../store/actions/questCreation"
import { useAppDispatch } from "../../../hooks/reduxHooks"

export default function NoCampaignBanner() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  return (
    <div className="no-campaign-banner-container">
      <div className="no-campaign-banner-left">
        <NoCampaignIcon />
        <div className="no-campaign-banner-content">
          <span>Get Started with Rep3</span>
          <ArrowForwardIcon />
          Setup a Quest Now
        </div>
      </div>
      <div className="no-campaign-banner-right">
        <button
          onClick={() => {
            dispatch(setIsInternalNavigation(true))

            navigate("setup/quest")
          }}
        >
          Create New Quest <ArrowForwardIcon />
        </button>
      </div>
    </div>
  )
}
