import React from "react"
import DashboardView from "../../views/DashboardView"
import HasCommunitiesWrapper from "../../views/HasCommunitiesWrapper"
import SettingsPage from "../../components/SettingsPage"

export default function Settings() {
  return (
    <DashboardView>
      <HasCommunitiesWrapper>
        <SettingsPage />
      </HasCommunitiesWrapper>
    </DashboardView>
  )
}
