import React from "react"
import inputErrorIcon from "../../../assets/icons/inputErrorIcon.svg"
import "./style.scss"

export default function TextArea({
  label,
  optional,
  value,
  isError,
  placeholder,
  rows,
  onChange,
  onBlur,
  shouldChangeBackground
}: {
  label: string
  optional?: boolean
  value: string
  isError?: boolean
  placeholder?: string
  rows?: number
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: () => void
  shouldChangeBackground?:boolean
}) {
  return (
    <div className="text-area-container">
      <label className="custom-text-area-label">
        {label}
        {optional ? <span>Optional</span> : null}
      </label>
      <div className="custom-text-area-wrapper">
        <textarea
          className={`custom-text-area ${value?.length && !shouldChangeBackground ? "custom-text-area-filled" : shouldChangeBackground && value?.length === 0 ? "empty-background-color": ""} ${
            isError ? "custom-text-area-error" : ""
          }`}
          placeholder={placeholder || ""}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          rows={rows || 3}
        />
        <div className="custom-text-area-right-wrapper">
          {isError ? (
            <div className="custom-text-area-error-wrapper">
              <div className="error-tooltip">this doesn&apos;t seem right</div>
              <img src={inputErrorIcon} alt="" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
