/* eslint-disable  @typescript-eslint/no-explicit-any */

import "./style.scss"
import { faker } from "@faker-js/faker"
import { Scale } from "chart.js"
import pluralize from "pluralize"
import { useEffect } from "react"
import { Bar } from "react-chartjs-2"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import { fetchCommunityOnchainGraphData } from "../../store/actions/overviewAction"
import { ICommunityOnChainGraphGroupedData } from "../../types/graph"
import dayjs from "dayjs"
import { getGraphDataMapped } from "../../utils/graphUtils"

export default function OverviewOnchainActionsChart() {
  const dispatch = useAppDispatch()
  const communityOnChainGraphData = useAppSelector(
    (state) => state.overview.communityOnChainGraphData,
  )

  if (!communityOnChainGraphData || !communityOnChainGraphData.length) {
    return <div>Loading data</div>
  }

  const groupedGraphData: ICommunityOnChainGraphGroupedData[] =
    getGraphDataMapped(communityOnChainGraphData)

  const totalOnChainActions = communityOnChainGraphData?.reduce((acc, curr) => {
    acc = acc + curr.TIER_DOWNGRADE + curr.TIER_CLAIMED + curr.TIER_UPGRADE
    return acc
  }, 0)

  const getNameFromDataSetIndex = (index: number) => {
    return index === 0 ? "Claim" : index === 1 ? "Upgrade" : "Downgrade"
  }
  const getColorFromDataSetIndex = (index: number) => {
    return index === 0 ? "#1D77FF" : index === 1 ? "#459A65" : "#FFB580"
  }

  const stackedBarLabels = groupedGraphData?.map((singleDayGraphData) => singleDayGraphData.DATE)

  const maxValueInGraph = groupedGraphData?.reduce((acc, curr) => {
    const currentLabelActions = curr.TIER_DOWNGRADE + curr.TIER_CLAIMED + curr.TIER_UPGRADE
    return acc >= currentLabelActions ? acc : currentLabelActions
  }, 0)
  // console.log(
  //   "grouped graph data",
  //   groupedGraphData,
  //   maxValueInGraph,
  //   communityOnChainGraphData,
  //   stackedBarLabels,
  // )

  const stackedBarOptions = {
    responsive: true,
    labels: null,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      tooltip: {
        displayColors: false,
        backgroundColor: "#fff",
        titleColor: "rgba(0,0,0,0.5)",
        callbacks: {
          title: function (context: any) {
            // console.log("title is", context)
            const dates = context[0].label?.split(",")
            if (dates) {
              if (dates?.length > 1)
                return `${dayjs(dates[0]).format("MMM D")}-${dayjs(dates[dates.length - 1]).format(
                  "MMM D",
                )}`
              return dayjs(dates[0]).format("MMM D")
            }
            return "tooltip"
          },
          label: function (context: any) {
            // console.log("label is", context)
            return `${context.formattedValue} ${pluralize(
              getNameFromDataSetIndex(context.datasetIndex),
              context.formattedValue,
            )}`
          },
          labelTextColor: function (context: any) {
            return getColorFromDataSetIndex(context.datasetIndex)
          },
        },
      },
    },
    scales: {
      x: {
        // display: false,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        stacked: true,
        afterTickToLabelConversion: (ctx: Scale) => {
          const numberOfTicks = Math.ceil(groupedGraphData.length / 4) // to have minimum one label on 4 bars
          const ticksToShow = groupedGraphData.filter((ele, index) => {
            return index % 4 === 0
          })

          ctx.ticks = []
          groupedGraphData.forEach((tick, index) => {
            // ctx.ticks.push({
            //   value: ctx?.ticks?.find((ele) => ele.label === tick.DATE)?.value || 0,
            //   label: dayjs(tick.DATE[0]).format("MMM DD"),
            // })
            if (index % 4 === 0)
              ctx.ticks.push({
                value: index,
                label: dayjs(tick.DATE[0]).format(
                  communityOnChainGraphData?.length > 90 ? "MMM" : "MMM D",
                ),
              })
          })

          // ctx.ticks.push({ value: 4, label: "JUNE" })
          // ctx.ticks.push({ value: 8, label: "JULY" })
          // ctx.ticks.push({ value: 12, label: "AUG" })
        },
        // ticks: {
        // display: false,
        // },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          display: false,
        },
        max: maxValueInGraph * 2,
      },
    },
    categoryPercentage: 1,
    barPercentage: 0.95,
    borderSkipped: false,
    showLine: false,
    borderRadius: {
      topLeft: 4,
      topRight: 4,
      bottomLeft: 4,
      bottomRight: 4,
    },
    borderWidth: 1,
  }

  const stackedBarData = {
    labels: stackedBarLabels,
    datasets: [
      {
        fill: true,
        data: groupedGraphData?.map((singleDayGraphData) => singleDayGraphData.TIER_CLAIMED),
        borderColor: "#f5f5f5",
        backgroundColor: "#1D77FF",
      },
      {
        fill: true,
        data: groupedGraphData?.map((singleDayGraphData) => singleDayGraphData.TIER_UPGRADE),
        borderColor: "#f5f5f5",
        backgroundColor: "#459A65",
      },
      {
        fill: true,
        data: groupedGraphData?.map((singleDayGraphData) => singleDayGraphData.TIER_DOWNGRADE),
        borderColor: "#f5f5f5",
        backgroundColor: "#FFB580",
      },
    ],
  }

  console.count("rendered")

  return (
    <div className="onchain-actions-chart-wrapper">
      <div className="stacked-chart-info">
        <div className="date-range">
          {dayjs(communityOnChainGraphData[0].DATE).format("D MMM")} -{" "}
          {dayjs(communityOnChainGraphData[communityOnChainGraphData.length - 1].DATE).format(
            "D MMM",
          )}
        </div>
        <div className="count-and-legend-row">
          <div className="onchain-count">{totalOnChainActions} Interactions</div>
          <div className="legend-wrapper">
            <div className="legend-item">
              <div className="legend-color mint-color"></div>
              Claims
            </div>
            <div className="legend-item">
              <div className="legend-color upgrade-color"></div>
              Upgrades
            </div>
            <div className="legend-item">
              <div className="legend-color downgrade-color"></div>
              Downgrades
            </div>
          </div>
        </div>
      </div>
      <div className="stacked-chart-wrapper">
        <Bar options={stackedBarOptions} data={stackedBarData} />
      </div>
    </div>
  )
}
