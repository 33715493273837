import React from "react"
import CommunitySpacePage from "../../components/CommunitySpacePage"
import DashboardView from "../../views/DashboardView"
import HasCommunitiesWrapper from "../../views/HasCommunitiesWrapper"

export default function CommunitySpace() {
  return (
    <DashboardView>
      <HasCommunitiesWrapper>
        <CommunitySpacePage />
      </HasCommunitiesWrapper>
    </DashboardView>
  )
}
