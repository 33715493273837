import React, { ReactNode, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
// import Header from "../../components/Header"
// import EmptyPageScreen from "../../components/EmptyPageScreen"
import "./style.scss"
import LoadingScreen from "../../components/LoadingScreen"
// import { TelegramWhitelistLink } from "../../constant/constantValues"
// import { ReactComponent as TelegramIcon } from "../../assets/icons/telegramIcon.svg"
// import DashboardView from "../DashboardView"
import { useNavigate } from "react-router-dom"
import { fetchUserInfo } from "../../store/actions/userAction"
import { useAccount } from "wagmi"
// import Footer from "../../components/Footer"

export default function WhiteListedUserWrapper({ children }: { children: ReactNode }) {
  const userInfo = useAppSelector((state) => state.user.userInfo)
  const fetchingUserInfo = useAppSelector((state) => state.user.fetchingUserInfo)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { address } = useAccount()

  console.log("fetching user inof is", fetchingUserInfo)

  // useEffect(() => {
  //   if (!fetchingUserInfo) {
  //     navigate("/")
  //   }
  // }, [fetchingUserInfo])

  // useEffect(() => {
  //   if (address) {
  //     dispatch(fetchUserInfo(address))
  //   }
  // }, [address])

  if (fetchingUserInfo) {
    return (
      <div className="whitelisted-user-wrapper">
        <LoadingScreen />
      </div>
    )
  }

  // if (!userInfo?.is_whitelisted) {
  //   return (
  //     //   <DashboardView>
  //     <div>
  //       You are not whitelisted to access this screen
  //       {/* <button onClick={() => window.open(TelegramWhitelistLink, "_blank")}>
  //         Reach out to get Whitelisted <TelegramIcon />
  //       </button> */}
  //     </div>
  //     //   </DashboardView>
  //   )
  // }

  return <div className="whitelisted-user-wrapper">{children}</div>
}
