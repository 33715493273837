import React, { useState } from "react"
import "./style.scss"
import { ReactComponent as CloseIcon } from "../../../assets/icons/closeIcon.svg"
import { ReactComponent as ArrowForwardIcon } from "../../../assets/icons/arrowForwardIcon.svg"
import OverviewStep from "./OverviewStep"
import QuestionStep from "./QuestionStep"
import { TNonStrategyQuizAdapterWithName, TQuizQA } from "../../../types/adapter"
import ReviewStep from "./ReviewStep"
import QuestTierQuizUI from "./QuestTierQuizUI"

export default function QuizAdapter({
  adapterInfo,
  updateAdapterInputValue,
}: {
  adapterInfo: TNonStrategyQuizAdapterWithName
  updateAdapterInputValue: (adapterKeyIndex: number, value: number | string | TQuizQA) => void
}) {
  const [selectedStep, setSelectedStep] = useState<1 | 2 | 3>(1)
  // const [linkTitle, setLinkTitle] = useState("")
  const [showQuizModal, setShowQuizModal] = useState(false)

  const questions = (adapterInfo?.options.keys?.find((keyDetail) => keyDetail?.key === "q/a")
    ?.value as TQuizQA) || {
    1: {
      options: { 1: "" },
      question: "",
      answer_id: 0,
    },
  }

  const passCriteria = adapterInfo?.options.keys?.find(
    (keyDetail) => keyDetail?.key === "min_score",
  )?.value as number
  const quizTitle = adapterInfo?.options.keys?.find((keyDetail) => keyDetail?.key === "title")
    ?.value as string
  const readingLinkTitle = adapterInfo?.options.keys?.find(
    (keyDetail) => keyDetail?.key === "reading_title",
  )?.value as string
  const readingLink = adapterInfo?.options.keys?.find(
    (keyDetail) => keyDetail?.key === "reading_link",
  )?.value as string
  const retryAfter = adapterInfo?.options.keys?.find(
    (keyDetail) => keyDetail?.key === "submission_interval",
  )?.value as number

  const questionsKeyIndex = adapterInfo?.options.keys?.findIndex(
    (keyDetail) => keyDetail?.key === "q/a",
  )
  const passCriteriaKeyIndex = adapterInfo?.options.keys?.findIndex(
    (keyDetail) => keyDetail?.key === "min_score",
  )
  const quizTitleKeyIndex = adapterInfo?.options.keys?.findIndex(
    (keyDetail) => keyDetail?.key === "title",
  )
  const readingTitlesKeyIndex = adapterInfo?.options.keys?.findIndex(
    (keyDetail) => keyDetail?.key === "reading_title",
  )
  const readingLinkKeyIndex = adapterInfo?.options.keys?.findIndex(
    (keyDetail) => keyDetail?.key === "reading_link",
  )
  const retryAfterKeyIndex = adapterInfo?.options.keys?.findIndex(
    (keyDetail) => keyDetail?.key === "submission_interval",
  )

  const setQuestionOption = (questionIndex: number, optionIndex: number, optionValue: string) => {
    const questionsCopy = structuredClone(questions)
    const optionsCopy = { ...questionsCopy[questionIndex].options }
    optionsCopy[optionIndex] = optionValue
    questionsCopy[questionIndex].options = optionsCopy
    updateAdapterInputValue(questionsKeyIndex, questionsCopy)
  }

  const setQuestionTitle = (questionIndex: number, questionValue: string) => {
    const questionsCopy = structuredClone(questions)
    questionsCopy[questionIndex].question = questionValue
    updateAdapterInputValue(questionsKeyIndex, questionsCopy)
  }

  const setQuestionAnswer = (questionIndex: number, answerValue: number) => {
    const questionsCopy = structuredClone(questions)
    questionsCopy[questionIndex].answer_id = answerValue
    updateAdapterInputValue(questionsKeyIndex, questionsCopy)
  }

  // add question
  const addQuestion = () => {
    const questionsCopy = structuredClone(questions)
    const totalQuestions = Object.keys(questionsCopy).length
    questionsCopy[totalQuestions + 1] = {
      question: "",
      answer_id: 0,
      options: { 1: "" },
    }
    updateAdapterInputValue(questionsKeyIndex, questionsCopy)
  }

  // delete question

  // add option
  const addOption = (questionIndex: number) => {
    const questionsCopy = structuredClone(questions)
    const optionsCopy = { ...questionsCopy[questionIndex].options }
    console.log("adding option", questionsCopy, optionsCopy)
    optionsCopy[Object.keys(optionsCopy).length + 1] = ""
    questionsCopy[questionIndex].options = optionsCopy
    updateAdapterInputValue(questionsKeyIndex, questionsCopy)
  }

  // delete option
  const deleteOption = (questionIndex: number, optionKey: number) => {
    // const questionsCopy = questions.map((ele) => ({ ...ele, options: { ...ele.options } }))
    const questionsCopy = structuredClone(questions)
    const optionsCopy = { ...questionsCopy[questionIndex].options }
    delete optionsCopy[optionKey]
    const options = Object.keys(optionsCopy)
      .map((ele) => optionsCopy[Number(ele)])
      .reduce((acc: { [x: number]: string }, curr, currIndex) => {
        acc[currIndex + 1] = curr
        return acc
      }, {})
    questionsCopy[questionIndex].options = options
    if (questionsCopy[questionIndex].answer_id === optionKey) {
      questionsCopy[questionIndex].answer_id = 0
    } else if (optionKey < questionsCopy[questionIndex].answer_id) {
      questionsCopy[questionIndex].answer_id -= 1
    }
    updateAdapterInputValue(questionsKeyIndex, questionsCopy)
  }

  const goToNextStep = () => {
    if (selectedStep === 3) {
      // TODO: submit the quiz
      // check if answer id is part of the options before finalizing
      setShowQuizModal(false)
      setSelectedStep(1)
    } else {
      setSelectedStep(selectedStep === 1 ? 2 : 3)
    }
  }

  const closeModal = () => {
    setShowQuizModal(false)
    setSelectedStep(1)
  }
  const overViewStepDisabled =
    !quizTitle?.length || !readingLink?.length || !readingLinkTitle?.length
  const reviewStepDisabled = !passCriteria || !retryAfter

  let validQuestions = 0
  Object.keys(questions).forEach((questionKey) => {
    const selectedQuestion = questions[Number(questionKey)]
    if (selectedQuestion?.question.length && Object.keys(selectedQuestion?.options).length) {
      let areOptionsValid = true
      Object.keys(selectedQuestion?.options).forEach((optionKey) => {
        if (!selectedQuestion?.options[Number(optionKey)].length) {
          areOptionsValid = false
        }
      })
      const isAnswerValid = Object.keys(selectedQuestion?.options)
        .map((ele) => Number(ele))
        .includes(selectedQuestion.answer_id)
      if (areOptionsValid && isAnswerValid) {
        validQuestions += 1
      }
    }
  })
  const questionStepDisabled = validQuestions !== Object.keys(questions).length
  const quizModalDisabled = overViewStepDisabled || questionStepDisabled || reviewStepDisabled

  if (showQuizModal) {
    return (
      <div className="quiz-adapter-container">
        <div className="quiz-adapter-backdrop" onClick={closeModal}>
          <div className="quiz-adapter-main-content-wrapper">
            <div className="quiz-adapter-main-content" onClick={(e) => e.stopPropagation()}>
              <div className="modal-close-btn" onClick={closeModal}>
                <CloseIcon />
              </div>
              <div className="setup-quiz-heading">Setup Quiz</div>
              <div className="setup-quiz-navbar-wrapper">
                <div
                  className={`setup-quiz-navbar-item ${selectedStep === 1 ? "selected-item" : ""}`}
                  onClick={() => setSelectedStep(1)}
                >
                  Overview
                </div>
                <div
                  className={`setup-quiz-navbar-item ${selectedStep === 2 ? "selected-item" : ""}`}
                  onClick={() => setSelectedStep(2)}
                >
                  Questions
                </div>
                <div
                  className={`setup-quiz-navbar-item ${selectedStep === 3 ? "selected-item" : ""}`}
                  onClick={() => setSelectedStep(3)}
                >
                  Review
                </div>
              </div>

              <div className="setup-quiz-content-wrapper">
                {selectedStep === 1 ? (
                  <OverviewStep
                    quizTitle={quizTitle}
                    setQuizTitle={(value) => updateAdapterInputValue(quizTitleKeyIndex, value)}
                    url={readingLink}
                    setUrl={(value) => updateAdapterInputValue(readingLinkKeyIndex, value)}
                    linkTitle={readingLinkTitle}
                    setLinkTitle={(value) => updateAdapterInputValue(readingTitlesKeyIndex, value)}
                  />
                ) : selectedStep === 2 ? (
                  <QuestionStep
                    questions={questions}
                    setQuestionOption={setQuestionOption}
                    setQuestionTitle={setQuestionTitle}
                    setQuestionAnswer={setQuestionAnswer}
                    addQuestion={addQuestion}
                    addOption={addOption}
                    deleteOption={deleteOption}
                  />
                ) : (
                  <ReviewStep
                    passCriteria={passCriteria}
                    setPassCriteria={(value) =>
                      updateAdapterInputValue(passCriteriaKeyIndex, value)
                    }
                    retryAfter={retryAfter}
                    setRetryAfter={(value) => updateAdapterInputValue(retryAfterKeyIndex, value)}
                  />
                )}
              </div>
              <button
                className="setup-quiz-btn"
                onClick={goToNextStep}
                disabled={
                  selectedStep === 1
                    ? overViewStepDisabled
                    : selectedStep === 2
                    ? questionStepDisabled
                    : quizModalDisabled
                }
              >
                {selectedStep === 1
                  ? "Setup Questions"
                  : selectedStep === 2
                  ? "Review Quiz"
                  : "Finalize Quiz"}
                <ArrowForwardIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <QuestTierQuizUI
      adapterInfo={adapterInfo}
      setShowQuizModal={setShowQuizModal}
      questions={questions}
    />
  )
}
