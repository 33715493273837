import { AppDispatch, RootState } from ".."
import { routes } from "../../constant/routes"
import { Address } from "../../types/common"
import apiClient from "../../utils/apiClient"
import { userActions } from "../reducers/userSlice"

export const fetchUserInfo = (eoa: Address) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      if (!jwt) return
      dispatch(userActions.setFetchingUserInfo(true))
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}${routes.user.baseUrl}/${eoa}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )

      if (response.status === 200) {
        dispatch(userActions.setUserInfo({ userInfo: response.data }))
      }
      dispatch(userActions.setFetchingUserInfo(false))
    } catch (err) {
      console.error("err in fetching user info", err)
      dispatch(userActions.setFetchingUserInfo(false))
    }
  }
}
