import "./style.scss"
import { AdapterTypes, TQuizQA } from "../../../../types/adapter"
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plusIcon.svg"
import pluralize from "pluralize"

export default function QuestTierQuizUI({
  adapterInfo,
  setShowQuizModal,
  questions,
}: {
  adapterInfo: AdapterTypes
  setShowQuizModal: (x: boolean) => void
  questions: TQuizQA
}) {
  let validQuestions = 0
  Object.keys(questions).forEach((questionKey) => {
    const selectedQuestion = questions[Number(questionKey)]
    if (selectedQuestion?.question.length && Object.keys(selectedQuestion?.options).length) {
      let areOptionsValid = true
      Object.keys(selectedQuestion?.options).forEach((optionKey) => {
        if (!selectedQuestion?.options[Number(optionKey)].length) {
          areOptionsValid = false
        }
      })
      const isAnswerValid = Object.keys(selectedQuestion?.options)
        .map((ele) => Number(ele))
        .includes(selectedQuestion.answer_id)
      if (areOptionsValid && isAnswerValid) {
        validQuestions += 1
      }
    }
  })

  return (
    <div className="quest-tier-quiz-ui-container">
      <div className="quest-quiz-info-container">
        <div className="quiz-info-left">
          {validQuestions} {pluralize("question", validQuestions)}
        </div>
        <div className="quiz-info-right" onClick={() => setShowQuizModal(true)}>
          Setup Quiz <PlusIcon />{" "}
        </div>
      </div>
    </div>
  )
}
