import DashboardView from "../../views/DashboardView"
import "./style.scss"
import SetupCommunityPage from "../../components/SetupCommunityPage"

export default function SetupCommunity() {
  return (
    <DashboardView>
      <SetupCommunityPage />
    </DashboardView>
  )
}
