import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { IReferralInfo } from "../../types/community"
import { Address } from "../../types/common"
interface SettingState {
  referralPoints: IReferralInfo
  allAdmins:Address[] | []
}

const initialState: SettingState = {
  referralPoints: {
    isActive: false,
    points: 100,
  },
  allAdmins : []
}

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setReferralPoints: (state, action: PayloadAction<IReferralInfo>) => {
      state.referralPoints = action.payload
    },
    setAllAdmins: (state,action: PayloadAction<Address[]>) => {
      state.allAdmins = action.payload
    },
    removeAdmin : (state,action:PayloadAction<Address>) => {
      const tempAllAdmin = state.allAdmins
      const filteredAdmins = tempAllAdmin.filter(admin => admin !== action.payload)
      state.allAdmins = filteredAdmins
    },
    addAdmin :(state,action:PayloadAction<Address>) => {
      const tempAllAdmins = [...state.allAdmins,action.payload]
      state.allAdmins = tempAllAdmins
    }
  },
})

// Action creators are generated for each case reducer function
export const settingActions = settingSlice.actions

export default settingSlice
