import { useEffect, useState } from "react"
import arrowRightIcon from "../../assets/icons/arrowRightIcon.svg"
import { ReactComponent as ArrowForwardIcon } from "../../assets/icons/arrowForwardIcon.svg"
import OverViewStep from "./OverViewStep"
import "./style.scss"
// import ProjectDetailStep from "./ProjectDetailStep"
import ReviewStep from "./ReviewStep"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import { createCommunity, fetchAllCommunities } from "../../store/actions/createCommunity"
import { isValidEmail } from "../../utils/regex"
import { useNavigate } from "react-router-dom"
import { message } from "antd"
import { useAccount } from "wagmi"
import { TApiStatus } from "../../types/common"
import animationLoadingData from "../../assets/lottie/buttonLoader.json"
import Lottie from "react-lottie"
import { fetchUserInfo } from "../../store/actions/userAction"

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationLoadingData,
  renderer: "svg",
}

export default function SetupCommunityPage() {
  const [setupCommunityStep, setSetupCommunityStep] = useState<number>(1)
  const [communityName, setCommunityName] = useState("")
  const [website, setWebsite] = useState("")
  const [name, setName] = useState("")
  const [telegramAccount, setTelegramAccount] = useState("")
  const [projectTwitterAccount, setProjectTwitterAccount] = useState("")
  const [email, setEmail] = useState("")
  const [role, setRole] = useState("")
  const [status, setStatus] = useState<TApiStatus>("idle")
  //   const [whitePaperLink, setWhitePaperLink] = useState("")
  //   const [priorInvestmentLink, setPriorInvestmentLink] = useState("")
  //   const [investmentLink, setInvestmentLink] = useState("")
  //   const [teamSize, setTeamSize] = useState("")
  //   const [monthlyGrowthBudget, setMonthlyGrowthBudget] = useState("")
  const [communityDisplayName, setCommunityDisplayName] = useState("")
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isConnected, address } = useAccount()
  const jwt = useAppSelector((state) => state.auth.jwt)
  const userInfo = useAppSelector((state) => state.user.userInfo)
  // const allCommunities = useAppSelector((state) => state.overview.allCommunities)

  // const communitiesDisplayUrlsInUse = allCommunities?.map(ele => ele.di)

  // useEffect(() => {
  //   if (address) {
  //     dispatch(fetchUserInfo(address))
  //     // dispatch(fetchAllCommunities())
  //   }
  // }, [address])

  useEffect(() => {
    if (userInfo) {
      if (userInfo?.name) {
        setName(userInfo?.name)
      }
      if (userInfo?.telegram) {
        setTelegramAccount(userInfo?.telegram)
      }
    }
  }, [userInfo])

  const onSubmit = async () => {
    // TODO: add community details
    setStatus("loading")
    const communityCreationResponse = await dispatch(
      createCommunity({
        name: communityName,
        website,
        projectTwitterAccount,
        admin_name: name,
        admin_telegram: telegramAccount,
      }),
    )
    if (!communityCreationResponse) {
      message.error("something went wrong please try again")
      setStatus("error")
      return
    }
    setStatus("success")
    navigate("/")
  }

  const SetupCommunityButton = (btnText: string, isLoading = false, isDisabled = false) => {
    return (
      <button
        className="setup-community-btn"
        disabled={isDisabled}
        onClick={() =>
          isDisabled
            ? {}
            : setupCommunityStep !== 2
            ? setSetupCommunityStep(setupCommunityStep + 1)
            : onSubmit()
        }
      >
        <div className="setup-community-btn-text">{btnText}</div>
        {isLoading ? (
          <Lottie options={lottieOptions} width={20} height={20} />
        ) : (
          <ArrowForwardIcon />
        )}
      </button>
    )
  }

  const isOverviewBtnDisabled =
    !communityName.length ||
    !website.length ||
    !name.length ||
    // !email.length ||
    !telegramAccount.length ||
    !projectTwitterAccount.length
  // !role.length ||
  // !isValidEmail(email)

  if (!isConnected || !jwt) {
    return (
      <>
        {/* <WalletConnectButton /> */}
        connect wallet
      </>
    )
  }

  return (
    <div className="setup-community-page-container">
      <div className="setup-community-left">
        <div className="setup-community-heading">Create new community</div>
        <div className="setup-community-left-nav">
          <div className="takes-time-wrapper">Takes ~1min</div>
          <div
            className={`setup-community-nav-item ${
              setupCommunityStep === 1 ? "selected-nav-item" : ""
            }`}
            onClick={() => setSetupCommunityStep(1)}
          >
            {setupCommunityStep === 1 ? <img src={arrowRightIcon} alt="" /> : null}
            Overview
          </div>
          {/* <div
            className={`setup-community-nav-item ${
              setupCommunityStep === 2 ? "selected-nav-item" : ""
            }`}
            onClick={() => setSetupCommunityStep(2)}
          >
            {setupCommunityStep === 2 ? <img src={arrowRightIcon} alt="" /> : null}
            Project Details
          </div> */}

          <div
            className={`setup-community-nav-item ${
              setupCommunityStep === 2 ? "selected-nav-item" : ""
            }`}
            onClick={() => setSetupCommunityStep(2)}
          >
            {setupCommunityStep === 2 ? <img src={arrowRightIcon} alt="" /> : null}
            Review
          </div>
        </div>
      </div>
      <div className="setup-community-right">
        {setupCommunityStep === 1 ? (
          <>
            <OverViewStep
              communityName={communityName}
              setCommunityName={setCommunityName}
              name={name}
              setName={setName}
              email={email}
              setEmail={setEmail}
              role={role}
              setRole={setRole}
              telegramAccount={telegramAccount}
              setTelegramAccount={setTelegramAccount}
              website={website}
              setWebsite={setWebsite}
              projectTwitterAccount={projectTwitterAccount}
              setProjectTwitterAccount={setProjectTwitterAccount}
            />
            <div className="step1-button-wrapper">
              {SetupCommunityButton("Review", false, isOverviewBtnDisabled)}
            </div>
          </>
        ) : (
          //  : setupCommunityStep === 2 ? (
          //   <>
          //     <ProjectDetailStep />
          //     <div className="step2-button-wrapper">{SetupCommunityButton("Review Details")}</div>
          //   </>
          // )
          <>
            <ReviewStep
              communityName={communityName}
              name={name}
              email={email}
              role={role}
              telegramAccount={telegramAccount}
              website={website}
              projectTwitterAccount={projectTwitterAccount}
              setSetupCommunityStep={setSetupCommunityStep}
              communityDisplayName={communityDisplayName}
              setCommunityDisplayName={setCommunityDisplayName}
            />
            <div className="step3-button-wrapper">
              {SetupCommunityButton("Submit", status === "loading", isOverviewBtnDisabled)}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
