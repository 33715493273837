import "./style.scss"

const FooterCard = ({
  Icon,
  cardHeading,
  cardDescription,
  footerLinkElement,
}: {
  Icon: JSX.Element
  cardHeading?: string
  cardDescription?: string
  footerLinkElement?: JSX.Element
}) => {
  return (
    <div className="footer-card-container">
      <div className="footer-card-icon">{Icon}</div>
      <div className="footer-card-heading">{cardHeading}</div>
      <div className="footer-card-description">{cardDescription}</div>
      <div className="footer-card-link">{footerLinkElement}</div>
    </div>
  )
}

export default FooterCard
