import React, { useEffect, useRef, useState } from "react"
import "./style.scss"
import { ReactComponent as XpIcon } from "../../../assets/icons/xpIcon.svg"
// import { ReactComponent as SbtsIcon } from "../../../assets/icons/SbtsIcon.svg"
import { ReactComponent as EditIcon } from "../../../assets/icons/editIcon.svg"
import { ReactComponent as UploadIcon } from "../../../assets/icons/uploadIcon.svg"
import { ReactComponent as TimelineIcon } from "../../../assets/icons/timelineIcon.svg"
import { ReactComponent as MinusIcon } from "../../../assets/icons/minusIcon.svg"
import { ReactComponent as PlusIcon } from "../../../assets/icons/plusIcon.svg"
import { ReactComponent as ArrowForwardIcon } from "../../../assets/icons/arrowForwardIcon.svg"
import { ReactComponent as ReplayIcon } from "../../../assets/icons/replayIcon.svg"
import { ReactComponent as InfoIcon } from "../../../assets/icons/infoSmall.svg"
import { ReactComponent as VisibilityIcon } from "../../../assets/icons/visibility.svg"
import { ReactComponent as PreviewIcon } from "../../../assets/icons/preview.svg"
import { ReactComponent as CopyContent } from "../../../assets/icons/ContentCopy.svg"
import { ReactComponent as CloseIcon } from "../../../assets/icons/closeIcon.svg"
import { ReactComponent as AddPhotoIcon } from "../../../assets/icons/addPhotoAlternate.svg"
import Markdown from "react-markdown"
import {
  IQuestCreationBadgeReward,
  IXpPointReward,
  IQuestAdapterTier,
  isBadgeReward,
} from "../../../types/quest"
import pluralize from "pluralize"
import { getAdapterName, getClassNameFromType, getIconFromType } from "../../../utils/adapterUtils"
import { AdapterTypes, isStrategyAdapter } from "../../../types/adapter"
import animationLoadingData from "../../../assets/lottie/buttonLoader.json"
import Lottie from "react-lottie"
import { message } from "antd"
import { useAppSelector } from "../../../hooks/reduxHooks"
import useOutsideClick from "../../../hooks/clickOutside"

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationLoadingData,
  renderer: "svg",
}

const XpReward = ({
  reward,
  setXpReward,
  setXpRewardImage,
  isSmallVariant,
  rewardIndex,
}: {
  reward: IXpPointReward
  setXpReward: (points: number) => void
  setXpRewardImage: (xpRewardFile: File | null, xpRewardImageUrl: string) => void
  isSmallVariant: boolean
  rewardIndex: number
}) => {
  // const xpReward = tier.rewards.filter((reward) => isXpReward(reward))?.[0]
  const [isManuallyEditing, setIsManuallyEditing] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  // const [xpRewardImage, setXpRewardImage] = useState(reward?.media_url || "")
  // const [xpRewardImageFile, setXpRewardImageFile] = useState<File>()

  const handleOutsideClick = () => {
    setIsManuallyEditing(false)
  }

  const handleClickNumber = async () => {
    await setIsManuallyEditing(true)
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files?.length) return
    const url = URL.createObjectURL(files[0])
    // setXpRewardImage(url)
    // setXpRewardImageFile(files[0])
    setXpRewardImage(files[0], url)
  }

  useOutsideClick(inputRef, handleOutsideClick)

  return (
    <>
      {reward?.media_url ? (
        <>
          <img src={reward?.media_url} alt="" className="xp-reward-image" />
          <div className="xp-reward-gradient"></div>
        </>
      ) : null}

      <div
        className={`xp-reward-content-wrapper ${
          reward.media_url ? "xp-reward-content-with-image" : ""
        }`}
      >
        {!isSmallVariant ? (
          <div className="xp-badge-top-row">
            <div className="xp-icon-wrapper">
              <TimelineIcon />
            </div>
            {!reward.media_url ? (
              <label htmlFor={`upload-file-input-xp-image-${rewardIndex}`}>
                <div className="xp-upload-image-wrapper">
                  Upload
                  <AddPhotoIcon />
                </div>
              </label>
            ) : (
              <div className="uploaded-xp-image-action-buttons">
                <label htmlFor={`upload-file-input-xp-image-${rewardIndex}`}>
                  <div className="xp-image-action-button">
                    <ReplayIcon />
                  </div>
                </label>
                <div
                  className="xp-image-action-button"
                  onClick={() => {
                    setXpRewardImage(null, "")
                  }}
                >
                  <CloseIcon />
                </div>
              </div>
            )}
            <input
              type="file"
              accept="image/png, image/jpeg"
              id={`upload-file-input-xp-image-${rewardIndex}`}
              className="upload-file-input-hidden"
              style={{ display: "none" }}
              onChange={(e) => onFileChange(e)}
              onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                const element = e.target as HTMLInputElement
                element.value = ""
              }}
            />{" "}
          </div>
        ) : null}
        <div className="xp-text-wrapper">
          <div className="xp-text-heading">XP Points</div>
          {!isSmallVariant ? (
            <div className="xp-text-info">Enter XP Points for this tier</div>
          ) : null}
          <div className={`${isManuallyEditing ? "xp-adding-btn-input" : ""} xp-adding-btn`}>
            {/* {xpReward && isXpReward(xpReward)
            ? `${xpReward.point} ${pluralize("Point", xpReward.point)}`
            : null} */}
            <span>
              {isManuallyEditing ? (
                <input
                  value={reward.point}
                  type="number"
                  onChange={(e) => setXpReward(e.target.value ? parseInt(e.target.value) : 0)}
                  ref={inputRef}
                />
              ) : (
                <span
                  // contentEditable
                  onClick={handleClickNumber}
                  className="point-span"
                >
                  {reward.point}
                </span>
              )}
              {` ${pluralize("Point", reward.point)}`}
            </span>
            <div
              className={`xp-adding-operators ${
                isManuallyEditing ? "xp-adding-operators-input-active" : ""
              }`}
            >
              <MinusIcon onClick={() => setXpReward(Math.max(reward.point - 10, 0))} />
              <PlusIcon onClick={() => setXpReward(reward.point + 10)} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const BadgeReward = ({
  reward,
  tierIndex,
  setBadgeReward,
}: {
  reward: IQuestCreationBadgeReward
  tierIndex: number
  setBadgeReward: (image_url: string, image_file: File) => void
}) => {
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files?.length) return
    const url = URL.createObjectURL(files[0])
    setBadgeReward(url, files[0])
    // copyOfBadges[badgeIndex].is_video = false
    // if (files[0].type === "video/mp4") {
    //     copyOfBadges[badgeIndex].is_video = true
    // }
  }
  return (
    <>
      {reward.image_url.length ? (
        <div className="sbt-reward-preview-image-wrapper">
          <img src={reward.image_url} alt="" width={240} height={240} />
          <label htmlFor={`upload-file-input-${tierIndex}`}>
            <div className="sbt-reward-reupload-wrapper">
              <ReplayIcon />
            </div>
          </label>
        </div>
      ) : (
        <label htmlFor={`upload-file-input-${tierIndex}`}>
          <div className="sbt-reward-upload-wrapper">
            <div className="upload-icon-wrapper">
              <UploadIcon />
            </div>
            <div className="upload-text-wrapper">
              <div className="upload-text-heading">Upload Asset</div>
              <div className="upload-text-info">PNG, JEPG or GIF, aspect ratio to be 1:1</div>
            </div>
          </div>
        </label>
      )}
      <input
        type="file"
        // accept="image/png, image/gif, image/jpeg, video/mp4, video/"
        accept="image/png, image/jpeg, image/jpg, image/webp"
        id={`upload-file-input-${tierIndex}`}
        className="upload-file-input-hidden"
        style={{ display: "none" }}
        onChange={(e) => onFileChange(e)}
      />
    </>
  )
}
const RequirementTextInput = ({
  adapter,
  index,
  text,
  updateRequirementText,
}: {
  adapter: AdapterTypes
  index: number
  text: string
  updateRequirementText: (value: string) => void
}) => {
  const [isPreview, setIsPreview] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  function containsLink(textToCheck: string) {
    const regex = /\b(?:https?:\/\/|.*\.com)\S*\b/
    return regex.test(textToCheck)
  }
  useEffect(() => {
    const shouldOpen = containsLink(text)
    if (shouldOpen) {
      setIsHovered(true)
      setTimeout(() => {
        setIsHovered(false)
      }, 2000)
    }
  }, [text])

  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto"
    textarea.style.height = `${textarea.scrollHeight}px`
  }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText("[link name](https://URL/)")
      message.success("syntax copied")
    } catch (err) {
      console.error("Failed to copy: ", err)
    }
  }
  return (
    <div className="tier-adapter-row" key={index}>
      <div className="tier-adapter-info">
        <div className={`tier-adapter-row-icon ${getClassNameFromType(adapter.adapter_name)}`}>
          {getIconFromType(adapter.adapter_name)}
        </div>
        <div className="tier-adapter-row-text">
          {getAdapterName(isStrategyAdapter(adapter) ? adapter.adapter_subtype : adapter.task_type)}
        </div>
      </div>
      <div className="tier-adapter-row-requirement-input">
        {!isPreview && (
          <textarea
            value={text}
            onChange={(e) => {
              updateRequirementText(e.target.value)
              adjustHeight(e.target)
            }}
            placeholder="Enter Requirement Text"
            rows={1}
          />
        )}
        {isPreview && <Markdown>{text}</Markdown>}
        <div className="tier-adapter-row-requirement-input-icon-wrapper">
          {/* <Tooltip title="quest url" arrow={false} placement="bottomRight"> */}
          <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <InfoIcon />
            {isHovered ? (
              <div className="tooltip-wrapper-div">
                <div className="transparent-div"></div>
                <div className="upper-container-div">
                  <div className="title-text"> Use this format to add links </div>
                  <div className="link-format-row">
                    {" "}
                    [link name](https://URL/) <CopyContent onClick={handleCopy} />{" "}
                  </div>
                </div>
                <div className="lower-container-div">
                  <div className="example-text">
                    eg. if you want “Swap 10 matic on socket” enter “Swap 10 matic on
                    [socket](socket.io/swap)”{" "}
                  </div>
                </div>
              </div>
            ) : null}
            {/* </Tooltip> */}
          </div>
          <div onClick={() => setIsPreview(!isPreview)}>
            {!isPreview ? <PreviewIcon /> : <VisibilityIcon />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default function QuestRewardStep({
  tiers,
  selectedRewardType,
  setSelectedRewardType,
  setXpRewardPoint,
  setXpRewardImage,
  setBadgeReward,
  onSubmit,
  setQuestCreationStep,
  setActiveTier,
  creatingQuest,
  isDisabled,
  requirementText,
  updateRequirementTextToTier,
}: Readonly<{
  tiers: IQuestAdapterTier[]
  selectedRewardType: string
  setSelectedRewardType: (value: string) => void
  setXpRewardPoint: (points: number, rewardIndex: number, tierIndex: number) => void
  setXpRewardImage: (
    xpRewardFile: File | null,
    xpRewardImageUrl: string,
    rewardIndex: number,
    tierIndex: number,
  ) => void
  setBadgeReward: (
    image_url: string,
    image_file: File,
    rewardIndex: number,
    tierIndex: number,
  ) => void
  onSubmit: () => void
  setQuestCreationStep: (value: number) => void
  setActiveTier: (value: number | undefined) => void
  creatingQuest: boolean
  isDisabled: boolean
  requirementText: { text: string; adapterIndex: number; tierIndex: number }[]
  updateRequirementTextToTier: (tierIndex: number, adapterIndex: number, value: string) => void
}>) {
  const isEditing = useAppSelector((state) => state.questCreation.isEditing)

  return (
    <div className="quest-reward-step-container">
      <div className="quest-reward-heading">Setup Rewards</div>
      <div className="reward-type-filter-row">
        {/* <div
          className={`reward-type-item ${
            selectedRewardType === "sbts" ? "selected-reward-item" : ""
          }`}
          onClick={() => setSelectedRewardType("sbts")}
        >
          <SbtsIcon />
          SBTs
        </div> */}
        <div
          className={`reward-type-item ${
            selectedRewardType === "xp" ? "selected-reward-item" : ""
          }`}
          onClick={() => setSelectedRewardType("xp")}
        >
          <XpIcon />
          XP Points
        </div>
        {/* <div
          className={`reward-type-item ${
            selectedRewardType === "bothSbtAndXp" ? "selected-reward-item" : ""
          }`}
          onClick={() => setSelectedRewardType("bothSbtAndXp")}
        >
          Both SBT and XP
        </div> */}
      </div>
      <div className="quest-reward-tier-mapping-wrapper">
        <div className="reward-tier-mapping-left">
          {tiers.map((tier, tierIndex) => (
            <div
              key={tierIndex}
              className={`single-tier-left-wrapper ${
                selectedRewardType === "bothSbtAndXp" ? "single-tier-left-flex-wrapper" : ""
              }`}
            >
              <div className="single-tier-details">
                <div className="single-tier-heading-row">
                  <div className="single-tier-heading-left">
                    {tier.tierName}{" "}
                    <span>
                      &bull;
                      {` ${tier.adapters.length} ${pluralize("Requirement", tier.adapters.length)}`}
                    </span>
                  </div>
                  <div className="single-tier-heading-right">
                    <EditIcon
                      onClick={() => {
                        setActiveTier(tierIndex + 1)
                        setQuestCreationStep(2)
                      }}
                    />
                  </div>
                </div>
                <div className="tier-adapters-wrapper">
                  {tier.adapters.map((adapter, index) => (
                    <RequirementTextInput
                      text={
                        requirementText?.filter(
                          (x) => x.adapterIndex === index && x.tierIndex === tierIndex,
                        )?.[0]?.text || ""
                      }
                      updateRequirementText={(value) => {
                        updateRequirementTextToTier(tierIndex, index, value)
                      }}
                      key={index.toString()}
                      adapter={adapter}
                      index={index}
                    />
                  ))}
                </div>
              </div>
              {tierIndex !== tiers.length - 1 ? <div className="tier-separator"></div> : null}
            </div>
          ))}
          <div className="publish-campaign-btn-wrapper">
            {isEditing ? (
              <div className="publish-campaign-update-info">
                <InfoIcon />
                Quest has been changed, updation will only affect new participants
              </div>
            ) : null}
            <button
              className="publish-campaign-btn"
              onClick={() => {
                if (!creatingQuest && !isDisabled) {
                  onSubmit()
                }
              }}
              disabled={creatingQuest || isDisabled}
            >
              <div className="create-campaign-btn-left">
                {creatingQuest
                  ? isEditing
                    ? "Updating Quest ~1min"
                    : "Creating Quest ~1min"
                  : isEditing
                  ? "Update Quest"
                  : "Create Quest"}{" "}
              </div>
              {creatingQuest ? (
                <Lottie options={lottieOptions} width={20} height={20} />
              ) : (
                <ArrowForwardIcon />
              )}
            </button>
          </div>
        </div>
        <div className="reward-tier-mapping-right">
          {tiers.map((tier, index) => {
            return (
              <div
                key={index}
                className="single-tier-right-wrapper-container"
                style={{
                  minHeight: `${Math.max(
                    selectedRewardType === "bothSbtAndXp" ? 23.75 : 17.5, // single adapter in the div
                    tiers[index].adapters.length * 9.5625 + 4.5 + 2.5, // (no. of adapter * height of single adapter with requirement text box)+div height remaining adapter height + line height
                  )}rem`,
                }}
              >
                <div key={index} className="single-tier-right-wrapper">
                  {tier.rewards.map((reward, rewardIndex) => {
                    return (
                      <div key={rewardIndex}>
                        {isBadgeReward(reward) ? (
                          <BadgeReward
                            reward={reward}
                            tierIndex={index}
                            setBadgeReward={(image_url: string, image_file: File) => {
                              setBadgeReward(image_url, image_file, rewardIndex, index)
                            }}
                          />
                        ) : (
                          <div
                            className={`xp-reward-expanded-wrapper ${
                              selectedRewardType === "bothSbtAndXp" ? "xp-reward-with-border" : ""
                            }`}
                          >
                            <XpReward
                              reward={reward}
                              setXpReward={(points: number) =>
                                setXpRewardPoint(points, rewardIndex, index)
                              }
                              setXpRewardImage={(
                                xpRewardFile: File | null,
                                xpRewardImageUrl: string,
                              ) =>
                                setXpRewardImage(xpRewardFile, xpRewardImageUrl, rewardIndex, index)
                              }
                              isSmallVariant={selectedRewardType === "bothSbtAndXp"}
                              rewardIndex={index}
                            />
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
