import React, { useState } from "react"
import "./style.scss"
import { uploadFileToS3 } from "../../utils/awsUtils"
import blackAnimationLoadingData from "../../assets/lottie/greenLoader.json"
import { RcFile } from "antd/es/upload"
import { ReactComponent as UploadDone } from "../../assets/icons/upload-done.svg"
import { ReactComponent as DeleteFile } from "../../assets/icons/deleteIcon.svg"
import Lottie from "react-lottie"
import numbro from "numbro"
import { ReactComponent as DragCSVIcon } from "../../assets/icons/drag-csv-upload.svg"
import { Upload, message, type UploadProps } from "antd"
import type { UploadFile } from "antd/lib/upload/interface"
import { useAppSelector } from "../../hooks/reduxHooks"

const { Dragger } = Upload

interface DraggerState {
  fileList: UploadFile[]
  uploading: boolean
  uploaded: boolean
}

const blackLottieOption = {
  loop: true,
  autoplay: true,
  animationData: blackAnimationLoadingData,
  renderer: "svg",
}

const CustomDragger = ({
  addFileToList,
  removeFileFromList,
  taskUuid,
}: {
  addFileToList: (uuid: string, fileName: string, file: File) => void
  removeFileFromList: (uuid: string) => void
  taskUuid: string
}) => {
  const jwt = useAppSelector((state) => state.auth.jwt)
  const [draggerState, setDraggerState] = useState<DraggerState>({
    fileList: [],
    uploading: false,
    uploaded: false,
  })
  const props = {
    name: "file",
    multiple: false,
    accept: "text/csv",
    action: "",
    customRequest: async (options: { file: string | Blob | RcFile }) => {
      const { file } = options

      try {
        if (jwt) {
          setDraggerState({
            fileList: [file as UploadFile],
            uploading: true,
            uploaded: false,
          })

          // const result: boolean = await uploadFileToS3(
          //   (file as RcFile).name,
          //   "rep3-community-files",
          //   jwt,
          //   file as RcFile,
          // )
          const result = true

          console.log("file is", file)

          if (result === true) {
            if (typeof file === "string") {
              throw new Error("Failed to upload file")
            } else if (file.size && file.size / 1048576 < 30) {
              setDraggerState({ fileList: [file as UploadFile], uploading: false, uploaded: true })

              addFileToList(taskUuid, (file as RcFile).name, file as RcFile)
              message.success("File uploaded successfully")
            } else {
              message.error("File size is too large, max 30Mb")
              setDraggerState({
                fileList: [],
                uploading: false,
                uploaded: false,
              })
            }
          } else {
            throw new Error("Failed to upload file")
          }
        }
      } catch (err) {
        console.error("Upload error:", err)
        message.error("Upload failed")
        // options.onError(err);
      }
    },
    onChange(info: { file: { name: string; status?: string } }) {
      const { status } = info.file
    },
    itemRender: () => {
      return <div style={{ display: "none" }}></div>
    },
  }

  const handleRemove = (file: UploadFile) => {
    setDraggerState({
      fileList: [],
      uploading: false,
      uploaded: false,
    })
    removeFileFromList(taskUuid)
    message.info(`File ${file.name} removed.`)
  }

  const handleCustomDeleteClick = (file: UploadFile) => {
    handleRemove(file)
  }
  return (
    <div>
      <Dragger {...props} className="dragger-custom-styles">
        {!draggerState.uploading && !draggerState.uploaded && (
          <p className="ant-upload-drag-icon">
            <DragCSVIcon />
          </p>
        )}
        {draggerState.uploading && <Lottie options={blackLottieOption} width={160} height={60} />}
        {!draggerState.uploading && draggerState.uploaded && draggerState.fileList.length > 0 && (
          <p className="ant-upload-drag-icon">
            <UploadDone />
          </p>
        )}
        {!draggerState.uploading && draggerState.uploaded && draggerState.fileList.length > 0 && (
          <div>
            <p className="ant-upload-text">{draggerState.fileList[0].name}</p>
            <p className="ant-upload-hint">
              {numbro(draggerState.fileList[0].size!).format({
                output: "byte",
                base: "binary",
                spaceSeparated: true,
              })}
            </p>
            <button
              className="delete-file-button"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleCustomDeleteClick(draggerState?.fileList[0])
              }}
            >
              <DeleteFile width={24} height={24} />
            </button>
          </div>
        )}
        {draggerState.uploading && (
          <>
            <p className="ant-upload-text">Uploading file...</p>
            <button
              className="cancel-btn"
              onClick={() => setDraggerState((prevState) => ({ ...prevState, uploading: false }))}
            >
              Cancel
            </button>
          </>
        )}
        {!draggerState.uploading && !draggerState.uploaded && (
          <div>
            <p className="ant-upload-text">Select a CSV file to upload</p>
            <p className="ant-upload-hint">or drag and drop it here</p>
          </div>
        )}
      </Dragger>
    </div>
  )
}

export default CustomDragger
