export const isProductionEnvironment = process.env.REACT_APP_NODE_ENV === "production"

export const ClientToolBaseUrl = isProductionEnvironment
  ? "https://app.rep3.gg"
  : "https://app-staging.rep3.gg"

export const S3ImageBucketName = "rep3-assests"
export const CloudFrontImageUrl = "https://d73f2aibn7qha.cloudfront.net"

export const Rep3BotAddress = "0xc8B4a82A1cc76C62BeFB883906ec12E2F1b4feB6"

export const ContractAddressTopicSignature =
  "0xa3396fd7f6e0a21b50e5089d2da70d5ac0a3bbbd1f617a93f134b76389980198"

export const Rep3PlaceHolderLogo = "https://d73f2aibn7qha.cloudfront.net/rep3/rep3PlaceLogo.png"

export const TelegramWhitelistLink = "https://t.me/someshc8i"
