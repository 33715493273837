import { Input, Switch, Button } from "antd"
import React, { useEffect, useRef, useState } from "react"
import "./style.scss"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import {
  addAdmin,
  getAllAdmins,
  removeAdmin,
  updateReferralInfo,
} from "../../store/actions/settingAction"
import { useAccount } from "wagmi"
import { IoMdClose, IoMdCheckmark } from "react-icons/io"
import Lottie from "react-lottie"
import blackAnimationLoadingData from "../../assets/lottie/blackButtonLoader.json"
import { updateLeaderboardInfo } from "../../store/actions/overviewAction"
import { Address } from "../../types/common"

const blackLottieOption = {
  loop: true,
  autoplay: true,
  animationData: blackAnimationLoadingData,
  renderer: "svg",
}

export default function SettingsPage() {
  const dispatch = useAppDispatch()
  const { address } = useAccount()

  const referralInfo = useAppSelector((state) => state.setting.referralPoints)
  const allAdmins = useAppSelector((state) => state.setting.allAdmins)
  const communityInfo = useAppSelector((state) => state.overview.selectedCommunity)

  const referralPointsInputRef = useRef<HTMLInputElement | null>(null)

  const [referralPointsValue, setReferralPointsValue] = useState<number>(
    referralInfo?.points || 100,
  )
  const [isEditingReferralPoints, setIsEditingReferralPoints] = useState<boolean>(false)
  const [isReferralActive, setIsReferralActive] = useState<boolean>(referralInfo?.isActive)
  const [isLeaderboardActive, setIsLeaderboardActive] = useState<boolean>(
    communityInfo?.show_leaderboard ? true : false,
  )
  const [tempAdmins, setTempAdmins] = useState<string[] | []>([])
  const [loading, setLoading] = useState<boolean[] | []>([])
  const [loadingDelete, setDeleteLoading] = useState<boolean[] | []>(
    Array(allAdmins.length).fill(false),
  )

  useEffect(() => {
    if (isEditingReferralPoints) {
      referralPointsInputRef.current?.focus()
    }
  }, [isEditingReferralPoints])

  useEffect(() => {
    dispatch(getAllAdmins())
  }, [communityInfo])

  const handleReferralSwitchChange = (checked: boolean) => {
    setIsReferralActive(checked)
    dispatch(updateReferralInfo(checked, referralPointsValue))
  }

  const handleLeaderboardSwitchChange = (checked: boolean) => {
    setIsLeaderboardActive(checked)
    dispatch(updateLeaderboardInfo(checked))
  }

  const handleReferralPointsBlur = () => {
    setIsEditingReferralPoints(false)
    dispatch(updateReferralInfo(isReferralActive, referralPointsValue))
  }

  const addTempAdmins = () => {
    let addCurrAdmin = [...tempAdmins]
    addCurrAdmin = [...addCurrAdmin, ""]
    setTempAdmins(addCurrAdmin)
    setLoading([...loading, false])
  }

  const handleDeleteTempAdmin = (index: number) => {
    const tempArray = [...tempAdmins]
    tempArray.splice(index, 1)
    setTempAdmins(tempArray)
  }

  const handleRemoveAdmin = async (eoa: Address, index: number) => {
    if (!loadingDelete.some((ele) => ele === true)) {
      const loadingDeleteArray = [...loadingDelete]
      loadingDeleteArray[index] = true
      setDeleteLoading(loadingDeleteArray)
      await dispatch(removeAdmin(eoa))
      loadingDeleteArray[index] = false
      setDeleteLoading(loadingDeleteArray)
    }
  }

  const handleAddAdmin = async (eoa: Address, index: number) => {
    if (!loading.some((element) => element === true || (eoa as string) === "")) {
      const loadingArray = [...loading]
      loadingArray[index] = true
      setLoading(loadingArray)
      await dispatch(addAdmin(eoa))
      await handleDeleteTempAdmin(index)
      loadingArray[index] = false
      setLoading(loadingArray)
    }
  }

  return (
    <div className="settings-page-container">
      <h1 className="settings-heading">Settings</h1>
      <div className="settings-content-wrapper">
        <div className="referral-setting-wrapper">
          <div className="referral-top-row">
            <div>Referral</div>
            <Switch checked={isReferralActive} onChange={handleReferralSwitchChange} />
          </div>
          <div className={`referral-points-row ${isReferralActive ? "referral-active" : ""}`}>
            Points per referral:{" "}
            {isEditingReferralPoints ? (
              <input
                type="number"
                onBlur={handleReferralPointsBlur}
                ref={referralPointsInputRef}
                value={referralPointsValue}
                onChange={(e) => setReferralPointsValue(Number(e.target.value))}
              />
            ) : (
              <>
                {referralPointsValue} &#x2022;{" "}
                <span
                  onClick={() => {
                    if (!isReferralActive) return
                    setIsEditingReferralPoints(true)
                  }}
                >
                  Edit
                </span>
              </>
            )}
          </div>
        </div>
        <div className="leaderboard-setting-wrapper">
          <div className="leaderboard-top-row">
            <div>Leaderboard</div>
            <Switch checked={isLeaderboardActive} onChange={handleLeaderboardSwitchChange} />
          </div>
          <div
            className={`leaderboard-info-row ${isLeaderboardActive ? "leaderboard-active" : ""}`}
            onClick={() => {
              window.open("https://docs.rep3.gg/", "_blank")
            }}
          >
            Know more about Leaderboard
          </div>
        </div>
      </div>
      <div className="settings-content-wrapper">
        <div className="add-admin-setting-wrapper">
          <div className="add-admin-top-row">
            Admins &#x2022;<span onClick={() => addTempAdmins()}> Add Admins </span>
          </div>
          <div>
            {allAdmins &&
              allAdmins.length > 0 &&
              allAdmins.map((admin, index) => {
                return (
                  <div key={index} className="admin-row">
                    <div>{admin}</div>
                    {admin.toLocaleLowerCase() !== address?.toLocaleLowerCase() ? (
                      <button
                        className="cross-button-container"
                        onClick={() => handleRemoveAdmin(admin, index)}
                      >
                        <span className="delete-button-text">Delete Admin</span>
                        {loadingDelete[index] === true ? (
                          <Lottie options={blackLottieOption} width={24} height={24} />
                        ) : (
                          <IoMdClose />
                        )}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                )
              })}
          </div>
          <div>
            {tempAdmins &&
              tempAdmins.map((tempAdmin, index: number) => {
                return (
                  <div key={index} className="add-admin-input-row">
                    <div className="add-admin-input-div">
                      <Input
                        type="text"
                        placeholder="Add Admin Address"
                        value={tempAdmin}
                        onChange={(e) => {
                          const newAdmins = [...tempAdmins]
                          newAdmins[index] = e.target.value
                          setTempAdmins(newAdmins)
                        }}
                      />
                      <IoMdClose onClick={() => handleDeleteTempAdmin(index)} />
                    </div>
                    <div
                      className="add-admin-check-container"
                      onClick={() => handleAddAdmin(tempAdmin as Address, index)}
                    >
                      {loading[index] === true ? (
                        <Lottie options={blackLottieOption} width={24} height={24} />
                      ) : (
                        <IoMdCheckmark />
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}
