import React, { useEffect, useState } from "react"
import "./style.scss"
import { NonStrategyAdapter, AdapterKeysWithValues } from "../../../types/adapter"
import { useAppSelector } from "../../../hooks/reduxHooks"
import { Switch } from "antd"

function RenderInputKeyOnType({
  keyObject,
  index,
  updateAdapterInputValue,
}: {
  keyObject: AdapterKeysWithValues
  index: number
  updateAdapterInputValue: (adapterKeyIndex: number, value: number | string) => void
}) {
  const inputType = keyObject.key
  switch (inputType) {
    case "title":
      return (
        <div
          className={`single-adapter-input-wrapper ${
            typeof keyObject.value === "string" && !keyObject.value.length
              ? "single-adapter-empty-input-wrapper"
              : ""
          }`}
          key={index}
        >
          <div className="single-adapter-input-text">Submission Title</div>
          <div className="single-adapter-input">
            <input
              type="text"
              value={keyObject.value}
              onChange={(e) => updateAdapterInputValue(index, e.target.value)}
              placeholder="Enter Title"
            />
          </div>
        </div>
      )
    case "description":
      return (
        <div
          className={`single-adapter-input-wrapper ${
            typeof keyObject.value === "string" && !keyObject.value.length
              ? "single-adapter-empty-input-wrapper"
              : ""
          }`}
          key={index}
        >
          <div className="single-adapter-input-text">Enter Description</div>
          <div className="single-adapter-textarea">
            <textarea
              value={keyObject.value}
              onChange={(e) => updateAdapterInputValue(index, e.target.value)}
              placeholder=""
            />
          </div>
        </div>
      )
    case "max_user_submission":
      return (
        <div
          className={`single-adapter-input-wrapper ${
            typeof keyObject.value === "string" && !keyObject.value.length
              ? "single-adapter-empty-input-wrapper"
              : ""
          }`}
        >
          <div className="single-adapter-input-text">Max Submission</div>
          <div className="single-adapter-input">
            <input
              type="number"
              value={keyObject.value}
              onChange={(e) => updateAdapterInputValue(index, e.target.value)}
              placeholder="Submission number per person"
            />
          </div>
        </div>
      )
    case "approved_submission_count":
      return (
        <div
          className={`single-adapter-input-wrapper ${
            typeof keyObject.value === "string" && !keyObject.value.length
              ? "single-adapter-empty-input-wrapper"
              : ""
          }`}
        >
          <div className="single-adapter-input-text">Approval limit</div>
          <div className="single-adapter-input">
            <input
              type="number"
              value={keyObject.value}
              onChange={(e) => updateAdapterInputValue(index, e.target.value)}
              placeholder="Approved submissions to clear task"
            />
          </div>
        </div>
      )
    default:
      return <></>
  }
}

const SubmitFormAdapter = ({
  adapterInfo,
  updateAdapterInputValue,
  insertKeysToAdapter,
  updateAdapterKeysList,
}: {
  adapterInfo: NonStrategyAdapter
  updateAdapterInputValue: (adapterKeyIndex: number, value: number | string) => void
  insertKeysToAdapter: (newAdapterToAdd: AdapterKeysWithValues[]) => void
  updateAdapterKeysList: (keyName: string, value: string | number) => void
}) => {
  const [switchValue, setSwitchValue] = useState(
    adapterInfo.options?.keys?.find((key) => key.key === "max_user_submission")?.value
      ? true
      : false,
  )

  const onChange = () => {
    setSwitchValue(!switchValue)
  }

  const isEditing = useAppSelector((state) => state.questCreation.isEditing)
  const isDuplicating = useAppSelector((state) => state.questCreation.isDuplicating)

  useEffect(() => {
    if (
      adapterInfo?.options?.keys?.filter((key) => key.key === "submit_form_uuid")?.[0]?.value === ""
    ) {
      const newKey = [
        {
          default: "",
          key: "title",
          required: true,
          user_level: false,
          value: "",
          type: "string",
          valid_values: [],
          name: "title",
        },
        {
          default: "",
          key: "description",
          required: true,
          user_level: false,
          value: "",
          type: "string",
          valid_values: [],
          name: "description",
        },
        {
          default: 1,
          key: "max_user_submission",
          required: false,
          user_level: false,
          value: "",
          type: "number",
          valid_values: [],
          name: "max_submission",
        },
        {
          default: 1,
          key: "approved_submission_count",
          required: false,
          user_level: false,
          value: "",
          type: "number",
          valid_values: [],
          name: "approved_submission",
        },
      ]
      insertKeysToAdapter(newKey)
    }
  }, [])

  useEffect(() => {
    if (!switchValue) {
      adapterInfo?.options?.keys.map((keyObject, index) => {
        if (
          keyObject.key === "approved_submission_count" ||
          keyObject.key === "max_user_submission"
        ) {
          updateAdapterInputValue(index, "")
        }
      })
    }
  }, [switchValue])
  console.log(adapterInfo?.options?.keys?.filter((key) => key.key === "submit_form_uuid"), "hello")
  return (
    <div>
      <div className="single-adapter-requirements-input-wrapper ">
        {adapterInfo?.options?.keys
          ?.filter((key) => key.key === "title" || key.key === "description")
          .map((keyObject, index) => {
            console.log(keyObject, index, "index")
            return (
              <>
                <RenderInputKeyOnType
                  keyObject={keyObject}
                  index={
                    adapterInfo?.options?.keys?.filter((key) => key.key === "submit_form_uuid")
                      .length
                      ? index + 1
                      : index
                  }
                  updateAdapterInputValue={updateAdapterInputValue}
                />
              </>
            )
          })}
        <div className="multiple-submission-switcher">
          <div className="single-adapter-input-text">Multiple Submission</div>
          <Switch
            checked={switchValue}
            onChange={() => {
              onChange()
            }}
          />
        </div>
        {switchValue &&
          adapterInfo?.options?.keys
            ?.filter((key) => key.key !== "title" && key.key !== "description")
            .map((keyObject, index) => (
              <>
                <RenderInputKeyOnType
                  keyObject={keyObject}
                  index={index + 2}
                  updateAdapterInputValue={updateAdapterInputValue}
                />
              </>
            ))}
      </div>
    </div>
  )
}

export default SubmitFormAdapter

//       <div className="single-adapter-input-wrapper">
//         <div className="submit-adapter-input-text">
//           {getAdapterInputHeadingFromKey(keyObject.key)}
//         </div>
//         <div className="single-adapter-select">
//           <Select
//             style={{ width: "100%" }}
//             defaultValue={
//               keyObject?.value
//                 ? {
//                     value:
//                       keyObject.name === "functionName" && texSelectOptions
//                         ? texSelectOptions[0]?.value
//                         : keyObject?.value,
//                     label: texSelectOptions?.filter((x) => x.value === keyObject?.value)?.[0]
//                       ?.label,
//                   }
//                 : null
//             }
//             onChange={(selection) => {
//               // if (keyObject?.key === "functionName") {
//               //   functionSelectFromAbi((selection?.value ?? selection).toString(), index)
//               // } else {
//               updateAdapterInputValue(index, selection?.value ?? selection)
//               // }
//             }}
//             placeholder={getAdapterPlaceHolderFromKey(keyObject.key)}
//             options={texSelectOptions}
//           />
//         </div>
//       </div>
