/* eslint-disable  @typescript-eslint/no-explicit-any */

import { Dayjs } from "dayjs"
import {
  AdapterIdentities,
  AdapterKeys,
  AdapterOptions,
  AdapterTypes,
  AdapterTypesWithInputs,
} from "./adapter"
import { Address, NetworkType } from "./common"

export interface Quest {
  contract_address: Address
  description: string
  display_path: string
  end_date: string
  is_active: boolean
  is_campaign: boolean
  is_publish: boolean
  meta_img_url: string
  name: string
  network: NetworkType
  showcase_img_url: string | null
  showcase_video_url: string | null
  tweet_text: string | null
  uuid: string
  bg_img_url: string | null
  claimer_count: number
  is_repeat_quest: boolean
  collection_uuid?:string
}

export interface IQuizOption {
  [key: string]: string // Assuming keys are "1", "2", etc., and values are option strings
}
export interface IQuizQuestion {
  options: IQuizOption
  question: string
}

export interface IQuiz {
  min_score: number
  "q/a": IQuizQuestion[] // Using "q/a" as the property name, note the quotes are necessary due to the slash
  reading_link: string
  reading_title: string
  submission_interval: number
  title: string
}
export interface IQuizAnswers {
  non_strategy_task_uuid: string
  "q&a": {
    ans_id: number
    ques_id: number
  }
}

export interface TSubmitFormTaskData {
  submit_form_uuid: string
}

// export interface Tasks{
//   task_type:string
// }
export interface Task {
  created_at: string
  requirement: string
  type: string
  updated_at: string
  uuid: string
  task_type: string
  task_data: IQuiz | TSubmitFormTaskData
}
export type TStrategyTask = {
  adapter: {
    adapter_subtype: string
    adapter_type: string
    commit_hash: string
    created_at: string
    description: string | null
    logo_url: string | null
    name: string
    options: AdapterOptions
    updated_at: string
    uuid: string
  }
  adapter_data: any
  created_at: string
  identities_required: [1]
  is_requirements_from_user_required: boolean
  requirement: string
  type: "strategy_task"
  updated_at: string
  uuid: string
}

export type TSubmitFormData = {
description: string
max_submission_per_interval:number
max_user_submission: number
submission_time_interval: number
title: string
uuid: string
}

export type TNonStrategyTask = {
  adapter: null
  created_at: string
  description: string | null
  identities_required: [0] | null
  requirement: string | null
  submit_form: null | TSubmitFormData
  task_data: any
  task_type: string
  type: "non_strategy_task"
  updated_at: string
  uuid: string
}

export type TQuestTask = TStrategyTask | TNonStrategyTask

export const isStrategyTask = (x: TQuestTask): x is TStrategyTask =>
  Boolean(x.adapter?.adapter_subtype)

export const isNonStrategyTask = (x: any): x is TNonStrategyTask => Boolean(x?.task_type)

export type TXpPointMediaType = "image" | "lottie" | null

export interface TaskGroup {
  uuid: string
  display_task_group_id: number | null
  tier: number
  xp_point: number
  name: string | null
  tasks: TQuestTask[]
  media_type: TXpPointMediaType
  media_url: string | null
}

export interface IRepeatedQuest {
  created_at: string
  description: string
  reconcile_days: number
  uuid: string
  next_recon_date: string
}

export interface QuestWithTaskGroups extends Quest {
  task_groups: TaskGroup[]
}

export interface IQuestCustomFields {
  display_task_group_count: number
  downgrade_count: number
  upgrade_count: number
  unseen_form_submission_count: number
  participant_count: number
  csv_task_uuid_arr: string[]
  submit_form_uuid: string | null
  task_group_count: number
  xp_range: number[]
  badge_img_arr: string[]
  repeat_quest?: IRepeatedQuest
  collection_uuid?: string
}

export interface QuestWithCustomFields extends QuestWithTaskGroups, IQuestCustomFields {}

export interface IOverviewPageQuests extends Quest, IQuestCustomFields {}

export interface IQuestBadgeReward {
  image_url: string
  is_dynamic: boolean
  metadata_hash: string
  name: string
  tier: number
  uuid: string
  video_url: string
}

export interface IXpPointReward {
  point: number
  media_type: TXpPointMediaType
  media_url: string | null
  media_file: File | null
}

export interface IQuestCreationBadgeReward {
  image_url: string
  image_file: File | null
}

export type TQuestTierReward = IQuestCreationBadgeReward | IXpPointReward

export const isBadgeReward = (x: TQuestTierReward): x is IQuestCreationBadgeReward => {
  if (x && typeof x === "object" && "image_url" in x) return true
  return false
}
export const isXpReward = (x: TQuestTierReward): x is IXpPointReward => {
  if (x && typeof x === "object" && "point" in x) {
    return true
  }
  return false
}
export interface QuestWithCustomFieldsAndRewards extends QuestWithCustomFields {
  rewards: IQuestBadgeReward[]
}

export interface IQuestAdapterTier {
  tierName: string
  adapters: AdapterTypes[]
  rewards: TQuestTierReward[]
  uuid?: string
}

export interface IQuestCreationInfo {
  questName: string
  questDescription: string
  questDisplayUrl: string
  questEndDateActive: boolean
  endDate?: Dayjs | null
  tweetText?: string
  questMetaImage?: string
  questMetaImageFile?: File
  tiers: IQuestAdapterTier[]
  questCollection?:{value:string, uuid:string} | null
}

export interface ISubmitFormData {
  created_at: string
  is_seen: boolean
  last_status_update: string
  status: string
  uuid: string
  submit_data: string
  user: {
    eoa: string
  }
}
export interface IUpdateSubmitFormData {
  is_seen?: boolean
  last_status_update?: string
  status?: string
  uuid: string
}

export interface QuestWithRepeated extends QuestWithCustomFieldsAndRewards {
  repeat_quest?: IRepeatedQuest
}
