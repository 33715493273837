import "./style.scss"
import inputErrorIcon from "../../../assets/icons/inputErrorIcon.svg"
import React from "react"

export default function TextInput({
  label,
  optional,
  value,
  isError,
  placeholder,
  isDisabled,
  errorMessage = "this doesn't seem right",
  onChange,
  onBlur,
  shouldChangeBackground
}: {
  label?: string
  optional?: boolean
  value: string
  isError?: boolean
  placeholder?: string
  isDisabled?: boolean
  errorMessage?: string
  shouldChangeBackground?:boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
}) {
  return (
    <div className="text-input-container">
      {label ? (
        <label className="custom-text-input-label">
          {label}
          {optional ? <span>Optional</span> : null}
        </label>
      ) : null}
      <div className="custom-text-input-wrapper">
        <input
          type="text"
          className={`custom-text-input ${value.length && !shouldChangeBackground ? "custom-text-input-filled" : shouldChangeBackground && value.length === 0 ? "empty-background-color": ""} ${
            isError ? "custom-text-input-error" : ""
          } ${isDisabled ? "custom-text-input-disabled" : ""}`}
          placeholder={placeholder || ""}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isDisabled}
        />
        <div className="custom-text-input-right-wrapper">
          {isError ? (
            <div className="custom-text-input-error-wrapper">
              <div className="error-tooltip">{errorMessage}</div>
              <img src={inputErrorIcon} alt="" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
