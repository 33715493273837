import { createSlice } from "@reduxjs/toolkit"

export type TToastTypes = "success" | "error"

interface IToastState {
  toastInfo: {
    toastContent: string
    toastType: TToastTypes
    toastDuration: number
  }
  showToast: boolean
}

const initialState: IToastState = {
  toastInfo: {
    toastContent: "",
    toastType: "success",
    toastDuration: 3,
  },
  showToast: false,
}

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setShowToast(state, action) {
      state.showToast = action.payload.status
    },
    setToastInfo(state, action) {
      state.toastInfo = {
        ...state.toastInfo,
        ...action.payload.toastInfo,
      }
    },
  },
})

export const toastActions = toastSlice.actions

export default toastSlice
