/* eslint-disable no-duplicate-imports */
import React from "react"
import type { DatePickerProps } from "antd"
import { DatePicker as AntdDatePicker } from "antd"
import "./style.scss"

export default function DatePicker({
  label,
  optional,
  value,
  isError,
  placeholder,
  onChange,
  onBlur,
  disabledDate,
}: {
  label?: string
  optional?: boolean
  value: DatePickerProps["value"]
  isError?: boolean
  placeholder?: string
  onChange?: DatePickerProps["onChange"]
  onBlur?: () => void
  disabledDate?: DatePickerProps["disabledDate"]
}) {
  return (
    <div className="date-picker-container">
      <label className="custom-date-picker-label">
        {label}
        {optional ? <span>Optional</span> : null}
      </label>
      <div className="custom-date-picker-wrapper">
        {/* <input
          type="text"
          className={`custom-date-picker ${value.length ? "custom-date-picker-filled" : ""} ${
            isError ? "custom-date-picker-error" : ""
          }`}
          placeholder={placeholder || ""}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        /> */}
        <AntdDatePicker
          onChange={onChange}
          placeholder={placeholder || "Select date"}
          disabledDate={disabledDate}
          value={value}
          className={value ? "custom-date-picker-filled" : ""}
        />
        {/* <div className="custom-date-picker-right-wrapper">
      {isError ? (
        <div className="custom-date-picker-error-wrapper">
          <div className="error-tooltip">this doesn&apos;t seem right</div>
          <img src={inputErrorIcon} alt="" />
        </div>
      ) : null}
    </div> */}
      </div>
    </div>
  )
}
