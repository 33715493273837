import React, { ReactNode } from "react"
import { useAccount } from "wagmi"
import { useAppSelector } from "../../hooks/reduxHooks"
import Header from "../../components/Header"
import EmptyPageScreen from "../../components/EmptyPageScreen"
import "./style.scss"

export default function AuthWrapper({ children }: { children: ReactNode }) {
  const { isConnected } = useAccount()
  const jwt = useAppSelector((state) => state.auth.jwt)

  const isSignedIn = isConnected && jwt

  console.count("render in auth")

  if (!isSignedIn) {
    return (
      <div className="auth-not-connected-wrapper">
        <Header />
        {/* <div className="-view-empty-screen-wrapper"> */}
        <EmptyPageScreen />
        {/* </div> */}
      </div>
    )
  }

  return <div className="auth-wrapper">{children}</div>
}
