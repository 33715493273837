import "./style.scss"
import militaryIcon from "../../assets/icons/militaryTechIcon.svg"
import awardStarIcon from "../../assets/icons/awardStarIcon.svg"
import { ReactComponent as DangerousIcon } from "../../assets/icons/dangerousIcon.svg"
import { ReactComponent as ArrowForwardIcon } from "../../assets/icons/arrowForwardIcon.svg"
import { ReactComponent as ArrowOutwardIcon } from "../../assets/icons/arrowOutwardIcon.svg"
import { ReactComponent as EditIcon } from "../../assets/icons/editIcon.svg"
import { ReactComponent as LinkOffIcon } from "../../assets/icons/linkOff.svg"
import { ReactComponent as BackspaceIcon } from "../../assets/icons/backspace.svg"
import { ReactComponent as WidgetsIcon } from "../../assets/icons/widgetsIcon.svg"
import { ReactComponent as InventoryIcon } from "../../assets/icons/inventoryIcon.svg"
import { ReactComponent as VerticalMenuIcon } from "../../assets/icons/verticalMenuIcon.svg"
import { ReactComponent as UploadFile } from "../../assets/icons/upload_file.svg"
import { ReactComponent as CopyIcon } from "../../assets/icons/ContentCopy.svg"
import Marquee from "react-fast-marquee"
import {
  IOverviewPageQuests,
  QuestWithCustomFieldsAndRewards,
  TNonStrategyTask,
  TSubmitFormTaskData,
  isNonStrategyTask,
} from "../../types/quest"
import pluralize from "pluralize"
import XpBadgeSnapShot from "../XpBadgeSnapshot"
import { useEffect, useState, useRef } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import { fetchActiveQuestsAndCampaigns, fetchSubmitForms } from "../../store/actions/overviewAction"
import animationLoadingData from "../../assets/lottie/buttonLoader.json"
import Lottie from "react-lottie"
import { ClientToolBaseUrl } from "../../constant/constantValues"
import { createPortal } from "react-dom"
import SubmitAdapterModal from "../SubmitAdapterModal"
import {
  editQuest,
  duplicateQuest,
  publishQuestApi,
  setIsInternalNavigation,
  unPublishQuestApi,
  updateQuestActiveStatus,
  openCsvModal,
} from "../../store/actions/questCreation"
import { Badge, Popover, message } from "antd"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import UploadCSVModal from "../UploadCSVModal"
import { questCreationActions } from "../../store/reducers/questCreationSlice"
import InfoPopup from "../InfoPopup"

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationLoadingData,
  renderer: "svg",
}

export default function CampaignCard({
  campaignInfo,
  openSubmission,
}: {
  campaignInfo: IOverviewPageQuests
  openSubmission?: (modalInfo: QuestWithCustomFieldsAndRewards) => void
}) {
  const [playingMarquee, setPlayingMarquee] = useState(false)
  const [isPublishing, setIsPublishing] = useState(false)
  const [isActivating, setIsActivating] = useState(false)
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [csvQuestId, setCsvQuestId] = useState("")
  const [isUnpublishModalOpen, setIsUnpublishModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  // const [isSubmit, setIsSubmit] = useState(false)
  const [openSubmitModal, setOpenSubmitModal] = useState(false)
  const isCsvModalOpen = useAppSelector((state) => state.questCreation.isCsvModalOpen)
  const popoverRef = useRef<HTMLDivElement>(null)
  // const formUuid = campaignInfo?.task_groups?.flatMap(
  //   (group) =>
  //     group?.tasks
  //       ?.filter(
  //         (task) =>
  //           isNonStrategyTask(task) &&
  //           task.task_type === "SUBMIT_FORM" &&
  //           "submit_form_uuid" in task.task_data,
  //       )
  //       .map((task) => {
  //         if (isNonStrategyTask(task)) {
  //           return task.task_data?.submit_form_uuid
  //         }
  //         return null
  //       }),
  // )[0]
  const formUuid = campaignInfo?.submit_form_uuid
  // const CSVTaskGroups = campaignInfo?.task_groups.filter(
  //   (group) => group?.tasks?.some((task) => task.adapter?.adapter_type === "CSV"),
  // )
  const CSVTaskGroups = campaignInfo?.csv_task_uuid_arr
  const allSubmitFormData = useAppSelector((state) => state.overview.submitFormInfo)
  const submitFormData = allSubmitFormData && formUuid ? allSubmitFormData[formUuid] : []
  const notificationCount = campaignInfo?.unseen_form_submission_count
  // submitFormData
  //   ? submitFormData.filter((data) => data.is_seen === false).length
  //   : 0
  // const [notificationCount, setNotificationCount] = useState(0);
  const selectedCommunity = useAppSelector((state) => state.overview.selectedCommunity)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const publishCampaign = async () => {
    setIsPublishing(true)
    const response = await dispatch(
      publishQuestApi(
        campaignInfo?.uuid,
        {
          questName: campaignInfo?.name,
          questDescription: campaignInfo?.description,
          questDisplayUrl: campaignInfo?.display_path,
          questEndDateActive: Boolean(campaignInfo?.end_date),
          questMetaImage: campaignInfo?.meta_img_url,
          questMetaImageFile: undefined,
          tiers: [],
          endDate: dayjs(campaignInfo?.end_date),
        },
        campaignInfo?.is_campaign,
      ),
    )
    setIsPublishing(false)
    if (response.status === "success" && selectedCommunity) {
      dispatch(fetchActiveQuestsAndCampaigns(selectedCommunity?.name))
    } else {
      message.error("something went wrong, please try again!")
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        hidePopover()
      }
    }
    if (popoverVisible) document.addEventListener("click", handleClickOutside)
    return () => document.removeEventListener("click", handleClickOutside)
  }, [popoverVisible])

  const hidePopover = () => {
    setPopoverVisible(false)
  }

  const getFormData = async (uuid: string) => {
    dispatch(fetchSubmitForms(uuid, "PENDING"))
  }

  // useEffect(() => {
  //   if (formUuid) {
  //     getFormData(formUuid)
  //   }
  // }, [formUuid])

  const handleQuestEdit = () => {
    dispatch(editQuest(campaignInfo?.uuid))
    dispatch(setIsInternalNavigation(true))
    navigate("/setup/quest")
  }

  const handleQuestDuplicate = () => {
    dispatch(duplicateQuest(campaignInfo?.uuid))
    dispatch(setIsInternalNavigation(true))
    navigate("/setup/quest")
  }

  const unpublishQuest = async () => {
    // setIsPublishing(true)
    const response = await dispatch(
      unPublishQuestApi(
        campaignInfo?.uuid,
        {
          questName: campaignInfo?.name,
          questDescription: campaignInfo?.description,
          questDisplayUrl: campaignInfo?.display_path,
          questEndDateActive: Boolean(campaignInfo?.end_date),
          questMetaImage: campaignInfo?.meta_img_url,
          questMetaImageFile: undefined,
          tiers: [],
          endDate: dayjs(campaignInfo?.end_date),
        },
        campaignInfo?.is_campaign,
      ),
    )
    // setIsPublishing(false)
    if (response.status === "success" && selectedCommunity) {
      setIsUnpublishModalOpen(false)
      dispatch(fetchActiveQuestsAndCampaigns(selectedCommunity?.name))
    } else {
      message.error("something went wrong, please try again!")
    }
  }

  const deactivateQuest = async () => {
    // setIsPublishing(true)
    const response = await dispatch(
      updateQuestActiveStatus(
        campaignInfo?.uuid,
        {
          questName: campaignInfo?.name,
          questDescription: campaignInfo?.description,
          questDisplayUrl: campaignInfo?.display_path,
          questEndDateActive: Boolean(campaignInfo?.end_date),
          questMetaImage: campaignInfo?.meta_img_url,
          questMetaImageFile: undefined,
          tiers: [],
          endDate: dayjs(campaignInfo?.end_date),
        },
        false,
      ),
    )
    // setIsPublishing(false)
    if (response.status === "success" && selectedCommunity) {
      setIsDeleteModalOpen(false)
      dispatch(fetchActiveQuestsAndCampaigns(selectedCommunity?.name))
    } else {
      message.error("something went wrong, please try again!")
    }
  }

  const activateQuest = async () => {
    setIsActivating(true)
    const response = await dispatch(
      updateQuestActiveStatus(
        campaignInfo?.uuid,
        {
          questName: campaignInfo?.name,
          questDescription: campaignInfo?.description,
          questDisplayUrl: campaignInfo?.display_path,
          questEndDateActive: Boolean(campaignInfo?.end_date),
          questMetaImage: campaignInfo?.meta_img_url,
          questMetaImageFile: undefined,
          tiers: [],
          endDate: dayjs(campaignInfo?.end_date),
        },
        true,
      ),
    )
    setIsActivating(false)
    if (response.status === "success" && selectedCommunity) {
      dispatch(fetchActiveQuestsAndCampaigns(selectedCommunity?.name))
    } else {
      message.error("something went wrong, please try again!")
    }
  }

  const popoverContent = () => {
    return (
      <div className="campaign-card-menu-wrapper">
        {/* <div className="campaign-card-menu-row">
          <div className="menu-row-left">View Details</div>
          <div className="menu-row-icon">
            <WidgetsIcon />
          </div>
        </div> */}
        <div className="campaign-card-menu-row" onClick={handleQuestEdit}>
          <div className="menu-row-left">Edit</div>
          <div className="menu-row-icon">
            <EditIcon />
          </div>
        </div>
        {formUuid && (
          <div
            className="campaign-card-menu-row"
            onClick={() => {
              getFormData(formUuid)
              setOpenSubmitModal(true)
              // openSubmission(formUuid, campaignInfo.is_repeat_quest, campaignInfo.uuid)
              hidePopover()
            }}
          >
            <div className="menu-row-left">
              {" "}
              View Submissions
              <div className="new-submissions-text-row">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <circle cx="6" cy="6" r="4" fill="#FF4E4D" />
                </svg>
                {notificationCount} new {pluralize("entry", notificationCount)}
              </div>
            </div>
            <div className="menu-row-icon">
              <InventoryIcon />
            </div>
          </div>
        )}
        {campaignInfo?.is_publish ? (
          <div className="campaign-card-menu-row" onClick={() => setIsUnpublishModalOpen(true)}>
            <div className="menu-row-left">Unpublish</div>
            <div className="menu-row-icon">
              <LinkOffIcon />
            </div>
          </div>
        ) : null}
        <div className="campaign-card-menu-row" onClick={handleQuestDuplicate}>
          <div className="menu-row-left">Duplicate</div>
          <div className="menu-row-icon">
            <CopyIcon />
          </div>
        </div>
        {campaignInfo?.is_active ? (
          <div className="campaign-card-menu-row" onClick={() => setIsDeleteModalOpen(true)}>
            <div className="menu-row-left">Deactivate</div>
            <div className="menu-row-icon">
              <BackspaceIcon />
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <div
      className="campaign-card-container"
      onMouseEnter={() => setPlayingMarquee(true)}
      onMouseLeave={() => setPlayingMarquee(false)}
    >
      <div className="campaign-card-top-row">
        <div className="campaign-card-images-wrapper">
          {campaignInfo.badge_img_arr?.length > 0 ? (
            <div className="image-wrapper1">
              <img src={campaignInfo.badge_img_arr[0] ?? ""} />
            </div>
          ) : (
            <XpBadgeSnapShot
              showcase={true}
              xpPoint={campaignInfo?.xp_range?.[1] ?? 0}
              showLottie={false}
            />
          )}
          {campaignInfo.badge_img_arr?.length > 1 ? (
            <div className="image-wrapper2">
              <img src={campaignInfo.badge_img_arr[1] ?? ""} />
            </div>
          ) : null}
        </div>
        <div className="campaign-card-top-row-right">
          {!campaignInfo?.is_active ? (
            <div className="deactivated-tag">
              <DangerousIcon />
              deactivated
            </div>
          ) : !campaignInfo?.is_publish ? (
            <div className="unpublished-tag">
              <DangerousIcon />
              draft
            </div>
          ) : null}
          <Badge count={notificationCount} offset={[-35, 5]}>
            <div ref={popoverRef}>
              <Popover
                content={popoverContent}
                trigger="click"
                placement="bottomRight"
                arrow={false}
                overlayClassName="campaign-card-more-menu-popover"
                open={popoverVisible}
              >
                <div
                  className="campaign-card-menu-trigger"
                  onClick={() => {
                    setPopoverVisible(true)
                  }}
                >
                  <VerticalMenuIcon />
                </div>
              </Popover>
              {openSubmitModal &&
                createPortal(
                  <SubmitAdapterModal
                    modalId={formUuid}
                    isRepeatable={campaignInfo.is_repeat_quest}
                    questUuid={campaignInfo.uuid}
                    handleCloseModal={() => setOpenSubmitModal(false)}
                  />,
                  document.body,
                )}
            </div>
          </Badge>
        </div>
      </div>

      <div className="campaign-details-row">
        <div className="campaign-tier-info">
          <img src={militaryIcon} alt="" />
          {campaignInfo.task_group_count} {pluralize("Tier", campaignInfo.task_group_count)}
        </div>
        <div className="campaign-xp-info">
          <img src={awardStarIcon} alt="" />
          {campaignInfo.xp_range?.[0]}
          {campaignInfo?.task_group_count > 1 ? `-${campaignInfo?.xp_range?.[1]}` : ""} XP
        </div>
      </div>
      <div className="campaign-name">{campaignInfo.name}</div>
      <div className="campaign-btn-container">
        <div className="campaign-link-btn">
          <button
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              window.open(
                `${ClientToolBaseUrl}/${selectedCommunity?.name}/quest/${campaignInfo?.display_path}`,
                "_blank",
              )
            }}
          >
            Open Link <ArrowOutwardIcon />
          </button>
        </div>
        {CSVTaskGroups?.length > 0 && (
          <div className="campaign-upload-csv-btn">
            <button
              onClick={(e) => {
                dispatch(openCsvModal(campaignInfo?.uuid))
                setCsvQuestId(campaignInfo.uuid)
                // setOpenCSVModal(true)
              }}
            >
              Upload CSV <UploadFile />
            </button>
          </div>
        )}
      </div>
      {campaignInfo?.is_active ? (
        campaignInfo?.is_publish ? (
          <div className="campaign-card-info-row">
            <div className="campaign-card-marquee">
              <Marquee speed={30} autoFill play={playingMarquee}>
                {` ${campaignInfo.participant_count} ${pluralize(
                  "Member",
                  campaignInfo.participant_count,
                )} / ${campaignInfo.upgrade_count} ${pluralize(
                  "Upgrade",
                  campaignInfo.upgrade_count,
                )} / ${campaignInfo.downgrade_count} ${pluralize(
                  "Downgrade",
                  campaignInfo.downgrade_count,
                )} /`}{" "}
                &nbsp;
              </Marquee>
              {/* <div className="marquee-shimmer"></div> */}
            </div>
          </div>
        ) : (
          <div className="campaign-card-publish-btn">
            <button onClick={publishCampaign} disabled={isPublishing}>
              <div className="publish-btn-left">Publish </div>
              {isPublishing ? (
                <Lottie options={lottieOptions} width={20} height={20} />
              ) : (
                <ArrowForwardIcon />
              )}
            </button>
          </div>
        )
      ) : (
        <div className="campaign-card-activate-btn">
          <button onClick={activateQuest} disabled={isActivating}>
            <div className="activate-btn-left">Activate Quest </div>
            {isActivating ? (
              <Lottie options={lottieOptions} width={20} height={20} />
            ) : (
              <ArrowForwardIcon />
            )}
          </button>
        </div>
      )}
      {isCsvModalOpen &&
        campaignInfo.uuid === csvQuestId &&
        createPortal(
          <UploadCSVModal
            closeModal={() => dispatch(questCreationActions.setIsCsvModalOpen(false))}
            // CSVTaskGroups={CSVTaskGroups}
            questId={csvQuestId}
          />,
          document.body,
        )}
      {isUnpublishModalOpen &&
        createPortal(
          <InfoPopup
            nextFunction={unpublishQuest}
            isDelete={false}
            closeModal={() => setIsUnpublishModalOpen(false)}
          />,
          document.body,
        )}
      {isDeleteModalOpen &&
        createPortal(
          <InfoPopup
            nextFunction={deactivateQuest}
            isDelete={true}
            closeModal={() => setIsDeleteModalOpen(false)}
          />,
          document.body,
        )}
    </div>
  )
}
