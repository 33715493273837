import React from "react"
import "./style.scss"
import { ReactComponent as ArrowForwardIcon } from "../../../assets/icons/arrowForwardIcon.svg"
import { IQuestAdapterTier } from "../../../types/quest"
import dayjs, { Dayjs } from "dayjs"
import pluralize from "pluralize"

export default function QuestPublishStep({
  tiers,
  questName,
  questDisplayUrl,
  endDate,
  questEndDateActive,
  setQuestCreationStep,
  openTestLink,
  publishQuest,
}: {
  tiers: IQuestAdapterTier[]
  questName: string
  questDisplayUrl: string
  endDate: Dayjs | null
  questEndDateActive: boolean
  setQuestCreationStep: (value: number) => void
  openTestLink: () => void
  publishQuest: () => void
}) {
  return (
    <div className="quest-publish-step-container">
      <div className="quest-publish-step-left">
        <div className="quest-publish-step-heading">
          <div className="quest-publish-step-heading-colored">Successfully</div> Created Quest
        </div>
        <div className="quest-publish-buttons-wrapper">
          <button onClick={openTestLink}>
            Quest Test Link <ArrowForwardIcon />
          </button>
          <button className="quest-publish-btn" onClick={publishQuest}>
            Publish on Community Space <ArrowForwardIcon />
          </button>
        </div>
      </div>
      <div className="quest-publish-step-right">
        <div className="quest-review-wrapper">
          <div className="review-section">
            <div className="review-section-header">
              <div>Quest Overview</div>
              {/* <div className="review-header-edit" onClick={() => setQuestCreationStep(1)}>
                Edit
              </div> */}
            </div>
            <div className="review-section-content">
              <div className="review-content-row">
                <div className="review-content-row-left">Quest Name</div>
                <div className="review-content-row-right">{questName}</div>
              </div>
              <div className="review-content-row">
                <div className="review-content-row-left">Display URL</div>
                <div className="review-content-row-right">{questDisplayUrl}</div>
              </div>
              {questEndDateActive ? (
                <div className="review-content-row">
                  <div className="review-content-row-left">End Date</div>
                  <div className="review-content-row-right">
                    {dayjs(endDate).format("DD MMM'YY")}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="review-section tier-review-section">
            <div className="review-section-header">
              <div>
                {tiers.length} {pluralize("Tier", tiers.length)}
              </div>
              {/* <div className="review-header-edit" onClick={() => setQuestCreationStep(2)}>
                Edit
              </div> */}
            </div>
            <div className="review-section-content">
              {tiers.map((tier, tierIndex) => (
                <div className="review-content-row" key={tierIndex}>
                  <div className="review-content-row-left">{tier.tierName}</div>
                  <div className="review-content-row-right">
                    {tier.adapters.length} {pluralize("Requirement", tier.adapters.length)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
