/* eslint-disable  @typescript-eslint/no-explicit-any */

import { AppDispatch, RootState } from ".."
import { routes } from "../../constant/routes"
import { Community, ICommunityAddingSocials, ICommunitySocial } from "../../types/community"
import apiClient from "../../utils/apiClient"
import { createDispatchResponse } from "../../utils/common"
import { overviewActions } from "../reducers/overviewSlice"
import { communityActions } from "../reducers/communitySlice"

export const addCommunitySocials = (
  communityName: string,
  communitySocials: ICommunitySocial[],
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_BASE_URL}${routes.community.baseUrl}/${communityName}/socials`,
        communitySocials,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if(response.status === 200){
        dispatch(communityActions.setCommunitySocials(response.data))
      }
    } catch (err) {
      console.error("error while adding community socials", err)
      return 0
    }
  }
}

export const updateCommunitySocials = (
  communityName: string,
  communitySocials: ICommunitySocial[],
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.put(
        `${process.env.REACT_APP_API_BASE_URL}${routes.community.baseUrl}/${communityName}/socials`,
        communitySocials,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if(response.status ===200){
        dispatch(communityActions.setCommunitySocials(response.data))
      }
    } catch (err) {
      console.error("error while adding community socials", err)
      return 0
    }
  }
}

export const createCommunity = (communityInfo: {
  name: string
  projectTwitterAccount: string
  website: string
  admin_name?: string
  admin_telegram?: string
}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const communityCreationInfo: { name: string; admin_name?: string; admin_telegram?: string } =
        {
          name: communityInfo?.name,
        }
      if (communityInfo?.admin_name?.length)
        communityCreationInfo.admin_name = communityInfo?.admin_name
      if (communityInfo?.admin_telegram?.length)
        communityCreationInfo.admin_telegram = communityInfo?.admin_telegram
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_BASE_URL}${routes.community.baseUrl}`,
        communityCreationInfo,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      console.log("response of creating community", response.status, response.data)
      if (response.status === 200) {
        dispatch(overviewActions.setSelectedCommunity(response.data))
        const communitySocials: ICommunitySocial[] = []
        if (communityInfo?.website.length) {
          communitySocials.push({
            account_name: communityInfo?.website.split("/").pop() || "",
            account_type: "website",
            account_url: communityInfo?.website,
          })
        }
        if (communityInfo?.projectTwitterAccount.length) {
          communitySocials.push({
            account_name: communityInfo?.projectTwitterAccount.split("/").pop() || "",
            account_type: "twitter",
            account_url: communityInfo?.projectTwitterAccount,
          })
        }
        if (communitySocials?.length) {
          await dispatch(addCommunitySocials(communityInfo.name, communitySocials))
        }
        return response.data
      }
      return 0
    } catch (err) {
      console.error("error while creating community", err)
      return 0
    }
  }
}

export const updateCommunity = (
  communityName: string,
  communityInfo: Partial<Community>,
  socialLinks: ICommunityAddingSocials[],
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.put(
        `${process.env.REACT_APP_API_BASE_URL}${routes.community.baseUrl}/${communityName}`,
        communityInfo,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.status !== 200) return createDispatchResponse("error", 0)

      dispatch(overviewActions.setSelectedCommunity(response.data))
      const socialLinksToUpdate = socialLinks.filter((socialLink) => socialLink.socialLinkUuid)
      const socialLinksToAdd = socialLinks.filter(
        (socialLink) => !socialLink.socialLinkUuid && socialLink.socialLinkValue.length,
      )
      if (socialLinksToAdd?.length) {
        await dispatch(
          addCommunitySocials(
            communityName,
            socialLinksToAdd.map((ele) => ({
              account_name: ele.socialLinkAccountName,
              account_type: ele.socialLinkName,
              account_url: ele.socialLinkValue,
            })),
          ),
        )
      }
      if (socialLinksToUpdate?.length) {
        await dispatch(
          updateCommunitySocials(
            communityName,
            socialLinksToUpdate.map((ele) => ({
              account_name: ele.socialLinkAccountName,
              account_type: ele.socialLinkName,
              account_url: ele.socialLinkValue,
              uuid: ele.socialLinkUuid || "",
            })),
          ),
        )
      }
      return createDispatchResponse("success", 1)
    } catch (err) {
      console.error("error while creating community", err)
      return createDispatchResponse("error", 0)
    }
  }
}

export const fetchAllCommunities = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const jwt = getState().auth.jwt
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}${routes.community.baseUrl}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      )
      if (response.status === 200) {
        dispatch(overviewActions.setAllCommunities(response.data))
        return 1
      }
      return 0
    } catch (err) {
      console.error("error while getting community", err)
      return 0
    }
  }
}
