import React from "react"
import animationLoadingData from "../../assets/lottie/pageLoader.json"
import Lottie from "react-lottie"
import "./style.scss"

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationLoadingData,
  renderer: "svg",
}

export default function LoadingScreen() {
  return (
    <div className="loading-screen-container">
      <Lottie options={lottieOptions} width={200} height={200} />
    </div>
  )
}
