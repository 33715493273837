import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Adapter } from "../../types/adapter"
import { Quest, QuestWithCustomFieldsAndRewards } from "../../types/quest"

interface QuestCreationState {
  availableAdapters: Adapter[] | null
  quests: Quest[] | null
  fetchingAbi: boolean
  isContractVerified: boolean
  isInternalNavigation: boolean
  isEditing: boolean
  isDuplicating: boolean
  selectedQuestInfo: QuestWithCustomFieldsAndRewards | null
  isCsvModalOpen: boolean
  isCreatingInsideCollection:boolean
}

const initialState: QuestCreationState = {
  availableAdapters: null,
  quests: null,
  fetchingAbi: false,
  isContractVerified: false,
  isInternalNavigation: false,
  isEditing: false,
  isDuplicating: false,
  selectedQuestInfo: null,
  isCsvModalOpen: false,
  isCreatingInsideCollection:false
}

export const questCreationSlice = createSlice({
  name: "questCreation",
  initialState,
  reducers: {
    setAvailableAdapters: (state, action: PayloadAction<Adapter[]>) => {
      state.availableAdapters = action.payload
    },
    setQuests: (state, action: PayloadAction<Quest[]>) => {
      state.quests = action.payload
    },
    setIsInternalNavigation: (state, action: PayloadAction<boolean>) => {
      state.isInternalNavigation = action.payload
    },
    setIsEditing: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload
    },
    setIsDuplicating: (state, action: PayloadAction<boolean>) => {
      state.isDuplicating = action.payload
    },
    setSelectedQuestInfo: (
      state,
      action: PayloadAction<QuestWithCustomFieldsAndRewards | null>,
    ) => {
      state.selectedQuestInfo = action.payload
    },
    setIsCsvModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isCsvModalOpen = action.payload
    },
    setIsCreatingInsideCollection: (state,action:PayloadAction<boolean>) =>{
      state.isCreatingInsideCollection = action.payload
    },
  },
})

export const questCreationActions = questCreationSlice.actions

export default questCreationSlice
